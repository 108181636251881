import React, {cloneElement} from 'react';
import {
    Datagrid,
    Filter,
    List, Pagination, RefreshButton,
    TextField,
    TextInput, TopToolbar
} from "react-admin";
import {defaultReSource, authorized} from '../../authorized'
import {getChannelName} from "../channels";
import {config} from "../../config";


const SettlementItemsFilters = (props) => (
    <Filter {...props} >
        <TextInput alwaysOn label="Settlement ID" source="deposit_id"/>
        <TextInput alwaysOn label="Order No" source="channel_order_no"/>
    </Filter>
);

/**
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const OMSPagination = props => <Pagination rowsPerPageOptions={[config.perPage]} {...props} />;

/**
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const ListActions = (props) => {
    return (
        <TopToolbar>
            {cloneElement(props.filters, {context: 'button'})}
            <RefreshButton/>
        </TopToolbar>
    );
};

/**
 *
 * @param props
 * @returns {*}
 * @constructor
 */
export const SettlementItemsReport = (props) => (
    <List {...props} filters={<SettlementItemsFilters/>}
          title="Settlement Items List"
          bulkActionButtons={false}
          actions={<ListActions/>}
          pagination={<OMSPagination/>}
          perPage={config.perPage}
          sort={{field: 'id', order: 'DESC'}}>
        <Datagrid>
            <PostedDate label="Posted date" source="posted_date"/>
            <LinkToOrder label="Order No" source="channel_order_no"/>
            <Fees label="Fees" source="channel_order_no"/>
            <Total label="Amount" source="total_settled"/>
            <TextField label="Amount Type" source="amount_type"/>
            <ChannelName label="Marketplace" source="amount_type"/>
            <LinkToPayout label="Payout" source="deposit_id"/>
        </Datagrid>
    </List>
);

/**
 *
 * @param record
 * @returns {*}
 * @constructor
 */
const SettlementTitle = ({record = {payout: ""}}) => {
    return <span>{record ? `${record.payout}` : ''}</span>;
};

/**
 *
 * @param record
 * @returns {*}
 * @constructor
 */
const PostedDate = ({record = {posted_date: ""}}) => (
    <span className="pl-24">{record.posted_date}</span>
);

/**
 *
 * @param record
 * @returns {*}
 * @constructor
 */
const ChargeID = ({record = {charge_id: ""}}) => (
    <span className="pl-24">{record.charge_id}</span>
);

/**
 *
 * @param record
 * @returns {*}
 * @constructor
 */
const SettlementTotal = ({record = {total_amount: "", currency: ""}}) => (
    <span className="uppercase">{(record) ? record.total_amount + " " + record.currency : ""}</span>
);

/**
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const LinkToOrder = (props = {record: {sales_order_id: "", channel_order_no: ""}}) => (
    <a className="pl-24" target="_blank" rel="noopener noreferrer"
       href={'#order/' + props.record.sales_order_id + '/show'} className="a-customize"
       title="">{props.record.channel_order_no}</a>
);

/**
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const LinkToPayout = (props = {record: {sales_settlement_id: "", deposit_id: ""}}) => (
    <a className="pl-24" rel="noopener noreferrer" target="_blank"
       href={'#settlementReport/' + props.record.sales_settlement_id + '/show'} className="a-customize"
       title="">{props.record.deposit_id}</a>
);

/**
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const Fees = (props = {record: {fees: "", currency: ""}}) => (
    <span className="pl-24"
          className="uppercase">{(props.record) ? props.record.fees + " " + props.record.currency : ""}</span>
);

/**
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const Total = (props = {record: {total_settled: "", currency: ""}}) => (
    <span className="pl-24"
          className="uppercase">{(props.record) ? props.record.total_settled + " " + props.record.currency : ""}</span>
);

/**
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const ChannelName = (props = {record: {channel_id: ""}}) => (
    <span>{getChannelName(props.record.channel_id)}</span>
);
