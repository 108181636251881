import React, {Component} from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import {MenuItem, Select} from '@material-ui/core';

/**
 *
 */
export class OMSSelectInput extends Component {

    state = {
        value: null,
    };

    componentWillReceiveProps(nextProps) {
        if (nextProps.input.value !== this.props.input.value) {
            this.setState({value: nextProps.input.value});
        }
    }

    handleChange = (event, index, value) => {
        this.props.input.onChange(value);
        this.setState({value});
    };

    addAllowEmpty = choices => {
        if (this.props.allowEmpty) {
            return [
                <MenuItem value={null} key="null" primaryText=""/>,
                ...choices,
            ];
        }

        return choices;
    };

    renderMenuItem = choice => {
        const className = choice.className === undefined ? '' : choice.className;
        const {
            optionText,
            optionValue,
        } = this.props;
        const choiceName = get(choice, optionText);
        return (
            <MenuItem
                className={className}
                key={get(choice, optionValue)}
                primaryText={choiceName}
                value={get(choice, optionValue)}
            />
        );
    };

    selectionRenderer = (value, menuItem) => {
        const className = menuItem.props.className;
        const choiceName = menuItem.props.primaryText ;
        return (
            <MenuItem
                className={className}
                primaryText={choiceName}
                />
        );
    };

    render() {
        const {
            choices,
            elStyle,
            isRequired,
            label,
            meta,
            options,
            resource,
            source,
        } = this.props;

        const {touched, error} = meta;
        return (
            <Select
                value={this.state.value}
                floatingLabelText={
                    <span>
                    {label}{isRequired && ' *'}
                    </span>
                }
                onChange={this.handleChange}
                autoWidth
                style={elStyle}
                errorText={touched && error}
                selectionRenderer={this.selectionRenderer}
                {...options}
            >
                {this.addAllowEmpty(choices.map(this.renderMenuItem))}
            </Select>
        );
    }
}

OMSSelectInput.propTypes = {
    addField: PropTypes.bool.isRequired,
    allowEmpty: PropTypes.bool.isRequired,
    choices: PropTypes.arrayOf(PropTypes.object),
    elStyle: PropTypes.object,
    input: PropTypes.object,
    isRequired: PropTypes.bool,
    label: PropTypes.string,
    meta: PropTypes.object,
    options: PropTypes.object,
    optionText: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.func,
        PropTypes.element,
    ]).isRequired,
    optionValue: PropTypes.string.isRequired,
    resource: PropTypes.string,
    source: PropTypes.string,
    translateChoice: PropTypes.bool.isRequired,
};

OMSSelectInput.defaultProps = {
    addField: true,
    allowEmpty: false,
    choices: [],
    options: {},
    optionText: 'name',
    optionValue: 'id',
    translateChoice: false,
};

export default OMSSelectInput;
