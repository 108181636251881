import * as React from 'react';
import {
    List,
    Datagrid,
    TextField,
    Filter, Pagination, TopToolbar, RefreshButton
} from 'react-admin';
import {SelectInput, DateInput, TextInput} from 'react-admin';
import {getChannelName, channelStatus} from './channels';
import {channels, config} from "../config";
import FulfilmentIssueBtn from "./buttons/FulfillmentIssueBtn";
import {getFullName} from "../rest/common";
import {dateParser} from "./filters/dateParser";
import {warehouseFilter} from "../config";
import {cloneElement} from "react";
import {defaultReSource, authorized} from '../authorized'

/**
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const ListActions = (props) => {
    return (
        <TopToolbar>
            {cloneElement(props.filters, {context: 'button'})}
        </TopToolbar>
    );
};

/**
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const OrdersFulfilmentIssuesFilters = (props) => (
    <Filter {...props}>
        <SelectInput source="channel_id" choices={channels} optionText="name" optionValue="_id"/>
        <DateInput text="To date" source="created_at_to" parse={dateParser}/>
        <DateInput text="From date" source="created_at_from" parse={dateParser}/>
        <TextInput alwaysOn label="Order #" source="channel_order_no"/>
        <TextInput label="Sku" source="sku"/>
        <SelectInput label="Warehouse" source="warehouse_id" choices={warehouseFilter} optionValue="_id"
                     optionsText="name"/>
        <SelectInput label="Status" source="internal_status" choices={[
            {id: 'pending', name: 'pending'},
            {id: 'processing', name: 'processing'},
            {id: 'shipped', name: 'shipped'},
            {id: 'canceled', name: 'canceled'},
            {id: 'refunded', name: 'refunded'},
            {id: 'delivered', name: 'delivered'},
        ]}/>
        <TextInput text="Customer Name" source="customer_name"/>
        <TextInput text="Customer email" source="customer_email"/>
    </Filter>
);

/**
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const LinkToOrderShow = (props = {record: {id: 0, channel_order_no: ""}}) => {
    return (
        <a href={'#order/' + props.record.id + '/show?backTo=fulfillmentIssues'} className="a-customize"
           title="Click here to display details">{props.record.channel_order_no}</a>
    )
};

/**
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const OMSPagination = props => <Pagination rowsPerPageOptions={[config.perPage]} {...props} />;

/**
 *
 * @param props
 * @returns {*}
 * @constructor
 */
export const OrdersFulfillmentIssuesList = (props) => {

    if (authorized.permissions.indexOf('order.fulfillment_issues.list') === -1) {
        return (<span/>)
    }

    return (
        <List bulkActionButtons={false} title="Fulfillment Issues List" {...props}
              filters={<OrdersFulfilmentIssuesFilters/>}
              filter={{items_status: 'pending'}}
              actions={<ListActions/>}
              perPage={config.perPage}
              pagination={<OMSPagination/>}
              sort={{field: 'channel_created_at', order: 'DESC'}}>
            <Datagrid>
                <ChannelName label="Channel" source="channel_id"/>
                <TextField label="Created At" source="channel_created_at"/>
                <LinkToOrderShow label="Order #" source="channel_order_no"/>
                <TextField label="Status" source="internal_status"/>
                <FullName source="customer_name"/>
                <TextField source="customer_email"/>
                <OrderTotal label="Total" source="grand_total"/>
                <TextField label="Items" source="skus"/>
                <ExportWarehouse label="WAREHOUSE" source="warehouse_id"/>
            </Datagrid>
        </List>
    )
};

/**
 *
 * @param record
 * @returns {*}
 * @constructor
 */
const OrderTotal = ({record = {grand_total: 0, order_currency_code: ""}}) => (
    <span>{(record) ? record.grand_total + " " + record.order_currency_code : ""}</span>
);

/**
 *
 * @param record
 * @returns {*}
 * @constructor
 */
const ExportWarehouse = ({record = {id: 0}}) => {
    if (authorized.permissions.indexOf('order.fulfillment_issues.edit') === -1) {
        return (<span/>)
    }
    return (<FulfilmentIssueBtn orderId={record.id} data={record} force={true}/>)
};

/**
 *
 * @param record
 * @returns {*}
 * @constructor
 */
const ChannelName = ({record = {channel_id: ""}}) => (
    <span>{(record) ? getChannelName(record.channel_id) : record.channel_id}</span>
);

/**
 *
 * @param record
 * @returns {*}
 * @constructor
 */
const FullName = ({record}) => {
    return <span>{getFullName(record)}</span>;
};

