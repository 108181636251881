import React, {Component} from 'react';
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";


/**
 *
 */
class ShipmentLabelDownload extends Component {

    constructor(props) {
        super(props);
    };

    /**
     *
     * @param filename
     * @returns {string|*}
     */
    parseExtension(filename) {
        let array = filename.split('.');
        if (array.length === 0) {
            return '';
        }
        return array[array.length - 1];
    }

    /**
     *
     * @param link
     */
    openLink(link) {
        window.open(link)
    }

    /**
     *
     * @returns {*}
     */
    render() {
        const record = this.props.record;
        if (record.label_image_name === undefined ||
            record.status === 'canceled' ||
            record.label_image_name === "" ||
            record.label_image_name === null) {
            return (<span/>);
        }

        return (
            <Button className="px-10"
                    primary={true}
                    size={"small"}
                    variant={"outlined"}
                    color={"secondary"}
                    onClick={this.openLink.bind(this, record.label_image_name)}
            >
                Download Label
            </Button>
        );
    }
}

/**
 *
 * @type {{record: Requireable<object>}}
 */
ShipmentLabelDownload.propTypes = {
    record: PropTypes.object,
};

export default ShipmentLabelDownload;