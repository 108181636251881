import { laravelJsonServerClient } from './rest';
import { fetchUtils } from 'react-admin';
import { config } from './config.js'

const httpClient = (url, options = {}) => {
    const token = localStorage.getItem('token') || '';
    if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/json', Authorization: 'Bearer ' + token });
    }
    return fetchUtils.fetchJson(url, options);
}

export default laravelJsonServerClient(config.apiURL, httpClient);
