import React, {Component} from 'react';
import OriginalOrder from "./originalOrder";
import {getChannelName} from "../channels";
import {
    TextField,
    DateField,
    FileField
} from 'react-admin';
import {defaultReSource, authorized} from '../../authorized'
import {config} from "../../config";


class OrderInformation extends Component {
    constructor(props) {
        super(props);
    }

    render() {

        const record = this.props.record;
        let refundDate = "";
        let settlementItem = "";

        if (record.refund_date) {
            refundDate = <div className="card">
                <label>Refunded date</label>
                <DateField label="Refunded date" record={record} source="refund_date"/>
            </div>
        }


        return (
            <main className="orderDetail-summary clearAll">
                <div className="card">
                    <label>Channel</label>
                    <ChannelName record={record}/>
                </div>
                <OriginalOrder record={record}/>
                <div style={{clear:'left'}} />
                <div className="card">
                    <label>Order #</label>
                    <TextField label="Order #" record={record} source="channel_order_no"/>
                </div>

                <div className="card">
                    <label>Created date</label>
                    <DateField label="Created date" record={record} source="channel_created_at"/>
                </div>
                <div style={{clear:'left'}} />
                <div className="card">
                    <label>Customer email</label>
                    <TextField label="Customer email" record={record} source="customer_email"/>
                </div>
                <div className="card">
                    <label>Payment method</label>
                    <TextField label="Payment method" record={record} source="payment_method"/>
                </div>

                <div style={{clear:'left'}} />
                <div className="card">
                    <label>Status</label>
                    <TextField label="Status" record={record} source="status"/>
                </div>
                <div style={{clear:'left'}} />
                <div className="card">
                    <label>&nbsp;</label>
                    <IsGift record={record}/>
                </div>
                {refundDate}


            </main>
        );
    }
}




const ChannelName = ({record = {channel_id: "", channel_status: ""}}) => (
    <span>{(record) ? getChannelName(record.channel_id, record.channel_status ) : record.channel_id }</span>
);

const IsGift = ({record = {is_gift: 0}}) => (
    <span>{(record.is_gift == 1) ? 'This is a gift' : ''}</span>
);

export default OrderInformation;