import React, {Component} from 'react';
import {Dialog, CardActions, DialogActions, Button, DialogTitle, DialogContent} from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import EditIcon from '@material-ui/icons/Edit';
import {
    SimpleForm,
    NumberInput,
    TextInput,
    UPDATE, GET_ONE,
    refreshView as refreshViewAction, GET_LIST, SelectInput
} from "react-admin";
import laravelJsonServerClient from '../../restClient'
import {connect} from 'react-redux';
import RichTextInput from "ra-input-rich-text";
import {authorized} from "../../authorized";
import OMSDeleteRecordBtn from "../buttons/OMSDeleteRecordBtn";

class EditProductBtn extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            open: false,
            dialog: false,
            values: null,
            allowSubmit: false,
            data: '',
            dataModels: []
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.validate = this.validate.bind(this);
    };

    validate(value) {
        const fields = [
            'name',
            'category',
            'brand',
            'manufacture',
            'upc',
            'ean',
            'cogs'
        ];
        const data = {};
        fields.forEach(field => {
            if (value.hasOwnProperty(field)) {
                data[field] = value[field];
            }
        });

        const requiredFields = [
            'name',
        ];

        let passed = true;
        requiredFields.forEach(field => {
            if (value[field] === undefined) {
                passed = false;
            }
            if (value.hasOwnProperty(field)) {
                if (value[field] === '' || value[field] === null) {
                    passed = false;
                }
            }
        });
        this.setState({values: value});
        this.setState({allowSubmit: passed});
    }

    fetchData() {
        laravelJsonServerClient(GET_ONE, 'product', {id: this.props.record.id}).then(r => {
            this.setState({
                data: r.data ? r.data : [],
            });
        });
        let reqParams = {
            filter: {pageSize: 1000, order: 'ASC', fields: 'id,name'},
            sort: {
                field: 'name',
                order: 'asc',
            }
        };
        laravelJsonServerClient(GET_LIST, 'product-model', reqParams).then(r => {
            this.setState({
                dataModels: r.data ? r.data : [],
            });
        });
    }

    handleSubmit() {
        const values = this.state.values;
        laravelJsonServerClient(UPDATE, 'product', {id: values.id, data: values}).then(
            r => {
                if (r.data.result) {
                    this.handleClose();
                    this.props.refreshView();
                } else {
                    this.handleClose();
                    alert(r.data.message);
                }
            }
        ).catch((e) => {
            console.error(e);
        });
    }

    showModal = () => {
        this.fetchData();
        this.setState({show: true});
    };

    handleClose = () => {
        this.setState({show: false});
    };

    render() {
        let record = this.props.record;
        const allowSubmit = !this.state.allowSubmit;
        const actions = [
            <Button
                label="Submit"
                key={'secondary'}
                primary="true"
                variant={"outlined"} color={"secondary"}
                size={"small"}
                startIcon={<SaveIcon/>}
                disabled={allowSubmit}
                onClick={this.handleSubmit}>Update</Button>,
            <Button
                label="Dismiss"
                primary="true"
                key={'primary'}
                size={"small"}
                color={"primary"}
                variant="outlined"
                onClick={this.handleClose}>Dismiss</Button>,
            <OMSDeleteRecordBtn unSelectAll={(this.props.unSelectAll !== undefined) ?? {}} permissionAllowed="product.delete" resource="product" {...this.props}/>
        ];

        let buttonEdit = '';
        if (record && authorized.permissions.indexOf('product.edit') !== -1) {
            buttonEdit = <Button
                label=""
                size="small"
                style={{overflow: 'inherit', color: '#000'}}
                onClick={this.showModal}
                startIcon={<EditIcon/>}>
                Edit
            </Button>
        }

        if (this.state.data !== '') {
            record = this.state.data;
        }

        return (
            <main>
                {buttonEdit}

                <Dialog
                    className="modal-edit-product"
                    title={record.sku}
                    actions={actions}
                    modal={true}
                    maxWidth={'sm'}
                    fullWidth={'true'}
                    open={this.state.show}
                    autoScrollBodyContent={true}
                >
                    <DialogTitle id="customized-dialog-title" onClose={this.handleClose}>
                        {record.name === undefined || record.name === null || record.name === "" ? record.sku: record.name}
                    </DialogTitle>
                    <DialogContent dividers>
                        <span>
                            <SimpleForm submitOnEnter={false}
                                        defaultValue={record}
                                        save={this.handleSubmit}
                                        validate={this.validate}
                                        toolbar={<CardActions/>} handleSubmit={this.handleClose}
                                        redirect={false}>
                                <TextInput fullWidth={true} disabled source="sku"/>
                                <TextInput fullWidth={true} source="name" isRequired={true}/>
                                <TextInput fullWidth={true} source="category"/>
                                <TextInput fullWidth={true} source="brand"/>
                                <TextInput fullWidth={true} source="manufacturer"/>
                                <TextInput fullWidth={true} source="upc" label="UPC"/>
                                <TextInput fullWidth={true} source="ean"/>
                                <TextInput fullWidth={true} source="product_line"/>
                                <NumberInput type="number" source="cogs" label="Cost of goods sold" className="w100 customInputNumber"/>
                                <SelectInput name="product_model_id" isRequired={true} label="Model"
                                             source="product_model_id"
                                             choices={this.state.dataModels}
                                             optionValue="id"
                                             fullWidth={true}
                                />
                                <RichTextInput source="description" style={{minHeight: "200px"}}
                                               fullWidth={true}/>
                            </SimpleForm>
                        </span>
                    </DialogContent>
                    <DialogActions>
                        {actions}
                    </DialogActions>
                </Dialog>
            </main>
        );
    }
}


export default connect(undefined, {refreshView: refreshViewAction})(
    EditProductBtn
);

