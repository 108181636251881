import * as React from 'react';
import {
    List,
    Datagrid,
    TextField,
    Filter,
    DateInput,
    SelectInput, TextInput, NumberInput, RichTextField,
    BooleanInput, RefreshButton, TopToolbar, Pagination, SimpleShowLayout, Show, ShowButton
} from 'react-admin';
import {config, warehouseFilter} from "../config";
import {Create, Edit, required, SimpleForm, CreateButton} from "react-admin";
import EditInventoryBtn from "./inventory/editInventoryBtn";
import {authorized} from '../authorized'
import {makeStyles} from '@material-ui/core/styles';
import {Card} from "@material-ui/core";
import {TransactionActions, TransactionPagination} from "./transactions";
/**
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const InventoryFilters = (props) => (
    <Filter {...props}>
        <DateInput text="To date" source="created_at_to"/>
        <DateInput text="From date" source="created_at_from"/>
        <TextInput alwaysOn text="Sku" source="sku"/>
        <SelectInput alwaysOn label="Warehouse" source="warehouse_id" choices={warehouseFilter} optionValue="_id"
                     optionsText="name"/>
        <BooleanInput label="In stock?" source="instock" alwaysOn filterdefaultvalues="true"/>
        <BooleanInput label="Archive?" source="archive" alwaysOn filterdefaultvalues="false"/>
    </Filter>
);


/**
 *
 * @param resource
 * @param filters
 * @param displayedFilters
 * @param filterValues
 * @param basePath
 * @param showFilter
 * @returns {*}
 * @constructor
 */
const InventoryActions = ({resource, filters, displayedFilters, filterValues, basePath, showFilter}) => (
    <TopToolbar style={cardActionStyle}>
        {filters && React.cloneElement(filters, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: 'button'
        })}

        {(authorized.permissions.indexOf('product.create') !== -1 &&
            authorized.permissions.indexOf('inventory.transfer.create') !== -1 &&
            authorized.permissions.indexOf('inventory.routing.create') !== -1) ?
            <CreateButton basePath={basePath}/> : ''}
    </TopToolbar>
);

const InventoryPagination = props => <Pagination rowsPerPageOptions={[config.perPage]} {...props} />;
/**
 *
 * @param props
 * @returns {*}
 * @constructor
 */

const TransactionFilters = (props) => (
    <Filter {...props}>
        <DateInput text="To date" source="created_at_to"/>
        <DateInput text="From date" source="created_at_from"/>
        <SelectInput alwaysOn label="Warehouse" source="warehouse_id" choices={warehouseFilter} optionValue="_id"
                     optionsText="name"/>
    </Filter>
);


export const InventoryList = (props) => {
    if (authorized.permissions.indexOf('inventory.list') === -1) {
        return (<span/>)
    }
    return (
        <span>
        <List {...props} actions={<InventoryActions/>}
              filters={<InventoryFilters/>} perPage={config.perPage}
              filterDefaultValues={{instock: true, archive: false}}
              bulkActionButtons={false}
              pagination={<InventoryPagination/>}
              sort={{field: 'id', order: 'DESC'}}>
            <Datagrid>
                <SkuText label="SKU" source="sku"/>
                <WarehouseSkuText label="Warehouse SKU" source="warehouse_sku"/>
                <TextField label="Warehouse" source="warehouse_name"/>
                <TextField label="Qty" source="qty"/>
                <TextField label="Available Qty" source="available_qty"/>
                <TextField label="In Transit" source="in_transit"/>
                <EditInventoryBtn/>
            </Datagrid>
        </List>
    </span>
    )
};


export const InventoryShow = (props) => {
    if (authorized.permissions.indexOf('inventory.log.list') === -1) {
        return (<span/>)
    }

    return (
        <main className="settlement">
            <Card>
                <main className="orderDetail-summary clearAll">
                    <Show  title={<InventoryTitle/>} {...props}>
                        <SimpleShowLayout>
                            <div className="card">
                                <label>Warehouse</label>
                                <TextField label="Warehouse" source="warehouse_name"/>
                            </div>
                            <div className="card">
                                <label>In Transit</label>
                                <TextField label="In Transit" source="in_transit"/>
                            </div>
                            <div className="card">
                                <label>Qty</label>
                                <TextField label="Qty" source="qty"/>
                            </div>
                            <div className="card">
                                <label>Available Qty</label>
                                <TextField label="Available Qty" source="available_qty"/>
                            </div>
                        </SimpleShowLayout>
                    </Show>
                </main>
            </Card>
            <div style={{'padding': '15px 0 0 0'}}>
                <List {...props}
                      resource={'transactions'}
                      filters={<TransactionFilters/>}
                      bulkActionButtons={false}
                      actions={<TransactionActions/>}
                      pagination={<TransactionPagination/>}
                      filter={{product_inventory_id: props.match.params.id}}
                      title={" "} perPage={config.perPage}
                      sort={{field: 'created_at', order: 'DESC'}}>
                    <Datagrid>
                        <TextField label="Date" source="created_at"/>
                        <TextField label="SKU" source="sku"/>
                        <TextField label="Warehouse" source="warehouse_name"/>
                        <TextField label="Qty" source="qty"/>
                        <TextField label="Available Qty" source="available_qty"/>
                        <TextField label="Disposition" source="disposition_message"/>
                        <ShowOrder label="Order"/>
                    </Datagrid>
                </List>
            </div>
        </main>
    );
}


/**
 *
 * @param record
 * @returns {*}
 * @constructor
 */
const InventoryTitle = ({record = {sku: ""}}) => {
    return <span>{record ? `${record.sku}` : ''}</span>;
};

const ShowOrder = ({record = {order_id: null}}) => {
    return (record.order_id != null) ?
        <ShowButton resource="Order" record={{id: record.order_id}} basePath={"/order"}/> : "";
};

/**
 *
 * @param record
 * @returns {*}
 * @constructor
 */
const SkuText = ({record = {sku: ""}}) => {
    if (authorized.permissions.indexOf('inventory.log.list') === -1) {
        return (<span className="sku-text">{record.sku}</span>)
    }

    return (<span>
        {record.sku ?
            <a rel="noopener noreferrer" target="_blank" href={'#inventory/' + record.id + '/show'}
               className="a-customize"
               title="Click here to display details">
                {record.sku}</a> : ''}</span>)
};


/**
 *
 * @param record
 * @returns {*}
 * @constructor
 */
const WarehouseSkuText = ({record = {warehouse_sku: ""}}) => (
    <span className="sku-text">{record.warehouse_sku}</span>
);


/**
 * @type {{display: string, float: string, zIndex: number}}
 */
const cardActionStyle = {
    zIndex: 2,
    display: 'inline-block',
    float: 'right',
};

/**
 *
 * @type {*[]}
 */
export const warehouseAllowAddInventoryFilter = warehouseFilter.filter((item) => item.add !== false);
/**
 *
 * @param props
 * @returns {*}
 * @constructor
 */
export const InventoryCreate = (props) => {
    const classes = useSidebarStyles();

    return (
        <span>
            {(authorized.permissions.indexOf('product.create') !== -1 &&
                authorized.permissions.indexOf('inventory.transfer.create') !== -1 &&
                authorized.permissions.indexOf('inventory.routing.create') !== -1) ?
                <Create title="Create Inventory" {...props}>
                    <SimpleForm redirect={'/inventory'}>
                        <TextInput source="sku" validate={required()}/>
                        <TextInput source="warehouse_sku"/>
                        <SelectInput source="warehouse_id" style={{minHeight: "30px", minWidth: "250px"}}
                                     validate={required()}
                                     className={classes.customWarehouse}
                                     choices={warehouseAllowAddInventoryFilter}
                                     options={{fullWidth: false, className: 'fullWidth-false'}}
                                     optionText="name" optionValue="_id"/>
                        <NumberInput source="qty" validate={required()} className={classes.customInventoryInput}/>
                        <NumberInput source="available_qty" className={classes.customInventoryInput}
                                     validate={required()}/>
                        <NumberInput className={classes.customInventoryInput} source="in_transit"/>
                    </SimpleForm>
                </Create>
                : ''}
    </span>
    );
};

const useSidebarStyles = makeStyles({
    customInventoryInput: {
        "& input[type='number']": {
            minHeight: "30px",
            padding: "27px 12px 10px"
        },
    },
    customWarehouse: {
        minWidth: "200px",
    }
});
