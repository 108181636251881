/**
 *
 * @type {*[]}
 */
import {channels} from "../config";


export const channelStatus = [
    { id: 'AFN', name: 'AFN'},
    { id: 'MFN', name: 'MFN' },
    { id: 'FBM', name: 'FBM'}
];

/**
 *
 * @param channelId
 * @returns {*}
 */
export function getChannelName(channelId, channel_status = null){
    let channelName = '';
    for (var i = 0, len = channels.length; i < len; i++) {
        if (channels[i]._id === channelId) {
            channelName += channels[i].name ;
        }
    }

    if(channel_status !== null && channel_status !== ''){
        channelName += ' (' ;
        channelName +=   channel_status === 'AFN' ? 'FBA' : channel_status ;
        channelName += ')';
    }

    return channelName;
}

/**
 *
 * @param channelId
 * @returns {boolean}
 */
export function isAmazonChannel(channelId){
    let isAmzChannel = _.find(channels, {_id: channelId, amz: true});
    return  isAmzChannel !== undefined;
}

/**
 *
 * @param channelId
 * @returns {boolean}
 */
export function isAllowCommentChannel(channelId) {
    let isAllowCommentChannel = _.find(channels, {_id: channelId, comment: true});
    return  isAllowCommentChannel !== undefined;
}

/**
 *
 * @param channelId
 * @returns {boolean}
 */
export function isReshipChannel(channelId) {
    return channelId === 1000;

}

/**
 *
 * @param channelId
 * @returns {boolean}
 */
export function isMagentoStore(channelId) {
    if (100 <= channelId && channelId <= 200) {
        return true;
    }
    return false;
}

/**
 *
 * @param channelId
 * @returns {boolean}
 */
export function isAllowPartialRefund(channelId) {
    // Darty, BestBuy CA, BestBuy CA M360
    if ([703, 706, 715].includes(channelId)) {
        return true;
    }

    if (isMagentoStore(channelId)) {
        return true;
    }

    return false;
}

/**
 *
 * @param channelId
 * @returns {boolean}
 */
export function isCancelAbleStores(channelId) {
    let isCancelAbleStores = _.find(channels, {_id: channelId, allow_cancel: true});
    return  isCancelAbleStores !== undefined;
}

/**
 *
 * @param channelId
 * @returns {boolean}
 */
export function isRefundAbleStores(channelId) {
    let isRefundAbleStores = _.find(channels, {_id: channelId, allow_refund: true});
    return  isRefundAbleStores !== undefined;
}

/**
 *
 * @param channelId
 * @returns {boolean}
 */
export function isReturnAbleStores(channelId) {
    let isReturnAbleStores = _.find(channels, {_id: channelId, returnRequest: true});
    return  isReturnAbleStores !== undefined;
}


/**
 *
 * @param channelId
 * @returns {boolean}
 */
export function isAbleAutoRefundStores(channelId) {
    let isAbleAutoRefundStores = _.find(channels, {_id: channelId, allow_auto_refund: true});
    return  isAbleAutoRefundStores !== undefined;
}


