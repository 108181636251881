import React, {Component} from 'react';
import {Dialog, CardActions, Button, DialogActions, DialogTitle, DialogContent} from '@material-ui/core';
import PropTypes from "prop-types";
import SaveIcon from '@material-ui/icons/Save';
import {
    SimpleForm,
    TextInput,
    required,
    email,
    BooleanInput,
    Edit,
    NumberInput,
    UPDATE,
    SelectInput,
} from "react-admin";
import laravelJsonServerClient from '../../../restClient'
import {countries} from "../../countries";
import Grid from '@material-ui/core/Grid';
import {defaultReSource, authorized} from '../../../authorized'

/**
 *
 */
class EditAddresses extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            data: null,
            values: null,
            id: '',
            allowSubmit: false,
            roles: '',
            permissions: ''
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.validate = this.validate.bind(this);

    }

    componentDidMount() {
    }

    handleSubmit() {
        const values = Object.assign({}, this.state.values);
        const sales_order_id = values.sales_order_id;
        values['id'] =  values['addressid'];

        laravelJsonServerClient(UPDATE, 'order/'+sales_order_id+'/address', {id: '', data: values}).then(
            r => {
                if (r.data.result) {
                    this.handleClose();
                } else {
                    this.handleClose();
                    alert('Update error!');
                }
            }
        ).catch((e) => {
            console.error(e);
        });
    }

    validate(value) {
        const fields = [
            'address1',
            'address2',
            'address3',
            'address_type',
            'channel_address_id',
            'channel_order_id',
            'channel_order_no',
            'city',
            'company',
            'country', 'email', 'fax',
            'firstname', 'is_commercial', 'is_pobox',
            'lastname', 'middlename', 'postcode',
            'region', 'sales_order_id', 'telephone', 'addressid'];
        const data = {};

        fields.forEach(field => {
            if (value.hasOwnProperty(field)) {
                data[field] = value[field];
            }
        });

        this.setState({values: data});

        const validate_fields = [
            'address1',
            'city',
            'country',
            'email',
            'firstname',
            'lastname',
            'postcode',
            ];

        let passed = true;
        validate_fields.forEach(field => {
            if (value.hasOwnProperty(field)) {
                if(value[field] === ''){
                    passed = false;
                }
            }
        });
        this.setState({allowSubmit: passed});
    }

    handleOpen = () => {
        this.setState({open: true});
    };

    handleClose = () => {
        this.setState({open: false});
        this.props.onCloseModal();
    };

    render() {
        const title = this.props.title;
        const open = this.props.open;
        const record = this.props.record;
        const allowSubmit = !this.state.allowSubmit

        const actions = [
            <Button
                label="Submit"
                primary="true"
                icon={<SaveIcon/>}
                variant={"outlined"} color={"secondary"}
                size={"small"}
                startIcon={<SaveIcon/>}
                disabled={allowSubmit}
                onClick={this.handleSubmit}
            >Submit</Button>,

            <Button
                label="Cancel"
                primary="true"
                disabled={false}
                key={'primary'}
                size={"small"}
                color={"primary"}
                variant="outlined"
                onClick={this.handleClose}
            >Cancel</Button>
        ];

        return (
            <Dialog
                bodyClassName={"modal-edit-address"}
                title={title}
                actions={actions}
                maxWidth={'sm'}
                modal={true}
                fullWidth={'true'}
                open={open}
                autoScrollBodyContent={true}
            >
                <DialogTitle id="customized-dialog-title" onClose={this.handleClose}>
                    Edit Address
                </DialogTitle>
                <DialogContent dividers>
                <span>
                    <SimpleForm submitOnEnter={false}
                                defaultValue={record}
                                save={this.handleSubmit}
                                validate={this.validate}
                                toolbar={<CardActions/>} handleSubmit={this.handleClose}
                                redirect={false}>
                        <Grid container spacing={3} style={{width: '100%'}}>
                             <Grid item xs={12} sm={6}>
                                    <TextInput style={{display: 'none'}} disabled source="sales_order_id"/>
                                    <TextInput source="email" validate={[required(), email()]}/>
                                    <TextInput source="firstname" validate={required()}/>
                                    <TextInput source="lastname" validate={required()}/>
                                    <TextInput source="middlename"/>
                                    <TextInput source="city" validate={required()}/>
                                    <TextInput source="region"/>
                                    <TextInput source="postcode" validate={required()}/>
                                    <BooleanInput label="Is PO Box Address" source="is_pobox"/>
                            </Grid>
                            <Grid item xs={12} sm={6}>

                                <SelectInput name="country" isRequired={true} validate={required()}
                                             source="country"
                                             choices={countries}
                                             optionValue="code"/>
                                <TextInput label="Address" source="address1" validate={required()}/>
                                <TextInput label="Second Address" source="address2"/>
                                <TextInput label="Third Address" source="address3"/>
                                <TextInput source="company"/>
                                <TextInput source="telephone"/>
                                <TextInput source="fax"/>
                                <BooleanInput label="Commersial Use" source="is_commercial"/>

                            </Grid>
                        </Grid>
                    </SimpleForm>
                </span>
                </DialogContent>
                <DialogActions>
                    {actions}
                </DialogActions>
            </Dialog>

        );
    }
}

EditAddresses.propTypes = {
    record: PropTypes.object,
    title: PropTypes.any,
    open: PropTypes.any,
    onCloseModal: PropTypes.func,
    hasEdit: PropTypes.any,
    hasList: PropTypes.any,
    hasShow: PropTypes.any,
    history: PropTypes.object,
    location: PropTypes.object,
    options: PropTypes.object,
    match: PropTypes.object,
    resource: PropTypes.string,
};

const EditActions = () => (
    <CardActions/>
);
export default EditAddresses;