import React, {Component} from 'react';
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import {authorized} from '../../authorized'
import { Link } from 'react-router-dom'
import EditIcon from '@material-ui/icons/Edit';


class EditRequestBtn extends Component {
    constructor(props) {
        super(props);
    };


    handleRequestClose = () => {
        this.setState({
            open: false,
        });
    };

    render() {
        if (authorized.permissions.indexOf('customer.returns_request.edit') === -1) {
            return false;
        }

        const { record } = this.props;
        const styles = {
            button: {
                marginLeft: 5,
                marginRight: 5,
            },
        };
        return (record !== undefined && record.status === 'pending' ? <span><Button
            component={Link}
            to={{pathname :"/returnsRequest/" + + record.id, search: "", state:{id: record.id}}}
            primary="true"
            startIcon={<EditIcon />}
            style={styles.button}
            size={"small"}
            className="display-block"
            variant="outlined" color="secondary"

        >Edit</Button></span>: <span />);
    }
}

EditRequestBtn.propTypes = {
    record: PropTypes.object,
};

export default EditRequestBtn;