import React, {Component} from 'react';
import {
    Table,
    TableBody,
    TableHead,
    TableRow,
    TableCell, Typography,
} from '@material-ui/core';
import {formatCurrency} from "../../rest/common";
import {getChannelName} from "../channels";
import {GET_LIST, Title} from "react-admin";
import laravelJsonServerClient from "../../restClient";
import Paper from '@material-ui/core/Paper';
import {defaultReSource, authorized} from '../../authorized'

/**
 *
 */
class SettlementItems extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: []
        }
    }

    componentDidMount() {
        this.fetchData();
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.fetchData();
    }

    fetchData() {
        laravelJsonServerClient(GET_LIST, 'settlementItemsReport', {
            filter: {channel_order_no: this.props.record.channel_order_no},
        }).then(r => {
            if (r.data !== undefined) {
                this.setState({data: r.data})
            }
        });
    }

    render() {
        const settlementItems = this.state.data;
        return (settlementItems.length ?
                <Paper style={{margin: '20px 0 0 0', padding: '10px'}} zdepth={2}>

                    <Typography gutterBottom variant="h5" component="h5">
                        Settlement Items
                    </Typography>
                    <Table selectable="false" >
                        <TableHead>
                            <TableRow>

                                <TableCell>Posted date</TableCell>
                                <TableCell>Transaction Type</TableCell>
                                <TableCell>Amount</TableCell>
                                <TableCell>Fees</TableCell>
                                <TableCell>Type</TableCell>
                                <TableCell>Marketplace</TableCell>
                                <TableCell>Payout</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {settlementItems.map((settlementItem) =>
                                <TableRow key={settlementItem.id}>
                                    <TableCell
                                        className="pl-24">{settlementItem.posted_date}</TableCell>
                                    <TableCell className="pl-24">{settlementItem.amount_type} </TableCell>
                                    <TableCell
                                        className="pl-24">{formatCurrency(settlementItem.amount, settlementItem.currency)}</TableCell>
                                    <TableCell
                                        className="pl-24">{formatCurrency(settlementItem.fees, settlementItem.currency)}</TableCell>
                                    <TableCell className="pl-24">{settlementItem.amount_description} {settlementItem.event_type}</TableCell>
                                    <TableCell
                                        className="pl-24">
                                        <span>{getChannelName(settlementItem.channel_id)}</span>
                                    </TableCell>
                                    <TableCell className="pl-24">
                                        <a className="pl-24" rel="noopener noreferrer" target="_blank"
                                           href={'#settlementReport/' + settlementItem.sales_settlement_id + '/show'}
                                           className="a-customize"
                                           title="">{settlementItem.deposit_id}</a>
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </Paper> : <span/>
        );

    }


}

export default SettlementItems;