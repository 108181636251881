import React, {Component} from 'react';
import {
    Table,
    TableBody,
    TableHead,
    TableRow,
    TableCell, Paper, Typography
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import laravelJsonServerClient from '../../restClient'
import {GET_LIST, Title} from 'react-admin';
import swal from "sweetalert";
import ShipmentLabelDownload from "../shipment/ShipmentLabelDownload";
import LabelCancel from "../shipment/LabelCancel";
import {defaultReSource, authorized} from '../../authorized'
import {connect} from "react-redux";

/**
 *
 */
class Shipments extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            id: '',
        }
    }

    componentDidMount() {
        this.setState({id: this.props.record.id});
        this.fetchData(this.props.record.id);
    }

    componentDidUpdate(prevprops, prevstate) {
        if (this.state.id !== prevprops.record.id) {
            this.setState({id: prevprops.record.id});
            this.fetchData(prevprops.record.id);
        }
    }


    fetchData(id) {
        laravelJsonServerClient(GET_LIST, 'shipment', {
            filter: {order_id: id},
        }).then(r => {
            this.setState({
                data: r.data,
            });
            let totalItemShipped = 0;
            this.state.data.forEach(function (k, v) {
                totalItemShipped += parseInt(k.qty_shipped);
            });

            this.disableCreateShipmentBtn(totalItemShipped);
        });
    }

    /**
     * If order items all are shipped (base on total qty shipped and orderItem in (exported, shipped, delivered)), hide the button create shipment
     */
    disableCreateShipmentBtn(totalItemShipped) {
        setTimeout(function () {
            if (parseInt(localStorage.getItem('itemsCanShip')) === 0 || parseInt(localStorage.getItem('totalItem')) === totalItemShipped) {
                let button = document.getElementById("btnCreateShipment");
                if (button !== null) {
                    button.style.display = 'none';
                }
            }
        }, 300);
    }

    /**
     * @param shipment
     */
    showPopupTrackingInformation(shipment) {
        let link = '', html = '', wrapper = document.createElement('div');
        if (shipment.carrier_tracking_url) {
            link = '<a rel="noopener noreferrer" target="_blank" href="' + shipment.carrier_tracking_url + '" title="Click to open in new tab" style="overflow-wrap: break-word;">' + shipment.carrier_tracking_url + '</a>';
        }
        html += '<p>- Shipment ID: ' + shipment.shipment_id + '</p>';
        html += '<p>- Cost: ' + shipment.shipping_cost + " " + shipment.shipping_currency + '</p>';
        html += '<p>- Package Size: ' + shipment.package_size + '</p>';
        html += '<p>- Carrier Name: ' + shipment.carrier_name + '</p>';
        html += '<p>- Carrier Code: ' + shipment.carrier_code + '</p>';
        html += '<p>- Carrier Tracking Number: ' + shipment.carrier_tracking_number + '</p>';
        html += '<p>- Carrier Tracking URL: ' + link + '</p>';
        wrapper.innerHTML = html;
        swal(wrapper,
            {
                title: 'Shipment information',
                buttons: {
                    cancel: "Close",
                },
                className: "swal-text-left"
            });
    }

    render() {
        const shipments = this.state.data;

        if (!shipments || shipments.length === 0) {
            return (
                <div>
                </div>
            )
        }

        const alingRight = {textAlign: 'right'};

        return (
            <Paper style={{margin: '20px 0 0 0', padding: '10px'}} zdepth={2}>
                <Typography gutterBottom variant="h5" component="h5">
                    Order Shipments
                </Typography>
                <Table selectable="false">
                    <TableHead>
                        <TableRow>
                            <TableCell>ID</TableCell>
                            <TableCell>Shipment ID</TableCell>
                            <TableCell>Date of shipping</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>SKUs</TableCell>
                            <TableCell style={alingRight}>QTY Shipped</TableCell>
                            <TableCell style={alingRight}>Cost</TableCell>
                            <TableCell style={alingRight}>Carrier</TableCell>
                            <TableCell style={alingRight}>Carrier Code</TableCell>
                            <TableCell style={alingRight}>Carrier Tracking Number</TableCell>
                            <TableCell style={alingRight}></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {shipments.map(shipment =>
                            <TableRow key={shipment.id}>
                                <TableCell className="pl-16">{shipment.id}</TableCell>
                                <TableCell>{shipment.shipment_id}</TableCell>
                                <TableCell>{shipment.created_at}</TableCell>
                                <TableCell>{shipment.status}</TableCell>
                                <TableCell>{shipment.skus}</TableCell>
                                <TableCell style={alingRight}>{shipment.qty_shipped}</TableCell>
                                <TableCell
                                    style={alingRight}>{shipment.shipping_cost} {shipment.shipping_currency}</TableCell>
                                <TableCell style={alingRight}>{shipment.carrier_name}</TableCell>
                                <TableCell style={alingRight}>{shipment.carrier_code}</TableCell>
                                <TableCell style={alingRight}><Button className="px-10"
                                                                      primary={true}
                                                                      size={"small"}
                                                                      variant={"outlined"}
                                                                      color={"secondary"}
                                                                      onClick={this.showPopupTrackingInformation.bind(this, shipment)}>
                                    {shipment.carrier_tracking_number}
                                </Button>
                                </TableCell>
                                <TableCell style={alingRight}>
                                    <ShipmentLabelDownload record={shipment}/>
                                    <LabelCancel record={shipment}/>
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </Paper>
        );
    }
}

export default Shipments;
