import React, {Component} from 'react';
import {
    SimpleShowLayout,
    TextField,
} from 'react-admin';
import {Title, ListButton} from "react-admin";
import laravelJsonServerClient from '../../restClient'
import {GET_ONE} from "react-admin";
import {defaultReSource, authorized} from '../../authorized'

/**
 *
 */
class SettlementInformation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
        }
    }

    componentDidMount() {
        this.fetchData();
    }

    fetchData() {
        laravelJsonServerClient(GET_ONE, 'settlementReport', {id: decodeURIComponent(this.props.match.params.id),}).then(r => {
            this.setState({
                data: r.data ? r.data : [],
            });
        });
    }

    render() {
        const record = this.state.data;
        if (!record || record.length === 0) {
            return (
                <div>
                </div>
            )
        }

        return (
            <main className="orderDetail-summary clearAll">
                <Title title={<SettlementTitle record={record}/>}/>
                <SimpleShowLayout>
                    <div className="card">
                        <label>Settlement ID</label>
                        <TextField label="Id #" record={record} source="id"/>
                    </div>
                    <div className="card">
                        <label>Provider</label>
                        <TextField label="Provider" record={record} source="payment_service"/>
                    </div>
                    <div className="card">
                        <label>Deposit date</label>
                        <TextField label="Deposit date" record={record} source="deposit_date"/>
                    </div>
                    <div className="card">
                        <label>Total amount</label>
                        <SettlementTotal record={record}/>
                    </div>

                </SimpleShowLayout>
            </main>
        );
    }
}

/**
 *
 * @type {{display: string, float: string, zIndex: number}}
 */
const cardActionStyle = {
    zIndex: 2,
    display: 'inline-block',
    float: 'right'
};

/**
 *
 * @param record
 * @returns {*}
 * @constructor
 */
const SettlementTitle = ({record = {payout: ""}}) => {
    return <span>{record ? `${record.payout}` : ''}</span>;
};

/**
 *
 * @param record
 * @returns {*}
 * @constructor
 */
const SettlementTotal = ({record = {total_amount: 0, currency: ""}}) => (
    <span className="uppercase">{(record) ? record.total_amount + " " + record.currency : ""}</span>
);

export default SettlementInformation;