import React, {Component} from 'react';
import PropTypes from "prop-types";
import { Link } from 'react-router-dom'


class OrderLink extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    };

    render() {
        const { record } = this.props;
        return (
            (record !== undefined) ?
                <Link to={{
                    pathname: `${"/order/" + record.sales_order_id + "/show"}`,
                    search: "",
                    state: {orderId: record.sales_order_id}
                }}> {record.channel_order_no} </Link>
                 : <span />);
    }
}

OrderLink.defaultProps = {
    addLabel: true,
};

OrderLink.propTypes = {
    addLabel: PropTypes.bool,
    label: PropTypes.string,
    record: PropTypes.object,
    source: PropTypes.string.isRequired,
};


export default OrderLink
