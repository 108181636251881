import React, {Component} from 'react';
import {SimpleForm, TextInput, CREATE, required} from 'react-admin';
import PropTypes from "prop-types";
import {Button, CardActions, Dialog, DialogActions, DialogContent, DialogTitle} from "@material-ui/core";
import laravelJsonServerClient from '../../restClient'
import {SelectInput} from "react-admin";
import swal from "sweetalert";
import {warehouseFilter} from "../../config";
import SaveIcon from "@material-ui/icons/Save";
import {defaultReSource, authorized} from '../../authorized'
/**
 *
 */
class FetchShipmentWarehouse extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openShipment: this.props.open,
            orderItem: this.props.orderItem,
            item: {},
            dialog: false,
            roles: '',
            permissions: '',
            warehouse_id: null
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.validate = this.validate.bind(this);
    };

    handleSubmit() {
        if (this.state.warehouse_id === undefined) {
            alert('Please select warehouse!');
            return;
            return;
        }

        let obj = {warehouse:  this.state.warehouse_id, id: this.state.orderItem.id, reference_id: this.state.orderItem.id};
        laravelJsonServerClient(CREATE, 'item/fetchshipment', {data: obj}).then(
            response => {
                let msg = response.data.json.message;
                swal(msg,
                    {
                        title: 'Message',
                        buttons: {
                            close: {
                                text: "CLOSE"
                            }
                        },
                    });

                if(msg === 'Shipment Found') {
                    setTimeout(function () {
                        window.location.reload();
                    }, 3000)
                }
            }
        ).catch((e) => {
            console.error(e);
        });
    }

    componentDidMount() {
        this.setState({openShipment:  this.props.openShipment});
    }

    componentWillReceiveProps(nextProps) {
        this.setState({openShipment: nextProps.open});
    }

    hideModal = () => {
        this.setState({openShipment: false});
        this.props.closeShipment();
    };

    validate(value) {
        this.setState({warehouse_id: value.warehouse_id});
    }

    render() {
        let _orderItems = this.props.orderItem;
        const actions = [
            <Button
                label="Find Shipment"
                primary="true"
                icon={<SaveIcon/>}
                variant={"outlined"} color={"secondary"}
                size={"small"}
                startIcon={<SaveIcon/>}
                onClick={this.handleSubmit}
            >Find Shipment</Button>,
            <Button
                label="Cancel"
                primary="true"
                disabled={false}
                key={'primary'}
                size={"small"}
                color={"primary"}
                variant="outlined"
                onClick={this.hideModal}
            >Cancel</Button>
        ];

        return (_orderItems ?
                <main>
                    <Dialog
                        bodyClassName={"modal-edit-address"}
                        title="Find Shipment"
                        actions={actions}
                        maxWidth={'sm'}
                        modal={true}
                        fullWidth={'false'}
                        open={this.state.openShipment}
                        autoScrollBodyContent={true}
                    >
                        <DialogTitle id="customized-dialog-title" onClose={this.handleClose}>
                            Find Shipment
                        </DialogTitle>
                        <DialogContent dividers>
                            <SimpleForm
                                validate={this.validate}
                                toolbar={<CardActions/>}
                                save={this.handleSubmit}
                                onSubmit={this.handleSubmit}
                                name="chanceWarehouseForm"
                                >
                                <SelectInput label="Warehouse"
                                             source="warehouse_id"
                                             choices={warehouseFindShipmentFilter} optionValue="_id"
                                             validate={required()}
                                             optionsText="name"/>
                                <TextInput options={{style: {display: 'none'}}} name="itemId" source="itemId" defaultValue={this.state.orderItem.id}/>

                            </SimpleForm>
                        </DialogContent>
                        <DialogActions>
                            {actions}
                        </DialogActions>
                    </Dialog>

                </main>
                : <span/>
        );
    }
}

FetchShipmentWarehouse.propTypes = {
    orderItem: PropTypes.object,
    open: PropTypes.bool,
    closeShipment: PropTypes.func
};

FetchShipmentWarehouse.defaultProps = {
    open: false,
};

export const warehouseFindShipmentFilter = warehouseFilter.filter((item) => item.findshipment === true);


export default FetchShipmentWarehouse;
