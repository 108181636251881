import React, {Component} from 'react';
import InfoIcon from '@material-ui/icons/Info';
import {Button} from "@material-ui/core";
import swal from "sweetalert";
import PropTypes from "prop-types";
import {defaultReSource, authorized} from '../../authorized'

/**
 *
 */
class ExportErrorMsgBtn extends Component {
    constructor(props) {
        super(props);
        this.state = {
            orderItem: this.props.orderItem,
            roles: '',
            permissions: ''
        };
    };

    handleClick = () => {
        let msg = this.state.orderItem.export_message ? this.state.orderItem.export_message : 'No message';
        swal(msg,
            {
                title: 'Message',
                buttons: {
                    close: {
                        text: "CLOSE"
                    }
                },
            });
    };

    render() {
        let item = this.props.orderItem;

        return ((item !== undefined && item.status === 'export_error') ?
                <span>
                    <Button className="textRed text-lc"
                                startIcon={<InfoIcon className="ml-5" />}
                                onClick={this.handleClick}
                                title={item.export_message}
                                label={item.status}
                    > {item.status} </Button>
                </span>
                : <span className='pr-10'>{item.status}</span>
        );
    }
}

ExportErrorMsgBtn.propTypes = {
    orderItem: PropTypes.object,
};

export default ExportErrorMsgBtn;
