import React, {Component} from 'react';
import Button from '@material-ui/core/Button';
import laravelJsonServerClient from '../../../restClient'
import {CREATE} from "react-admin";
import {Snackbar} from "@material-ui/core";
import PropTypes from "prop-types";
import LaunchIcon from "@material-ui/icons/Launch";

class ResendEmailBtn extends Component {
    constructor(props) {
        super(props);
        let location = window.location.hash.split('/');
        this.state = {
            autoHideDuration: 3000,
            open: false,
            returnId: location[2]
        };
    };

    handleClick = () => {
        this.setState({
            open: true,
        });

        laravelJsonServerClient(CREATE, 'returnsRequest/resend-email', {data: {reference_id: this.state.returnId}}).then().catch((e) => {
            console.error(e);
        });
    };

    handleRequestClose = () => {
        this.setState({
            open: false,
        });
    };

    render() {
        let showButton = true;
        if (this.props.record) {
            showButton = (this.props.record.status !== 'canceled' && this.props.record.status !== 'shipped' && this.props.record.status !== 'returned');
        }

        const styles = {
            button: {
                marginLeft: 5,
                marginRight: 5,
            },
        };

        return ((showButton === true) ? <span><Button
            onClick={this.handleClick}
            id="resend_email_button"
            variant="outlined" color="secondary"
            primary="true"
            style={styles.button}
            size={"small"}
            className="display-block"
            startIcon={<LaunchIcon/>}
        >
            <Snackbar
                open={this.state.open}
                message="Requested resend email."
                autoHideDuration={this.state.autoHideDuration}
                onClose={this.handleRequestClose}
            />Resend email</Button>
        </span> : <span/>);
    }
}

ResendEmailBtn.propTypes = {
    record: PropTypes.object,
};

export default ResendEmailBtn;
