export const countries = [
    {"code": "AF", "name": "AF - Afghanistan"},
    {"code": "AX", "name": "AX - Aland Islands"},
    {"code": "AL", "name": "AL - Albania"},
    {"code": "DZ", "name": "DZ - Algeria"},
    {"code": "AS", "name": "AS - American Samoa"},
    {"code": "AD", "name": "AD - Andorra"},
    {"code": "AO", "name": "AO - Angola"},
    {"code": "AI", "name": "AI - Anguilla"},
    {"code": "AQ", "name": "AQ - Antarctica"},
    {"code": "AG", "name": "AG - Antigua and Barbuda"},
    {"code": "AR", "name": "AR - Argentina"},
    {"code": "AM", "name": "AM - Armenia"},
    {"code": "AW", "name": "AW - Aruba"},
    {"code": "AU", "name": "AU - Australia"},
    {"code": "AT", "name": "AT - Austria"},
    {"code": "AZ", "name": "AZ - Azerbaijan"},
    {"code": "BS", "name": "BS - Bahamas"},
    {"code": "BH", "name": "BH - Bahrain"},
    {"code": "BD", "name": "BD - Bangladesh"},
    {"code": "BB", "name": "BB - Barbados"},
    {"code": "BY", "name": "BY - Belarus"},
    {"code": "BE", "name": "BE - Belgium"},
    {"code": "BZ", "name": "BZ - Belize"},
    {"code": "BJ", "name": "BJ - Benin"},
    {"code": "BM", "name": "BM - Bermuda"},
    {"code": "BT", "name": "BT - Bhutan"},
    {"code": "BO", "name": "BO - Bolivia"},
    {"code": "BQ", "name": "BQ - Bonaire, Saint Eustatius and Saba "},
    {"code": "BA", "name": "BA - Bosnia and Herzegovina"},
    {"code": "BW", "name": "BW - Botswana"},
    {"code": "BV", "name": "BV - Bouvet Island"},
    {"code": "BR", "name": "BR - Brazil"},
    {"code": "IO", "name": "IO - British Indian Ocean Territory"},
    {"code": "VG", "name": "VG - British Virgin Islands"},
    {"code": "BN", "name": "BN - Brunei"},
    {"code": "BG", "name": "BG - Bulgaria"},
    {"code": "BF", "name": "BF - Burkina Faso"},
    {"code": "BI", "name": "BI - Burundi"},
    {"code": "KH", "name": "KH - Cambodia"},
    {"code": "CM", "name": "CM - Cameroon"},
    {"code": "CA", "name": "CA - Canada"},
    {"code": "CV", "name": "CV - Cape Verde"},
    {"code": "KY", "name": "KY - Cayman Islands"},
    {"code": "CF", "name": "CF - Central African Republic"},
    {"code": "TD", "name": "TD - Chad"},
    {"code": "CL", "name": "CL - Chile"},
    {"code": "CN", "name": "CN - China"},
    {"code": "CX", "name": "CX - Christmas Island"},
    {"code": "CC", "name": "CC - Cocos Islands"},
    {"code": "CO", "name": "CO - Colombia"},
    {"code": "KM", "name": "KM - Comoros"},
    {"code": "CK", "name": "CK - Cook Islands"},
    {"code": "CR", "name": "CR - Costa Rica"},
    {"code": "HR", "name": "HR - Croatia"},
    {"code": "CU", "name": "CU - Cuba"},
    {"code": "CW", "name": "CW - Curacao"},
    {"code": "CY", "name": "CY - Cyprus"},
    {"code": "CZ", "name": "CZ - Czech Republic"},
    {"code": "CD", "name": "CD - Democratic Republic of the Congo"},
    {"code": "DK", "name": "DK - Denmark"},
    {"code": "DJ", "name": "DJ - Djibouti"},
    {"code": "DM", "name": "DM - Dominica"},
    {"code": "DO", "name": "DO - Dominican Republic"},
    {"code": "TL", "name": "TL - East Timor"},
    {"code": "EC", "name": "EC - Ecuador"},
    {"code": "EG", "name": "EG - Egypt"},
    {"code": "SV", "name": "SV - El Salvador"},
    {"code": "GQ", "name": "GQ - Equatorial Guinea"},
    {"code": "ER", "name": "ER - Eritrea"},
    {"code": "EE", "name": "EE - Estonia"},
    {"code": "ET", "name": "ET - Ethiopia"},
    {"code": "FK", "name": "FK - Falkland Islands"},
    {"code": "FO", "name": "FO - Faroe Islands"},
    {"code": "FJ", "name": "FJ - Fiji"},
    {"code": "FI", "name": "FI - Finland"},
    {"code": "FR", "name": "FR - France"},
    {"code": "GF", "name": "GF - French Guiana"},
    {"code": "PF", "name": "PF - French Polynesia"},
    {"code": "TF", "name": "TF - French Southern Territories"},
    {"code": "GA", "name": "GA - Gabon"},
    {"code": "GM", "name": "GM - Gambia"},
    {"code": "GE", "name": "GE - Georgia"},
    {"code": "DE", "name": "DE - Germany"},
    {"code": "GH", "name": "GH - Ghana"},
    {"code": "GI", "name": "GI - Gibraltar"},
    {"code": "GR", "name": "GR - Greece"},
    {"code": "GL", "name": "GL - Greenland"},
    {"code": "GD", "name": "GD - Grenada"},
    {"code": "GP", "name": "GP - Guadeloupe"},
    {"code": "GU", "name": "GU - Guam"},
    {"code": "GT", "name": "GT - Guatemala"},
    {"code": "GG", "name": "GG - Guernsey"},
    {"code": "GN", "name": "GN - Guinea"},
    {"code": "GW", "name": "GW - Guinea-Bissau"},
    {"code": "GY", "name": "GY - Guyana"},
    {"code": "HT", "name": "HT - Haiti"},
    {"code": "HM", "name": "HM - Heard Island and McDonald Islands"},
    {"code": "HN", "name": "HN - Honduras"},
    {"code": "HK", "name": "HK - Hong Kong"},
    {"code": "HU", "name": "HU - Hungary"},
    {"code": "IS", "name": "IS - Iceland"},
    {"code": "IN", "name": "IN - India"},
    {"code": "ID", "name": "ID - Indonesia"},
    {"code": "IR", "name": "IR - Iran"},
    {"code": "IQ", "name": "IQ - Iraq"},
    {"code": "IE", "name": "IE - Ireland"},
    {"code": "IM", "name": "IM - Isle of Man"},
    {"code": "IL", "name": "IL - Israel"},
    {"code": "IT", "name": "IT - Italy"},
    {"code": "CI", "name": "CI - Ivory Coast"},
    {"code": "JM", "name": "JM - Jamaica"},
    {"code": "JP", "name": "JP - Japan"},
    {"code": "JE", "name": "JE - Jersey"},
    {"code": "JO", "name": "JO - Jordan"},
    {"code": "KZ", "name": "KZ - Kazakhstan"},
    {"code": "KE", "name": "KE - Kenya"},
    {"code": "KI", "name": "KI - Kiribati"},
    {"code": "XK", "name": "XK - Kosovo"},
    {"code": "KW", "name": "KW - Kuwait"},
    {"code": "KG", "name": "KG - Kyrgyzstan"},
    {"code": "LA", "name": "LA - Laos"},
    {"code": "LV", "name": "LV - Latvia"},
    {"code": "LB", "name": "LB - Lebanon"},
    {"code": "LS", "name": "LS - Lesotho"},
    {"code": "LR", "name": "LR - Liberia"},
    {"code": "LY", "name": "LY - Libya"},
    {"code": "LI", "name": "LI - Liechtenstein"},
    {"code": "LT", "name": "LT - Lithuania"},
    {"code": "LU", "name": "LU - Luxembourg"},
    {"code": "MO", "name": "MO - Macao"},
    {"code": "MK", "name": "MK - Macedonia"},
    {"code": "MG", "name": "MG - Madagascar"},
    {"code": "MW", "name": "MW - Malawi"},
    {"code": "MY", "name": "MY - Malaysia"},
    {"code": "MV", "name": "MV - Maldives"},
    {"code": "ML", "name": "ML - Mali"},
    {"code": "MT", "name": "MT - Malta"},
    {"code": "MH", "name": "MH - Marshall Islands"},
    {"code": "MQ", "name": "MQ - Martinique"},
    {"code": "MR", "name": "MR - Mauritania"},
    {"code": "MU", "name": "MU - Mauritius"},
    {"code": "YT", "name": "YT - Mayotte"},
    {"code": "MX", "name": "MX - Mexico"},
    {"code": "FM", "name": "FM - Micronesia"},
    {"code": "MD", "name": "MD - Moldova"},
    {"code": "MC", "name": "MC - Monaco"},
    {"code": "MN", "name": "MN - Mongolia"},
    {"code": "ME", "name": "ME - Montenegro"},
    {"code": "MS", "name": "MS - Montserrat"},
    {"code": "MA", "name": "MA - Morocco"},
    {"code": "MZ", "name": "MZ - Mozambique"},
    {"code": "MM", "name": "MM - Myanmar"},
    {"code": "NA", "name": "NA - Namibia"},
    {"code": "NR", "name": "NR - Nauru"},
    {"code": "NP", "name": "NP - Nepal"},
    {"code": "NL", "name": "NL - Netherlands"},
    {"code": "NC", "name": "NC - New Caledonia"},
    {"code": "NZ", "name": "NZ - New Zealand"},
    {"code": "NI", "name": "NI - Nicaragua"},
    {"code": "NE", "name": "NE - Niger"},
    {"code": "NG", "name": "NG - Nigeria"},
    {"code": "NU", "name": "NU - Niue"},
    {"code": "NF", "name": "NF - Norfolk Island"},
    {"code": "KP", "name": "KP - North Korea"},
    {"code": "MP", "name": "MP - Northern Mariana Islands"},
    {"code": "NO", "name": "NO - Norway"},
    {"code": "OM", "name": "OM - Oman"},
    {"code": "PK", "name": "PK - Pakistan"},
    {"code": "PW", "name": "PW - Palau"},
    {"code": "PS", "name": "PS - Palestinian Territory"},
    {"code": "PA", "name": "PA - Panama"},
    {"code": "PG", "name": "PG - Papua New Guinea"},
    {"code": "PY", "name": "PY - Paraguay"},
    {"code": "PE", "name": "PE - Peru"},
    {"code": "PH", "name": "PH - Philippines"},
    {"code": "PN", "name": "PN - Pitcairn"},
    {"code": "PL", "name": "PL - Poland"},
    {"code": "PT", "name": "PT - Portugal"},
    {"code": "PR", "name": "PR - Puerto Rico"},
    {"code": "QA", "name": "QA - Qatar"},
    {"code": "CG", "name": "CG - Republic of the Congo"},
    {"code": "RE", "name": "RE - Reunion"},
    {"code": "RO", "name": "RO - Romania"},
    {"code": "RU", "name": "RU - Russia"},
    {"code": "RW", "name": "RW - Rwanda"},
    {"code": "BL", "name": "BL - Saint Barthelemy"},
    {"code": "SH", "name": "SH - Saint Helena"},
    {"code": "KN", "name": "KN - Saint Kitts and Nevis"},
    {"code": "LC", "name": "LC - Saint Lucia"},
    {"code": "MF", "name": "MF - Saint Martin"},
    {"code": "PM", "name": "PM - Saint Pierre and Miquelon"},
    {"code": "VC", "name": "VC - Saint Vincent and the Grenadines"},
    {"code": "WS", "name": "WS - Samoa"},
    {"code": "SM", "name": "SM - San Marino"},
    {"code": "ST", "name": "ST - Sao Tome and Principe"},
    {"code": "SA", "name": "SA - Saudi Arabia"},
    {"code": "SN", "name": "SN - Senegal"},
    {"code": "RS", "name": "RS - Serbia"},
    {"code": "SC", "name": "SC - Seychelles"},
    {"code": "SL", "name": "SL - Sierra Leone"},
    {"code": "SG", "name": "SG - Singapore"},
    {"code": "SX", "name": "SX - Sint Maarten"},
    {"code": "SK", "name": "SK - Slovakia"},
    {"code": "SI", "name": "SI - Slovenia"},
    {"code": "SB", "name": "SB - Solomon Islands"},
    {"code": "SO", "name": "SO - Somalia"},
    {"code": "ZA", "name": "ZA - South Africa"},
    {"code": "GS", "name": "GS - South Georgia and the South Sandwich Islands"},
    {"code": "KR", "name": "KR - South Korea"},
    {"code": "SS", "name": "SS - South Sudan"},
    {"code": "ES", "name": "ES - Spain"},
    {"code": "LK", "name": "LK - Sri Lanka"},
    {"code": "SD", "name": "SD - Sudan"},
    {"code": "SR", "name": "SR - Suriname"},
    {"code": "SJ", "name": "SJ - Svalbard and Jan Mayen"},
    {"code": "SZ", "name": "SZ - Swaziland"},
    {"code": "SE", "name": "SE - Sweden"},
    {"code": "CH", "name": "CH - Switzerland"},
    {"code": "SY", "name": "SY - Syria"},
    {"code": "TW", "name": "TW - Taiwan"},
    {"code": "TJ", "name": "TJ - Tajikistan"},
    {"code": "TZ", "name": "TZ - Tanzania"},
    {"code": "TH", "name": "TH - Thailand"},
    {"code": "TG", "name": "TG - Togo"},
    {"code": "TK", "name": "TK - Tokelau"},
    {"code": "TO", "name": "TO - Tonga"},
    {"code": "TT", "name": "TT - Trinidad and Tobago"},
    {"code": "TN", "name": "TN - Tunisia"},
    {"code": "TR", "name": "TR - Turkey"},
    {"code": "TM", "name": "TM - Turkmenistan"},
    {"code": "TC", "name": "TC - Turks and Caicos Islands"},
    {"code": "TV", "name": "TV - Tuvalu"},
    {"code": "VI", "name": "VI - U.S. Virgin Islands"},
    {"code": "UG", "name": "UG - Uganda"},
    {"code": "UA", "name": "UA - Ukraine"},
    {"code": "AE", "name": "AE - United Arab Emirates"},
    {"code": "GB", "name": "GB - United Kingdom"},
    {"code": "US", "name": "US - United States"},
    {"code": "UM", "name": "UM - United States Minor Outlying Islands"},
    {"code": "UY", "name": "UY - Uruguay"},
    {"code": "UZ", "name": "UZ - Uzbekistan"},
    {"code": "VU", "name": "VU - Vanuatu"},
    {"code": "VA", "name": "VA - Vatican"},
    {"code": "VE", "name": "VE - Venezuela"},
    {"code": "VN", "name": "VN - Vietnam"},
    {"code": "WF", "name": "WF - Wallis and Futuna"},
    {"code": "EH", "name": "EH - Western Sahara"},
    {"code": "YE", "name": "YE - Yemen"},
    {"code": "ZM", "name": "ZM - Zambia"},
    {"code": "ZW", "name": "ZW - Zimbabwe"}
];

/**
 *
 * @type {string[]}
 */
export const EUList = [
    'NLD', 'AUT', 'BEL', 'BGR', 'CYP', 'CZE', 'DNK', 'EST', 'FIN', 'FRA', 'DEU', 'GRC', 'HUN', 'ITA', 'LVA', 'LTU', 'LUX', 'MLT', 'POL', 'PRT', 'ROU', 'SVK', 'SVN', 'ESP', 'SWE'
];

export const country2To3ISOMapping = {
    AF: 'AFG',
    AX: 'ALA',
    AL: 'ALB',
    DZ: 'DZA',
    AS: 'ASM',
    AD: 'AND',
    AO: 'AGO',
    AI: 'AIA',
    AQ: 'ATA',
    AG: 'ATG',
    AR: 'ARG',
    AM: 'ARM',
    AW: 'ABW',
    AU: 'AUS',
    AT: 'AUT',
    AZ: 'AZE',
    BS: 'BHS',
    BH: 'BHR',
    BD: 'BGD',
    BB: 'BRB',
    BY: 'BLR',
    BE: 'BEL',
    BZ: 'BLZ',
    BJ: 'BEN',
    BM: 'BMU',
    BT: 'BTN',
    BO: 'BOL',
    BA: 'BIH',
    BW: 'BWA',
    BV: 'BVT',
    BR: 'BRA',
    VG: 'VGB',
    IO: 'IOT',
    BN: 'BRN',
    BG: 'BGR',
    BF: 'BFA',
    BI: 'BDI',
    KH: 'KHM',
    CM: 'CMR',
    CA: 'CAN',
    CV: 'CPV',
    KY: 'CYM',
    CF: 'CAF',
    TD: 'TCD',
    CL: 'CHL',
    CN: 'CHN',
    HK: 'HKG',
    MO: 'MAC',
    CX: 'CXR',
    CC: 'CCK',
    CO: 'COL',
    KM: 'COM',
    CG: 'COG',
    CD: 'COD',
    CK: 'COK',
    CR: 'CRI',
    CI: 'CIV',
    HR: 'HRV',
    CU: 'CUB',
    CY: 'CYP',
    CZ: 'CZE',
    DK: 'DNK',
    DJ: 'DJI',
    DM: 'DMA',
    DO: 'DOM',
    EC: 'ECU',
    EG: 'EGY',
    SV: 'SLV',
    GQ: 'GNQ',
    ER: 'ERI',
    EE: 'EST',
    ET: 'ETH',
    FK: 'FLK',
    FO: 'FRO',
    FJ: 'FJI',
    FI: 'FIN',
    FR: 'FRA',
    GF: 'GUF',
    PF: 'PYF',
    TF: 'ATF',
    GA: 'GAB',
    GM: 'GMB',
    GE: 'GEO',
    DE: 'DEU',
    GH: 'GHA',
    GI: 'GIB',
    GR: 'GRC',
    GL: 'GRL',
    GD: 'GRD',
    GP: 'GLP',
    GU: 'GUM',
    GT: 'GTM',
    GG: 'GGY',
    GN: 'GIN',
    GW: 'GNB',
    GY: 'GUY',
    HT: 'HTI',
    HM: 'HMD',
    VA: 'VAT',
    HN: 'HND',
    HU: 'HUN',
    IS: 'ISL',
    IN: 'IND',
    ID: 'IDN',
    IR: 'IRN',
    IQ: 'IRQ',
    IE: 'IRL',
    IM: 'IMN',
    IL: 'ISR',
    IT: 'ITA',
    JM: 'JAM',
    JP: 'JPN',
    JE: 'JEY',
    JO: 'JOR',
    KZ: 'KAZ',
    KE: 'KEN',
    KI: 'KIR',
    KP: 'PRK',
    KR: 'KOR',
    KW: 'KWT',
    KG: 'KGZ',
    LA: 'LAO',
    LV: 'LVA',
    LB: 'LBN',
    LS: 'LSO',
    LR: 'LBR',
    LY: 'LBY',
    LI: 'LIE',
    LT: 'LTU',
    LU: 'LUX',
    MK: 'MKD',
    MG: 'MDG',
    MW: 'MWI',
    MY: 'MYS',
    MV: 'MDV',
    ML: 'MLI',
    MT: 'MLT',
    MH: 'MHL',
    MQ: 'MTQ',
    MR: 'MRT',
    MU: 'MUS',
    YT: 'MYT',
    MX: 'MEX',
    FM: 'FSM',
    MD: 'MDA',
    MC: 'MCO',
    MN: 'MNG',
    ME: 'MNE',
    MS: 'MSR',
    MA: 'MAR',
    MZ: 'MOZ',
    MM: 'MMR',
    NA: 'NAM',
    NR: 'NRU',
    NP: 'NPL',
    NL: 'NLD',
    AN: 'ANT',
    NC: 'NCL',
    NZ: 'NZL',
    NI: 'NIC',
    NE: 'NER',
    NG: 'NGA',
    NU: 'NIU',
    NF: 'NFK',
    MP: 'MNP',
    NO: 'NOR',
    OM: 'OMN',
    PK: 'PAK',
    PW: 'PLW',
    PS: 'PSE',
    PA: 'PAN',
    PG: 'PNG',
    PY: 'PRY',
    PE: 'PER',
    PH: 'PHL',
    PN: 'PCN',
    PL: 'POL',
    PT: 'PRT',
    PR: 'PRI',
    QA: 'QAT',
    RE: 'REU',
    RO: 'ROU',
    RU: 'RUS',
    RW: 'RWA',
    BL: 'BLM',
    SH: 'SHN',
    KN: 'KNA',
    LC: 'LCA',
    MF: 'MAF',
    PM: 'SPM',
    VC: 'VCT',
    WS: 'WSM',
    SM: 'SMR',
    ST: 'STP',
    SA: 'SAU',
    SN: 'SEN',
    RS: 'SRB',
    SC: 'SYC',
    SL: 'SLE',
    SG: 'SGP',
    SK: 'SVK',
    SI: 'SVN',
    SB: 'SLB',
    SO: 'SOM',
    ZA: 'ZAF',
    GS: 'SGS',
    SS: 'SSD',
    ES: 'ESP',
    LK: 'LKA',
    SD: 'SDN',
    SR: 'SUR',
    SJ: 'SJM',
    SZ: 'SWZ',
    SE: 'SWE',
    CH: 'CHE',
    SY: 'SYR',
    TW: 'TWN',
    TJ: 'TJK',
    TZ: 'TZA',
    TH: 'THA',
    TL: 'TLS',
    TG: 'TGO',
    TK: 'TKL',
    TO: 'TON',
    TT: 'TTO',
    TN: 'TUN',
    TR: 'TUR',
    TM: 'TKM',
    TC: 'TCA',
    TV: 'TUV',
    UG: 'UGA',
    UA: 'UKR',
    AE: 'ARE',
    GB: 'GBR',
    US: 'USA',
    UM: 'UMI',
    UY: 'URY',
    UZ: 'UZB',
    VU: 'VUT',
    VE: 'VEN',
    VN: 'VNM',
    VI: 'VIR',
    WF: 'WLF',
    EH: 'ESH',
    YE: 'YEM',
    ZM: 'ZMB',
    ZW: 'ZWE',
    XK: 'XKX'
}

