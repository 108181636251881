import React, {Component} from 'react';
import {SimpleForm, TextInput, SelectInput, UPDATE, GET_LIST} from 'react-admin';
import PropTypes from "prop-types";
import laravelJsonServerClient from '../../restClient'
import OMSSelectInput from "../form/OMSSelectInput";
import {dynamicSort} from "../../rest/common"
import RefreshIcon from '@material-ui/icons/Refresh';
import {warehouseFilter} from "../../config";
import {Dialog, Snackbar, Button, DialogActions, DialogContent, DialogContentText} from '@material-ui/core';
import FulfillmentIssueBtn from "./FulfillmentIssueBtn";
import {defaultReSource, authorized} from '../../authorized'

/**
 *
 * @param handleClose
 * @param show
 * @param children
 * @returns {*}
 * @constructor
 */
const Modal = ({handleClose, show, children}) => {
    const showHideClassName = show ? "modal display-block" : "modal display-none";
    return (
        <div className={showHideClassName}>
            <section className="modal-main">
                <button onClick={handleClose} type="button" className="close btnClose"><span
                    aria-hidden="true">×</span></button>
                {children}
            </section>
        </div>
    );
};

/**
 *
 */
class ChangeSkuBtn extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            open: false,
            autoHideDuration: 5000,
            snackbarMessage: '',
            orderItem: this.props.orderItem,
            item: {},
            inventoryLoaded: false,
            warehouseIncSku: {},
            inventory: [],
            defaultSku: this.props.orderItem.sku,
            defaultValue: '',
            selectWarehouseDefault: this.props.orderItem.warehouse_id,
            dialog: false,
            roles: '',
            permissions: ''
        };

        this.loadInventory(this.props.orderItem.warehouse_id, false)
    };

    loadInventory = (warehouseId, showMgs) => {
        if (warehouseId) {
            let _this = this;
            laravelJsonServerClient(GET_LIST, 'inventory/warehouse/' + warehouseId, {}).then(
                r => {
                    let skuData = [];
                    let _i = 0;
                    let _defaultValue = 0;

                    if (r.data.length) {
                        let inSkus = false;
                        r.data.forEach(function (v) {
                            skuData[_i] = ({
                                id: v.id,
                                sku: v.sku,
                                skuQty: v.sku + ' (Qty:' + v.available_qty + ')'
                            });
                            if (v.sku === _this.state.orderItem.sku) {
                                inSkus = true;
                            }
                            _i++;
                        });

                        if (inSkus === false && _this.state.item.warehouse_id === warehouseId) {
                            _defaultValue = -1;
                            skuData[_i] = ({
                                id: _defaultValue,
                                sku: _this.state.orderItem.sku,
                                skuQty: _this.state.orderItem.sku + ' (Qty: 0)'
                            });
                        }
                    } else {
                        // There are no products available for this warehouseId
                        if (showMgs !== undefined && showMgs) {
                            this.setState({
                                snackbarMessage: 'There are no products available for this warehouse!',
                                open: true
                            });
                        }

                        _defaultValue = -2;
                        if (_this.state.item.warehouse_id === warehouseId) {
                            skuData[_i] = ({
                                id: _defaultValue,
                                sku: _this.state.orderItem.sku,
                                skuQty: _this.state.orderItem.sku + ' (Qty: 0)'
                            });
                        } else {
                            skuData[_i] = ({
                                id: _defaultValue, sku: '--', skuQty: '--'
                            });
                        }
                    }

                    let defaultSku = this.props.orderItem.sku;
                    skuData.forEach(function (k) {
                        if (defaultSku === k.sku) {
                            _defaultValue = k.id;
                            return false;
                        }
                    });

                    this.setState({
                        inventory: skuData.sort(dynamicSort("sku")),
                        inventoryLoaded: true,
                        defaultValue: _defaultValue
                    });
                }
            ).catch((e) => {
                console.error(e);
            });
        }
    };

    handleSubmit(values) {
        switch (values.sku) {
            case 0:
                this.setState({
                    snackbarMessage: 'Please select any sku',
                    open: true
                });
                return;
            case -2:
                this.setState({
                    snackbarMessage: 'Please select another warehouse and sku to save',
                    open: true
                });
                return;
        }

        let sku = '';
        this.state.inventory.forEach(function (k) {
            if (k.id === values.sku) {
                sku = k.sku;
                return false;
            }
        });

        if (!sku) {
            alert('Something\'s wrong!');
            return;
        }
        if (sku === this.props.orderItem.sku && this.props.orderItem.warehouse_id === values.warehouse) {
            this.setState({
                snackbarMessage: 'Please select another sku "' + this.props.orderItem.sku + '" to save',
                open: true
            });
            return;
        }

        laravelJsonServerClient(UPDATE, 'item/change/sku/itemId', {
            id: values.itemId,
            data: {sku: sku, warehouse: values.warehouse}
        }).then(
            r => {
                if (r.data.result) {
                    this.setState({dialog: true});
                } else {
                    this.setState({snackbarMessage: 'Update error!', open: true});
                }
                this.hideModal();
            }
        ).catch((e) => {
            console.error(e);
        });
    }

    onChangeWarehouse(event) {
        this.setState({selectWarehouseDefault: event.target.value});
        this.loadInventory(event.target.value, true);
    }

    handleSubmitWithRedirect() {
        this.props.updateListItem();
        this.hideModal();
    };

    showModal = () => {
        this.setState({
            show: true,
            item: this.props.orderItem,
            inventoryLoaded: false,
            selectWarehouseDefault: this.props.orderItem.warehouse_id
        });
        this.loadInventory(this.props.orderItem.warehouse_id, true);
    };

    hideModal = () => {
        this.setState({show: false, item: {}});
    };

    handleClose = () => {
        this.props.updateListItem();
        this.setState({dialog: false});
    };

    handleRequestClose = () => {
        this.setState({
            open: false,
        });
    };

    render() {
        let _orderItems = this.props.orderItem;
        let _defaultValue = this.state.defaultValue;

        const styles = {
            button: {
                margin: 5,
            },
        };

        let SnackbarCons = <Snackbar
            open={this.state.open}
            message={this.state.snackbarMessage}
            autoHideDuration={this.state.autoHideDuration}
            onClose={this.handleRequestClose}
        />;

        const actions = [
            <Button
                label="Dismiss"
                size={"small"}
                variant={"outlined"}
                color={"secondary"}
                onClick={this.handleClose}
            >Dismiss</Button>,
            <FulfillmentIssueBtn
                label="Export order"
                raised
                size={"small"}
                variant={"outlined"}
                color={"secondary"}
                force={true}
                orderId={_orderItems.sales_order_id}
            >Export order</FulfillmentIssueBtn>,
        ];


        return ((_orderItems &&
                (_orderItems.status === 'export_error' || _orderItems.status === 'processing')
                && this.state.inventoryLoaded) ?
                <main className="sf-order-items">
                    {SnackbarCons}
                    <Modal show={this.state.show} handleClose={this.hideModal}>
                        <h2>Change Sku for Item</h2>
                        <SimpleForm
                            save={this.handleSubmit.bind(this)}
                            name="chanceItemSkuForm"
                            handleSubmitWithRedirect={this.handleSubmitWithRedirect.bind(this)}>
                            <TextInput style={{display: 'none'}} disabled name="itemId" label={''} source="itemId"
                                       defaultValue={this.state.item.id}/>
                            <SelectInput
                                label="Warehouse"
                                name="warehouse"
                                isRequired={true}
                                source="warehouse"
                                defaultValue={this.state.selectWarehouseDefault}
                                choices={warehouseFilter}
                                optionText="name"
                                onChange={(event) => this.onChangeWarehouse(event)}
                                optionValue="_id"/>
                            <SelectInput
                                label="Sku"
                                name="sku"
                                isRequired={true}
                                source="sku"
                                defaultValue={_defaultValue}
                                choices={this.state.inventory}
                                optionText="skuQty"
                                optionValue="id"/>
                        </SimpleForm>
                    </Modal>

                    <Button startIcon={<RefreshIcon/>}
                            size={"small"}
                            variant={"outlined"}
                            color={"secondary"}
                            style={styles.button}
                            onClick={this.showModal.bind(this)}>
                        CHANGE SKU
                    </Button>
                    <Dialog
                        title="Item changed successfully"
                        bodyClassName={"modal-edit-address"}
                        actions={actions}
                        modal={true}
                        open={this.state.dialog}
                        onRequestClose={this.handleClose}
                    >
                        <DialogContent>
                            <DialogContentText>
                                Please click the button below to send the order to the warehouse, or dismiss the dialog
                                to review the order.
                                Please don't forget to click <b>"Export to warehouse"</b> once you've reviewed the
                                information in order to avoid fulfillment delays
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            {actions}
                        </DialogActions>
                    </Dialog>
                </main>
                : <span/>
        );
    }
}

ChangeSkuBtn.propTypes = {
    orderItem: PropTypes.object,
};

export default ChangeSkuBtn;