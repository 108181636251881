import React, {Component} from 'react';
import Button from '@material-ui/core/Button';
import laravelJsonServerClient from '../../restClient'
import {refreshView as refreshViewAction, UPDATE} from "react-admin";
import PropTypes from "prop-types";
import LaunchIcon from "@material-ui/icons/Launch";
import swal from "sweetalert";
import {connect} from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";

/**
 *
 */
class LabelCancel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            loading: false,

        };
    };

    handleClick = () => {
        let _this = this;
        swal('Are you sure you want to CANCEL this label ?',
            {
                title: '#' + _this.props.record.shipment_id,
                buttons: {
                    cancel: "Cancel",
                    ok: {
                        text: "Ok",
                        value: "catch",
                    }
                },
            }).then(value => {
            switch (value) {
                case "catch":
                    swal('Canceling label ...');
                    laravelJsonServerClient(UPDATE, 'shipment/label/cancel', {id: this.props.record.id}).then(
                        r => {
                            _this.setState({
                                loading: false,
                            });
                            if (r.data.error === true) {
                                swal(r.data.message, {title: 'NOTIFY'});
                            } else {
                                _this.props.refreshView();
                                swal.close();
                            }
                        }
                    ).catch((e) => {
                        console.error(e);
                    });
                    return true;
                case "cancel":
                    return false;
                default:
                    return false;
            }
        });
    };

    render() {
        const styles = {
            button: {
                marginLeft: 5,
                marginRight: 5,
                color: '#fff',
                backgroundColor: 'rgb(220, 0, 78)'
            },
        };

        let showButton = false;
        //todo when need to show it
        // if (this.props.record) {
        //     showButton = (this.props.record.status !== 'canceled' && this.props.record.status !== 'shipped' && this.props.record.status !== 'returned' && this.props.record.shipper_account !== "");
        // }

        return ((showButton === true) ? <span><Button
            onClick={this.handleClick}
            id="resend_email_button"
            color="secondary"
            primary="true"
            style={styles.button}
            size={"small"}
            className="display-block"
            disabled={this.state.loading}
            startIcon={this.state.loading === false  ? <LaunchIcon/> : <CircularProgress size={15} color={'inherit'}/>}
        >
            Cancel Label</Button>
        </span>:<span/>);
    }
}

LabelCancel.propTypes = {
    record: PropTypes.object,
};

export default connect(undefined, {refreshView: refreshViewAction})(
    LabelCancel
);
