import React, {Component} from 'react';
import {Button} from '@material-ui/core';
import {
    DELETE_MANY,
    refreshView as refreshViewAction
} from "react-admin";
import laravelJsonServerClient from '../../restClient'
import {Delete} from "@material-ui/icons";
import swal from "sweetalert";
import {connect} from "react-redux";
import {authorized} from "../../authorized";

/**
 * Bulk delete list data
 */
class OMSDeleteBulkRecordsBtn extends Component {
    constructor(props) {
        super(props);

        this.handleDelete = this.handleDelete.bind(this);
        this.doBulkDelete = this.doBulkDelete.bind(this);
        this.refreshDataGird = this.refreshDataGird.bind(this);
    };

    refreshDataGird() {
        if (this.props.unSelectAll instanceof Function) {
            this.props.unSelectAll();
        }
        this.props.refreshView();
    }

    doBulkDelete(ids) {
        laravelJsonServerClient(DELETE_MANY, this.props.resource, {ids: ids}).then(
            r => {
                if (r.data.result) {
                    this.refreshDataGird();
                } else {
                    if (r.data.error_messages !== undefined) {
                        if (r.data.deletedIds !== undefined && r.data.deletedIds.length) {
                            this.refreshDataGird();
                        }

                        const wrapper = document.createElement('div');
                        let buildHtml = "<ul class='swal-text-left'>";
                        r.data.error_messages.forEach(function (msg) {
                            buildHtml += "<li>" + msg + "</li>";
                        });
                        buildHtml += "</ul>";
                        wrapper.innerHTML = buildHtml;

                        swal({
                            icon: "info",
                            content: wrapper,
                        });
                    } else {
                        swal('Error! An error occurred. Please try again later!');
                    }
                }
            }
        ).catch((e) => {
            console.error(e);
        });
    }

    handleDelete() {
        let _this = this;
        let totalItemSelected = this.props.selectedIds.length;
        if (totalItemSelected > 25) {
            swal("Do not delete more than 25 records in a single delete!");
            return false;
        }

        let msg = totalItemSelected > 1 ? 'these records' : 'this record';
        swal('You will not be able to recover ' + msg + '. Are you sure?', {
            title: 'CONFIRM DELETE',
            buttons: {
                cancel: "Cancel",
                ok: {
                    text: "Ok",
                    value: "catch",
                }
            },
        }).then(value => {
            switch (value) {
                case "catch":
                    _this.doBulkDelete(_this.props.selectedIds);
                    return true;
                case "cancel":
                default:
                    return false;
            }
        });
    }

    render() {
        const allowPermission = (authorized.permissions.indexOf(this.props.permissionAllowed) !== -1);
        const validStyle = {color: '#f44336'}
        let buttonDelete = <Button
            size="small"
            style={allowPermission ? validStyle : {}}
            disabled={!allowPermission}
            onClick={this.handleDelete}
            startIcon={<Delete/>}
        >Delete</Button>

        return (
            <span>
                {buttonDelete}
            </span>
        );
    }
}

export default connect(undefined, {refreshView: refreshViewAction})(
    OMSDeleteBulkRecordsBtn
);
