import React, {Component} from 'react';
import {Dialog, DialogActions, Button, DialogTitle, DialogContent, Hidden} from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import EditIcon from '@material-ui/icons/Edit';
import {
    refreshView as refreshViewAction, CREATE
} from "react-admin";
import laravelJsonServerClient from '../../restClient'
import {connect} from 'react-redux';
import ModelForm from "./modelForm";
import {authorized} from "../../authorized";

class AddProductModelBtn extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            open: false,
            dialog: false,
            values: null,
            allowSubmit: false,
            data: {
                warranty_us: 1,
                warranty_eu: 1,
                warranty_uk: 1,
                warranty_us_rp: 1,
                warranty_eu_rp: 1,
                warranty_uk_rp: 1,
            }
        };

        this.handleSubmit = this.handleSubmit.bind(this);
    };

    handleSubmit() {
        const values = this.state.values;
        laravelJsonServerClient(CREATE, 'product-model', {id: values.id, data: values}).then(
            r => {
                if (r.data.json.result) {
                    this.handleClose();
                    this.props.refreshView();
                } else {
                    this.handleClose();
                    alert(r.message);
                }
            }
        ).catch((e) => {
            console.error(e);
        });
    }

    showModal = () => {
        this.setState({show: true});
        if (this.props.onHidePopupCallback !== undefined) {
            document.getElementById(this.props.parentPpId).style.opacity = 0;
        }

    };

    handleClose = () => {
        this.setState({show: false});
        if (this.props.onHidePopupCallback !== undefined) {
            this.props.onHidePopupCallback();
        }
    };

    onHandleCallbackData = (params, isFormValid) => {
        this.setState({values: params});
        this.setState({allowSubmit: isFormValid});
    }

    render() {
        let initFormValue = this.props.defaultFormValue ?? this.state.data;
        if (false !== initFormValue && initFormValue.hasOwnProperty('id')) {
            delete initFormValue.id;
        }

        const btnText = (this.props.btnText !== undefined) ? this.props.btnText : 'Create';
        const btnIcon = (this.props.btnIcon !== undefined) ? this.props.btnIcon : <EditIcon/>;
        const allowSubmit = !this.state.allowSubmit;
        const actions = [
            <Button
                label="Submit"
                key={'secondary'}
                primary="true"
                variant={"outlined"} color={"secondary"}
                size={"small"}
                startIcon={<SaveIcon/>}
                disabled={allowSubmit}
                onClick={this.handleSubmit}>Create</Button>,
            <Button
                label="Dismiss"
                primary="true"
                key={'primary'}
                size={"small"}
                color={"primary"}
                variant="outlined"
                onClick={this.handleClose}>Dismiss</Button>
        ];

        let buttonEdit = '';
        if (authorized.permissions.indexOf('product.model.create') !== -1) {
            buttonEdit = <Button
                className="btn-add-model"
                label={btnText}
                size="small"
                style={{overflow: 'inherit', color: '#000'}}
                onClick={this.showModal}
                startIcon={btnIcon}>
                {btnText}
            </Button>
        }

        return (
            <span>
                {buttonEdit}
                <Dialog
                    className="modal-edit-product"
                    title="Create product model"
                    actions={actions}
                    modal={true}
                    maxWidth={'sm'}
                    fullWidth={'true'}
                    open={this.state.show}
                    autoScrollBodyContent={true}
                >
                    <DialogTitle id="customized-dialog-title-form-add" onClose={this.handleClose}>
                        Create product model
                    </DialogTitle>
                    <DialogContent dividers>
                        <span>
                            <ModelForm
                                save={this.handleSubmit}
                                handleSubmit={this.handleClose}
                                validate={this.validate}
                                defaultFormValue={initFormValue}
                                onFormCallback={this.onHandleCallbackData}
                            />
                        </span>
                    </DialogContent>
                    <DialogActions>
                        {actions}
                    </DialogActions>
                </Dialog>
            </span>
        );
    }
}

export default connect(undefined, {refreshView: refreshViewAction})(
    AddProductModelBtn
);

