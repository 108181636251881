import React, {Component} from 'react';
import {Button} from '@material-ui/core';
import {
    DELETE,
    refreshView as refreshViewAction
} from "react-admin";
import laravelJsonServerClient from '../../restClient'
import {Delete} from "@material-ui/icons";
import swal from "sweetalert";
import {connect} from "react-redux";
import {authorized} from "../../authorized";

/**
 * Delete one record
 */
class OMSDeleteRecordBtn extends Component {
    constructor(props) {
        super(props);

        this.doDelete = this.doDelete.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.refreshDataGird = this.refreshDataGird.bind(this);
    };

    refreshDataGird() {
        if (this.props.unSelectAll instanceof Function) {
            this.props.unSelectAll();
        }
        this.props.refreshView();
    }

    doDelete(record) {
        laravelJsonServerClient(DELETE, this.props.resource, {id: record.id}).then(
            r => {
                if (r.data.result) {
                    this.refreshDataGird();
                } else {
                    if (r.data.error_messages !== undefined) {
                        if (r.data.deletedIds !== undefined && r.data.deletedIds.length) {
                            this.refreshDataGird();
                        }

                        const wrapper = document.createElement('div');
                        let buildHtml = "<ul class='swal-text-left'>";
                        r.data.error_messages.forEach(function (msg) {
                            buildHtml += "<li>" + msg + "</li>";
                        });
                        buildHtml += "</ul>";
                        wrapper.innerHTML = buildHtml;

                        swal({
                            icon: "info",
                            content: wrapper,
                        });
                    } else {
                        swal('Error! An error occurred. Please try again later!');
                    }
                }
            }
        ).catch((e) => {
            console.error(e);
        });
    }

    handleDelete() {
        let _this = this;
        swal('You will not be able to recover this record. Are you sure?', {
            title: 'CONFIRM DELETE',
            buttons: {
                cancel: "Cancel",
                ok: {
                    text: "Ok",
                    value: "catch",
                }
            },
        }).then(value => {
            switch (value) {
                case "catch":
                    _this.doDelete(_this.props.record);
                    return true;
                case "cancel":
                default:
                    return false;
            }
        });
    }

    render() {
        let record = this.props.record;
        let buttonDelete;
        const allowPermission = (authorized.permissions.indexOf(this.props.permissionAllowed) !== -1);
        const validStyle = {color: '#f44336'}
        if (record) {
            buttonDelete = <Button
                size="small"
                style={allowPermission ? validStyle : {}}
                disabled={!allowPermission}
                onClick={this.handleDelete}
                startIcon={<Delete/>}>Delete
            </Button>
        }

        return (
            <span>
                {buttonDelete}
            </span>
        );
    }
}

export default connect(undefined, {refreshView: refreshViewAction})(
    OMSDeleteRecordBtn
);
