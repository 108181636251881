import React, {Component} from 'react';
import PropTypes from "prop-types";
import {isReshipChannel} from "../../channels";
import laravelJsonServerClient from '../../../restClient'
import {CREATE} from "react-admin";
import {Snackbar} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import {defaultReSource, authorized} from '../../../authorized'

class ReShipResendEmailBtn extends Component {
    constructor(props) {
        super(props);
        let location = window.location.hash.split('/');

        this.state = {
            autoHideDuration: 5000,
            message: "Requested resend email.",
            open: false,
            orderId: location[2],
            roles: '',
            permissions: ''
        };
    };

    handleClick = () => {
        let _this = this;
        this.setState({
            open: true,
        });

        laravelJsonServerClient(CREATE, 'reship/resend-email', {data: {reference_id: this.state.orderId}}).then( r => {
            _this.setState({
                message: r.data.json.message
            });
        }).catch((e) => {
            console.error(e);
        });
    };

    render() {
        const {record} = this.props;
        const styles = {
            button: {
                marginLeft: 5,
                marginRight: 5,
            },
        };

        return (
            (record !== undefined && authorized.permissions.indexOf('order.email.create') !== -1 && isReshipChannel(record.channel_id)) ? <span><Button
                onClick={this.handleClick}
                id="reship_resend_email_button"
                variant="contained"
                color="secondary"
                size={'small'}
                className="display-block"
                label="Resend email"
                style={styles.button}
            >Resend email</Button>
            <Snackbar
                open={this.state.open}
                message={this.state.message}
                autoHideDuration={this.state.autoHideDuration}
                onRequestClose={this.handleRequestClose}
            /></span> : <span/>);
    }
}

ReShipResendEmailBtn.propTypes = {
    record: PropTypes.object,
};

export default ReShipResendEmailBtn
