import * as React from 'react';
import {Create} from "react-admin"
import SalesReportDownload from "./buttons/salesReportDownload";
import {authorized} from '../authorized'

/**
 *
 * @param props
 * @returns {*}
 * @constructor
 */
export const SalesReport = (props) => {
    if (authorized.permissions.indexOf('finance.sales_reports.get') === -1) {
        return (<span/>)
    }

    return (
        <Create {...props} title="Sales reports">
            <SalesReportDownload/>
        </Create>
    );
}