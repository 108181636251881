import React, {Component} from 'react';
import {
    CardActions
} from '@material-ui/core';
import {FormTab, NumberInput, SelectInput, SimpleForm, TextInput, TabbedForm} from 'react-admin';
import {ProductModelCategoryOps, ProductModelProductLineOps} from "../../config";
import BacktoOrderBtn from "../returns/actions/BacktoOrderBtn";

class ModelForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: []
        }

        this.validate = this.validate.bind(this);
    }

    validate(value) {
        const fields = [
            'name',
            'category',
            'weight',
            'width',
            'height',
            'depth',
            'warranty_us',
            'warranty_eu',
            'warranty_uk',
            'warranty_us_rp',
            'warranty_eu_rp',
            'warranty_uk_rp',
        ];
        const data = {};
        fields.forEach(field => {
            if (value.hasOwnProperty(field)) {
                data[field] = value[field];
            }
        });

        const requiredFields = [
            'name',
            'category',
        ];

        let passed = true;
        requiredFields.forEach(field => {
            if (value[field] === undefined) {
                passed = false;
            }
            if (value.hasOwnProperty(field)) {
                if (value[field] === '' || value[field] === null) {
                    passed = false;
                }
            }
        });

        this.props.onFormCallback(value, passed);
    }

    render() {
        const save = this.props.save;
        const handleSubmit = this.props.handleSubmit;
        const defaultFormValue = this.props.defaultFormValue;

        return (
            <TabbedForm submitOnEnter={false}
                        defaultValue={defaultFormValue}
                        save={save}
                        validate={this.validate}
                        toolbar={<CardActions/>}
                        handleSubmit={handleSubmit}
                        redirect={false}>
                <FormTab label="Infomation">
                    <TextInput fullWidth={true} isRequired={true} name="name" source="name"/>
                    <SelectInput name="category" isRequired={true} label="Category"
                                 source="category"
                                 choices={ProductModelCategoryOps}
                                 optionValue="name"
                                 fullWidth={true}
                    />
                    <NumberInput type="number" name="weight" source="weight" className="customInputNumber" min={1}
                                 fullWidth={true}/>
                    <NumberInput type="number" name="width" source="width" min={1}
                                 className="w100 customInputNumber"/>
                    <NumberInput type="number" name="height" source="height" min={1}
                                 className="w100 customInputNumber"/>
                    <NumberInput type="number" name="depth" source="depth" min={1}
                                 className="w100 customInputNumber"/>
                </FormTab>
                <FormTab label="Warranty">
                    <NumberInput type="number" label="America Warranty(year)" source="warranty_us" min={1} isRequired={true}
                                 className="w100 customInputNumber"/>
                    <NumberInput type="number" label="EU Warranty (year)" source="warranty_eu" min={1}  isRequired={true}
                                 className="w100 customInputNumber"/>
                    <NumberInput type="number" label="UK Warranty (year)" source="warranty_uk" min={1}  isRequired={true}
                                 className="w100 customInputNumber"/>
                </FormTab>
                <FormTab label="Warranty Recertified">
                    <NumberInput type="number" label="America Warranty(year)" source="warranty_us_rp" min={1} isRequired={true}
                                 className="w100 customInputNumber"/>
                    <NumberInput type="number" label="EU Warranty (year)" source="warranty_eu_rp" min={1}  isRequired={true}
                                 className="w100 customInputNumber"/>
                    <NumberInput type="number" label="UK Warranty (year)" source="warranty_uk_rp" min={1}  isRequired={true}
                                 className="w100 customInputNumber"/>
                </FormTab>
            </TabbedForm>
        );
    }
}

export default ModelForm;
