import {config} from "../config";
import * as React from "react";
import {
    List,
    Datagrid,
    TextField,
    required,
    TextInput,
    TopToolbar, Pagination, Create, SimpleForm, SelectInput
} from 'react-admin';
import EditBtn from "./usersapi/editUserApiBtn";
import OMSDeleteRecordBtn from "./buttons/OMSDeleteRecordBtn";


/**
 * @type {{display: string, float: string, zIndex: number}}
 */
const cardActionStyle = {
    zIndex: 2,
    display: 'inline-block',
    float: 'right',
};

/**
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const UsersPagination = props => <Pagination rowsPerPageOptions={[config.perPage]} {...props} />;

/**
 *
 * @param props
 * @returns {*}
 * @constructor
 */
export const UsersApiList = (props) => (
    <span>
        <List {...props}
              perPage={config.perPage}
              bulkActionButtons={false}
              pagination={<UsersPagination />}
              sort={{field: 'id', order: 'DESC'}}>
            <Datagrid>
                <TextField label="ID" source="id"/>
                <TextField label="Name" source="name"/>
                <TextField label="Role" source="role"/>
                <TextField label="Created At" source="created_at"/>
                <EditBtn />
                <DeleteUserBtn {...props}/>
            </Datagrid>
        </List>
    </span>
);

/**
 *
 * @param props
 * @returns {*}
 * @constructor
 */
export const UsersApiCreate = (props) => {
    return (
        <span>
            <Create title="Create Api Key" {...props}>
                <SimpleForm redirect={'/user_api'}>
                    <TextInput label="Name" source="name" validate={required()}/>
                </SimpleForm>
            </Create>
        </span>
    );
};

const DeleteUserBtn = (props) => {
    let canDelete = (props.record.id !== Number(localStorage.getItem('user_id')));
    return canDelete ? <OMSDeleteRecordBtn permissionAllowed="user.delete" resource="user" {...props}/> : '';
};
