export const config = {
    apiURL : 'https://opa.cebrands.co/api',
    storageUrl: 'https://d1s5mv0ze5e8t5.cloudfront.net/',
    environment: 'production',
    perPage: 25,
    GoogleOAuthProviderClientID: '794206828532-q9tt5kkf1acfupb5g97ekol9vrepksgn.apps.googleusercontent.com'
}

export const warehouseConfigParse = () => {
    const defaultWarehouse =  [{_id: 0, name: '', add: false, edit: false, findshipment: false}];
    try {
        return (localStorage.getItem('warehouse') !== null && localStorage.getItem('warehouse') !== undefined) ? JSON.parse(localStorage.getItem('warehouse')) : defaultWarehouse;
    } catch (e) {
        return defaultWarehouse;
    }
}

export const warehouseFilter = warehouseConfigParse();

/**
 *
 * @type {*[]}
 */
export const warehoseRegionReport = [
    { _id: 'Australia', name: 'Australia' },
    { _id: 'Canada', name: 'Canada' },
    { _id: 'China', name: 'China' },
    { _id: 'Europe', name: 'Europe' },
    { _id: 'Hong Kong', name: 'Hong Kong' },
    { _id: 'India', name: 'India' },
    { _id: 'Japan', name: 'Japan' },
    { _id: 'Mexico', name: 'Mexico' },
    { _id: 'United Kingdom', name: 'United Kingdom'},
    { _id: 'USA', name: 'USA' }
];

export const commonCurrency = [
    {_id: 'USD', name: 'USD'},
    {_id: 'CAD', name: 'CAD'},
    {_id: 'GBP', name: 'GBP'},
    {_id: 'EUR', name: 'EUR'},
    {_id: 'MXN', name: 'MXN'}
];


export const channels = [
    { _id: 106, name: 'motowatch.com', invoice: true, allow_refund: true, allow_auto_refund: true, allow_cancel: true, returnRequest: true, comment: true},
    { _id: 107, name: 'store.vitalist.co', invoice: true, allow_refund: false, allow_auto_refund: false, allow_cancel: false, returnRequest: true, comment: false},
    { _id: 500, name: 'Amazon US', amz: true, returnRequest: true, comment: true},
    { _id: 508, name: 'Amazon CA', amz: true, returnRequest: true, comment: true},
    { _id: 509, name: 'Amazon MX', amz: true, returnRequest: true, comment: true},
    { _id: 511, name: 'Amazon US RB', amz: true, returnRequest: true, comment: true},
    { _id: 519, name: 'Amazon CA RB', amz: true, returnRequest: true, comment: true},
    { _id: 520, name: 'Amazon MX RB', amz: true, returnRequest: true, comment: true},
    { _id: 703, name: 'BestBuy CA', allow_refund: true , returnRequest: true},
    { _id: 716, name: 'HSN', returnRequest: true },
    { _id: 1000, name: 'Reship', manual: true },
    { _id: 1001, name: 'Stock transfer', manual: true, returnRequest: true},
    { _id: 1002, name: 'Sample', manual: true, returnRequest: true}
];

export const ReasonforRefund = [
    {name: 'Accidental order'},
    {name: 'Arrived as an addition to what was ordered'},
    {name: 'Arrived too late'},
    {name: 'Better price available'},
    {name: 'Defective, it does not work'},
    {name: 'Did not like the color'},
    {name: 'Item used - refurbished'},
    {name: 'Missing parts or accessories'},
    {name: 'No longer needed/wanted'},
    {name: 'Not as described'},
    {name: 'Not compatible with my setup'},
    {name: 'Not satisfied with the quality'},
    {name: 'Ordered wrong style/size/color'},
    {name: 'Other reason'},
    {name: 'Out of stock'},
    {name: 'Received damaged box'},
    {name: 'Received damaged device'},
    {name: 'Unauthorized purchase'}
]

export const ReasonforReplacement = [
    {name: 'Catalog error/wrong item ordered'},
    {name: 'Damaged due to DC/FC handling'},
    {name: 'Delivered not received'},
    {name: 'Delivery problem (correct address)'},
    {name: 'Item Defective'},
    {name: 'Item missing from shipment'},
    {name: 'Item used - refurbished'},
    {name: 'Lost by Courrier'},
    {name: 'Not as described'},
    {name: 'Not satisfied with the quality'},
    {name: 'Policy exception MGMT approved'},
    {name: 'Policy exception/customer error'},
    {name: 'Shipped to wrong address'},
    {name: 'Shipped wrong item'}
]

export const ProductModelCategoryOps = [
    {name: 'Moto Watch Accessories'},
    {name: 'Moto Watch'},
    {name: 'Vitalist'},
    {name: 'Other / Legacy'}
]

export const ProductModelProductLineOps = [
    {name: 'Other / Legacy'},
    {name: 'Spare Parts'},
    {name: 'moto watch - accessories'},
    {name: 'moto watch 100 - black'},
    {name: 'moto watch 100 - silver'},
    {name: 'moto watch 70 - black'},
    {name: 'moto watch 40 - black'},
    {name: 'moto watch 40 - gold'}
]

