import React, {Component} from 'react';
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import RefreshIcon from "@material-ui/icons/Refresh";
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import {defaultReSource, authorized} from '../../../authorized'

class CreateShipment extends Component {
    constructor(props) {
        super(props);
        let location = window.location.hash.split('/');
        this.state = {
            orderId: location[2],
            roles: '',
            permissions: ''
        };
    };

    handleClick = () => {
        window.location.href = '#/shipment/create?orderId=' + this.state.orderId;
    };

    render() {
        const {record} = this.props;
        return (
            (record !== undefined) && authorized.permissions.indexOf('order.shipment.create') !== -1 ?
                <Button
                    id="btnCreateShipment"
                    onClick={this.handleClick}
                    size={"small"}
                    variant="contained"
                    startIcon={<LocalShippingIcon/>}
                    label="Create shipment"
                    style={{float: "right"}}
                >Create shipment</Button>
                : <span/>);
    }
}

CreateShipment.propTypes = {
    record: PropTypes.any,
};

export default CreateShipment
