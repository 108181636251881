import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import LaunchIcon from '@material-ui/icons/Launch';
import {Link} from 'react-router-dom'
import {isReturnAbleStores} from "../../channels";
import {defaultReSource, authorized} from '../../../authorized'
import {TopToolbar} from "react-admin";
import {warehouseFilter} from "../../../config";

/**
 *
 */
class ReturnRequestButton extends Component {
    constructor(props) {
        super(props);
        let location = window.location.hash.split('/');
        this.state = {
            orderId: location[2],
            roles: '',
            permissions: ''
        }
    }

    render() {

        if (authorized.permissions.indexOf('customer.returns_request.create') === -1) {
            return (<span/>)
        }

        const allowedCountry = allowedWarehouses();

        const styles = {
            button: {
                marginLeft: 5,
                marginRight: 5,
            },
        };

        const {record} = this.props;
        let allowReturnRequest = false;
        let shippedQty = 0
        if (record && record.addresses) {
            shippedQty = hasShippedItem( record.items);
            record.addresses.forEach((address) => {
                if (address.address_type === 'shipping') {
                    if (allowedCountry.includes(address.country.toUpperCase())) {
                        allowReturnRequest = true;
                    }
                }
            });
        }

        let is_return = record && isReturnAbleStores(record.channel_id) && allowReturnRequest === true && shippedQty > 0
        return (is_return) ? <Button
            component={Link}
            variant="outlined" color="secondary"
            to={{pathname: "/returnsRequest/create", search: "?orderId=" + this.props.record.id, state: ""}}
            size={"small"}
            style={styles.button}
            startIcon={<LaunchIcon/>}>Return Request</Button> : <span/>;
    }
}

ReturnRequestButton.propTypes = {
    record: PropTypes.object,
};

export default ReturnRequestButton;

/**
 *
 * @returns {string}
 */
const allowedWarehouses = () => {
    const warehouseReturn = warehouseFilter.filter((item) => item.returnRequest === true);
    let country = '';
    warehouseReturn.forEach(field => {
        country = country + field.countryReturn + ', ';
    });


    return country;
};

/**
 *
 * @param items
 * @returns {number}
 */
const hasShippedItem = (items) => {
    let totalShippedQty = 0
    items.forEach(item => {
        totalShippedQty = totalShippedQty + item.qty_shipped;
    })
    return totalShippedQty;
}