import React, {Component} from 'react';
import {
    Card,
    CardHeader,
    CardContent, Paper, Button, Typography
} from '@material-ui/core';
import {refreshView as refreshViewAction} from 'react-admin';
import CreateIcon from '@material-ui/icons/Create';
import EditAddresses from "./actions/EditAddresses";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {authorized} from '../../authorized'
import {warehouseFilter} from "../../config";

/**
 *
 */
class Addresses extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: this.props.record.addresses,
            editAddress: {sales_order_id: ''},
            editAddressTitle: '',
            editAddressOpen: false
        };
        this.handleEditBillingAddressClick = this.handleEditBillingAddressClick.bind(this, null);
        this.handleEditShippingAddressClick = this.handleEditShippingAddressClick.bind(this, null);
        this.onCloseModal = this.onCloseModal.bind(this);
    }

    onCloseModal() {
        this.setState({editAddressOpen: false});
        this.props.refreshView();
    }

    handleEditBillingAddressClick() {
        const addresses = this.props.record.addresses;

        addresses.forEach((address) => {
            if (address.address_type === 'billing') {
                address['addressid'] = address['id'];
                this.setState({editAddress: address, editAddressTitle: "Edit Billing Address", editAddressOpen: true});
            }
        });
    }

    handleEditShippingAddressClick() {
        const addresses = this.props.record.addresses;
        addresses.forEach((address) => {
            if (address.address_type === 'shipping') {
                address['addressid'] = address['id'];
                this.setState({editAddress: address, editAddressTitle: "Edit Shipping Address", editAddressOpen: true});
            }
        });
    }

    render() {
        const addresses = this.props.record.addresses;
        const record = this.props.record;

        if (!addresses || addresses.length === 0) {
            return (<div></div>);
        }

        var billing = null;
        var shipping = null;

        addresses.forEach((address) => {
            if (address.address_type === 'billing') {
                billing = address;
            } else if (address.address_type === 'shipping') {
                shipping = address;
            }
        });

        if (!billing || !shipping) {
            return (<div></div>);
        }

        const cardStyle = {
            width: '47%',
            minWidth: 300,
            margin: '0.5em',
            display: 'inline-block',
            verticalAlign: 'top'
        };

        const cardText = {
            paddingTop: 2,
            paddingBottom: 2
        };

        const styles = {
            button: {
                margin: 12,
            },
        };

        let editBillingButton = allowEdit(record) ?
            <Button
                style={styles.button}
                variant="outlined" color="default"
                size={"small"}
                onClick={this.handleEditBillingAddressClick}
                startIcon={<CreateIcon/>}>Edit</Button> : <span/>;
        let editShippingButton = allowEdit(record) ?
            <Button
                style={styles.button}
                variant="outlined" color="default"
                size={"small"}
                onClick={this.handleEditShippingAddressClick}
                labelPosition="after"
                startIcon={<CreateIcon/>}>Edit</Button> : <span/>;

        return (
            <Paper style={{margin: '20px 0 20px 0', padding: '10px'}} zdepth={2}>
                <Typography gutterBottom variant="h5" component="h5">
                    Order Addresses
                </Typography>
                <Card style={cardStyle}>
                    <CardHeader title="Billing Address"/>
                    <CardContent style={cardText}>{billing.firstname}&nbsp;{billing.lastname}</CardContent>
                    <CardContent style={cardText}>Address 1: {billing.address1}</CardContent>
                    <CardContent style={cardText}>Address 2: {billing.address2}</CardContent>
                    <CardContent style={cardText}>Address 3: {billing.address3}</CardContent>
                    <CardContent
                        style={cardText}>{billing.city},&nbsp;{billing.region},&nbsp;{billing.postcode}</CardContent>
                    <CardContent style={cardText}>{billing.country}</CardContent>
                    <CardContent style={cardText}>T:&nbsp;{billing.telephone}</CardContent>
                    {authorized.permissions.indexOf('order.address.edit') !== -1 ? editBillingButton : <span/>}
                </Card>
                <Card style={cardStyle}>
                    <CardHeader title="Shipping Address"/>
                    <CardContent style={cardText}>{shipping.firstname}&nbsp;{shipping.lastname}</CardContent>
                    <CardContent style={cardText}>Address 1: {shipping.address1}</CardContent>
                    <CardContent style={cardText}>Address 2: {shipping.address2}</CardContent>
                    <CardContent style={cardText}>Address 3: {shipping.address3}</CardContent>
                    <CardContent
                        style={cardText}>{shipping.city},&nbsp;{shipping.region},&nbsp;{shipping.postcode}</CardContent>
                    <CardContent style={cardText}>{shipping.country}</CardContent>
                    <CardContent style={cardText}>T:&nbsp;{shipping.telephone}</CardContent>
                    {authorized.permissions.indexOf('order.address.edit') !== -1 ? editShippingButton : <span/>}
                </Card>
                <EditAddresses record={this.state.editAddress}
                               title={this.state.editAddressTitle}
                               open={this.state.editAddressOpen}
                               onCloseModal={this.onCloseModal}
                               hasEdit={false}
                               hasList={false}
                               hasShow={false}
                               history={{}}
                               location={{pathname: "", search: "", hash: ""}}
                               match={{
                                   isExact: false,
                                   params: {id: this.state.editAddress.sales_order_id},
                                   path: "",
                                   url: ""
                               }}
                               options={{}}
                               resource={'order'}/>
            </Paper>
        );
    }
}

/**
 *
 * @param record
 * @returns {boolean}
 */
export function allowEdit(record) {
    if (!record.items) return false;

    if (record.internal_status === 'pending' || record.internal_status === 'processing') {
        return true;
    }

    let exception = false;
    const warehouses = warehouseFilter.filter((item) => (item.generateLabel === true));
    record.items.forEach(function (item) {
        if (item.status !== 'shipped' && _.find(warehouses, {_id: item.warehouse_id}) !== undefined) {
            exception = true;
        }
    })

    return exception;
}

Addresses.propTypes = {
    record: PropTypes.any,
};

export default connect(undefined, {refreshView: refreshViewAction})(
    Addresses
);
