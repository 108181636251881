// in src/App.js
import React from 'react';
import {Admin, Resource, defaultTheme} from 'react-admin';
import {OrderList, OrderShow} from './entities/orders';
import {InventoryList, InventoryShow, InventoryCreate} from "./entities/inventory";
import {
    ReturnsList,
    ReturnEdit,
    ReturnsRequestShow,
    ReturnsRequestCreate,
    ReturnsRequestEdit,
    ReturnsRequestList
} from "./entities/returns";
import {ReShipCreate} from "./entities/reship";
import laravelJsonServerClient from './restClient'
//import authClient from './authClient';
import authProvider from "./authProvider";
import {config} from './config.js'
import LoginPage from './LoginPage.js'
import addUploadFeature from './rest/uploadCapable.js';
import {OrdersFulfillmentIssuesList} from "./entities/ordersFulfilmentIssues";
import {OrdersShipmentIssuesList} from "./entities/ordersShipmentIssues";
import {CustomerList, CustomerShow} from './entities/customers';
import {SalesReport} from "./entities/salesReport";
import {ShipmentCreate} from "./entities/shipment";
import {taxCodeList, taxCodeShow, TaxcodeCreate} from "./entities/taxcodes/taxCodes";
import {SettlementReport, SettlementReportShow} from "./entities/settlementReport/settlementReport";
import {SettlementItemsReport} from "./entities/settlementReport/settlementItemsReport";
import {deviceRegistrationList} from "./entities/deviceRegistration/deviceRegistrationList";
import NotFound from "./layout/NotFound";
import Layout from "./layout/Layout";
import StarsIcon from '@material-ui/icons/Stars';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import StorageIcon from '@material-ui/icons/Storage';
import PeopleIcon from '@material-ui/icons/People';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import KeyboardReturnIcon from '@material-ui/icons/KeyboardReturn';
import DevicesIcon from '@material-ui/icons/Devices';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import ReportIcon from '@material-ui/icons/Report';
import SdStorageIcon from '@material-ui/icons/SdStorage';
import HorizontalSplitIcon from '@material-ui/icons/HorizontalSplit';
import ModeCommentIcon from '@material-ui/icons/ModeComment';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import CreateOrderForm from "./entities/orders/actions/create";
import AddIcon from '@material-ui/icons/Add';
import {UsersList} from "./entities/users";
import {UsersApiList, UsersApiCreate} from "./entities/usersApi";
import {RolesList} from "./entities/roles";
import DoneAllIcon from '@material-ui/icons/DoneAll';
import DashBoard from "./layout/dashBoard";
import {WarehouseList, WarehouseShow} from "./entities/warehouse";
import HomeWorkIcon from '@material-ui/icons/HomeWork';
import SdCategoryIcon from '@material-ui/icons/Category';
import {ProductList, ProductShow, ProductCreate} from "./entities/product";
import {ModelList} from "./entities/product/models";
import ClearAllIcon from '@material-ui/icons/ClearAll';
import {TransactionList} from "./entities/transactions";
import {authorized} from './authorized'

document.title = "CE Brands - OMS - " + config.environment;
const AppTitle = () => {
    return "CE Brands - OMS - " + config.environment;
};

const theme = {
    ...defaultTheme,
    palette: {
        ...defaultTheme.palette,
        secondary: {
            main: '#000',
            primary: '#000'
        },
    },
};

const uploadCapableClient = addUploadFeature(laravelJsonServerClient);
const App = () => (
    <React.StrictMode>
        <Admin catchAll={NotFound}
               layout={Layout}
               dataProvider={uploadCapableClient}
               restClient={uploadCapableClient}
               authProvider={authProvider}
               loginPage={LoginPage}
               title={AppTitle}
               theme={theme}
        >
            <Resource name="dashboard" list={DashBoard}
                      options={{label: 'Dashboard', authorized: true, display: false}}/>
            {/*Products        */}
            <Resource name="Sales" icon={StarsIcon} list={OrderList} show={OrderShow}
                      options={{
                          label: 'Sales',
                          'isMenuParent': true,
                          order: 1,
                          authorized: (authorized.permissions.indexOf('order.list') !== -1 ||
                              authorized.permissions.indexOf('order.create') !== -1 ||
                              authorized.permissions.indexOf('order.fulfillment_issues.list') !== -1 ||
                              authorized.permissions.indexOf('order.delayed_shipments.list') !== -1
                          )
                      }}/>
            <Resource name="order" icon={StarsIcon} list={OrderList} show={OrderShow}
                      options={{label: 'Order', display: false, authorized: true}}/>

            <Resource name="order" icon={DevicesIcon} show={OrderList} list={OrderShow}
                      options={{
                          "label": "Orders",
                          menuParent: "Sales",
                          order: 1,
                          authorized: (authorized.permissions.indexOf('order.list') !== -1)
                      }}/>
            <Resource name="createOrder" icon={AddIcon} list={CreateOrderForm} show={CreateOrderForm}
                      options={{
                          label: 'Create Order',
                          menuParent: "Sales",
                          order: 2,
                          authorized: (authorized.permissions.indexOf('order.create') !== -1)
                      }}/>

            <Resource name="fulfillmentIssues" icon={ReportIcon} list={OrdersFulfillmentIssuesList}
                      options={{
                          label: 'Fulfillment Issues',
                          menuParent: "Sales",
                          order: 3,
                          authorized: (authorized.permissions.indexOf('order.fulfillment_issues.list') !== -1)
                      }}/>
            <Resource name="shipmentIssues" icon={LocalShippingIcon} list={OrdersShipmentIssuesList}
                      options={{
                          label: 'Delayed Shipments',
                          menuParent: "Sales",
                          order: 4,
                          authorized: (authorized.permissions.indexOf('order.delayed_shipments.list') !== -1)
                      }}/>
            {/*Products        */}
            <Resource name="Products" icon={HorizontalSplitIcon} list={OrdersFulfillmentIssuesList}
                      options={{
                          label: 'Products', 'isMenuParent': true, order: 2,
                          authorized: (
                              authorized.permissions.indexOf('inventory.list') !== -1 ||
                              authorized.permissions.indexOf('warehouse.list') !== -1 ||
                              authorized.permissions.indexOf('product.list') !== -1 ||
                              authorized.permissions.indexOf('product.device_registraction.list') !== -1)
                      }}/>
            <Resource name="product" icon={SdCategoryIcon} list={ProductList} create={ProductCreate}
                      options={{
                          label: 'Items', "menuParent": "Products",
                          authorized: (
                              authorized.permissions.indexOf('product.list') !== -1 ||
                              authorized.permissions.indexOf('product.edit') !== -1 ||
                              authorized.permissions.indexOf('product.create') !== -1)
                      }}/>
            <Resource name="product-model" icon={SdCategoryIcon} list={ModelList}
                      options={{
                          label: '',
                          display: false,
                          authorized: (
                              authorized.permissions.indexOf('product.model.list') !== -1 ||
                              authorized.permissions.indexOf('product.model.edit') !== -1 ||
                              authorized.permissions.indexOf('product.model.create') !== -1)
                      }}/>
            <Resource name="inventory" icon={SdStorageIcon} list={InventoryList}  create={InventoryCreate}
                      show={InventoryShow} options={{
                label: 'Inventory', "menuParent": "Products",
                authorized: (authorized.permissions.indexOf('inventory.list') !== -1)
            }}/>
            <Resource name="transactions" icon={ClearAllIcon} list={TransactionList}
                      options={{
                label: 'Inventory Transactions', "menuParent": "Products",
                authorized: (authorized.permissions.indexOf('inventory.log.list') !== -1)
            }}/>
            <Resource name="warehouse" icon={HomeWorkIcon} list={WarehouseList}
                      options={{
                          label: 'Warehouse', "menuParent": "Products",
                          authorized: (authorized.permissions.indexOf('warehouse.list') !== -1)
                      }}/>

            <Resource name="deviceregistration" icon={DevicesIcon} list={deviceRegistrationList}
                      options={{
                          label: 'Device Registrations', "menuParent": "Products",
                          authorized: (authorized.permissions.indexOf('product.device_registraction.list') !== -1)
                      }}/>

            {/*Customers        */}
            <Resource name="Customers" icon={ModeCommentIcon} list={OrdersFulfillmentIssuesList}
                      options={{
                          label: 'Customers',
                          'isMenuParent': true,
                          order: 3,
                          authorized: (authorized.permissions.indexOf('customer.list') !== -1 ||
                              authorized.permissions.indexOf('customer.returns.list') !== -1 ||
                              authorized.permissions.indexOf('customer.returns_request.list') !== -1)
                      }}/>
            <Resource name="customer" icon={PeopleIcon} list={CustomerList} show={CustomerShow}
                      options={{
                          label: 'Customers',
                          "menuParent": "Customers",
                          authorized: (authorized.permissions.indexOf('customer.list') !== -1)
                      }}/>
            <Resource name="returns" icon={KeyboardReturnIcon} list={ReturnsList} edit={ReturnEdit}
                      options={{
                          label: 'Returns',
                          "menuParent": "Customers",
                          authorized: (authorized.permissions.indexOf('customer.returns.list') !== -1)
                      }}/>
            <Resource name="returnsRequest" icon={KeyboardReturnIcon} list={ReturnsRequestList}
                      show={ReturnsRequestShow} create={ReturnsRequestCreate} edit={ReturnsRequestEdit}
                      options={{
                          label: 'Return Requests',
                          "menuParent": "Customers",
                          authorized: (authorized.permissions.indexOf('customer.returns_request.list') !== -1)
                      }}/>
            {/*Finance        */}
            <Resource name="Finance" icon={PlaylistAddCheckIcon} list={OrdersFulfillmentIssuesList}
                      options={{
                          label: 'Finance',
                          'isMenuParent': true,
                          order: 4,
                          authorized: (authorized.permissions.indexOf('finance.sales_reports.get') !== -1 ||
                              authorized.permissions.indexOf('finance.settlement_reports.list') !== -1 ||
                              authorized.permissions.indexOf('finance.tax_rates.list') !== -1)
                      }}/>
            <Resource name="settlementReport" icon={AttachMoneyIcon} list={SettlementReport} show={SettlementReportShow}
                      options={{
                          label: 'Settlement Reports',
                          "menuParent": "Finance",
                          authorized: (authorized.permissions.indexOf('finance.settlement_reports.list') !== -1)
                      }}/>
            <Resource name="taxcodes" icon={StorageIcon} list={taxCodeList} show={taxCodeShow} create={TaxcodeCreate}
                      options={{
                          label: 'Taxcodes',
                          "menuParent": "Finance",
                          authorized: (authorized.permissions.indexOf('finance.tax_rates.list') !== -1)
                      }}/>
            <Resource name="salesReport" icon={ReportIcon} list={SalesReport}
                      options={{
                          label: 'Sales Report', "menuParent": "Finance",
                          authorized: (authorized.permissions.indexOf('finance.sales_reports.get') !== -1)
                      }}/>
            <Resource name="settlementItemsReport" list={SettlementItemsReport} options={{
                label: '',
                display: false,
                authorized: (authorized.permissions.indexOf('finance.settlement_reports.list') !== -1)
            }}/>

            {/*ACL        */}
            <Resource name="Permissions" icon={StarsIcon} options={{
                label: 'Permissions',
                isMenuParent: true,
                order: 5,
                authorized: (authorized.permissions.indexOf('user.list') !== -1)
            }}/>
            <Resource name="user" list={UsersList} icon={PeopleIcon} options={{
                label: 'Users',
                menuParent: "Permissions",
                useLink: true,
                authorized: (authorized.permissions.indexOf('user.list') !== -1)
            }}/>
            <Resource name="user_api" list={UsersApiList} create={UsersApiCreate} icon={VpnKeyIcon} options={{
                label: 'Api Keys',
                    menuParent: "Permissions",
                    useLink: true,
                    authorized: (authorized.permissions.indexOf('user.list') !== -1)
            }}/>
            <Resource name="roles" icon={DoneAllIcon} list={RolesList} options={{
                label: 'Roles',
                menuParent: "Permissions",
                useLink: true,
                authorized: (authorized.permissions.indexOf('user.list') !== -1)
            }}/>
            {/*Other        */}
            <Resource name="shipment" create={ShipmentCreate} options={{
                label: '',
                display: false,
                authorized: (authorized.permissions.indexOf('order.shipment.create') !== -1)
            }}/>
            <Resource name="reship" create={ReShipCreate} options={{
                label: '',
                display: false,
                authorized: (authorized.permissions.indexOf('order.reship.create') !== -1)
            }}/>
        </Admin>
    </React.StrictMode>
);

export default App;
