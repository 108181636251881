import React, {Component} from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow, Typography,
} from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import {Snackbar} from "@material-ui/core";
import {config} from "../../config";
import {defaultReSource, authorized} from '../../authorized'

/**
 *
 */
class OrderFiles extends Component {
    /**
     *
     * @param props
     */
    constructor(props) {
        super(props);
        this.state = {
            data: this.props.record.order_files,
        }
    }

    /**
     *
     * @returns {*}
     */
    render() {
        const data = this.props.record.order_files;
        if (data === null || data.length === 0) {
            return (
                <div>
                </div>
            )
        }

        /**
         *
         * @param record
         * @returns {string|[]}
         * @constructor
         */
        const OrderFiles = ({record}) => {
            if(record['order_files'] === ''){
                return '';
            }
            const orderFiles = JSON.parse(record['order_files']) ;
            let orderFilesObj = []
            for (const file of orderFiles) {
                orderFilesObj.push(<TableRow><TableCell className="pl-24"><div
                    style={{clear:'left', paddingBottom: '0px'}}>
                    <a target="_blank" href={config.storageUrl + 'orderfiles/' + record['id'] + '/' + file} title={file}>{file}</a>
                </div></TableCell></TableRow>)
            }

            return orderFilesObj;
        }

        return (
            <Paper style={{margin: '20px 0 0 0', padding: '10px'}} zdepth={2}>
                <Typography gutterBottom variant="h5" component="h5">
                    Packing Slips
                </Typography>
                <Table selectable="false" >
                    <TableHead>
                        <TableRow>
                            <TableCell/>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <OrderFiles record={this.props.record}/>
                    </TableBody>
                </Table>

                <Snackbar
                    open={this.state.open}
                    message={this.state.SnackbarMsg}
                    autoHideDuration={this.state.autoHideDuration}
                />
            </Paper>
        );
    }
}

export default OrderFiles;
