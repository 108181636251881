import * as React from 'react';
import {
    TextField,
    Filter,
    Show,
    SimpleShowLayout,
    EditButton,
    ShowButton,
    Create,
    TextInput,
    BooleanInput,
    Edit,
    SimpleForm,
    Datagrid,
    List,
    SelectInput,
    DateInput,
    ChipField,
    Toolbar,
    SaveButton,
    useRedirect,
    TopToolbar, Pagination, FunctionField
} from 'react-admin';
import ReturnRequestForm from './returns/returnRequestForm'
import {getChannelName} from "./channels";
import {channels, config} from "../config";
import ResendEmailBtn from "./returns/actions/ResendEmailBtn";
import CancelBtn from "./returns/actions/CancelBtn";
import AutoRefundSwitch from "./returns/actions/AutoRefundSwitch";
import AutoRefundBtn from "./returns/actions/AutoRefundBtn";
import BacktoOrderBtn from "./returns/actions/BacktoOrderBtn";
import LinkDownload from "./returns/LinkDownload";
import ReturnRequestsItems from "./returns/returnRequestsItems";
import {Link} from 'react-router-dom'
import OrderLink from './orders/actions/OrderLink'
import {dateParser} from "./filters/dateParser";
import {cloneElement} from "react";
import moment from "moment";
import Button from "@material-ui/core/Button";
import RefreshIcon from "@material-ui/icons/Refresh";
import {getUrlParameter} from "../rest/common";
import FormHelperText from '@material-ui/core/FormHelperText';
import DoneIcon from '@material-ui/icons/Done'
import ClearIcon from '@material-ui/icons/Clear'
import Chip from '@material-ui/core/Chip';
import {authorized} from '../authorized'
import EditRequestBtn from "./returns/EditRequestBtn";

/**
 *
 * @param record
 * @returns {*}
 * @constructor
 */
const ChannelName = ({record = {}}) => (
    <span>{(record) ? getChannelName(record.channel_id) : record.channel_id}</span>
);

/**
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const ReturnsFilters = (props) => (
    <Filter {...props}>
        <SelectInput source="channel_id" choices={channels} optionText="name" optionValue="_id"/>
        <DateInput text="To date" source="created_at_to" parse={dateParser}/>
        <DateInput text="From date" source="created_at_from"/>
        <TextInput text="Channel Order ID" source="channel_order_no" alwaysOn/>
    </Filter>
);

/**
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const ReturnsRequestFilters = (props) => (
    <Filter {...props}>
        <SelectInput source="channel_id" choices={channels.filter((item) => item.returnRequest === true)}
                     optionText="name" optionValue="_id"/>
        <SelectInput source="status" choices={[
            {_id: 'pending', name: 'pending'},
            {_id: 'processing', name: 'processing'},
            {_id: 'shipped', name: 'shipped'},
            {_id: 'error', name: 'error'},
            {_id: 'returned', name: 'returned'},
            {_id: 'canceled', name: 'canceled'}
        ]} optionText="name" optionValue="_id"/>
        <SelectInput source="carrier_code" choices={[
            {_id: 'dpd-uk', name: 'DPD UK'},
            {_id: 'canada-post', name: 'Canada Post'},
            {_id: 'usps-discounted', name: 'USPS'},
            {_id: 'sendle', name: 'Sendle'},
        ]} optionText="name" optionValue="_id"/>
        <TextInput label="Tracking Number" source="carrier_tracking_number"/>
        <DateInput text="To date" source="created_at_to" parse={dateParser}/>
        <DateInput text="From date" source="created_at_from"/>
        <TextInput text="Channel Order ID" source="channel_order_no" alwaysOn/>
    </Filter>
);
/**
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const OMSPagination = props => <Pagination rowsPerPageOptions={[config.perPage]} {...props} />;

/**
 *
 * @type {undefined}
 */
const defautDate = dateParser(moment().subtract(6, 'months').format(''), 'created_at_from');

/**
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const ListActions = (props) => {
    return (
        <TopToolbar>
            {cloneElement(props.filters, {context: 'button'})}
        </TopToolbar>
    );
};
/**
 *
 * @param props
 * @returns {*}
 * @constructor
 */
export const ReturnsList = (props) => {
    if (authorized.permissions.indexOf('customer.returns.list') === -1) {
        return (<span/>)
    }

    return (
        <List {...props} bulkActionButtons={false}
              filters={<ReturnsFilters/>} perPage={config.perPage}
              actions={<ListActions/>}
              sort={{field: 'created_at', order: 'DESC'}}
              pagination={<OMSPagination/>}>
            <Datagrid>
                <ChannelName label="Channel" source="channel_id"/>
                <OrderLink label="Order #" source="channel_order_id"/>
                <TextField source="sku" className="sku-text"/>
                <TextField source="qty"/>
                <TextField source="status"/>
                <TextField label="Created At" source="created_at"/>
                <TextField source="disposition"/>
                <TextField source="reason"/>
                <TextField source="comments"/>
                <ChipField label="Returned to warehouse" source="is_returned_txt"/>
                {authorized.permissions.indexOf('customer.returns.edit') !== -1 ? <EditButton/> : <span/>}
            </Datagrid>
        </List>
    );
};

export const ReturnsRequestList = (props) => {
    if (authorized.permissions.indexOf('customer.returns_request.list') === -1) {
        return (<span/>)
    }

    return (
        <List {...props} bulkActionButtons={false}
              filters={<ReturnsRequestFilters/>} perPage={config.perPage}
              actions={<ListActions/>}
              sort={{field: 'created_at', order: 'DESC'}}
              pagination={<OMSPagination/>}>
            <Datagrid>

                <ChannelName label="Channel" source="channel_id"/>
                <OrderLink label="Order #" source="channel_order_id"/>

                <FunctionField
                    label="Label Cost"
                    sortBy="return_cost"
                    sortByOrder="DESC"
                    render={record => `${record.return_cost != null ? record.return_cost : ''} ${record.currency != null ? record.currency : ''}`}
                />

                <FunctionField
                    label="Refund Automatically"
                    sortBy="can_refund_auto"
                    sortByOrder="DESC"
                    render={record => {
                        const text = record.can_refund_auto === 1 ? 'Yes' : record.can_refund_auto === 0 ? 'No' : '';
                        return text !== '' ?
                            <Chip icon={text === 'Yes' ? <DoneIcon/> : <ClearIcon/>} label={text}/> : ''
                    }
                    }
                />
                <TextField source="skus"/>
                <TextField source="total_return_qty"/>
                <TextField source="status"/>
                <TextField label="Carrier" source="carrier_code"/>
                <TextField label="Tracking Code" source="carrier_tracking_number"/>
                <TextField label="Created At" source="created_at"/>
                <FunctionField
                    label="Zendesk Ticket "
                    sortBy="warranty_ticket_id"
                    sortByOrder="DESC"
                    render={record => {

                        return <div><a rel="noopener noreferrer" target="_blank"
                                       href={'https://ebuynow.zendesk.com/agent/tickets/'.concat(record.warranty_ticket_id)}>
                            {record.warranty_ticket_id}</a></div>
                    }
                    }
                />
                <TextField source="warranty_reason"/>
                <TextField source="message"/>
                {authorized.permissions.indexOf('customer.returns_request.get') !== -1 ? <ShowRequest/> : <span/>}
                {authorized.permissions.indexOf('customer.returns_request.edit') !== -1 ? <EditRequestBtn/> : <span/>}
            </Datagrid>
        </List>
    );
}

/**
 *
 * @param record
 * @returns {*}
 * @constructor
 */
const ReturnSku = ({record}) => {
    return <span>Returned Item: {record.sku}</span>;
};

/**
 *
 * @param props
 * @returns {*}
 * @constructor
 */

const ReturnEditToolbar = props => (
    <Toolbar
        {...props}
        sx={{display: 'flex', justifyContent: 'space-between'}}
    >
        <SaveButton/>
    </Toolbar>
);


export const ReturnEdit = (props) => {
    if (authorized.permissions.indexOf('customer.returns.edit') === -1) {
        return (<span/>)
    }

    const redirect = useRedirect();

    const onSuccess = () => {
        notify(`Changes saved`);
        redirect('/returns');
        refresh();
    };


    return (
        <Edit mutationOptions={{onSuccess}} title={<ReturnSku/>} {...props}>
            <SimpleForm toolbar={<ReturnEditToolbar/>}>
                <BooleanInput label="Mark item is received" source="is_returned"/>
            </SimpleForm>
        </Edit>
    );
};

const ReturnsRequestActions = ({basepath, props, data}) => (
    <TopToolbar  {...props}>
        {authorized.permissions.indexOf('order.get') !== -1 ? <BacktoOrderBtn record={data}/> : ""}
        {authorized.permissions.indexOf('order.fulfillment_issues.edit') !== -1 ? <ResendEmailBtn record={data}/> : ""}
        {authorized.permissions.indexOf('order.fulfillment_issues.edit') !== -1 ? <CancelBtn record={data}/> : ""}
        {authorized.permissions.indexOf('order.fulfillment_issues.edit') !== -1 ? <AutoRefundBtn record={data}/> : ""}
        {authorized.permissions.indexOf('order.fulfillment_issues.edit') !== -1 ? <EditRequestBtn record={data}/> : ""}

    </TopToolbar>
);

/**
 *
 * @param basePath
 * @param data
 * @returns {*}
 * @constructor
 */
const EditReturnsRequestActions = ({basePath, data}) => (
    <TopToolbar style={cardActionStyle}>
        <ShowButton basePath={basePath} record={data}/>
    </TopToolbar>
);

/**
 *
 * @param props
 * @returns {*}
 * @constructor
 */
export const ReturnsRequestShow = (props) => {
    const title = "Return Requests";
    if (authorized.permissions.indexOf('customer.returns_request.get') === -1) {
        return (<span/>)
    }
    return (
        <main>
            <Show title={title} actions={<ReturnsRequestActions/>} {...props}>
                <SimpleShowLayout>
                    <TextField label={"Order #"} source="channel_order_no"/>
                    <TextField source="request_type"/>
                    <TextField source="status"/>
                    <AutoRefundSwitch record={props}/>
                    <TextField source="" label={"Adjustment Fee"}/>
                    <>
                        <TextField source="adjustment_fee"/> &nbsp;
                        <TextField source="currency"/>
                    </>
                    <TextField source="" label={"Label Cost"}/>
                    <>
                        <TextField source="return_cost"/> &nbsp;
                        <TextField source="currency"/>
                    </>
                    <TextField source="created_at"/>
                    <LinkTicket record={props}/>
                    <TextField source="warranty_reason"/>
                    <TextField source="email"/>
                    <TextField source="firstname"/>
                    <TextField source="lastname"/>
                    <TextField source="city"/>
                    <TextField source="address1" label="Address"/>
                    <TextField source="message"/>
                    <TextField source="asn" label="ASN"/>
                    <LinkDownload record={props}/>
                    <LinkTracking record={props}/>
                    <ReturnRequestsItems record={props}/>
                </SimpleShowLayout>
            </Show>
        </main>
    )
};

/**
 *
 * @param props
 * @returns {*}
 * @constructor
 */
export const ReturnsRequestCreate = (props) => {
    const styles = {
        button: {
            marginLeft: 5,
            marginRight: 5,
        },
    };

    const orderId = getUrlParameter('orderId', window.location.hash);
    return (
        <span>
        <TopToolbar  {...props}>
             <Button
                 component={Link}
                 to={{
                     pathname: "/order/" + orderId + '/show',
                     state: {orderId: orderId}
                 }}
                 className="display-none"
                 variant="outlined" color="primary"
                 size={"small"}
                 startIcon={<RefreshIcon/>}
                 style={styles.button}
                 labelPosition="after"
             >Back to Order</Button>
        </TopToolbar>
        <Create title="Create Return Requests" {...props}>
            <ReturnRequestForm {...props}/>
        </Create>
    </span>
    )
};

/**
 *
 * @param props
 * @returns {*}
 * @constructor
 */
export const ReturnsRequestEdit = (props) => (
    <Edit title="Edit Return Requests"  {...props}>
        <ReturnRequestForm {...props}/>
    </Edit>
);

/**
 *
 * @param record
 * @returns {*}
 * @constructor
 */
const LinktoOrder = ({record = {sales_order_id: "", channel_order_no: ""}}) => {
    return <Link to={{
        pathname: `${"/order/" + record.sales_order_id + "/show"}`,
        search: "",
        state: {orderId: record.sales_order_id}
    }}> {record.channel_order_no} </Link>
};

/**
 *
 * @param record
 * @returns {*}
 * @constructor
 */
const ShowRequest = ({record = {id: 0}}) => {
    return (record.id != null) ?
        <ShowButton resource="Returnrequest" record={{id: record.id}} basePath={"returnsRequest"}/> : "";
};

/**
 *
 * @param record
 * @returns {*}
 * @constructor
 */
const LinkTracking = ({record = {tracking_url: "", carrier_tracking_number: ""}}) => {
    return (
        <div>
            <br/>
            <FormHelperText> Tracking Number
            </FormHelperText>
            <a rel="noopener noreferrer" target="_blank" href={record.tracking_url}>
                <small>{record.carrier_tracking_number}</small>
            </a>
        </div>);
};

/**
 *
 * @param record
 * @returns {*}
 * @constructor
 */
const LinkTicket = ({record = {warranty_ticket_id: ""}}) => {
    return (<span>
        <label
            className="MuiFormLabel-root MuiInputLabel-root RaLabeled-label-relative MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiInputLabel-marginDense"
            data-shrink="true"><span>Open the ticket in Zendesk</span></label>
            <div><a rel="noopener noreferrer" target="_blank"
                    href={'https://ebuynow.zendesk.com/agent/tickets/'.concat(record.warranty_ticket_id)}> <small>{record.warranty_ticket_id}</small> </a></div>
    </span>);

};
