import React, {Component} from 'react';
import {
    Table,
    TableBody,
    TableHead,
    TableRow,
    TableCell,
    Paper, Typography, Card
} from '@material-ui/core';
import laravelJsonServerClient from '../../restClient'
import {GET_LIST, TopToolbar, ShowButton} from 'react-admin';
import {Link} from "react-router-dom";
import Chip from "@material-ui/core/Chip";
import DoneIcon from "@material-ui/icons/Done";
import ClearIcon from "@material-ui/icons/Clear";
import {authorized} from '../../authorized'

/**
 *
 */
class ReturnRequests extends Component {

    /**
     *
     * @param props
     */
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            id: '',
        }
    }

    /**
     *
     */
    componentDidMount() {
        this.fetchData(this.props.record.id);
    }

    /**
     *
     * @param prevprops
     * @param prevstate
     */
    componentDidUpdate(prevprops, prevstate) {
        if (this.state.id !== prevprops.record.id) {
            this.setState({id: prevprops.record.id});
            this.fetchData(prevprops.record.id);
        }
    }

    /**
     *
     * @param id
     */
    fetchData(id) {
        laravelJsonServerClient(GET_LIST, 'returnsRequest/order', {
            filter: {order_id: id},
        }).then(r => {
            this.setState({
                data: r.data,
            });
        });
    }

    /**
     *
     * @param request
     * @returns {string}
     */
    getFullname(request) {
        return request.firstname + ' ' + request.lastname;
    }

    /**
     *
     * @param request
     * @returns {string}
     */
    getFullAddress(request) {
        return request.address1 + ', ' + request.city + ', ' + request.region + ' '
            + request.postcode + ' ' + request.country;
    }

    /**
     *
     * @param rowIndex
     * @returns {boolean}
     */
    cellClickHandler(rowIndex) {
        if (authorized.permissions.indexOf('customer.returns_request.get') === -1) {
            return false;
        }


        const requests = this.state.data;
        let request = requests[rowIndex];
        if (request) {
            window.location.href = '/#/returnsRequest/' + request.id + '/show';
        }
    }

    render() {
        const requests = this.state.data;

        if (!requests || requests.length === 0) {
            return (
                <div>
                </div>
            )
        }

        const alingRight = {textAlign: 'right'};
        const rowStyle = {cursor: 'pointer'}
        const cardActionStyle = {
            zIndex: 2,
            display: 'inline-block',
            width: '100%',
        };

        return (
            <Paper style={{margin: '20px 0 0 0', padding: '10px'}} zdepth={2}>
                <Card style={{boxShadow: 'none'}}>
                    <TopToolbar style={cardActionStyle}>
                        <Typography style={{float: "left"}} gutterBottom variant="h5" component="h5">
                            Return Requests
                        </Typography>
                    </TopToolbar>
                </Card>

                <Table selectable="true" onRowSelection={this.cellClickHandler.bind(this)}>
                    <TableHead>
                        <TableRow>
                            <TableCell>Ticket Id</TableCell>
                            <TableCell>Customer</TableCell>
                            <TableCell>Address</TableCell>
                            <TableCell>Tracking</TableCell>
                            <TableCell>Label Cost</TableCell>
                            <TableCell>Refund Automatically</TableCell>
                            <TableCell style={alingRight}>Returning Items</TableCell>
                            <TableCell style={alingRight}>Status</TableCell>
                            <TableCell style={alingRight}> </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {requests.map(request =>
                            <TableRow key={request.id} style={rowStyle}>
                                <TableCell className="pl-24">{request.warranty_ticket_id}</TableCell>
                                <TableCell className="pl-24">{this.getFullname(request)}</TableCell>
                                <TableCell className="pl-24">{this.getFullAddress(request)}</TableCell>
                                <TableCell className="pl-24">{request.carrier_tracking_number}</TableCell>
                                <TableCell className="px-24">
                                    {request.return_cost} {request.currency}
                                </TableCell>
                                <TableCell className="px-24">
                                    <Chip icon={request.can_refund_auto === 1 ? <DoneIcon /> : <ClearIcon />} label={request.can_refund_auto === 1 ? 'Yes' : request.can_refund_auto === 0 ? 'No' :  ''} />
                                </TableCell>

                                <TableCell style={alingRight} className="pr-24">{request.skus}</TableCell>
                                <TableCell style={alingRight} className="pr-24">{request.status}</TableCell>
                                <TableCell style={alingRight} className="pr-24"><ShowRequest record={request}/></TableCell>

                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </Paper>
        );
    }
}

const LinktoReturnRequest = ({record}) => {
    return <Link to={{
        pathname: `${"/returnsRequest/" + record.id + "/show"}`,
        search: "",
        state: {id: record.id}
    }}> {record.warranty_ticket_id} </Link>
};

const ShowRequest = ({record}) => {
    return (record.id != null) ? <ShowButton resource="Returnrequest" record={{id: record.id}} basePath={"/returnsRequest"}/> : "";
};

export default ReturnRequests;
