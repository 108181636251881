import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SaveIcon from '@material-ui/icons/Save';
import {SaveButton} from "./OMSToolbar";
import Button from "@material-ui/core/Button";
import CircularProgress from '@material-ui/core/CircularProgress';

export class OMSSaveButton extends Component {
    handleClick = e => {
        const { handleSubmitWithRedirect, redirect, handleState } = this.props;

        if(typeof (handleState) == "function"){
            handleState({'saving': true});
        }

        if (this.props.isSaving || this.props.saving) {
            // prevent double submission
            e.preventDefault();
        } else {
            if (e) {
                e.preventDefault();
            }
            if(typeof(handleSubmitWithRedirect) === "function"){
                //console.log(handleSubmitWithRedirect)
                handleSubmitWithRedirect(redirect)();
            }

        }
    };

    render() {
        const { label, submitOnEnter, onClick, isSaving, saving, disabled, disabledWhenSaving } = this.props;
        const type = submitOnEnter ? 'submit' : 'button';
        const hanelClick = typeof (onClick) == "function" ? onClick : this.handleClick;
        return (<Button type={type} label={label}
                                 startIcon={
                                     isSaving === true || saving === true ? <CircularProgress size={20} color={'inherit'}/> :  <SaveIcon/>
                                 }
                                 disabled={disabled || (disabledWhenSaving == true && isSaving === true)}
                                 onClick={hanelClick}
                                 primary="true"
                                 variant="outlined" color="primary"
                                 style={{
                                     margin: '10px 24px',
                                     position: 'relative',
                                 }}
        >{label}</Button>);
    }
}

OMSSaveButton.propTypes = {
    label: PropTypes.string,
    raised: PropTypes.bool,
    submitOnEnter: PropTypes.bool,
    onClick: PropTypes.func,
    handleSubmitWithRedirect: PropTypes.func,
    handleState: PropTypes.func,
    saving: PropTypes.bool,
    isSaving: PropTypes.any,
    disabled: PropTypes.bool,
    disabledWhenSaving: PropTypes.bool
};

OMSSaveButton.defaultProps = {
    handleSubmitWithRedirect: () => () => {},
    raised: true,
    isSaving: false,
    disabledWhenSaving: false
};


export default OMSSaveButton;
