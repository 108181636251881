import React, {Component} from 'react';
import {Dialog, CardActions, DialogActions, Button, DialogTitle, DialogContent} from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import EditIcon from '@material-ui/icons/Edit';
import {BooleanInput} from "react-admin";
import {
    SimpleForm,
    NumberInput,
    TextInput,
    UPDATE, GET_ONE,
    refreshView as refreshViewAction
} from "react-admin";
import laravelJsonServerClient from '../../restClient'
import {connect} from 'react-redux';
import {authorized} from '../../authorized'
/**
 *
 */
class EditInventoryBtn extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            open: false,
            dialog: false,
            values: {},
            allowSubmit: true,
            roles: '',
            permissions: '',
            data: ''
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.validate = this.validate.bind(this);
    };

    validate(value) {
        Object.keys(value).forEach(key => {
            if (this.state.values.hasOwnProperty(key) === false || this.state.values[key] !== value[key]) {
                this.setState({values: value});
            }
        });
    }

    fetchData() {
        laravelJsonServerClient(GET_ONE, 'inventory', {id: this.props.record.id}).then(r => {
            this.setState({
                data: r.data ? r.data : [],
            });
        });
    }

    handleSubmit() {
        const values = this.state.values;
        laravelJsonServerClient(UPDATE, 'inventory', {id: values.id, data: values}).then(
            r => {
                if (r.data.result) {
                    this.handleClose();
                    this.props.refreshView();
                } else {
                    this.handleClose();
                    alert(r.data.message);
                }
            }
        ).catch((e) => {
            console.error(e);
        });
    }

    showModal = () => {
        this.fetchData();
        this.setState({show: true});
        this.setState({roles: localStorage.getItem('roles')});
        this.setState({permissions: localStorage.getItem('permissions')});
    };

    hideModal = () => {
        this.setState({show: false});
    };

    handleClose = () => {
        this.setState({show: false});
    };

    render() {
        let record = this.props.record;
        let actions = [
            <Button
                label="Dismiss"
                primary="true"
                key={'primary'}
                size={"small"}
                color={"primary"}
                variant="outlined"
                onClick={this.handleClose}>Dismiss</Button>
        ];


        if (authorized.permissions.indexOf('inventory.routing.edit') !== -1 ||
            authorized.permissions.indexOf('inventory.transfer.edit') !== -1 ||
            authorized.permissions.indexOf('inventory.edit') !== -1 ||
            authorized.permissions.indexOf('product.edit') !== -1
        ) {
            actions.push(
                <Button
                    label="Submit"
                    key={'secondary'}
                    primary="true"
                    variant={"outlined"} color={"secondary"}
                    size={"small"}
                    startIcon={<SaveIcon/>}
                    onClick={this.handleSubmit}>Submit</Button>
            );
            actions = actions.reverse();
        }

        let buttonEdit = '';
        if (record) {
            buttonEdit = <Button
                label=""
                size="small"
                style={{overflow: 'inherit', color: '#000'}}
                onClick={this.showModal}
                startIcon={<EditIcon/>}>
                Edit
            </Button>
        }

        if (this.state.data !== '') {
            record = this.state.data;
        }

        return (
            <main>
                {buttonEdit}
                <Dialog
                    bodyClassName={"modal-edit-address"}
                    title={record.sku}
                    actions={actions}
                    modal={true}
                    maxWidth={'sm'}
                    fullWidth={'true'}
                    open={this.state.show}
                    autoScrollBodyContent={true}
                >
                    <DialogTitle id="customized-dialog-title" onClose={this.handleClose}>
                        {record.sku}
                    </DialogTitle>
                    <DialogContent dividers>
                        <span>
                            <SimpleForm submitOnEnter={false}
                                        defaultValue={record}
                                        save={this.handleSubmit}
                                        validate={this.validate}
                                        toolbar={<CardActions/>} handleSubmit={this.handleClose}
                                        redirect={false}>
                                <TextInput fullWidth={true} disabled source="id"/>
                                <BooleanInput label="Archive" fullWidth={true} source="archive"/>
                                <TextInput fullWidth={true} disabled source="warehouse_name"/>
                                <TextInput fullWidth={true} source="warehouse_sku"/>
                                <TextInput fullWidth={true} source="product_upc" label="UPC"/>
                                <NumberInput fullWidth={true} className='customInventoryInput' source="qty"/>
                                <NumberInput className='customInventoryInput' source="available_qty" fullWidth={true}/>
                                <NumberInput className='customInventoryInput' fullWidth={true}
                                             source="in_transit"/>
                            </SimpleForm>
                        </span>
                    </DialogContent>
                    <DialogActions>
                        {actions}
                    </DialogActions>
                </Dialog>
            </main>
        );
    }
}


export default connect(undefined, {refreshView: refreshViewAction})(
    EditInventoryBtn
);

