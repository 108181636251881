import React, {Component} from 'react';
import {
    Dialog,
    CardActions,
    DialogActions,
    Button,
    DialogTitle,
    DialogContent,
} from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import EditIcon from '@material-ui/icons/Edit';
import RefreshIcon from '@material-ui/icons/Refresh';
import {
    SimpleForm,
    TextInput,
    UPDATE, GET_ONE,
    refreshView as refreshViewAction,
    required
} from "react-admin";
import laravelJsonServerClient from '../../restClient'
import {connect} from 'react-redux';

/**
 *
 */
class EditUserApiBtn extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            open: false,
            dialog: false,
            values: null,
            allowSubmit: true,
            roles: '',
            permissions: '',
            data: ''
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.validate = this.validate.bind(this);
        this.handleRefreshToken = this.handleRefreshToken.bind(this);
    };

    /**
     *
     * @param value
     */
    validate(value) {
        this.setState({values: value});
    }

    /**
     *
     */
    fetchData() {
        laravelJsonServerClient(GET_ONE, 'user', {id: this.props.record.id}).then(r => {
            this.setState({
                data: r.data ? r.data : [],
            });
        });
    }

    handleSubmit() {
        const values = this.state.values;
        laravelJsonServerClient(UPDATE, 'user_api', {id: this.props.record.id, data: values}).then(
            r => {
                if (r.data.result) {
                    this.handleClose();
                    this.props.refreshView();
                } else {
                    this.handleClose();
                    alert(r.data.message);
                }
            }
        ).catch((e) => {
            console.error(e);
        });
    }

    handleRefreshToken() {
        laravelJsonServerClient(UPDATE, 'user_api', {id: this.props.record.id, data: {refresh_token: true}}).then(
            r => {
                if (r.data.result && r.data.auth_token) {
                    this.props.refreshView();
                } else {
                    this.handleClose();
                    alert(r.data.message);
                }
            }
        ).catch((e) => {
            console.error(e);
        });
    }

    /**
     *
     */
    showModal = () => {
        this.fetchData();
        this.setState({show: true});
    };

    /**
     *
     */
    hideModal = () => {
        this.setState({show: false});
    };

    /**
     *
     */
    handleClose = () => {
        this.setState({show: false});
    };

    render() {
        let record = this.props.record;
        const actions = [
            <Button
                label="Update"
                key={'secondary'}
                primary="true"
                variant={"outlined"} color={"secondary"}
                size={"small"}
                startIcon={<SaveIcon/>}
                onClick={this.handleSubmit}>Update</Button>,
            <Button
                label="Refresh Token"
                variant={"outlined"} color={"secondary"}
                size={"small"}
                startIcon={<RefreshIcon/>}
                onClick={this.handleRefreshToken}>Refresh Token</Button>,
            <Button
                label="Dismiss"
                primary="true"
                key={'primary'}
                size={"small"}
                color={"primary"}
                variant="outlined"
                onClick={this.handleClose}>Dismiss</Button>
        ];

        let buttonEdit = '';
        if (record){
            buttonEdit =  <Button
                label=""
                size="small"
                style={{overflow: 'inherit', color: '#000'}}
                onClick={this.showModal}
                startIcon={<EditIcon/>} >
                Edit
            </Button>
        }

        return (
            <main>
                {buttonEdit}
                <Dialog
                    bodyClassName={"modal-edit-api"}
                    title={record.email}
                    actions={actions}
                    modal={true}
                    open={this.state.show}
                    autoScrollBodyContent={true}
                >
                    <DialogTitle id="modal-edit-api-dialog-title" onClose={this.handleClose}>Update Api Key</DialogTitle>
                    <DialogContent dividers>
                        <span>
                            <SimpleForm submitOnEnter={false}
                                        initialValues={record}
                                        save={this.handleSubmit}
                                        validate={this.validate}
                                        toolbar={<CardActions/>}
                                        handleSubmit={this.handleClose}
                                        redirect={false}>
                                <TextInput source="name" validate={required()} />
                                <TextInput source="auth_token" disabled />
                            </SimpleForm>
                        </span>
                    </DialogContent>
                    <DialogActions>
                        {actions}
                    </DialogActions>
                </Dialog>
            </main>
        );
    }
}

/**
 *
 */
export default connect(undefined, {refreshView: refreshViewAction})(
    EditUserApiBtn
);

