import React, {Component} from 'react';
import laravelJsonServerClient from '../../restClient'
import {refreshView as refreshViewAction, UPDATE} from "react-admin";
import PropTypes from "prop-types";
import {Snackbar, Button} from "@material-ui/core";
import {warehouseFilter} from "../../config";
import * as _ from 'lodash'
import {connect} from "react-redux";
import {defaultReSource, authorized} from '../../authorized'
/**
 *
 */
class FulfillmentIssueBtn extends Component {
    constructor(props) {
        super(props);
        this.state = {
            autoHideDuration: 4000,
            open: false,
            orderId: this.props.orderId,
            roles: '',
            permissions: ''
        };
    };

    handleClick = () => {
        this.setState({
            open: true,
        });
        laravelJsonServerClient(UPDATE, 'order/fulfill', {id: this.props.orderId}).then(
            r => {
                setTimeout(function () {
                    window.location.reload();
                }, 2000)
            }
        ).catch((e) => {
            console.error(e);
        });
    };

    /**
     *
     * @returns {*}
     */
    render() {
        const record = this.props.record;

        let showButton = this.props.force === true;
        if (record && record.items) {
            record.items.forEach(function (item) {
                if (item !== undefined && (item.status === 'export_error' || item.status === 'processing')) {
                    showButton = true;
                }
            });
        }

        const {label, raised, data} = this.props;
        const ButtonComponent = raised ? Button : Button;
        const labelText = label ? label : 'EXPORT TO: ' + _.result(_.find(warehouseFilter, {_id: data.warehouse_id}), 'name');
        const styles = {
            button: {
                marginLeft: 5,
                marginRight: 5,
            },
        };
        return ((showButton === true) ?
                <span>
                <Button
                    color="primary" variant="outlined"
                    size="small"
                    onClick={this.handleClick}
                    id="export_warehouse_button"
                    className="display-none px-5"
                    title={data !== undefined ? data.export_message : ''}
                    label={labelText}
                    style={styles.button}
                >
                    {labelText}
                </Button>
                <Snackbar
                    open={this.state.open}
                    message="Requesting order fulfillment."
                    autoHideDuration={this.state.autoHideDuration}
                    onRequestClose={this.handleRequestClose}
                />
            </span>
                :
                <span></span>
        );
    }
}

FulfillmentIssueBtn.propTypes = {
    label: PropTypes.string,
    raised: PropTypes.bool,
    orderId: PropTypes.any,
    data: PropTypes.any,
    record: PropTypes.any,
    force: PropTypes.bool
};

export default connect(undefined, {refreshView: refreshViewAction})(
    FulfillmentIssueBtn
);
