import React, {Component} from 'react';
import {
    Datagrid, DateInput,
    Filter,
    List,
    Pagination,
    TextField, TextInput,
    TopToolbar
} from 'react-admin';
import EditProductModelBtn from "./editProductModelBtn";
import {config} from "../../config";
import {Link} from "react-router-dom";
import SdCategoryIcon from '@material-ui/icons/Category';
import AddProductModelBtn from "./addProductModelBtn";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import {authorized} from "../../authorized";
import OMSDeleteRecordBtn from "../buttons/OMSDeleteRecordBtn";

/**
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const ModelsFilters = (props) => (
    <Filter {...props}>
        <TextInput alwaysOn text="Name" source="name"/>
        <TextInput text="Product line" source="product_line"/>
        <TextInput text="Category" source="category"/>
        <DateInput text="To date" source="created_at_to"/>
        <DateInput text="From date" source="created_at_from"/>
    </Filter>
);

/**
 *
 * @param resource
 * @param filters
 * @param displayedFilters
 * @param filterValues
 * @param basePath
 * @param showFilter
 * @returns {*}
 * @constructor
 */
const ModelsActions = ({resource, filters, displayedFilters, filterValues, basePath, showFilter}) => (
    <TopToolbar style={cardActionStyle}>
        <AddProductModelBtn/>
        <Link className="MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-textSizeSmall MuiButton-textPrimary" to="/product"><SdCategoryIcon/> Products</Link>
        {filters && React.cloneElement(filters, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: 'button'
        })}
    </TopToolbar>
);

const ModelsPagination = props => <Pagination rowsPerPageOptions={[config.perPage]} {...props} />;

const Empty = () => {
    return (
        <Box textAlign="center" m={1} style={{paddingTop: '25vh'}}>
            <svg className="MuiSvgIcon-root text-size-4em" focusable="false" viewBox="0 0 24 24" aria-hidden="true">
                <path
                    d="M19 3H4.99c-1.11 0-1.98.89-1.98 2L3 19c0 1.1.88 2 1.99 2H19c1.1 0 2-.9 2-2V5c0-1.11-.9-2-2-2zm0 12h-4c0 1.66-1.35 3-3 3s-3-1.34-3-3H4.99V5H19v10z"></path>
            </svg>
            <Typography variant="h5" paragraph>
                No Product-model yet.
            </Typography>
            {(authorized.permissions.indexOf('product.model.create') !== -1) ? 'Do you want to create one?' : ''}
            <AddProductModelBtn/>
        </Box>
    );
};


export const ModelList = (props) => (
    <span>
        <List {...props} actions={<ModelsActions/>}
              empty={<Empty/>}
              title="Product Models"
              filters={<ModelsFilters/>} perPage={config.perPage}
              bulkActionButtons={false}
              pagination={<ModelsPagination/>}
              sort={{field: 'category', order: 'ASC'}}>
            <Datagrid>
                <TextField label="Name" source="name"/>
                <TextField source="category"/>
                <TextField source="weight"/>
                <TextField source="width"/>
                <TextField source="height"/>
                <TextField source="depth"/>
                <EditProductModelBtn/>
                <OMSDeleteRecordBtn permissionAllowed="product.model.delete" resource="product-model"/>
            </Datagrid>
        </List>
    </span>
);

/**
 * @type {{display: string, float: string, zIndex: number}}
 */
const cardActionStyle = {
    zIndex: 2,
    display: 'inline-block',
    float: 'right',
};