const tzOffset = new Date().getTimezoneOffset() / 60;
export const dateParser = (v,k, offset) => {

    const regexp = /(\d{4})-(\d{2})-(\d{2})/
    var match = regexp.exec(v);
    if (match === null) return;

    var year = match[1];
    var month = match[2];
    var day = match[3];

    var date = new Date(v);
    if (tzOffset < 0 && offset === undefined) {
        date.setDate(date.getDate() + 1);
    }else {
        date.setDate(date.getDate());
    }

    match = regexp.exec(date.toISOString());
    year = match[1];
    month = match[2];
    day = match[3];

    let time = '';
    const beginDate = ['created_at_from','apply_at'];
    const endDate = ['created_at_to', 'deposit_date_to'];
    time = beginDate.includes(k) ? ' 00:00:00' : '';
    time = endDate.includes(k) ? ' 23:59:59' : time;

    const d = [year, month, day].join("-") + time;
    return d;
};