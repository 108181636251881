import {config} from './config.js'
import {fetchUtils} from "react-admin";

function getParams(token) {
    return  {
        method: 'POST',
        body: {},
        data: {},
        headers: new Headers({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        })
    }
};

const helper =  {
    checkToken: () => {
        return localStorage.getItem('token') ?
            Promise.resolve() :
            Promise.reject({ redirectTo: '/login', message: 'Token is missing' });
    },
    getAcl: () => {
        return fetchUtils.fetchJson(config.apiURL + '/acl', getParams(localStorage.getItem('token')))
            .then(response => {
                const data = response.json;

                if (data && data.result === 'true') {
                    localStorage.setItem('roles', data.roles !== undefined ? data.roles : null);
                    localStorage.setItem('user_id', data.user_id !== undefined ? data.user_id : 0);
                    localStorage.setItem('permissions', data.permissions !== undefined ? data.permissions : null);
                }

                return data;
            })
            .catch(error => {
                return error;
            });
    },
    getWarehouses: async () => {
        const warehouses = localStorage.getItem('warehouse');
        if (!warehouses) {
            return await fetchUtils.fetchJson(config.apiURL + '/warehouse/config', getParams(localStorage.getItem('token')))
                .then(response => {
                    const data = response.json;
                    if (data) {
                        localStorage.setItem('warehouse', data !== null ? JSON.stringify(data['data']) : null);
                    }
                }).catch(error => {
                    console.log('Error on loading warehouses data', error);
                });
        }
    },
    getErrorMessage: (data) => {
        let message = ''
        if (data.error) {
            message = data.error;
        } else if (data.message)  {
            message = data.message
        }
        return message;
    }
}
const authProvider = {
    login: (token) =>  {
        localStorage.setItem('token', token);
        console.log('Token: ' + token);
        return  helper.getAcl().then(function(data) {
            if (data && data.result === false) {
                console.log(data)
                return Promise.reject(helper.getErrorMessage(data))
            }
            window.location.href = '#/order'
            return Promise.resolve();
        });
    },
    logout: () => {
        localStorage.removeItem('token');
        localStorage.removeItem('roles');
        localStorage.removeItem('permissions');
        localStorage.removeItem('user_id');
        localStorage.removeItem('warehouse');

        return Promise.resolve();
    },
    checkAuth: () => {
        // Required for the authentication to work
        return helper.checkToken().then(() => {
            return helper.getAcl().then(function(data) {
                if (data && data.result === false) {
                    return Promise.reject({ redirectTo: '/login', message: 'Session has been ended' });
                }
                return Promise.resolve();
            });
        }).catch((error) => {
            return Promise.reject(error);
        })
    },
    getPermissions: () => {
        helper.getWarehouses(localStorage.getItem('token'));

        if (localStorage.getItem('roles') && localStorage.getItem('permissions') ) {
            return Promise.resolve();
        }

        return  helper.getAcl().then(function(data) {
            if (data && data.result === false) {
                console.log(data)
                return Promise.reject(helper.getErrorMessage(data))
            }
            return Promise.resolve();
        });
    },
};

export default authProvider;