import React, {Component} from 'react';
import OMSSelectInput from "../form/OMSSelectInput";
import {channels, warehouseFilter} from "../../config";
import {DateInput, SelectInput, GET_LIST, GET_ONE, SaveButton, Filter} from "react-admin";
import { SimpleForm } from "react-admin";
import OMSToolbar from "../form/OMSToolbar";
import moment from "moment";
import laravelJsonServerClient from '../../restClient';
import {dateParser} from "../filters/dateParser";
import {defaultReSource, authorized} from '../../authorized'

/**
 *
 */
class SalesReportDownload extends Component {
    constructor(props) {
        super(props);

        const now = dateParser(moment().utc().format(''), 'created_at_to', false);
        const startOfMonth = dateParser(moment().startOf('month').utc().format(), 'created_at_from');

        this.timeout = 180000;
        let allChannelOption = [{_id: -1, name: 'All channels'}];
        let allWarehouselOption = [{_id: -1, name: 'All warehouses'}];
        this.state = {
            selectChannelDefault: -1,
            _channels: allChannelOption.concat(channels),
            _warehouses: allWarehouselOption.concat(warehouseFilter),
            _startDate: startOfMonth,
            _endDate: now,
            saving: false,
            roles: '',
            permissions: ''
        };
    };

    handleSubmit = (values) => {
        this.setState({saving: true});
        const uniqueId = 'salereport_' + Math.random().toString(36).substr(2, 9);

        const timer = setTimeout(() => {
            this.removeReport(uniqueId)
        }, this.timeout);

        laravelJsonServerClient(GET_LIST, 'sales-reports/download', {
            filter: {channel_id: values.channel_id, created_at_from: values.created_at_from, created_at_to: values.created_at_to, id: uniqueId, warehouse_id: values.warehouse_id < 0 ? null : values.warehouse_id },
        }).then(r => {
            this.setState({saving: false});
            if (r.data.report) {

                const fileName = 'saleReport-' + moment().utc().format('YYYY-MM-DD-h:mm-a') + '.xlsx';
                let downloadLink = document.createElement("a");
                downloadLink.href = r.data.report;
                downloadLink.download = fileName;
                downloadLink.click();
                clearTimeout(timer);
            }else{
                this.removeReport(uniqueId);
                clearTimeout(timer);
            }
        });
    };


    removeReport(report){
        this.setState({saving: false});
        alert("An error occurred while creating a report file to download!");
        laravelJsonServerClient(GET_ONE, 'sales-reports/remove', {id: report}).then(r => {});
    }

    render() {
        const PostCreateToolbar = props => <OMSToolbar {...props} >
            <SaveButton saving={this.state.saving} label="Generate report"/>
        </OMSToolbar>;

        return (
            <main className="sales-report-form">
                <SimpleForm toolbar={<PostCreateToolbar/>} save={this.handleSubmit.bind(this)} >
                    <SelectInput label="Channel" source="channel_id" name="channel_id" choices={this.state._channels}
                                    optionText="name" optionValue="_id"
                                    defaultValue={this.state.selectChannelDefault}/>
                    <SelectInput label="Warehouse" source="warehouse_id" choices={this.state._warehouses} optionValue="_id" optionsText="name"  defaultValue={this.state.selectChannelDefault}/>
                    <DateInput label="From date" source="created_at_from" name="created_at_from"
                                defaultValue={this.state._startDate} parse={dateParser}/>
                    <DateInput label="To date" source="created_at_to" name="created_at_to" defaultValue={this.state._endDate} parse={dateParser}/>
                </SimpleForm>
            </main>
        );
    }
}

export default SalesReportDownload;
