import React, {Component} from 'react';
import {
    Dialog,
    CardActions,
    DialogActions,
    Button,
    DialogTitle,
    DialogContent,
    TableRow,
    TableCell
} from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import EditIcon from '@material-ui/icons/Edit';
import * as _ from 'lodash'
import {
    SimpleForm,
    BooleanInput,
    UPDATE, GET_ONE,
    refreshView as refreshViewAction
} from "react-admin";
import laravelJsonServerClient from '../../restClient'
import {connect} from 'react-redux';
import Grid from "@material-ui/core/Grid";

/**
 *
 */
class EditRoleBtn extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            open: false,
            dialog: false,
            values: {},
            allowSubmit: true,
            roles: '',
            permissions: '',
            data: ''
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.validate = this.validate.bind(this);
    };

    /**
     *
     * @param value
     */
    validate(value) {
        Object.keys(value).forEach(key => {
            if (this.state.values.hasOwnProperty(key) === false || this.state.values[key] !== value[key]) {
                this.setState({values: value});
            }
        });
    }

    /**
     *
     */
    fetchData() {
        laravelJsonServerClient(GET_ONE, 'roles', {id: this.props.record.id}).then(r => {
            this.setState({
                data: r.data ? r.data : [],
            });
        });
    }

    /**
     *
     */
    handleSubmit() {
        const values = this.state.values;
        laravelJsonServerClient(UPDATE, 'roles', {id: this.props.record.id, data: values}).then(
            r => {
                if (r.data.result) {
                    this.handleClose();
                    this.props.refreshView();
                } else {
                    this.handleClose();
                    alert(r.data.message);
                }
            }
        ).catch((e) => {
            console.error(e);
        });
    }

    /**
     *
     */
    showModal = () => {
        this.fetchData();
        this.setState({show: true});
    };

    /**
     *
     */
    hideModal = () => {
        this.setState({show: false});
    };

    /**
     *
     */
    handleClose = () => {
        this.setState({show: false});
    };

    render() {
        let record = this.props.record;
        const actions = [
            <Button
                label="Submit"
                key={'secondary'}
                primary="true"
                variant={"outlined"} color={"secondary"}
                size={"small"}
                startIcon={<SaveIcon/>}
                onClick={this.handleSubmit}>Submit</Button>,
            <Button
                label="Dismiss"
                primary="true"
                key={'primary'}
                size={"small"}
                color={"primary"}
                variant="outlined"
                onClick={this.handleClose}>Dismiss</Button>
        ];

        let buttonEdit = '';
        if (record) {
            buttonEdit = <Button
                label=""
                size="small"
                style={{overflow: 'inherit', color: '#000'}}
                onClick={this.showModal}
                startIcon={<EditIcon/>}>
                Edit
            </Button>
        }

        /**
         *
         * @param props
         * @constructor
         */
        const ListBooleanInput = props => {
            if (this.state.data.length === 0) {
                return <span/>
            }

            let items = [];
            this.state.data.ListPermission.forEach((item) => {
                let isActive = this.state.data.RolePermissions.indexOf(item.id) !== -1
                items.push(<Grid item lg={4} xs={4} sm={4}><BooleanInput style={{width: "100%"}}
                                                                         label={item.desc !== "" ? item.desc : item.name}
                                                                         source={item.name.replaceAll('.', '--')}
                                                                         initialValue={isActive}/></Grid>);
            });

            return (items)
        }


        return (
            <main>
                {buttonEdit}
                <Dialog
                    bodyClassName={"modal-edit-address"}
                    title={record.name}
                    actions={actions}
                    modal={true}
                    maxWidth={'lg'}
                    fullWidth={'true'}
                    open={this.state.show}
                    autoScrollBodyContent={true}
                >
                    <DialogTitle id="customized-dialog-title" onClose={this.handleClose}>
                        {record.name}
                    </DialogTitle>
                    <DialogContent dividers>
                        <span>
                            <SimpleForm submitOnEnter={false}
                                        defaultValue={record}
                                        save={this.handleSubmit}
                                        validate={this.validate}
                                        toolbar={<CardActions/>}
                                        handleSubmit={this.handleClose}
                                        redirect={false}>
                                <Grid container spacing={3} style={{width: '100%'}}>
                                <ListBooleanInput/>
                                 </Grid>
                            </SimpleForm>
                        </span>
                    </DialogContent>
                    <DialogActions>
                        {actions}
                    </DialogActions>
                </Dialog>
            </main>
        );
    }
}

/**
 *
 */
export default connect(undefined, {refreshView: refreshViewAction})(
    EditRoleBtn
);

