import * as React from 'react';
import {
    List,
    Datagrid,
    TextField,
    Filter,
    Show,
    SimpleShowLayout,
    ShowButton,
    RefreshButton,
    TopToolbar,
    Pagination
} from 'react-admin';
import {SelectInput, DateInput, TextInput} from 'react-admin';
import {channelStatus, getChannelName} from './channels';
import {cloneElement} from 'react';
import {channels, config} from "../config";
import Addresses from './orders/addresses'

import Items from './orders/items'
import Comments from './orders/Comments'

import Shipments from './orders/shipments'
import ReShipments from './orders/reshipments'
import ReturnRequestButton from './orders/actions/returnRequestButton'
import ReturnRequests from './returns/returnRequests'
import ReShipBtn from './orders/actions/ReShipRequestButton'
import ReShipResendEmailBtn from "./orders/actions/ReShipResendEmailButton";
import {getFullName} from "../rest/common";
import OrderInformation from "./orders/orderInformation";
import FulfilmentIssueBtn from "./buttons/FulfillmentIssueBtn";
import ListBtn from "./buttons/ListBtn";
import Invoice from "./orders/invoice";
import {warehouseFilter} from "../config";
import {dateParser} from "./filters/dateParser";
import moment from "moment";
import OrderHistories from "./orders/orderHistories";
import CancelOrderBtn from "./buttons/cancelOrderBtn";
import SettlementItems from "./orders/settlementItems";
import RefundRequest from "./orders/actions/RefundRequest";
import GenerateLabelButton from "./orders/actions/generateLabelButton";


import OrderFiles from "./orders/OrderFiles";
import {defaultReSource, authorized} from '../authorized'

const OMSPagination = props => <Pagination rowsPerPageOptions={[config.perPage]} {...props} />;

const OrderFilters = (props) => {
    return (
        <Filter {...props} >
            <SelectInput source="channel_id" choices={channels} optionText="name" optionValue="_id"/>
            <DateInput text="To date" source="created_at_to"/>
            <DateInput text="From date" source="created_at_from"/>
            <TextInput label="Order #" source="channel_order_no" alwaysOn/>
            <TextInput label="Invoice #" source="invoice_no"/>
            <TextInput label="Sku" source="sku"/>
            <SelectInput label="Warehouse" source="warehouse_id" choices={warehouseFilter} optionValue="_id"
                         optionsText="name"/>
            <SelectInput label="Status" source="internal_status" choices={[
                {id: 'pending', name: 'pending'},
                {id: 'processing', name: 'processing'},
                {id: 'exported', name: 'exported'},
                {id: 'shipped', name: 'shipped'},
                {id: 'canceled', name: 'canceled'},
                {id: 'refunded', name: 'refunded'},
                {id: 'partially_refunded', name: 'partially_refunded'},
                {id: 'delivered', name: 'delivered'},
            ]}/>
            <TextInput label="Customers address" source="customer_address"/>
            <TextInput text="Telephone" source="telephone"/>
            <TextInput alwaysOn text="Customer Name" source="customer_name"/>
            <TextInput alwaysOn text="Customer email" source="customer_email"/>
        </Filter>
    );

}
/**
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const ListActions = (props) => {
    return (
        <TopToolbar>
            {cloneElement(props.filters, {context: 'button'})}
        </TopToolbar>
    );
};

const AuthShowButton = (props) => {

    if (authorized.permissions.indexOf('order.get') === -1) {
        return (<span/>)
    }
    return <ShowButton/>
}

export const OrderList = (props) => {
    if (authorized.permissions.indexOf('order.list') === -1) {
        return (<span/>)
    }
    return (<List {...props}
                  perPage={config.perPage}
                  pagination={<OMSPagination/>}
                  actions={<ListActions/>}
                  filters={<OrderFilters/>}
                  bulkActionButtons={false}
                  sort={{field: 'channel_created_at', order: 'DESC'}}>
            <Datagrid>
                <ChannelName label="Channel" source="channel_id"/>
                <TextField label="Created At" source="channel_created_at"/>
                <TextField label="Order #" source="channel_order_no"/>
                <TextField label="Status" source="internal_status"/>
                <FullName source="customer_name"/>
                <TextField source="customer_email"/>
                <OrderTotal label="Total" source="grand_total"/>
                <TextField label="Items" source="skus"/>
                {AuthShowButton()}
            </Datagrid>
        </List>
    );
}

const OrderActions = ({basePath, props, data}) => (
    <TopToolbar {...props}>
        <GenerateLabelButton record={data}/>
        <ReShipResendEmailBtn record={data}/>
        <ReShipBtn record={data}/>
        <ExportWarehouseAction record={data}/>
        <CancelOrderBtn record={data}/>
        <RefundRequest record={data} open={false}/>
        <ReturnRequestButton record={data}/>
        <ListBtn basePath={basePath}/>
    </TopToolbar>
);

export const OrderShow = (props) => {
    if (authorized.permissions.indexOf('order.get') === -1) {
        return (<span/>)
    }
    return (<main className="orderDetail">
            <Show title={<OrderTitle/>} actions={<OrderActions/>} {...props}>
                <SimpleShowLayout>
                    <OrderInformation/>
                    <Addresses/>
                    <OrderFiles/>
                    <Items/>
                    <OrderHistories/>
                    <Invoice/>
                    <Comments/>
                    <Shipments/>
                    <ReShipments/>
                    <ReturnRequests/>
                    <SettlementItems/>
                </SimpleShowLayout>
            </Show>
        </main>
    );
};

const OrderTitle = ({record = {channel_order_no: ""}}) => {
    return <span>{record ? `${record.channel_order_no}` : ''}</span>;
};

/**
 *
 * @param record
 * @returns {*}
 * @constructor
 */
const ChannelName = ({record = {channel_id: ""}}) => {
    return <span>{(record) ? getChannelName(record.channel_id) : record.channel_id}</span>;
};

/**
 *
 * @param record
 * @returns {*}
 * @constructor
 */
const OrderTotal = ({record = {order_currency_code: "", grand_total: 0}}) => (
    <span>{(record) ? record.grand_total.toFixed(2) + " " + record.order_currency_code : ""}</span>
);

/**
 *
 * @param record
 * @returns {*}
 * @constructor
 */
const ExportWarehouseAction = ({record = {id: 0}}) => {
    if (authorized.permissions.indexOf('order.fulfillment_issues.edit') === -1) {
        return (<span/>)
    }
    return (<FulfilmentIssueBtn label="EXPORT TO WAREHOUSE" record={record} orderId={record.id}/>);
};

const defautDate = dateParser(moment().subtract(4, 'months').format(''), 'created_at_from');

const FullName = ({record}) => {
    return <span>{getFullName(record)}</span>;
};

const cardActionStyle = {
    zIndex: 2,
    display: 'inline-block',
    float: 'right',
};
