import React, {Component, Fragment, useCallback} from 'react';
import laravelJsonServerClient from '../../restClient'
import {
    CREATE,
    GET_ONE,
    GET_LIST,
    FormTab,
    TextInput,
    SelectInput,
    required,
    minValue,
    TabbedForm,
    Toolbar,
    NumberInput,
    number
} from 'react-admin';
import {
    Table,
    TableBody,
    TableRow,
    CardHeader,
    TableHead,TableCell
} from '@material-ui/core';
import { useField, useForm} from 'react-final-form';
import OMSSaveButton from "../form/OMSSaveButton";
import swal from 'sweetalert';
import {getUrlParameter} from "../../rest/common";
import TextField from '@material-ui/core/TextField'
import {defaultReSource, authorized} from '../../authorized'
/**
 *
 */
class ShipmentForm extends Component {

    constructor(props) {
        super(props);
        let location = window.location.hash;
        this.state = {
            styleToolbar: 'hideToolBar',
            orderId: 0,
            orderNumber: '',
            items: [],
            validateItems: [],
            saving: false,
            timeouts: [],
            preparedItem: false,
            trackingCodes: {},
            location: location,
            roles: '',
            permissions: '',
            values: null
        };

        this.validate = this.validate.bind(this);
    }

    handleChange(value, item, newitem, key) {
        const items = this.state.items;
        for (let i = 0; i < items.length; i++) {
            let currentItem = items[i];
            if (currentItem['id'] === item['id']) {
                items[i][key] = newitem;
                if (key === 'qty_to_ship') {
                    items[i]['qty_to_ship_' + item['id']] = newitem;
                }
            }
        }

        this.setState({items: items});
        this.validateItemsToShip();
    }

    doSubmit = (formData) => {
        if(this.validate(formData) === false){
            alert('Please Select Qty');
            return;
        }

        let _this = this;
        formData.sales_order_id = _this.state.orderId;
        this.setState({saving: true});
        laravelJsonServerClient(CREATE, 'shipment', {'data': formData}).then(r => {
            if (r.data.json.error !== undefined && r.data.json.error === false) {
                _this.props.history.push('/order/' + _this.state.orderId + '/show');
            } else {
                swal(r.data.json.message, {title: 'NOTIFY'});
                _this.setState({saving: false});
            }
        }).catch((e) => {
            console.error(e);
        });
    };

    componentDidMount() {
        let orderId = getUrlParameter('orderId', this.state.location);
        if (orderId) {
            this.orderId = orderId;
            this.setState({orderId: orderId});
            this.fetchData();
        } else {
            alert('Order ID not found');
        }
    }

    loadShipmentTrackingCodes() {
        let _this = this;
        laravelJsonServerClient(GET_ONE, 'shipment/tracking/codes', {id: 0}).then(
            r => {
                _this.setState({trackingCodes: r.data});
                _this.prepairData();
            }
        ).catch((e) => {
            console.error(e);
        });
    }

    prepairData() {
        const items = this.state.items;
        const validateItems = [];

        for (let i = 0; i < items.length; i++) {
            let current = items[i];
            items[i]['qty_ordered_' + current['id']] = items[i]['qty_ordered'];
            items[i]['sku_' + current['id']] = items[i]['sku'];
            items[i]['qty_to_ship_' + current['id']] = this.calculatorQtyToShipDefault(items[i]);
            validateItems.push('qty_' + current['id']);
            validateItems.push('sku_' + current['id']);
        }

        this.setState({
            items: items,
            preparedItem: true,
            validateItems: validateItems
        });
        //this.props.record items = items;
    }

    calculatorQtyToShipDefault(item) {
        let defaultShip = 0;
        if (item['qty_ordered'] !== undefined) {
            defaultShip = item['qty_ordered'] - item['qty_shipped'];
            if (item['qty_invoiced'] < defaultShip) {
                defaultShip = item['qty_invoiced'];
            }
        }

        return defaultShip;
    }

    fetchData() {
        let _this = this;
        if (this.orderId) {
            laravelJsonServerClient(GET_LIST, 'items', {
                filter: {order_id: this.orderId, realItem: true},
            }).then(r => {

                _this.setState({
                    items: r.data,
                    orderNumber: r.data[0].channel_order_no
                });
                _this.loadShipmentTrackingCodes();
            });
        }
    }



    validate(value) {
        let styleToolbar = '';
        let _this = this;
        let allowedFields = ['carrier_tracking_code', 'carrier_tracking_number'];

        for (let i = 0; i < allowedFields.length; i++) {
            let item = allowedFields[i];
            if (value[item] === '' || value[item] === null || value[item] === undefined) {
                styleToolbar = 'hideToolBar';
                this.setState({
                    styleToolbar: styleToolbar
                });
                return;
            }
        }
        _this.validateItemsToShip(value);
    }

    validateItemsToShip(value) {
        let styleToolbar = 'hideToolBar';
        let items = this.state.items;
        if (value === undefined || !value) {
            return false;
        }

        let allKey = Object.keys(value);
        let customKey = [];
        allKey.forEach(function (key) {
            if (key.indexOf("qty_to_ship") >= 0) {
                if(value[key] > 0) {
                    let keys = key.split('qty_to_ship_');
                    if (keys[1] !== undefined) {
                        customKey.push(keys[1]);
                    }
                }
            }
        });

        if(customKey.length === 0){
            return false;
        }

        items.forEach(function (item) {
            customKey.forEach(function (_key) {
                if (_key === item['id'].toString()) {
                    if (parseInt(item['qty_to_ship_' + _key]) > 0 && item['sku_' + _key].trim() !== '') {
                        styleToolbar = '';
                        return true;
                    }
                }
            });
        });

        this.setState({
            styleToolbar: styleToolbar
        });
    }

    componentWillUnmount() {
        this.state.timeouts.forEach(clearTimeout);
    }

    customSource(key, name) {
        return name + '_' + key;
    }

    render() {
        const items = this.state.items;
        const requiredValidator = [required()];
        const qtyValidate = [required(), number()];
        const toolbarTile = 'Create shipment for ' + this.state.orderNumber;
        let defaultValue = {};
        for (let i = 0; i < items.length; i++) {
            let current = items[i];
            this.props.record.reason = current.reason;
            this.props.record.comment = current.comments;
            defaultValue['qty_to_ship_' + current['id']] = this.calculatorQtyToShipDefault(items[i]);
        }

        const PostCreateToolbar = props => {
            const form = useForm();
            var formdata = form.getState().values;
            const handleClick = useCallback(() => {
                this.doSubmit(formdata);
            }, [formdata, form]);


            return (
                <Toolbar {...props} >
                    <OMSSaveButton label='Create shipment'
                                   disabled={props.invalid}
                                   saving={this.state.saving}
                                   onClick={handleClick}
                                   submitOnEnter={false}/>
                </Toolbar>
            );
        }

        return ((this.state.items && this.state.preparedItem && this.state.trackingCodes.length) ?
                <div className="formShipment">
                    <CardHeader title={toolbarTile} className="title"/>
                    <TabbedForm
                        resource="posts"
                        idtoolbar="shipmentToolbar"
                        toolbar={<PostCreateToolbar/>}
                        validate={this.validate}
                        invalid={false}
                        label={toolbarTile}
                        submitOnEnter={false}
                        defaultValue={defaultValue}
                    >
                        <FormTab label="Shipment Info">
                            <SelectInput
                                validate={requiredValidator}
                                label="Shipment code" optionValue="_id"
                                name="carrier_tracking_code"
                                optionText="name"
                                source="carrier_tracking_code"
                                choices={this.state.trackingCodes}/>

                            <TextInput
                                validate={requiredValidator}
                                label='Tracking number'
                                name="carrier_tracking_number"
                                source="carrier_tracking_number"
                            />
                            <TextInput
                                name="shipping_currency"
                                label='Shipping currency'
                                source="shipping_currency"/>

                            <TextInput
                                name="shipping_cost"
                                label='Ship cost'
                                source="shipping_cost"/>

                            <Table selectable="false" style={{ width: '100%' }}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Product</TableCell>
                                        <TableCell>Qty</TableCell>
                                        <TableCell>Qty to Ship</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {items.map((item, key) =>

                                        <TableRow key={key} style={item.style}>
                                            <TableCell>
                                                Sku: {item.sku}
                                                <br/>
                                                Name: {item.name}
                                            </TableCell>
                                            <TableCell>
                                                Qty Ordered: {item.qty_ordered}
                                                <br/>
                                                Qty Invoiced: {item.qty_invoiced}
                                                <br/>
                                                Qty Shipped: {item.qty_shipped}
                                            </TableCell>
                                            <TableCell>
                                                <NumberInput source={this.customSource(item.id, 'qty_to_ship')}
                                                             step={1} label="" placeholder=""
                                                             validate={qtyValidate}/>
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>

                        </FormTab>
                    </TabbedForm>
                </div>
                : <span/>
        );
    }
}

export default ShipmentForm;

const CustomNumberField = ({ name, type, label }) => {
    const {
        input: { onChange },
        meta: { touched, error }
    } = useField(name);
    return (
        <TextField
            type={type}
            name={name}
            label={label}
            onChange={onChange}
            error={!!(touched && error)}
            helperText={touched && error}
            vali
        />
    );
};

