import React from 'react';
import {
    GET_LIST,
    Datagrid, DateInput,
    Filter,
    List, Pagination, SelectInput,
    TextField,
    TopToolbar,
    TextInput,
    ExportButton
} from "react-admin";
import {cloneElement} from 'react';
import {getChannelName} from "../channels";
import {channels, config} from "../../config";
import {dateParser} from "../filters/dateParser";
import moment from "moment";
import DeviceRegistrationDialog from "../deviceRegistration/DeviceRegistrationDialog";
import laravelJsonServerClient from "../../restClient";
import {getUrlParameter} from "../../rest/common";
import {defaultReSource, authorized} from '../../authorized'
/**
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const SettlementItemsFilters = (props) => (
    <Filter {...props} >
        <SelectInput alwaysOn source="channel_id" choices={channels} optionText="name" optionValue="_id"/>
        <DateInput text="Created At Date" source="created_at"/>
        <DateInput text="Purchase Date" source="purchase_date" label="Purchase Date"/>
        <TextInput alwaysOn label="Order number" source="purchase_order_number"/>
        <TextInput alwaysOn label="Model" source="model_number"/>
        <TextInput alwaysOn label="Email" source="email"/>
        <TextInput label="Country" source="country"/>
        <TextInput label="State" source="state"/>
        <TextInput label="Address" source="address"/>
        <TextInput label="Serial" source="serial_number"/>
        <TextInput label="Marketplace" source="purchase_location"/>
        <TextInput label="Zip Code" source="zip_code"/>
        <TextInput label="Phone" source="phone_number"/>
        <TextInput label="Full Name" source="full_name"/>
        <TextInput label="ID" source="entity_id"/>
    </Filter>
);

const OMSPagination = props => <Pagination rowsPerPageOptions={[config.perPage]} {...props} />;

const ListActions = (props) => {
    return (
        <TopToolbar>
            {cloneElement(props.filters, {context: 'button'})}
            <ExportButton exporter={exporter}/>
        </TopToolbar>
    );
};

/**
 *
 * @param posts
 */
const exporter = (posts) => {
    let filter = getUrlParameter('filter', window.location.hash);

    if (filter == null) {
        filter = {'default_created_at': defautDate, 'created_at': defautDate}
    } else {
        filter = JSON.parse(filter);
    }

    laravelJsonServerClient(GET_LIST, 'deviceregistration/export', {filter: filter})
        .then(r => {
            if (r.data) {
                const fileName = 'deviceregistration-' + moment().utc().format('YYYY-MM-DD-h:mm-a') + '.xlsx';
                let downloadLink = document.createElement("a");
                downloadLink.href = r.data;
                downloadLink.download = fileName;
                downloadLink.click();
            }
        })
        .catch((e) => {
            console.error(e)
        });
};

/**
 *
 * @param props
 * @returns {*}
 */
export const deviceRegistrationList = (props) => (
    <List {...props} filters={<SettlementItemsFilters/>}
          actions={<ListActions/>}
          title="Device Registration List"
          perPage={config.perPage}
          bulkActionButtons={false}
          pagination={<OMSPagination/>}
          sort={{field: 'entity_id', order: 'DESC'}}>
        <Datagrid>
            <DeviceRegistrationDialog label="ID" source="entity_id"/>
            <PurchaseDate label="purchase date" source="purchase_date"/>
            <LinkToOrderShow label="Order number" source="purchase_order_number"/>
            <ChannelName label="Channel" source="channel_id"/>
            <TextField label="Model" source="model_number"/>
            <TextField label="Serial" source="serial_number"/>
            <TextField label="Full Name" source="full_name"/>
            <TextField label="Email" source="email"/>
            <TextField label="Phone" source="phone_number"/>
            <TextField label="Address" source="address"/>
            <TextField label="State" source="state"/>
            <TextField label="Country" source="country"/>
            <TextField label="Zip code" source="zip_code"/>
            <TextField label="Created At" source="created_at"/>
        </Datagrid>
    </List>
);

/**
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const LinkToOrderShow = ({record = {}}) => (
    <span className="pl-24">{(record.sales_order_id) ?
        <a href={'#order/' + record.sales_order_id + '/show'} className="a-customize"
           title="Click here to display details">{record.purchase_order_number}</a> : record.purchase_order_number}</span>
);


/**
 *
 * @type {*|(function(*=, *=): undefined)|(function(*, *=): *)|(function(*, *=): *)|(function(*=): void)|(function(*): void)}
 */
const defautDate = dateParser(moment().subtract(6, 'months').format(''), 'created_at');

/**
 * @param props
 * @returns {*}
 * @constructor
 */
const ChannelName = (props) => (
    <span>{getChannelName(props.record.channel_id)}</span>
);

/**
 *
 * @param record
 * @returns {*}
 * @constructor
 */
const PurchaseDate = ({record = {}}) => (
    <span className="pl-24">{record.purchase_date}</span>
);