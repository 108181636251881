import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import PostAddIcon from '@material-ui/icons/PostAdd';
import {warehouseFilter} from "../../../config";
import ShipmentLabel from "../../form/ShipmentLabel";

/**
 *
 */
class GenerateLabelButton extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
        }
    }


    /**
     *
     */
    showModal = () => {
        this.setState({open: true});
    };

    hideModal = () => {
        this.setState({open: false});
    }

    /**
     *
     * @returns {*}
     */
    render() {
        const styles = {
            button: {
                marginLeft: 5,
                marginRight: 5,
            },
        };

        const {record} = this.props;
        let allowLabelWarehouse = false;
        let warehouses = [];

        if (record && record.items) {
            record.warehouse = 0;
            record.shipper_account = null;
            record.package_size = null;

            record.items.forEach((item) => {
                record[item.id + "_id"] = item.qty_ordered - item.qty_shipped - item.qty_canceled;
                if (isAbleGenerateLabelWarehouse(item.warehouse_id) === true && (item.qty_ordered - item.qty_shipped - item.qty_canceled) > 0) {
                    allowLabelWarehouse = true;
                }
            });

            warehouses = warehouseLabels(record.items);
            if (warehouses.length !== 0) {
                record.warehouse = warehouses[0]._id;
            }
        }

        let canLabel = record && allowLabelWarehouse === true &&
            (record.status !== 'shipped' && record.status !== 'delivered' && record.status !== 'received' && record.status !== 'complete' && record.status !== 'canceled');
        return (canLabel && record) ? <span>
                <Button startIcon={<PostAddIcon/>}
                        className="px-5" size={"small"}
                        variant="contained"
                        color="secondary"
                        onClick={this.showModal}
                        style={styles.button}
                >Generate Label</Button>
                <ShipmentLabel record={record} open={this.state.open} onClick={this.hideModal} warehouses={warehouses}/>
            </span>
            : <span/>;
    }
}

GenerateLabelButton.propTypes = {
    record: PropTypes.object,
};

/**
 *
 * @param items
 * @returns {[]}
 */
export function warehouseLabels(items) {
    let warehouses = [];

    items.forEach((item) => {
        let warehouse = _.find(warehouseFilter, {_id: item.warehouse_id, generateLabel: true});
        if (warehouse !== undefined && (item.qty_ordered - item.qty_shipped - item.qty_canceled) > 0) {
            let isAvailable = _.find(warehouses, {_id: item.warehouse_id});
            if (isAvailable === undefined) {
                warehouses.push(warehouse)
            }
        }
    });
    return warehouses;
};


/**
 *
 */
export default GenerateLabelButton

/**
 *
 * @param warehouseId
 * @returns {boolean}
 */
export function isAbleGenerateLabelWarehouse(warehouseId) {
    let isAble = _.find(warehouseFilter, {_id: warehouseId, generateLabel: true});
    return isAble !== undefined;
}