import React, {Component} from 'react';
import {
    Table,
    TableBody,
    TableHead,
    TableRow,
    TableCell, Paper, Card, Typography
} from '@material-ui/core';
import laravelJsonServerClient from '../../restClient'
import {GET_LIST, Title, TopToolbar} from 'react-admin';
import {defaultReSource, authorized} from '../../authorized'
import {formatCurrency} from "../../rest/common";

/**
 *
 */
class ReturnRequestsItems extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: []
        }
    }

    componentDidMount() {

    }

    render() {
        const requests = this.props.record.items
        if (!requests || requests.length === 0) {
            return (
                <div>
                </div>
            )
        }

        const cardActionStyle = {
            zIndex: 2,
            display: 'inline-block',
            width: '100%',
        };

        const rowStyle = {cursor: 'pointer'};
        const alignRight = {textAlign: 'right'};

        return (
            <Paper style={{margin: '20px 0 0 0', padding: '10px'}} zdepth={2}>
                <Card style={{boxShadow: 'none'}}>
                    <TopToolbar style={cardActionStyle}>
                        <Typography style={{float:"left"}} gutterBottom variant="h5" component="h5">
                            Return Request items
                        </Typography>
                    </TopToolbar>
                </Card>

                <Table selectable="true" >
                    <TableHead>
                        <TableRow>
                            <TableCell>Sku</TableCell>
                            <TableCell>Qty return</TableCell>
                            <TableCell>Package Size</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Received</TableCell>
                            <TableCell>Created At</TableCell>
                            <TableCell>Disposition</TableCell>
                            <TableCell>Comment</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {requests.map(request =>
                            <TableRow key={request.id} style={rowStyle}>
                                <TableCell className="px-24">{request.sku}</TableCell>
                                <TableCell className="px-24">{request.qty}</TableCell>
                                <TableCell className="px-24">{request.model_size}</TableCell>
                                <TableCell className="px-24">{request.status}</TableCell>
                                <TableCell className="px-24">{request.is_returned_txt}</TableCell>
                                <TableCell className="px-24">{request.created_at}</TableCell>
                                <TableCell className="px-24">{request.disposition}</TableCell>
                                <TableCell className="px-24">{request.comments}</TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                    <TableRow>
                        <TableCell/>
                        <TableCell  style={alignRight} className="pr-24" >Full Package Size:</TableCell>
                        <TableCell  className="px-24">
                            {this.props.record.package_size}
                        </TableCell>

                        <TableCell colSpan={5}/>
                    </TableRow>
                </Table>
            </Paper>
        );
    }
}

export default ReturnRequestsItems;
