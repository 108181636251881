import React, {Component} from 'react';
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import ChatIcon from '@material-ui/icons/Chat';
import {CardActions, Dialog, DialogActions, DialogContent, DialogTitle} from "@material-ui/core";
import {
    BooleanInput,
    refreshView as refreshViewAction,
    SimpleForm,
    CREATE,
    email,
    TextInput
} from "react-admin";
import Grid from "@material-ui/core/Grid";
import SaveIcon from "@material-ui/icons/Save";
import {connect} from "react-redux";
import RichTextInput from "ra-input-rich-text";
import laravelJsonServerClient from '../../../restClient'
import {authorized} from "../../../authorized";

class CreateComment extends Component {
    constructor(props) {
        super(props);
        this.state = {
            allowSubmit: false,
            open: false,
            values: null,
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.validate = this.validate.bind(this);
    };

    handleOpen = () => {
        this.setState({open: true});
    };

    handleClose = () => {
        this.setState({open: false});
    };

    validate(value) {
        this.setState({values: value});
        this.setState({allowSubmit: false});

        if (!this.isValidEmail(value['customer_email'])) {
            return false
        }

        if (value['message'] === "" || value['message'] === undefined) {
            return false
        }
        this.setState({allowSubmit: true});

    }

    isValidEmail(email) {
        return /\S+@\S+\.\S+/.test(email);
    }

    handleSubmit() {
        const values = this.state.values;
        laravelJsonServerClient(CREATE, 'order/comment/' + this.props.record.id, {
            data: {
                message: values.message,
                sendemail: values.sendemail,
                customer_email:  values.customer_email
            }
        }).then(
            r => {
                if (!r.data.error) {
                    this.handleClose();
                    this.props.refreshView();
                } else {
                    this.handleClose();
                    alert(r.data.message);
                }
            }
        ).catch((e) => {
            console.error(e);
            alert(e.message);
        });
    }

    render() {
        const {record} = this.props;
        const title = "Create Comment"
        const allowSubmit = !this.state.allowSubmit

        if (authorized.permissions.indexOf('comment.create') === -1) {
            return (
                <div></div>
            )
        }

        const actions = [
            <Button
                label="Submit"
                key={'secondary'}
                primary="true"
                variant={"outlined"} color={"secondary"}
                size={"small"}
                startIcon={<SaveIcon/>}
                disabled={allowSubmit}
                onClick={this.handleSubmit}>Submit Comment</Button>
            ,
            <Button
                label="Cancel"
                primary="true"
                disabled={false}
                key={'primary'}
                size={"small"}
                color={"primary"}
                variant="outlined"
                onClick={this.handleClose}
            >Cancel</Button>
        ];

        return ((record !== undefined) ?
                <main>
                    <Button
                        onClick={this.handleOpen}
                        size={"small"}
                        variant="contained"
                        startIcon={<ChatIcon/>}
                        label="Create Comment"
                        style={{float: "right"}}
                    >Create Comment</Button>

                    <SimpleForm submitOnEnter={false}
                                defaultValue={record}
                                save={this.handleSubmit}
                                validate={this.validate}
                                toolbar={<CardActions/>} handleSubmit={this.handleClose}
                                redirect={false}>
                        <Dialog
                            bodyClassName={"modal-edit-address"}
                            title={title}
                            actions={actions}
                            maxWidth={'sm'}
                            modal={true}
                            fullWidth={'true'}
                            autoScrollBodyContent={true}
                            open={this.state.open}
                        >
                            <DialogTitle id="customized-dialog-title" onClose={this.handleClose}>
                                Create Comment
                            </DialogTitle>
                            <DialogContent dividers>
                <span>


                        <Grid container spacing={3} style={{width: '100%'}}>
                            <Grid item xs={12} sm={12}>
                                <TextInput source="customer_email" validate={email()} resettable fullWidth={true}/>
                                <RichTextInput source="message" style={{minHeight: "300px"}}/>
                                <BooleanInput label="Notify Customer" source="sendemail" defaultValue={true}/>
                            </Grid>
                        </Grid>

                </span>
                            </DialogContent>
                            <DialogActions>
                                {actions}
                            </DialogActions>
                        </Dialog>
                    </SimpleForm>
                </main>
                : <span/>

        );
    }
}

CreateComment.propTypes = {
    record: PropTypes.object,
};


export default connect(undefined, {refreshView: refreshViewAction})(
    CreateComment
);

