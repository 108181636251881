import React, {Component} from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow, Typography,
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import laravelJsonServerClient from '../../restClient'
import {GET_LIST, UPDATE, Title} from 'react-admin';
import {Snackbar} from "@material-ui/core";
import {GET_ONE} from "react-admin";
import swal from "sweetalert";
import {formatCurrency} from "../../rest/common";
import {isMagentoStore} from "../channels";
import {channels} from "../../config";
import {defaultReSource, authorized} from '../../authorized'

/**
 *
 */
class Invoice extends Component {
    constructor(props) {
        super(props);
        this.state = {
            SnackbarMsg: '',
            open: false,
            autoHideDuration: 5000,
            data: this.props.record.invoice,
            roles: '',
            permissions: ''
        }
    }

    /**
     * @param invoice
     */
    downloadInvoice(invoice) {
        let _this = this;
        _this.setState({
            SnackbarMsg: 'Preparing a download file please wait a moment...',
            open: true,
        });
        laravelJsonServerClient(GET_ONE, 'invoice/download-pdf', {id: invoice.id}).then(
            r => {
                _this.setState({open: false});
                if (r.data.pdf_base64) {
                    let downloadLink = document.createElement("a");
                    downloadLink.href = r.data.pdf_base64;
                    downloadLink.download = "invoice-" + invoice.channel_order_no + ".pdf";
                    downloadLink.click();
                } else {
                    alert("An error occurred while creating a pdf file to download!")
                }
            }
        ).catch((e) => {
            console.error(e);
        });
    }

    doResendEmail(invoice) {
        let _this = this;
        _this.setState({
            SnackbarMsg: 'Sending request...!',
            open: true,
        });

        laravelJsonServerClient(UPDATE, 'invoice/resendEmail', {id: invoice.id}).then(
            r => {
                if (r.data.error == false) {
                    _this.setState({
                        SnackbarMsg: 'Sent a request!',
                        open: true,
                    });
                } else if (r.data.error == true) {
                    _this.setState({
                        SnackbarMsg: 'Something error!',
                        open: true,
                    });
                }
            }
        ).catch((e) => {
            console.error(e);
        });
    }

    resendEmail(invoice) {
        let _this = this;
        swal('Are you sure you want to send Invoice email to customer?',
            {
                title: 'CONFIRM',
                buttons: {
                    cancel: "Cancel",
                    ok: {
                        text: "Ok",
                        value: "catch",
                    }
                },
            }).then(value => {
            switch (value) {
                case "catch":
                    _this.doResendEmail(invoice);
                    return true;
                case "cancel":
                    return false;
                default:
                    return false;
            }
        });
    }

    render() {
        const invoice = this.props.record.invoice;

        if (!invoice || invoice.length === 0 || authorized.permissions.indexOf('order.invoice.get') === -1) {
            return (
                <div>
                </div>
            )
        }

        const alignRight = {textAlign: 'right'};
        const ActHeader = ({invoice}) => {
            let channelConfig = _.find(channels, {_id: invoice.channel_id});
            return (isMagentoStore(invoice.channel_id) && channelConfig.invoice === true  ? <TableCell colSpan="2">Actions</TableCell> : <td colSpan="2"/>);
        };

        const ActRowDownloadPdf = ({invoice}) => {
            let channelConfig = _.find(channels, {_id: invoice.channel_id});
            return (isMagentoStore(invoice.channel_id) && channelConfig.invoice === true ? <TableCell>
                <Button className="px-10"
                        primary={true}
                        size={"small"}
                        variant={"outlined"}
                        color={"secondary"}
                        onClick={this.downloadInvoice.bind(this, invoice)}>Download Invoice</Button>
            </TableCell> : <td/>);
        };
        const ActRowResendEmail = ({invoice}) => {
            let channelConfig = _.find(channels, {_id: invoice.channel_id});
            return (isMagentoStore(invoice.channel_id) && channelConfig.invoice === true ? <TableCell style={alignRight}>
                <Button className="px-10" primary={true}
                        size={"small"}
                        variant={"outlined"}
                        color={"secondary"}
                        onClick={this.resendEmail.bind(this, invoice)}>Resend email</Button>
            </TableCell> : <td/>);
        };

        return (
            <Paper style={{margin: '20px 0 0 0', padding: '10px'}} zdepth={2}>

                <Typography gutterBottom variant="h5" component="h5">
                    Invoice
                </Typography>
                <Table selectable="false" >
                    <TableHead>
                        <TableRow>
                            <TableCell>Invoice No</TableCell>
                            <TableCell>Total qty</TableCell>
                            <TableCell>Subtotal</TableCell>
                            <TableCell>Grand total</TableCell>
                            <TableCell>Tax amount</TableCell>
                            <TableCell>Currency code</TableCell>
                            <TableCell>Created at</TableCell>
                            <ActHeader invoice={invoice}/>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow key={invoice.id}>
                            <TableCell className="pl-16">{invoice.channel_invoice_no ? invoice.channel_invoice_no : invoice.id}</TableCell>
                            <TableCell className="pl-24">{invoice.total_qty}</TableCell>
                            <TableCell
                                className="pl-24">{formatCurrency(invoice.subtotal, invoice.order_currency_code)}</TableCell>
                            <TableCell
                                className="pl-24">{formatCurrency(invoice.grand_total, invoice.order_currency_code)}</TableCell>
                            <TableCell
                                className="pl-24">{formatCurrency(invoice.tax_amount, invoice.order_currency_code)}</TableCell>
                            <TableCell className="pl-24">{invoice.order_currency_code}</TableCell>
                            <TableCell className="pl-24">{invoice.channel_created_at}</TableCell>

                            <ActRowResendEmail invoice={invoice}/>
                            <ActRowDownloadPdf invoice={invoice}/>

                        </TableRow>
                    </TableBody>
                </Table>

                <Snackbar
                    open={this.state.open}
                    message={this.state.SnackbarMsg}
                    autoHideDuration={this.state.autoHideDuration}
                />
            </Paper>
        );
    }
}

export default Invoice;
