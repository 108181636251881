import * as React from 'react';
import {Component, Fragment} from 'react';
import {
    List,
    Datagrid,
    TextField,
    Filter,
    DateInput,
    Show,
    TextInput, RichTextField,
    TopToolbar, Pagination, SimpleShowLayout,
    useUnselectAll,
    required,
    CreateButton, Create, SimpleForm, SelectInput, NumberInput, GET_LIST
} from 'react-admin';
import {config} from "../config";
import EditProductBtn from "./product/editProductBtn";
import { Link } from 'react-router-dom'
import Button from "@material-ui/core/Button";
import ListIcon from "@material-ui/icons/List";
import SdCategoryIcon from "@material-ui/icons/Category";
import AddProductModelBtn from "./product/addProductModelBtn";
import OMSDeleteBulkRecordsBtn from "./buttons/OMSDeleteBulkRecordsBtn";
import OMSDeleteRecordBtn from "./buttons/OMSDeleteRecordBtn";
import EditProductModelBtn from "./product/editProductModelBtn";
import {authorized} from "../authorized";

/**
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const ProductFilters = (props) => (
    <Filter {...props}>
        <DateInput text="To date" source="created_at_to"/>
        <DateInput text="From date" source="created_at_from"/>
        <TextInput alwaysOn text="Sku" source="sku"/>
        <TextInput alwaysOn text="Name" source="name"/>
    </Filter>
);

/**
 *
 * @param resource
 * @param filters
 * @param displayedFilters
 * @param filterValues
 * @param basePath
 * @param showFilter
 * @returns {*}
 * @constructor
 */
const ProductActions = ({resource, filters, displayedFilters, filterValues, basePath, showFilter}) => (
    <TopToolbar style={cardActionStyle}>
        <Link className="MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-textSizeSmall MuiButton-textPrimary" to="/product-model"><ListIcon/> Models</Link>
        {filters && React.cloneElement(filters, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: 'button'
        })}
        {(authorized.permissions.indexOf('product.create') !== -1) ?
        <CreateButton basePath={basePath}/> : ''}
    </TopToolbar>
);

const ProductPagination = props => <Pagination rowsPerPageOptions={[config.perPage]} {...props} />;
const ProductBulkActionButtons = props => (
    <Fragment>
        <OMSDeleteBulkRecordsBtn unSelectAll={useUnselectAll('product')} permissionAllowed="product.delete" {...props} />
    </Fragment>
);

/**
 *
 * @param props
 * @returns {*}
 * @constructor
 */
export const ProductList = (props) => {

    return (
        <List {...props} actions={<ProductActions/>}
              filters={<ProductFilters/>} perPage={config.perPage}
              bulkActionButtons={<ProductBulkActionButtons/>}
              pagination={<ProductPagination/>}
              sort={{field: 'id', order: 'DESC'}}>
            <Datagrid>
                <SkuText label="SKU" source="sku"/>
                <TextField label="Name" source="name"/>
                <TextField label="Brand" source="brand"/>
                <TextField label="Category" source="category"/>
                <TextField label="COGS" title="Cost of goods sold" source="cogs"/>
                <ViewProductModelBtn  label="Model" source="model_name"/>
                <EditProductBtn unSelectAll={useUnselectAll('product')}/>
                <OMSDeleteRecordBtn unSelectAll={useUnselectAll('product')} permissionAllowed="product.delete" resource="product-model"/>
            </Datagrid>
        </List>
    )
};

const ProductShowActions = ({basePath, props, data}) => (
    <TopToolbar {...props}>
        <AddProductModelBtn record={data}/>
        <Link className="MuiButtonBase-root" to={{
            pathname: '/product',
            search: "",
        }}>
            <Button className="MuiButton-containedSecondary ml-5" startIcon={<SdCategoryIcon/>}>Items</Button>
        </Link>
    </TopToolbar>
);

/**
 *
 * @param props
 * @returns {*}
 * @constructor
 */
export const ProductShow = (props) => (
    <main className="product-models">
        <Show title={<ProductTitle/>} actions={<ProductShowActions/>} {...props}>
            <SimpleShowLayout>
                {/*<Models/>*/}
            </SimpleShowLayout>
        </Show>
    </main>
);

/**
 *
 * @param record
 * @returns {*}
 * @constructor
 */
const ProductTitle = ({record = {sku: ""}}) => {
    return <span>{record ? `${record.name ?? record.sku}` : ''}</span>;
};

/**
 *
 * @param record
 * @returns {*}
 * @constructor
 */
const SkuText = ({record = {sku: ""}}) => (
    <span className="pl-10 sku-text">{record.sku}</span>
);

const DescriptionText = ({record = {description: ""}}) => {
    const description = record.description;
    return (
        <span className="pl-10 string-dots" dangerouslySetInnerHTML={{__html: description}}></span>
    )
};

/**
 *
 * @param record
 * @returns {*}
 * @constructor
 */
const ViewProductModelBtn = ({record = {product_model_id: null, model_name: ""}}) => {
    return (
        <span className="pl-10 model-text"><EditProductModelBtn modelId={record.product_model_id} modelName={record.model_name} viewOnly={true}/></span>
    )
};


/**
 *
 * @param props
 * @returns {*}
 * @constructor
 */
export const ProductCreate = (props) => {
    return (
        <span>
                <Create title="Create Product" {...props}>
                    <SimpleForm redirect={'/product'}>
                        <TextInput source="sku" validate={required()}/>
                        <SelectInput name="product_model_id" isRequired={true} label="Model"
                            source="product_model_id"
                            choices={[{id: 1, name: 'Default'}]}
                            optionValue="id" />
                        <TextInput source="name"/>
                        <TextInput source="description"/>
                    </SimpleForm>
                </Create>
        </span>
    );
};


/**
 * @type {{display: string, float: string, zIndex: number}}
 */
const cardActionStyle = {
    zIndex: 2,
    display: 'inline-block',
    float: 'right',
};


