import React, {Component} from 'react';
import laravelJsonServerClient from '../../restClient'
import {GET_LIST, Title, GET_ONE, TopToolbar} from 'react-admin';
import Paper from '@material-ui/core/Paper';
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow, Typography,
} from '@material-ui/core';
import {Collapse, Button, Card} from '@material-ui/core';
import {getWarehouseName} from "../../rest/common";
import swal from "sweetalert";
import CreateShipment from "./actions/createShipmentBtn";
import {defaultReSource, authorized} from '../../authorized'


/**
 *
 */
class OrderHistories extends Component {

    /**
     *
     * @param props
     */
    constructor(props) {
        super(props);
        let location = window.location.hash.split('/');
        this.state = {
            openOrderHistory: false,
            openOrderFulfillmentHistory: false,
            showButtonDetailedLog: false,
            fulfilmentHistory: [],
            orderHistory: [],
            historyLoaded: false,
            orderId: location[2],
            roles: '',
            permissions: ''
        }

        this.loadOrderHistories();
    }

    state = {
        open: 0,
    };

    loadOrderHistories = () => {
        let _this = this;
        if (_this.state.historyLoaded === false) {
            laravelJsonServerClient(GET_LIST, 'history/order/' + this.state.orderId, {}).then(
                r => {
                    if (r.data && (r.data.orderHistory.length || r.data.fulfilment.length)) {
                        _this.setState({orderHistory: r.data.orderHistory});
                        _this.setState({fulfilmentHistory: r.data.fulfilment});
                    }
                    _this.setState({historyLoaded: true});
                }
            ).catch((e) => {
                console.error(e);
            });
        }
    }

    handleOrderFulfillmentHistory = () => {
        this.setState({
            openOrderFulfillmentHistory: !this.state.openOrderFulfillmentHistory,
        });
    };

    handleToggleOrderHistory = () => {
        this.setState({
            openOrderHistory: !this.state.openOrderHistory,
        });
        if (this.state.orderHistory.length && !this.state.openOrderHistory) {
            this.setState({
                showButtonDetailedLog: true
            })
        } else {
            this.setState({
                showButtonDetailedLog: false
            })
        }
    };

    handleClickShowHistoryDetail = () => {
        let html = '', wrapper = document.createElement('div');
        if (this.state.orderHistory === false) {
            html += 'No history for this order yet.';
        } else {
            this.state.orderHistory.forEach(function (item) {
                html += '<p><span>[' + item.created_at + ']:</span> ' + (item.message_detail ? item.message_detail : item.message) + '</p>';
            });
        }

        wrapper.innerHTML = html;
        swal(wrapper,
            {
                title: 'Detailed Order Log',
                buttons: {
                    cancel: "Close",
                },
                className: "swal-order-history"
            });
    }

    render() {
        const cardStyle = {
            boxSizing: 'border-box',
            minWidth: 300,
            border: '1px solid #dedede',
            display: 'inline-block',
            verticalAlign: 'top'
        };

        const blockMessage = {
            display: 'block',
            wordWrap: 'break-word',
            maxWidth: '1024px'
        };

        const _openOrderHistory = this.state.openOrderHistory;
        const _openOrderFulfillmentHistory = this.state.openOrderFulfillmentHistory;
        const classNameHistoryBlock = (this.state.orderHistory.length && this.state.fulfilmentHistory.length) ? 'order-histories render2Block' : 'order-histories';

        const OrderHistory = () => {
            return (this.state.orderHistory.length ?
                <Collapse in={_openOrderHistory}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell></TableCell>
                                <TableCell>Date</TableCell>
                                <TableCell>Status</TableCell>
                                <TableCell>Message</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.orderHistory.map((item, key) =>
                                <TableRow key={key}>

                                    <TableCell>{item.created_at}</TableCell>
                                    <TableCell>{item.status}</TableCell>
                                    <TableCell title={item.message}><p style={blockMessage}>{item.message}</p>
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </Collapse>
                : '')
        }
        const Fulfilment = () => {
            return (this.state.fulfilmentHistory.length ?
                <Collapse in={_openOrderFulfillmentHistory}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell></TableCell>
                                <TableCell>Sku</TableCell>
                                <TableCell>Warehouse</TableCell>
                                <TableCell>Export Date</TableCell>
                                <TableCell>Message</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.fulfilmentHistory.map((item, key) =>
                                <TableRow key={key}>
                                    <TableCell>{item.sku}</TableCell>
                                    <TableCell>{getWarehouseName(item.warehouse_id)}</TableCell>
                                    <TableCell>{item.export_date}</TableCell>
                                    <TableCell title={item.error_message}><p
                                        style={blockMessage}>{item.error_message}</p></TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </Collapse>
                : '')
        }

        const cardActionStyle = {
            zIndex: 2,
            display: 'inline-block',
            width: '100%',
        };

        return (
            <Paper style={{margin: '20px 0 0 0', padding: '10px'}} zdepth={2}>
                <Card style={{boxShadow: 'none'}}>
                    <TopToolbar style={cardActionStyle}>
                        <Typography style={{float: "left"}} gutterBottom variant="h5" component="h5">
                            Order Log
                        </Typography>

                        {this.state.showButtonDetailedLog ? (
                            <Button variant="outlined" className="ui right"
                                    onClick={this.handleClickShowHistoryDetail}>Detailed Order Log</Button>
                        ) : ('')}
                    </TopToolbar>
                </Card>
                <Card style={{boxShadow: 'none'}}>
                    {this.state.orderHistory.length ? (
                        <div className={classNameHistoryBlock} style={cardStyle}>
                            <Button className="buttonTitle" onClick={this.handleToggleOrderHistory}>Order Status
                                History</Button>
                            <OrderHistory/>
                        </div>
                    ) : ('')}
                    {this.state.fulfilmentHistory.length ? (
                        <div className={classNameHistoryBlock + ' fulfilmentBox'} style={cardStyle}>
                            <Button className="buttonTitle" onClick={this.handleOrderFulfillmentHistory}>Fulfilment
                                History</Button>
                            <Fulfilment/>
                        </div>
                    ) : ('')}
                    {(!this.state.orderHistory.length && !this.state.fulfilmentHistory.length) ? (
                        <div className={classNameHistoryBlock + ' fulfilmentBox'} style={cardStyle}>
                            <span className="text-center display-block py-15">No history yet</span>
                        </div>
                    ) : ('')}
                </Card>
            </Paper>
        );
    }
}

export default OrderHistories;
