import * as React from 'react';
import {
    List,
    Datagrid,
    TextField,
    Filter,
    SelectInput,
    DateInput,
    TextInput,
    Pagination,
    TopToolbar
} from 'react-admin';
import {getChannelName} from './channels';
import {channels, config} from "../config";
import {IconButton} from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import {warehouseFilter} from "../config";
import ErrorIcon from '@material-ui/icons/Error';
import WarningIcon from '@material-ui/icons/Warning';
import moment from "moment";
import {dateParser} from "./filters/dateParser";
import {Link} from "react-router-dom";
import {cloneElement} from "react";
import {defaultReSource, authorized} from '../authorized'

const ListActions = (props) => {
    return (
        <TopToolbar>
            {cloneElement(props.filters, {context: 'button'})}
        </TopToolbar>
    );
};
/**
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const OrdersShipmentIssuesFilters = (props) => (
    <Filter {...props}>
        <SelectInput source="channel_id" choices={channels} optionText="name" optionValue="_id"/>
        <DateInput alwaysOn text="To date" source="created_at_to" parse={dateParser}/>
        <DateInput text="From date" source="created_at_from" parse={dateParser}/>
        <TextInput alwaysOn label="Order #" source="channel_order_no"/>
        <TextInput label="Sku" source="sku"/>
        <SelectInput label="Warehouse" choices={warehouseFilter} source="warehouse" optionsText="name"
                     optionValue="_id"/>
    </Filter>
);

/**
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const LinkToOrderShow = ({record = {sales_order_id: ""}}) => {
    if (!record) return (<span/>)

    return (
        <IconButton
            component={Link}
            to={{
                pathname: "/order/" + record.sales_order_id + '/show', search: "?backTo=shipmentIssues",
                state: {}
            }}
        >
            <VisibilityIcon/>
        </IconButton>
    )
};

/**
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const OMSPagination = props => <Pagination rowsPerPageOptions={[config.perPage]} {...props} />;

/**
 *
 * @param props
 * @returns {*}
 * @constructor
 */
export const OrdersShipmentIssuesList = (props) => {
    if (authorized.permissions.indexOf('order.delayed_shipments.list') === -1) {
        return (<span/>)
    }
    return (
        <List bulkActionButtons={false}
              title="Shipment List" {...props}
              pagination={<OMSPagination/>}
              actions={<ListActions/>}
              filters={<OrdersShipmentIssuesFilters/>} perPage={config.perPage}
              filterDefaultValues={{created_at_to: defaultDate}}

              sort={{field: 'created_at', order: 'ASC'}}>
            <Datagrid>
                <ChannelName label="Channel" source="channel_id"/>
                <TextField label="Order #" source="channel_order_no"/>
                <TextField label="Created At" source="created_at"/>
                <TextField label="Sku" source="sku"/>
                <Qty label="QTY" source="qty_ordered" record={props.record}/>
                <TextField label="Warehouse" source="warehouse_name"/>
                <StateIcon label="Delay" source="export_date" record={props.record}/>
                <Exported label="Exported date" source="export_date" record={props.record}/>
                <LinkToOrderShow record={props.record}/>
            </Datagrid>
        </List>
    )
};

/**
 *
 * @param record
 * @returns {*}
 * @constructor
 */
const ChannelName = ({record = {channel_id: 0}}) => (
    <span>{(record) ? getChannelName(record.channel_id) : record.channel_id}</span>
);

/**
 *
 * @param record
 * @returns {*}
 * @constructor
 */
const Qty = ({record = {qty_ordered: 0}}) => (
    <span className="pl-24">{(record) ? record.qty_ordered : 0}</span>
);

/**
 *
 * @param record
 * @returns {*}
 * @constructor
 */
const Exported = ({record = {export_date: ''}}) => (
    <span className="pl-10">{(record) ? record.export_date : ''}</span>
);

/**
 *
 * @param record
 * @returns {*}
 * @constructor
 */
const StateIcon = ({record}) => {
    let _icon = '', _className = '';
    let diff = 0
    let _days = ' day'
    if (record !== undefined && record.channel_created_at) {
        let dateNow = moment();
        let export_date = moment(record.channel_created_at, 'YYYY-MM-DD H:mm:ss');
        diff = dateNow.diff(export_date, 'days');
        if (diff === 2) {
            _icon = <WarningIcon/>;
            _className += 'yellow-icon';
            _days = ' days'
        } else if (diff >= 3) {
            _icon = <ErrorIcon/>;
            _className += 'red-icon';
            _days = ' days'
        }
    }

    return (
        <span className={_className}>{diff} {_days} {_icon} </span>
    )
};

const defaultDate = dateParser(moment().subtract(4, 'days').format(''), 'created_at_to');

