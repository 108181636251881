/**
 * Sort array Object by key
 *
 * @param property
 * @returns {Function}
 */
import {warehouseFilter} from "../config";

export function dynamicSort(property) {
    let sortOrder = 1;

    if (property[0] === "-") {
        sortOrder = -1;
        property = property.substr(1);
    }

    return function (a, b) {
        if (sortOrder === -1) {
            return b[property].localeCompare(a[property]);
        } else {
            return a[property].localeCompare(b[property]);
        }
    }
}

/**
 * Make fullname from customer_firstname and customer_lastname
 *
 * @param record
 * @returns {*}
 */
export function getFullName(record) {
    if (record !== undefined) {
        let fn = record.customer_firstname ? record.customer_firstname : '';
        let ln = record.customer_lastname ? record.customer_lastname : '';
        return fn + " " + ln;
    }
    return '';
}

/**
 * Get value param from hashString url
 *
 * @param name
 * @param hashString
 * @returns {*}
 */
export function getUrlParameter(name, hashString) {
    name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
    let regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
    let results = regex.exec(hashString);

    return results === null ? null : decodeURIComponent(results[1].replace(/\+/g, ' '));
}

/**
 *
 * @param amount
 * @param currencyCode
 * @returns {string|number}
 */
export function formatCurrency(amount, currencyCode) {
    if (amount === null) {
        amount = 0;
    }

    try {
        return amount.toLocaleString(
            undefined, {style: 'currency', currency: currencyCode}
        )
    } catch (e) {}
    return amount;
}

/**
 *
 * @param coupon_code
 * @returns {string}
 */
export function formatCoupon(coupon_code) {
    if (coupon_code === null || coupon_code === '') {
        return
    }

    return "(" + coupon_code + ")";
}

/**
 * @param warehouseId
 * @returns {string}
 */
export function getWarehouseName(warehouseId) {
    let wName = '';
    warehouseFilter.forEach(function (k) {
        if (k._id === warehouseId) {
            wName = k.name;
            return false;
        }
    });

    return wName;
}