import React, {Component} from 'react';
import {
    Table,
    TableBody,
    TableRow,
    TableHead,
    TableCell,
    Card, CardActions
} from '@material-ui/core';
import {Paper, Typography} from '@material-ui/core';
import laravelJsonServerClient from '../../restClient'
import {GET_LIST, Title, GET_ONE, TopToolbar} from 'react-admin';
import {warehouseFilter} from "../../config";
import ChangeWarehouseBtn from "../buttons/ChangeWarehouseBtn";
import ExportedOptions from "../buttons/exportedOptions";
import ChangeSkuBtn from "../buttons/ChangeSkuBtn";
import ExportErrorMsgBtn from "../buttons/ExportErrorMsgBtn";
import CreateShipment from "../orders/actions/createShipmentBtn";
import {formatCoupon, formatCurrency} from "../../rest/common";
import {defaultReSource, authorized} from '../../authorized'


/**
 *
 */
class Items extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            fulfilmentHistory: [],
            roles: '',
            permissions: ''
        }
    }

    componentDidMount() {
        this.fetchData();
    }

    fetchData() {
        laravelJsonServerClient(GET_LIST, 'items', {
            filter: {order_id: this.props.record.id},
        }).then(r => {
            this.setState({
                data: r.data,
            });
            let totalItem = 0;
            let itemsCanShip = 0;
            this.state.data.forEach(function (k, v) {
                if (k.status === 'export_error' || k.status === 'exported' || k.status === 'shipped' || k.status === 'delivered') {
                    itemsCanShip += 1;
                    totalItem += parseInt(k.qty_ordered - k.qty_canceled);
                }
            });

            localStorage.setItem('totalItem', totalItem);
            localStorage.setItem('itemsCanShip', itemsCanShip);
        });
    }

    updateListItem = () => {
        this.fetchData();
    };

    componentDidUpdate(prevprops, prevstate) {
        if (prevstate.fetchToggle !== this.state.fetchToggle) {
            this.updateNews();
        }
    }

    getWarehouseName(warehouseId) {
        for (let i = 0; i < warehouseFilter.length; i++) {
            var element = warehouseFilter[i];
            if (element._id === warehouseId) {
                return element.name;
            }
        }
    }

    render() {
        const items = this.state.data;
        const cardActionStyle = {
            zIndex: 2,
            display: 'inline-block',
            width: '100%',
        };
        if (!items || items.length === 0) {
            return (
                <div>
                </div>
            )
        }

        const order = this.props.record;
        const alignRight = {textAlign: 'right'};

        const fulfilmentHistory = order.fulfilment_history ? order.fulfilment_history : [];

        return (
            <div>
                <Paper style={{margin: '20px 0 0 0', padding: '10px'}} zdepth={2}>
                    <Card style={{boxShadow: 'none'}}>
                        <TopToolbar style={cardActionStyle}>
                            <Typography style={{float: "left"}} gutterBottom variant="h5" component="h5">
                                Order Items
                            </Typography>
                            <CreateShipment record={items}/>
                        </TopToolbar>
                    </Card>

                    <Table selectable="false">
                        <TableHead>
                            <TableRow>
                                <TableCell>SKU</TableCell>
                                <TableCell>Name</TableCell>
                                <TableCell style={alignRight}>Status</TableCell>
                                <TableCell style={alignRight}>Warehouse</TableCell>
                                <TableCell style={alignRight}>Quantity</TableCell>
                                <TableCell style={alignRight}>Price</TableCell>
                                <TableCell style={alignRight}>Total</TableCell>
                                <TableCell style={alignRight}>Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {items.map(item =>
                                <TableRow key={item.id}>
                                    <TableCell className="pl-10">
                                        {item.sku}
                                    </TableCell>
                                    <TableCell className="pl-24" style={{
                                        fontSize: '12px',
                                        maxWidth: '300px',
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis'
                                    }}>
                                        {item.name}
                                    </TableCell>
                                    <TableCell style={alignRight}><ExportErrorMsgBtn orderItem={item}/></TableCell>
                                    <TableCell
                                        style={alignRight}>{this.getWarehouseName(item.warehouse_id)}</TableCell>
                                    <TableCell style={alignRight} className="pr-24">{item.qty_ordered}</TableCell>
                                    <TableCell style={alignRight} className="pr-24">
                                        {formatCurrency(item.price, order.order_currency_code)}
                                    </TableCell>
                                    <TableCell style={alignRight} className="pr-24">
                                        {formatCurrency(item.row_total, order.order_currency_code)}
                                    </TableCell>
                                    <TableCell style={alignRight}>
                                        {authorized.permissions.indexOf('order.fulfillment_issues.edit') === -1 ?
                                            <span/> :
                                            <ChangeSkuBtn updateListItem={this.updateListItem} orderItem={item}/>}
                                        {authorized.permissions.indexOf('order.fulfillment_issues.edit') === -1 ?
                                            <span/> :
                                            <ChangeWarehouseBtn updateListItem={this.updateListItem} orderItem={item}
                                                                label="Change Warehouse"/>
                                        }
                                        {<ExportedOptions updateListItem={this.updateListItem} orderItem={item}
                                                          label="Options"/>}
                                    </TableCell>
                                </TableRow>
                            )}
                            <TableRow>
                                <TableCell colSpan={5}/>
                                <TableCell style={alignRight} className="pr-24">Subtotal</TableCell>
                                <TableCell style={alignRight} className="pr-24">
                                    {formatCurrency(order.subtotal, order.order_currency_code)}
                                </TableCell>
                                <TableCell/>
                            </TableRow>

                            <TableRow>
                                <TableCell colSpan={5}/>
                                <TableCell style={alignRight} className="pr-24">Shipping Fee</TableCell>
                                <TableCell style={alignRight} className="pr-24">
                                    {formatCurrency(order.shipping_amount, order.order_currency_code)}
                                </TableCell>
                                <TableCell/>
                            </TableRow>

                            <TableRow>
                                <TableCell colSpan={6} style={alignRight}
                                           className="pr-24">Discount {formatCoupon(order.coupon_code)}</TableCell>
                                <TableCell style={alignRight} className="pr-24">
                                    {formatCurrency(order.discount_amount, order.order_currency_code)}
                                </TableCell>
                                <TableCell/>
                            </TableRow>
                            <TableRow>
                                <TableCell colSpan={5}/>
                                <TableCell style={alignRight} className="pr-24">Total Tax</TableCell>
                                <TableCell style={alignRight} className="pr-24">
                                    {formatCurrency(order.tax_amount, order.order_currency_code)}
                                </TableCell>
                                <TableCell/>
                            </TableRow>
                            <TableRow>
                                <TableCell colSpan={5}/>
                                <TableCell style={{textAlign: 'right', fontWeight: 'bold'}} className="pr-24">Grand
                                    Total</TableCell>
                                <TableCell style={{textAlign: 'right', fontWeight: 'bold'}} className="pr-24">
                                    {formatCurrency(order.grand_total, order.order_currency_code)}
                                </TableCell>
                                <TableCell/>
                            </TableRow>
                            <TableRow>
                                <TableCell colSpan={5}/>
                                <TableCell style={{textAlign: 'right', fontWeight: 'bold'}} className="pr-24">Total
                                    Refunded</TableCell>
                                <TableCell style={{textAlign: 'right', fontWeight: 'bold'}} className="pr-24">
                                    {formatCurrency(order.total_refunded, order.order_currency_code)}
                                </TableCell>
                                <TableCell/>
                            </TableRow>
                            <TableRow>
                                <TableCell colSpan={5}/>
                                <TableCell style={{textAlign: 'right', fontWeight: 'bold'}} className="pr-24">
                                    Adjustment Fee</TableCell>
                                <TableCell style={{textAlign: 'right', fontWeight: 'bold'}} className="pr-24">
                                    {formatCurrency(order.refund_fee, order.order_currency_code)}
                                </TableCell>
                                <TableCell/>
                            </TableRow>
                        </TableBody>
                    </Table>
                </Paper>
            </div>
        );
    }
}

export default Items;
