import React, {Component, Fragment, useCallback} from 'react';
import laravelJsonServerClient from '../../restClient'
import {
    CREATE,
    GET_LIST,
    FormTab,
    TextInput,
    NumberInput,
    BooleanInput,
    required, maxValue,
    number,
    email,
    SelectInput,
    TabbedForm, Toolbar, minValue, refreshView as refreshViewAction
} from 'react-admin';
import {
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    CardHeader, Button
} from '@material-ui/core';
import {useForm, Field} from 'react-final-form';
import {channels, warehouseFilter} from "../../config";
import OMSSaveButton from "../form/OMSSaveButton";
import OMSNotification from "../form/OMSNotification";
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import {countries} from "../countries";
import {ReasonforReplacement} from "../../config";
import swal from 'sweetalert';
import {getUrlParameter} from "../../rest/common";
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import {defaultReSource, authorized} from '../../authorized'

/**
 *
 */
class ReshipForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            styleToolbar: 'hideToolBar',
            addresses: [],
            items: [],
            defaultValue: [],
            validateItems: [],
            notification_open: false,
            reship_order: null,
            timeout: 7000,
            saving: false,
            timeouts: [],
            orderNumber: '',
            removeItem: '',
            default_warehouseId: '',
            inventory: {},
            warehouseLoaded: false,
            warehouseHasProductInStock: []
        };

        this.alertStock = 'textRed_stock';
        this.validate = this.validate.bind(this);
        this.openReshipOrder = this.openReshipOrder.bind(this);
        this.removeItem = this.removeItem.bind(this);
        this.addItem = this.addItem.bind(this);
        this.loadInventory = this.loadInventory.bind(this);
    }

    loadWarehouseBySku() {
        let _this = this;
        if (this.state.items) {
            this.state.items.map((item, key) => {
                let wareHouseData = [];
                let maxQty = 0;
                laravelJsonServerClient(GET_LIST, 'warehouse/by/order-item/' + item.id, {}).then(
                    r => {
                        let inWarehouse = false;
                        let _i = 0;
                        r.data.forEach(function (v, key) {

                            wareHouseData[_i] = ({
                                _id: v.id,
                                name: v.name + ' (Qty: ' + v.available_qty + ')',
                                qty: v.available_qty,
                                className: v.available_qty === 0 ? _this.alertStock : ''
                            });
                            if (v.id === item.warehouse_id) {
                                inWarehouse = true;
                                maxQty = v.available_qty;
                                item.maxQty = maxQty
                            }
                            _i++;
                        });
                        if (inWarehouse === false) {
                            warehouseFilter.forEach(v => {
                                if (v._id === item.warehouse_id) {
                                    wareHouseData[_i] = ({
                                        _id: v._id,
                                        name: v.name + ' (Qty: 0)',
                                        qty: 0,
                                        className: _this.alertStock
                                    });
                                    item.addRedText = true;
                                }
                            });
                        }
                    }
                ).catch((e) => {
                    console.error(e);
                });
                item.warehouseIncSku = wareHouseData;
            });
            this.setState({warehouseLoaded: true})
        }
    }

    loadWarehouseHaveProductInStock() {
        if (this.props !== undefined) {
            laravelJsonServerClient(GET_LIST, 'warehouse/product/inStock', {}).then(
                r => {
                    let makeWarehouseData = [];
                    r.data.forEach(function (v, key) {
                        makeWarehouseData[key] = ({_id: v.id, name: v.name});
                    });
                    this.setState({warehouseHaveProductInStock: makeWarehouseData})
                }
            ).catch((e) => {
                console.error(e);
            });
        }
    }



    removeItem(id) {

        let items = this.state.items;
        if(items.length > 1) {

            for (let i = 0; i < items.length; i++) {
                let current = items[i];
                if (current['id'] === id) {
                    items.splice(i, 1);
                }
            }
        }

        this.setState({items: items});
    }

    addItem() {
        let items = this.state.items;
        let validateItems = this.state.validateItems;

        const randitem = 'rand' + Math.floor(Math.random() * 99999) + 1;
        const initItem = {
            id: randitem,
            sku: '',
            warehouse_id: '',
            randitem: true,
            qty_shipped: 1,
            qty_ordered: 0,
            fixed_qty_shipped: 0
        };

        initItem['qty_' + randitem] = 1;
        initItem['sku_' + randitem] = '';
        initItem['removed'] = false;
        initItem['warehouse_' + randitem] = '';
        initItem['inventory'] = '';
        initItem['warehouseIncSku'] = this.state.warehouseHaveProductInStock;
        items.push(initItem);

        validateItems.push('qty_' + randitem);
        validateItems.push('sku_' + randitem);

        this.setState({items: items, validateItems: validateItems});
    }

    componentDidMount() {
        let orderId = getUrlParameter('orderId', window.location.hash);
        if (orderId) {
            this.orderId = orderId;
            this.setState({orderId: orderId});
            this.fetchData();
        } else {
            alert('Order ID not found');
        }

    }

    prepairData() {
        const items = this.state.items;
        const addresses = this.state.addresses;
        const validateItems = [];
        let defaultValue = {};
        let default_warehouseId = '';

        for (let i = 0; i < items.length; i++) {
            let current = items[i];
            this.loadInventory(current['warehouse_id']);
            default_warehouseId = current['warehouse_id'];
            items[i]['fixed_qty_shipped'] = items[i]['qty_ordered'];
            items[i]['sku_' + current['id']] = items[i]['sku'];
            items[i]['qty_' + current['id']] = 0;
            items[i]['warehouse_' + current['id']] = items[i]['warehouse_id'];
            items[i]['inventory'] = '';
            items[i]['removed'] = false;

            defaultValue['qty_' + current['id']] = 0;
            defaultValue['warehouse_' + current['id']] = items[i]['warehouse_id'];
            defaultValue['sku_' + current['id']] = items[i]['sku'];

            validateItems.push('qty_' + current['id']);
            validateItems.push('sku_' + current['id']);

        }

        let allowedFields = [
            'sales_order_id', 'email', 'firstname', 'lastname', 'city', 'region', 'postcode', 'country', 'address1',
            'middlename', 'company', 'address2', 'address3', 'telephone', 'fax', 'is_commercial', 'is_pobox'
        ];

        if (addresses && addresses.length > 0) {
            addresses.forEach((address) => {
                if (address.address_type === 'shipping') {
                    allowedFields.forEach(field => {
                        if (address.hasOwnProperty(field)) {
                            try {
                                defaultValue[field] = address[field];
                            } catch (e) {
                                defaultValue[field] = {}
                            }
                        }
                    });
                }
            });
        }

        this.setState({
            default_warehouseId: default_warehouseId,
            items: items,
            defaultValue: defaultValue,
            validateItems: validateItems
        });

    }

    loadInventory = warehouse => {
        let inventory = this.state.inventory;
        laravelJsonServerClient(GET_LIST, 'inventory/warehouse/' + warehouse, {filter: {inclOOS: false}}).then(
            r => {
                let _data = [];
                r.data.forEach(function (v, k) {
                    _data[k] = {
                        id: v.id,
                        sku: v.sku,
                        qty: v.available_qty,
                        skuQty: ' (Qty: ' + v.available_qty + ')',
                    };
                });
                inventory[warehouse] = _data;
                this.setState({inventory: inventory});
            }
        ).catch((e) => {
            console.error(e);
        });
    };

    initAddress() {
        const initAddress = [
            {
                sales_order_id: this.orderId,
                address1: "",
                address2: "",
                address3: "",
                address_type: "shipping",
                channel_order_no: "",
                city: "",
                company: "",
                country: "",
                email: "",
                fax: "",
                firstname: "",
                is_commercial: "",
                is_pobox: "",
                lastname: "",
                middlename: "",
                postcode: "",
                region: "",
                telephone: ""
            }
        ];

        this.setState({
            addresses: initAddress
        });
    }

    fetchData() {
        let _this = this;
        if (this.orderId) {
            laravelJsonServerClient(GET_LIST, 'address', {
                filter: {order_id: this.orderId},
            }).then(r => {
                if (r.data.length === 0) {
                    _this.initAddress();
                } else {
                    _this.setState({
                        addresses: r.data,
                    });
                }
                this.prepairData()
            });

            laravelJsonServerClient(GET_LIST, 'items', {
                filter: {order_id: this.orderId, realItem: true},
            }).then(r => {
                _this.setState({
                    items: r.data
                });

                _this.setState({
                    orderNumber: r.data[0]['channel_order_no']
                });
                _this.loadWarehouseBySku();
                _this.loadWarehouseHaveProductInStock();
                _this.prepairData()
            });
        }
    }

    validate(value) {
        let styleToolbar = '';
        let allowedFields = ['city', 'postcode', 'country', 'address1', 'email', 'firstname', 'lastname'];

        for (let i = 0; i < allowedFields.length; i++) {
            let item = allowedFields[i];
            if (value[item] === '' || value[item] === null || value[item] === undefined) {
                styleToolbar = 'hideToolBar';
                this.setState({
                    styleToolbar: styleToolbar
                });
                return;
            }
        }

        let skusQty = _.pickBy(value, function (inputValue, inputKey) {
            return _.startsWith(inputKey, "qty_") && inputValue > 0;
        });

        if (Object.keys(skusQty).length === 0) {
            return {
                message: '',
                value: value
            };
        }

        return true;
    }

    componentWillUnmount() {
        this.state.timeouts.forEach(clearTimeout);
    }

    customSource(key, name) {
        return name + '_' + key;
    }

    checkToShowWarning(items, formData) {
        let _inventory = this.state.inventory;
        let toggleShow = false;

        items.forEach(function (_item) {
            if (_item.removed === undefined || _item.removed === false) {
                if (_item.qty_ordered > 0) {
                    // Item original
                    let qtyReship = parseInt(formData['qty_' + _item.id]);
                    if (qtyReship > 0) {
                        _item.warehouseIncSku.forEach(function (wh) {
                            if (wh._id === _item.warehouse_id && qtyReship > wh.qty) {
                                toggleShow = true;
                                return false;
                            }
                        });
                    }else{
                        toggleShow = false;
                        return false;
                    }
                } else {
                    // Item added
                    if (_inventory[_item.warehouse_id] !== undefined) {
                        _inventory[_item.warehouse_id].forEach(function (wh) {
                            let qtyReship = parseInt(formData['qty_' + _item.id]);
                            if (wh.sku === formData['sku_' + _item.id] && qtyReship > 0 && qtyReship > wh.qty) {
                                toggleShow = true;
                                return false;
                            }
                        });
                    }else{
                        toggleShow = true;
                        return false;
                    }
                }
            }
        });

        return toggleShow;
    };

    doSubmit = formData => {
        this.setState({saving: true});
        laravelJsonServerClient(CREATE, 'reship', {data: formData})
            .then(r => {
                this.setState({
                    notification_open: true,
                    reship_order: r.data.id,
                });
                this.state.timeouts.push(setTimeout(() => {
                    this.props.history.push('/order')
                }, this.state.timeout));
                this.props.refreshView();
            })
            .catch((e) => {
                this.setState({saving: false});
                console.error(e);
            });
    };

    onSubmit = formData => {
        if(this.state.saving === true){
            return false;
        }

        if (this.checkToShowWarning(this.state.items, formData) === false) {
            this.doSubmit(formData);
            return true;
        }

        let _this = this;
        let msg = "This reship order includes items that are out of stock in the selected warehouse. Please select item(s) which is(are) in stock.";

        swal(msg,
            {
                title: 'WARNING',
                buttons: {
                    cancel: "Ok"
                },
            }).then(value => {
            switch (value) {
                case "catch":
                    _this.doSubmit(formData);
                    return true;
                case "cancel":
                    return false;
                default:
                    return false;
            }
        });
    };

    openReshipOrder = () => {
        return (
            <Button color="secondary" size="small"
                    component={Link}
                    to={{pathname :'/order/' + this.state.reship_order + '/show', search: "",
                        state:{} }}
            >Open order</Button>
        )
    };

    qtyValidate(item) {
        return [number(), minValue(0), maxValue(item !== undefined ? item['maxQty'] : 0)];
    }

    render() {
        const items = this.state.items;
        const requiredValidator = [required()];
        const emailValidator = [required(), email()];
        const inventoryList = this.state.inventory;
        const defaultValue = this.state.defaultValue;
        const toolbarTile = 'Create reship for: ' + this.state.orderNumber;

        if (defaultValue.length !== 0) {
            items.forEach((item) => {
                if (item['removed'] === false) {
                    if (item.hasOwnProperty('randitem')) {
                        let inventory = [{id: '', sku: '', skuQty: ''}];
                        if (inventoryList.hasOwnProperty(item.warehouse_id)) {
                            inventory = inventoryList[item.warehouse_id];
                        }
                        const optionRenderer = choice => `${choice.sku} ${choice.skuQty} `;

                        item['inventory'] = (<SelectInput
                            source={this.customSource(item.id, 'sku')}
                            label="Item"
                            validate={requiredValidator}
                            optionText={optionRenderer} optionValue="sku"
                            choices={inventory}
                            onChange={(event, index, value) => handleChange(value, item, event, 'sku')}
                        />);
                        item['qty'] = <NumberInput source={this.customSource(item.id, 'qty')}
                                                   initialValue={0}
                                                   step={1} label="" placeholder=""
                                                   validate={this.qtyValidate(item)}/>
                    } else {
                        item['inventory'] = (<Field
                            className="customclass"
                            readOnly={true}
                            name={this.customSource(item.id, 'sku')}
                            source={this.customSource(item.id, 'sku')}
                            component="input" type="text"/>
                            );

                        item['qty'] = <NumberInput source={this.customSource(item.id, 'qty')}
                                                   step={1} label="" placeholder=""
                                                   validate={this.qtyValidate(item)}/>
                    }

                    item['remove'] = (<Button
                        onClick={() => this.removeItem(item.id)}
                        id="reship_button"
                        startIcon={<DeleteIcon/>}
                        labelPosition="after"
                        label="Remove">Remove</Button>);
                    item['style'] = {};
                } else {
                    item['style'] = {display: 'none'};
                }
            });
        }

        /**
         *
         * @param props
         * @returns {*}
         * @constructor
         */
        const PostCreateToolbar = props => {
            const form = useForm();
            var formdata = form.getState().values;

            const handleClick = useCallback(() => {
                this.onSubmit(formdata);
            }, [formdata, form]);

            return (
                <Toolbar {...props} >
                    <OMSSaveButton label='Create reship'
                                   disabled={props.invalid}
                                   saving={this.state.saving}
                                   onClick={handleClick}
                                    submitOnEnter={false}/>
                </Toolbar>
            );
        }

        const handleChange = (value, item, event, key) => {
            const newitem = event.target.value;
            const maxQty = {id: newitem, value: 0};
            if (key === 'warehouse_id') {
                let lagRedText = this.alertStock;
                item.warehouseIncSku.forEach(function (value) {
                    if (value._id === newitem && (value.qty > 0 || value.qty === undefined)) {
                        lagRedText = '';
                        maxQty['value'] = value.qty;
                    }
                });
                item['className'] = lagRedText;
            }

            if (key === 'sku') {
                let inventoryList = this.state.inventory;
                if (inventoryList.hasOwnProperty(item.warehouse_id)) {
                    inventoryList = inventoryList[item.warehouse_id ];
                }
                let inventoryQty = _.find(inventoryList, {sku: newitem});
                maxQty['value'] = inventoryQty === undefined ? 0 : inventoryQty['qty'];
            }


            const items = this.state.items;
            for (let i = 0; i < items.length; i++) {
                let currentItem = items[i];
                if (currentItem['id'] === item['id']) {
                    items[i][key] = newitem;
                    items[i]['maxQty'] = maxQty['value'];
                    if (key === 'sku') {
                        items[i]['sku_' + item['id']] = newitem;
                    }

                    if (key === 'qty_shipped') {
                        items[i]['qty_' + item['id']] = newitem;
                    }

                    if (key === 'warehouse_id') {
                        items[i]['warehouse_' + item['id']] = newitem;
                    }

                    if (key === 'warehouse_id') {
                        this.loadInventory(newitem)
                    }
                }
            }
            this.setState({items: items});
        }

        return (this.state.warehouseLoaded ?
                <div className="formReships">
                    <CardHeader title={toolbarTile} className="title"/>
                    <TabbedForm idToolbar="reshipToolbar" syncWithLocation={false}
                                   toolbar={<PostCreateToolbar/>}
                                   styleToolbar={this.state.styleToolbar}
                                   validate={this.validate}
                                   submitOnEnter={false}
                                   invalid={false}
                                   label={toolbarTile}
                                   defaultValue={defaultValue} {...this.props}>
                        <FormTab label="Address">
                            <TextInput disabled source="sales_order_id" validate={requiredValidator}/>
                            <TextInput source="email" validate={emailValidator}/>
                            <TextInput source="firstname" validate={requiredValidator}/>
                            <TextInput source="lastname" validate={requiredValidator}/>
                            <TextInput source="middlename"/>
                            <TextInput source="city" validate={requiredValidator}/>
                            <TextInput source="region"/>
                            <TextInput source="postcode" validate={requiredValidator}/>
                            <SelectInput name="country" isRequired={true}
                                         source="country"
                                         choices={countries}
                                         optionValue="code"/>
                            <TextInput label="Address" source="address1" validate={requiredValidator}/>
                            <TextInput label="Second Address" source="address2"/>
                            <TextInput label="Third Address" source="address3"/>
                            <TextInput source="company"/>
                            <TextInput source="telephone"/>
                            <TextInput source="fax"/>
                            <BooleanInput label="Commersial Use" source="is_commercial"/>
                            <BooleanInput label="Is PO Box Address" source="is_pobox"/>
                        </FormTab>
                        <FormTab label="Items to ReShip">
                            <Table selectable="false" className="tbl_tab3" style={{ width: '100%' }}>
                                <TableHead >
                                    <TableRow>
                                        <TableCell></TableCell>
                                        <TableCell>Item SKU </TableCell>
                                        <TableCell>Qty</TableCell>
                                        <TableCell>Reship Items</TableCell>
                                        <TableCell>Warehouse</TableCell>
                                        <TableCell>&nbsp;</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {items.map((item, key) =>
                                        <TableRow key={key} style={item.style}>
                                            <TableCell>
                                                {item.inventory}
                                            </TableCell>
                                            <TableCell>{item.fixed_qty_shipped}</TableCell>
                                            <TableCell>
                                                {item.qty}
                                            </TableCell>
                                            <TableCell>
                                                <SelectInput label="Warehouse"
                                                             source={this.customSource(item.id, 'warehouse')}
                                                             choices={item.warehouseIncSku}
                                                             optionText="name" optionValue="_id"
                                                             onChange={(event, index, value) => handleChange(value, item, event, 'warehouse_id')}
                                                             defaultValue={this.state.selectChannelDefault}/>

                                            </TableCell>
                                            <TableCell>
                                                {item['remove']}
                                            </TableCell>
                                        </TableRow>
                                    )}
                                    <TableRow>
                                        <TableCell></TableCell>
                                        <TableCell></TableCell>
                                        <TableCell></TableCell>
                                        <TableCell></TableCell>
                                        <TableCell>
                                            <Button
                                                onClick={this.addItem}
                                                id="reship_button"
                                                className="display-none"
                                                primary="true"
                                                startIcon={<AddIcon/>}
                                                labelPosition="after"
                                                label="Add Item">Add Item</Button>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </FormTab>
                        <FormTab label="Warranty Claims">
                            <SelectInput name="warranty_reason" isRequired={true}
                                         source="warranty_reason"
                                         choices={ReasonforReplacement}
                                         optionValue="name"/>
                            <NumberInput label={"Ticket ID"} source="warranty_ticket_id" validate={[number(), minValue(1), required()]} className={"custom_number_input"}/>
                        </FormTab>
                    </TabbedForm>
                    <OMSNotification open={this.state.notification_open}
                                     message={'Reship Order Created'} action={this.openReshipOrder()}/>
                </div>
                : <span/>
        );
    }
}

export default connect(undefined, {refreshView: refreshViewAction})(
    ReshipForm
);
