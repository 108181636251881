import React, {Children} from 'react';
import PropTypes from 'prop-types';
import {Toolbar} from '@material-ui/core';
import {SaveButton} from 'react-admin'
import {Responsive} from 'react-admin'

const styles = {
    mobileToolbar: {
        position: 'fixed',
        bottom: 0,
        width: '100%',
        backgroundColor: 'white',
        justifyContent: 'flex-end',
        zIndex: 2,
    },

    displayToolbar: {
        display: 'none'
    }
};

const valueOrDefault = (value, defaultValue) =>
    typeof value === 'undefined' ? defaultValue : value;

const OMSToolbar = ({
                        invalid, submitOnEnter, handleSubmitWithRedirect, children, styletoolbar, disabled, idToolbar, handleState, saving
                    }) => (
    <Responsive
        small={
            <Toolbar id={idToolbar} className={styletoolbar} style={styles.mobileToolbar} noGutter>
                <Toolbar>
                    {Children.count(children) === 0 ? (
                        <SaveButton
                            handleSubmitWithRedirect={handleSubmitWithRedirect}
                            invalid={invalid}
                            handleState={handleState}
                            submitOnEnter={submitOnEnter}

                        />
                    ) : (
                        Children.map(
                            children,
                            button =>
                                button
                                    ? React.cloneElement(button, {
                                        invalid,
                                        saving: valueOrDefault(
                                            button.props.saving,
                                            saving
                                        ),
                                        handleSubmitWithRedirect,
                                        handleState,
                                        raised: false,
                                        submitOnEnter: valueOrDefault(
                                            button.props.submitOnEnter,
                                            submitOnEnter
                                        ),
                                        disabled
                                    })
                                    : null
                        )
                    )}
                </Toolbar>
            </Toolbar>
        }
        medium={
            <Toolbar id={idToolbar} className={styletoolbar}>
                <Toolbar>
                    {Children.count(children) === 0 ? (
                        <SaveButton
                            handleSubmitWithRedirect={handleSubmitWithRedirect}
                            invalid={invalid}
                            handleState={handleState}
                            submitOnEnter={submitOnEnter}

                        />
                    ) : (
                        Children.map(
                            children,
                            button =>
                                button
                                    ? React.cloneElement(button, {
                                        handleSubmitWithRedirect,
                                        handleState,
                                        invalid,
                                        saving: valueOrDefault(
                                            button.props.saving,
                                            saving
                                        ),
                                        submitOnEnter: valueOrDefault(
                                            button.props.submitOnEnter,
                                            submitOnEnter
                                        ),
                                        disabled,
                                    })
                                    : null
                        )
                    )}
                </Toolbar>
            </Toolbar>
        }
    />
);

OMSToolbar.propTypes = {
    children: PropTypes.node,
    handleSubmitWithRedirect: PropTypes.func,
    invalid: PropTypes.bool,
    submitOnEnter: PropTypes.bool,
    styletoolbar: PropTypes.any,
    idToolbar: PropTypes.any,
    disabled: PropTypes.bool,
    handleState: PropTypes.func,
    saving: PropTypes.bool,
};

OMSToolbar.defaultProps = {
    submitOnEnter: true,
};

export default OMSToolbar;
