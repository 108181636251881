import React, {Component} from 'react';
import PropTypes from "prop-types";

const downloadStyle = {
    display: 'block',
    paddingTop: 20
};

class LinkDownload extends Component {
    constructor(props) {
        super(props);
    };

    fileName(orderId) {
        return 'returnlabel-' + orderId + '.pdf';
    }

    parseExtension(filename) {
        let array = filename.split('.');
        if (array.length == 0) {
            return '';
        }
        return array[array.length - 1];
    }


    render() {
        const record = this.props.record;

        if (record.label_file == undefined) {
            return (<span/>);
        }

        return (
            <a href={Object.values(record.label_file)} style={downloadStyle}
               download={this.fileName(record.sales_order_id)}>Download Shipping Label</a>
        );
    }
}

LinkDownload.propTypes = {
    record: PropTypes.object,
};

export default LinkDownload;