import React, {Component} from 'react';
import {Dialog, Card, Button, DialogActions, DialogTitle, DialogContent} from '@material-ui/core';
import {
    TextInput,
    refreshView as refreshViewAction,
    SelectInput,
    SimpleForm
} from "react-admin";
import {connect} from 'react-redux';
import PropTypes from "prop-types"
import {channels} from "../../config";
import {defaultReSource, authorized} from '../../authorized'
/**
 *
 */
class DeviceRegistrationDialog extends Component {
    /**
     *
     * @param props
     */
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            open: false,
            dialog: false,
            values: null,
            allowSubmit: true,
            data: null,
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.validate = this.validate.bind(this);

    };


    showModal = () => {
        this.setState({show: true});
        this.setState({data: this.props.record});
    };

    hideModal = () => {
        this.setState({show: false});
    };

    validate(value) {
        this.setState({values: value});
    }


    handleSubmit() {

    }


    handleClose = () => {
        this.setState({show: false});
    };

    /**
     *
     * @returns {*}
     */
    render() {
        const record = this.props.record;
        let actions = [
            <Button
                size={"small"}
                label="Dismiss"
                primary="true"
                color={"primary"}
                variant="outlined"
                onClick={this.handleClose}
            >Dismiss</Button>,

        ];


        return (
            <main>
                <span className="pl-24" onClick={this.showModal}>{record[this.props.source]}</span>
                {this.state.data !== null ?
                    <Dialog
                        bodyClassName={"modal-edit-address"}
                        title={record.purchase_order_number}
                        actions={actions}
                        modal={true}
                        open={this.state.show}
                        autoScrollBodyContent={true}
                    >
                        <DialogTitle id="customized-dialog-title" onClose={this.handleClose}>
                            {record.purchase_order_number}
                        </DialogTitle>
                        <DialogContent dividers>
                        <div>
                        <SimpleForm
                            defaultValue={this.state.data}
                            toolbar={<Card/>}
                            showToolBar={false}>

                            <SelectInput  source="channel_id" choices={channels} optionText="name" optionValue="_id"/>
                            <TextInput label="model"   source="model_number"/>
                            <TextInput label="serial"  source="serial_number"/>
                            <TextInput label="Unit "  source="unit_suite"/>
                            <TextInput label="Full Name"  source="full_name"/>
                            <TextInput label="Email" source="email"/>
                            <TextInput label="Phone"  source="phone_number"/>
                            <TextInput label="Address"  source="address"/>
                            <TextInput label="State" source="state"/>
                            <TextInput label="Country"  source="country"/>
                            <TextInput label="Zip Code"  source="zip_code"/>
                            <TextInput label="Purchase Date"  source="purchase_date"/>
                            <TextInput label="Purchase Location"  source="purchase_location"/>

                        </SimpleForm>
                        </div>
                        </DialogContent>
                        <DialogActions>
                            {actions}
                        </DialogActions>
                    </Dialog>

                    :
                    <span></span>
                }
            </main>
        );
    }
}

/**
 *
 * @type {{addLabel: Requireable<boolean>, elStyle: React.DetailedHTMLFactory<React.ObjectHTMLAttributes<HTMLObjectElement>, HTMLObjectElement> | Requireable<Object> | React.DetailedHTMLProps<React.ObjectHTMLAttributes<HTMLObjectElement>, HTMLObjectElement> | string, record: React.DetailedHTMLFactory<React.ObjectHTMLAttributes<HTMLObjectElement>, HTMLObjectElement> | Requireable<Object> | React.DetailedHTMLProps<React.ObjectHTMLAttributes<HTMLObjectElement>, HTMLObjectElement> | string, label: number | string | Requireable<string> | string, source: Validator<NonNullable<T>>}}
 */
DeviceRegistrationDialog.propTypes = {
    addLabel: PropTypes.bool,
    elStyle: PropTypes.object,
    label: PropTypes.string,
    record: PropTypes.object,
    source: PropTypes.string.isRequired,
};

/**
 *
 */
export default connect(undefined, {refreshView: refreshViewAction})(
    DeviceRegistrationDialog
);