import {fetchUtils } from 'react-admin';
import {
    GET_LIST,
    GET_ONE,
    GET_MANY,
    GET_MANY_REFERENCE,
    CREATE,
    UPDATE,
    DELETE,
    DELETE_MANY
} from 'react-admin';
import {config} from "../config";

/**
 * Maps admin-on-rest queries to a json-server powered REST API
 *
 * @see https://github.com/typicode/json-server
 * @example
 * GET_LIST     => GET http://my.api.url/posts?_sort=title&_order=ASC&_start=0&_end=24
 * GET_ONE      => GET http://my.api.url/posts/123
 * GET_MANY     => GET http://my.api.url/posts/123, GET http://my.api.url/posts/456, GET http://my.api.url/posts/789
 * UPDATE       => PUT http://my.api.url/posts/123
 * CREATE       => POST http://my.api.url/posts/123
 * DELETE       => DELETE http://my.api.url/posts/123
 */

export default (apiUrl, httpClient = fetchUtils.fetchJson) => {
    /**
     * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
     * @param {String} resource Name of the resource to fetch, e.g. 'posts'
     * @param {Object} params The REST request params, depending on the type
     * @returns {Object} { url, options } The HTTP request parameters
     */
    const convertRESTRequestToHTTP = (type, resource, params) => {
        let url = '';
        const options = {};
        switch (type) {
            case GET_LIST: {
                const {perPage} = params.perPage || { perPage: config.perPage };
                const { page } = params.pagination || { page: 1 };
                const { field, order } = params.sort || { field:'id', order:'DESC' };
                const query = {
                    ...params.filter,
                    sort: field,
                    order: order,
                    perPage: perPage,
                    page: page,
                };
                url = `${apiUrl}/${resource}?${fetchUtils.queryParameters(query)}`;
                break;
            }
            case GET_ONE:
                url = params ? `${apiUrl}/${resource}/${params.id}` : `${apiUrl}/${resource}`;
                break;
            case GET_MANY_REFERENCE: {
                const { page } = params.pagination;
                const { field, order } = params.sort;
                const query = {
                    ...params.filter,
                    [params.target]: params.id,
                    sort: field,
                    order: order,
                    page: page,
                };
                if (resource.indexOf('/') === -1){
                    url = `${apiUrl}/${resource}?${fetchUtils.queryParameters(query)}`;
                } else {
                    const parts = resource.split('/');
                    url = `${apiUrl}/${parts[0]}/${params.id}/${parts[1]}?${fetchUtils.queryParameters(query)}`;
                }
                break;
            }
            case UPDATE:
                url = `${apiUrl}/${resource}/${params.id}`;
                options.method = 'PUT';
                options.body = JSON.stringify(params.data);
                break;
            case CREATE:
                if (resource.indexOf('/') === -1){
                    url = `${apiUrl}/${resource}`;
                } else {
                    const parts = resource.split('/');
                    if(params.data.reference_id !== undefined) {
                        url = `${apiUrl}/${parts[0]}/${params.data.reference_id}/${parts[1]}`;
                    }else{
                        url = `${apiUrl}/${resource}`;
                    }
                }

                options.method = 'POST';
                options.body = JSON.stringify(params.data);
                break;
            case DELETE:
                url = `${apiUrl}/${resource}/${params.id}`;
                options.method = 'DELETE';
                break;
            case DELETE_MANY:
                url = `${apiUrl}/${resource}/${params.ids}`;
                options.method = 'DELETE';
                break;
            default:
                throw new Error(`Unsupported fetch action type ${type}`);
        }
        return { url, options };
    };

    /**
     * @param {Object} response HTTP response from fetch()
     * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
     * @param {String} resource Name of the resource to fetch, e.g. 'posts'
     * @param {Object} params The REST request params, depending on the type
     * @returns {Object} REST response
     */
    const convertHTTPResponseToREST = (response, type, resource, params) => {
        const { json } = response;
        switch (type) {
            case GET_LIST:
                return {
                    data: json.data,
                    total: parseInt(json.total, 10),
                };
            case GET_MANY_REFERENCE:
                return { data: json.data };
            case CREATE:
                return { data: { ...params.data, id: json.id, json: json } };
            case DELETE:
            case DELETE_MANY:
                return {data: json};
            default:
                return { data: json };
        }
    };

    /**
     * @param {string} type Request type, e.g GET_LIST
     * @param {string} resource Resource name, e.g. "posts"
     * @param {Object} payload Request parameters. Depends on the request type
     * @returns {Promise} the Promise for a REST response
     */
    return (type, resource, params) => {
        // json-server doesn't handle WHERE IN requests, so we fallback to calling GET_ONE n times instead
        if (type === GET_MANY) {
            return Promise.all(params.ids.map(id => httpClient(`${apiUrl}/${resource}/${id}`)))
                .then(responses => responses.map(response => response.json));
        }
        const { url, options } = convertRESTRequestToHTTP(type, resource, params);
        return httpClient(url, options)
            .then(response => convertHTTPResponseToREST(response, type, resource, params));
    };
};

