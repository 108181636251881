import React, {Component} from 'react';
import {connect} from 'react-redux';
import {userLogin, useLogin, useNotify} from 'react-admin';
import {Button} from "@material-ui/core";
import {withRouter} from 'react-router-dom';
import {config} from "./config";
import {fetchUtils} from 'react-admin';
import {GoogleOAuthProvider} from '@react-oauth/google';
import {useGoogleLogin} from '@react-oauth/google';
import PropTypes from "prop-types";

/**
 *
 */
class LoginPage extends Component {
    /**
     *
     * @returns {*}
     */
    render() {
        const history = this.props.history;

        return (
            <div class="login-page">
                <div class="content">
                    <img class="logo" src="cebi-logo.png"/>
                    <GoogleOAuthProvider clientId={config.GoogleOAuthProviderClientID}>
                        <LoginBtn history={history}/>
                    </GoogleOAuthProvider>
                </div>
            </div>
        );
    }

}

export default withRouter(connect(undefined, {userLogin})(LoginPage));

/**
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const LoginBtn = (props) => {
    const {history} = props;
    const login = useLogin();

    const onSuccess = (response) => {
        console.log(response);
        login(response.access_token);
    };

    /**
     *
     * @param res
     */
    const onFailure = (res) => {
        console.log('Login failed: res:', res);
    };

    let signIn = useGoogleLogin({
        onSuccess: response => onSuccess(response),
        onError: errorResponse => onFailure(errorResponse),
        flow: 'implicit'
    });

    if (config.environment === 'development') {
        const $respond = {
            access_token: "9Ap9taRaagaltMocftyMtThkTALlNG9rGQjz4OOBck0rRhYn8oux9iqx8XiBH1gi3vzbNys1ovFveyUuf6oUjcDFC73LG84OeUjAXqmaNx5nfbWgbBFY5dAjmrxV8ArH",
            token_type: "Bearer",
            expires_in: 3599,
            scope: "email profile https://www.googleapis.com/auth/userinfo.profile openid https://www.googleapis.com/auth/userinfo.email",
            authuser: "0",
            hd: "ebuynow.com",
            prompt: "none"
        }

        signIn = () => {
            onSuccess($respond)
        };
    }

    return (
        <button onClick={signIn} className="googleLoginContent">
            <img src="google.png" width={40} alt="google"/>
            <span>
                OMS Sign in with Google
            </span>
        </button>
    );
}

/**
 *
 * @type {{history: Requireable<any>}}
 */
LoginBtn.propTypes = {
    history: PropTypes.any,
};
