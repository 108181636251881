import React, {Component} from 'react';
import MenuIcon from '@material-ui/icons/Menu';
import {getParamFromUrl, getUrlParameter} from "../../rest/common";
import Button from "@material-ui/core/Button";
import {defaultReSource, authorized} from '../../authorized'

/**
 *
 */
class ListBtn extends Component {
    constructor(props) {
        super(props);
        let location = window.location.hash;
        this.state = {
            location: location
        };
    };

    handleClick = () => {
        let backTo = getUrlParameter('backTo', this.state.location);
        if (backTo) {
            window.location.href = '#/' + backTo;
            return;
        }
        window.location.href = '#/order';
    };

    render() {
        return (
            <span>
                <Button
                    variant="contained"
                    color="primary"
                    size={'small'}
                    onClick={this.handleClick}
                    startIcon={<MenuIcon/>}
                    label="LIST"
                >
                    LIST
                </Button>
            </span>
        );
    }
}

export default ListBtn;
