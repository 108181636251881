import React, {Component} from 'react';
import {Dialog, DialogActions, Button, DialogTitle, DialogContent} from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import EditIcon from '@material-ui/icons/Edit';
import {
    UPDATE, GET_ONE,
    refreshView as refreshViewAction, SelectInput
} from "react-admin";
import laravelJsonServerClient from '../../restClient'
import {connect} from 'react-redux';
import PropTypes from "prop-types";
import ModelForm from "./modelForm";
import {authorized} from "../../authorized";
import CopyIcon from "@material-ui/icons/FileCopy";
import AddProductModelBtn from "./addProductModelBtn";
import EditRequestBtn from "../returns/EditRequestBtn";
import FetchShipmentWarehouse from "../buttons/FetchShipmentWarehouse";

/**
 *
 */
class EditProductModelBtn extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            open: false,
            dialog: false,
            values: null,
            allowSubmit: false,
        };

        this.handleSubmit = this.handleSubmit.bind(this);
    };

    fetchData() {
        let modelId = this.props.modelId == null ? this.props.record.id : this.props.modelId
        laravelJsonServerClient(GET_ONE, 'product-model', {id: modelId}).then(r => {
            this.setState({
                data: r.data ? r.data : [],
            });
        });
    }

    handleSubmit() {
        const values = this.state.values;
        laravelJsonServerClient(UPDATE, 'product-model', {id: this.props.record.id, data: values}).then(
            r => {
                if (r.data.result) {
                    this.handleClose();
                    this.props.refreshView();
                } else {
                    this.handleClose();
                    alert(r.data.message);
                }
            }
        ).catch((e) => {
            console.error(e);
        });
    }

    showModal = () => {
        this.fetchData();
        this.setState({show: true});
    };

    handleClose = () => {
        this.setState({show: false});
    };

    onHandleCallbackData = (params, isFormValid) => {
        this.setState({values: params});
        this.setState({allowSubmit: isFormValid});
    }

    render() {
        let record = this.props.record;
        let buttonEdit = '';
        let actions = [];
        let ppFormId = "";

        if (this.props.viewOnly === false) {
            ppFormId = "pp-form-model-edit-" + record.id;
            let initFormValue = {...record};
            initFormValue = {...initFormValue, ...JSON.parse(initFormValue.warranty)}
            initFormValue.name = "Copy " + record.name;
            const allowSubmit = !this.state.allowSubmit;
            actions = [
                <AddProductModelBtn
                    btnText="Duplicate"
                    btnIcon={<CopyIcon/>}
                    defaultFormValue={initFormValue}
                    parentPpId={ppFormId}
                    onHidePopupCallback={this.handleClose}/>,
                <Button
                    label="Submit"
                    key={'secondary'}
                    primary="true"
                    variant={"outlined"} color={"secondary"}
                    size={"small"}
                    startIcon={<SaveIcon/>}
                    disabled={allowSubmit}
                    onClick={this.handleSubmit}>Update</Button>,
                <Button
                    label="Dismiss"
                    primary="true"
                    key={'primary'}
                    size={"small"}
                    color={"primary"}
                    variant="outlined"
                    onClick={this.handleClose}>Dismiss</Button>
            ];

            if (record && authorized.permissions.indexOf('product.model.edit') !== -1) {
                buttonEdit = <Button
                    label=""
                    size="small"
                    style={{overflow: 'inherit', color: '#000'}}
                    onClick={this.showModal}
                    startIcon={<EditIcon/>}>
                    Edit
                </Button>
            }
        } else {
            record = {name: this.props.modelName, id: this.props.modelId}
            actions = [
                <Button
                    label="Dismiss"
                    primary="true"
                    key={'primary'}
                    size={"small"}
                    color={"primary"}
                    variant="outlined"
                    onClick={this.handleClose}>Dismiss</Button>
            ];

            if (this.props.modelId != null) {
                buttonEdit = <Button
                    label=""
                    size="small"
                    style={{overflow: 'inherit', color: '#000'}}
                    onClick={this.showModal}>
                    {this.props.modelName}
                </Button>
            }
            ppFormId = "pp-form-model-edit-" + this.props.modelId;
        }


        return (
            <span>
                {buttonEdit}
                <Dialog
                    id={ppFormId}
                    className="modal-edit-product has-duplicate-btn"
                    title={false}
                    actions={actions}
                    modal={true}
                    maxWidth={'sm'}
                    fullWidth={'true'}
                    open={this.state.show}
                    autoScrollBodyContent={true}
                >
                    <DialogTitle id="customized-dialog-title" onClose={this.handleClose}>
                        Model: {record.name ?? record.id}
                    </DialogTitle>
                    <DialogContent dividers>
                        <span>
                            <ModelForm
                                save={this.handleSubmit}
                                handleSubmit={this.handleClose}
                                productId={record.product_id}
                                defaultFormValue={this.state.data}
                                onFormCallback={this.onHandleCallbackData}
                            />
                        </span>
                    </DialogContent>
                    <DialogActions className="dialog-footer">
                        {actions}
                    </DialogActions>
                </Dialog>
            </span>
        );
    }
}

/**
 *
 * @type {{model_name: Requireable<string>, model_id: Requireable<number>}}
 */
EditProductModelBtn.propTypes = {
    modelId: PropTypes.number,
    modelName: PropTypes.string,
    viewOnly: PropTypes.bool
};

/**
 *
 * @type {{model_name: string, model_id: null}}
 */
EditProductModelBtn.defaultProps = {
    modelId: null,
    modelName: '',
    viewOnly: false
};

export default connect(undefined, {refreshView: refreshViewAction})(
    EditProductModelBtn
);

