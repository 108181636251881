import * as React from 'react';
import {
    List,
    Datagrid,
    TextField,
    Filter,
    NumberInput,
    TopToolbar, Pagination
} from 'react-admin';
import {config} from "../config";
import WarehouseDialog from "./inventory/WarehouseDialog";
import {authorized} from '../authorized'

/**
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const InventoryFilters = (props) => (
    <Filter {...props}>
    </Filter>
);


/**
 *
 * @param resource
 * @param filters
 * @param displayedFilters
 * @param filterValues
 * @param basePath
 * @param showFilter
 * @returns {*}
 * @constructor
 */
const WarehouseActions = ({resource, filters, displayedFilters, filterValues, basePath, showFilter}) => (
    <TopToolbar style={cardActionStyle}>
        {filters && React.cloneElement(filters, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: 'button'
        })}
    </TopToolbar>
);

const WarehousePagination = props => <Pagination rowsPerPageOptions={[config.perPage]} {...props} />;
/**
 *
 * @param props
 * @returns {*}
 * @constructor
 */
export const WarehouseList = (props) => {
    if (authorized.permissions.indexOf('warehouse.list') === -1) {
        return (<span/>)
    }
    return (
        <span>
        <List {...props} actions={<WarehouseActions/>}
              filters={<InventoryFilters/>} perPage={config.perPage}
              filterDefaultValues={{}}
              bulkActionButtons={false}
              pagination={<WarehousePagination/>}
              sort={{field: 'id', order: 'DESC'}}>
            <Datagrid>
                <TextField label="Name" source="name"/>
                <TextField label="Stock Owner" source="stock_owner"/>
                <TextField label="City" source="city"/>
                <TextField label="Region" source="region"/>
                <TextField label="Postcode" source="postcode"/>
                <TextField label="Country" source="country"/>
                <TextField label="Address" source="address"/>
                <TextField label="Region Reporting" source="region_reporting"/>
                <TextField label="Telephone" source="telephone"/>
                <WarehouseDialog label="" source="warehouse_id"/>

            </Datagrid>
        </List>
    </span>
    )
};

/**
 * @type {{display: string, float: string, zIndex: number}}
 */
const cardActionStyle = {
    zIndex: 2,
    display: 'inline-block',
    float: 'right',
};

