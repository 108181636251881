import {config} from "../config";
import * as React from "react";
import {
    List,
    Datagrid,
    TextField,
    Filter,
    TextInput,
    TopToolbar, Pagination
} from 'react-admin';
import EditUserBtn from "./users/editUserBtn";
import UsersReportDownload from "./buttons/usersReportDownload";
import OMSDeleteRecordBtn from "./buttons/OMSDeleteRecordBtn";

/**
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const UsersFilters = (props) => (
    <Filter {...props}>
        <TextInput alwaysOn text="email" source="email"/>
    </Filter>
);

/**
 *
 * @param resource
 * @param filters
 * @param displayedFilters
 * @param filterValues
 * @param basePath
 * @param showFilter
 * @returns {*}
 * @constructor
 */
const UsersActions = ({resource, filters, displayedFilters, filterValues, basePath, showFilter}) => (
    <TopToolbar style={cardActionStyle}>
        {filters && React.cloneElement(filters, { resource, showFilter, displayedFilters, filterValues, context: 'button' }) }
        <UsersReportDownload />
    </TopToolbar>
);

/**
 * @type {{display: string, float: string, zIndex: number}}
 */
const cardActionStyle = {
    zIndex: 2,
    display: 'inline-block',
    float: 'right',
};

/**
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const UsersPagination = props => <Pagination rowsPerPageOptions={[config.perPage]} {...props} />;

/**
 *
 * @param props
 * @returns {*}
 * @constructor
 */
export const UsersList = (props) => (
    <span>
        <List {...props} actions={<UsersActions/>}
              perPage={config.perPage}
              filters={<UsersFilters/>}
              bulkActionButtons={false}
              pagination={<UsersPagination />}
              sort={{field: 'id', order: 'DESC'}}>
            <Datagrid>
                <TextField label="ID" source="id"/>
                <TextField label="Name" source="name"/>
                <TextField label="Email" source="email"/>
                <TextField label="Role" source="role_names"/>
                <TextField label="Created At" source="created_at"/>
                <EditUserBtn />
                <DeleteUserBtn {...props}/>
            </Datagrid>
        </List>
    </span>

);

const DeleteUserBtn = (props) => {
    let canDelete = (props.record.id !== Number(localStorage.getItem('user_id')));
    return canDelete ? <OMSDeleteRecordBtn permissionAllowed="user.delete" resource="user" {...props}/> : '';
};
