import StarsIcon from "@material-ui/icons/Stars";
import {OrderList, OrderShow} from "./entities/orders";
import {fetchUtils, Resource} from "react-admin";
import SdStorageIcon from "@material-ui/icons/SdStorage";
import {InventoryCreate, InventoryList, InventoryShow} from "./entities/inventory";
import DashBoard from "./layout/dashBoard";

export const authorized = {
    roles: localStorage.getItem('roles') === null ? "" : localStorage.getItem('roles'),
    permissions: localStorage.getItem('permissions') === null ? [] : localStorage.getItem('permissions')
};

export const defaultReSource = () => {
    const roles = authorized.roles;
    let resource = '';

    switch (roles) {
        case 'admin':
        case 'merchandising':
        case 'scm':
        case 'finance':
        case 'customer_support_agent':
        case 'customer_support_manager':
            resource = <Resource name="order" icon={StarsIcon} list={OrderList} show={OrderShow}
                                 options={{label: 'Order', display: false, authorized: true}}/>
            break;
        case 'warehouse':
            resource = <Resource name="inventory" icon={SdStorageIcon}
                                 list={InventoryList}
                                 create={InventoryCreate}
                                 show={InventoryShow}
                                 options={{label: 'Inventory', display: false, authorized: true}}/>

            break;
        default:
            resource = <Resource name="dashboard" list={DashBoard} options={{label: '', display: false}}/>
    }

    return resource;
};