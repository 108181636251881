import React, {Component} from 'react';
import laravelJsonServerClient from '../../restClient'
import {refreshView as refreshViewAction, UPDATE} from "react-admin";
import PropTypes from "prop-types";
import {Snackbar, Button} from "@material-ui/core";
import {isReshipChannel, isCancelAbleStores} from "../channels";
import {connect} from 'react-redux';
import swal from "sweetalert";
import CancelIcon from '@material-ui/icons/Cancel';
import {defaultReSource, authorized} from '../../authorized'

/**
 *
 */
class CancelOrderBtn extends Component {
    constructor(props) {
        super(props);
        this.state = {
            autoHideDuration: 4000,
            open: false,
            msg: '',
            order: this.props.record,
            data: []
        };
    };

    /**
     *
     */
    handleClickCancel = () => {
        this.processCancel();
    };

    handleRequestClose = () => {
        this.setState({
            open: false,
        });
    };

    /**
     *
     */
    processCancel = () => {
        let _this = this;
        swal('Are you sure you want to CANCEL this order?',
            {
                title: '#' + _this.props.record.channel_order_no,
                buttons: {
                    cancel: "Cancel",
                    ok: {
                        text: "Ok",
                        value: "catch",
                    }
                },
            }).then(value => {
            switch (value) {
                case "catch":
                    _this.setState({
                        msg: 'Canceling orders #' + _this.props.record.channel_order_no + '...',
                        open: true,
                    });
                    laravelJsonServerClient(UPDATE, 'order/cancel', {id: _this.props.record.id}).then(
                        r => {
                            if (r.data.message && r.data.message === 'Error' && r.data.error) {
                                let html = '', wrapper = document.createElement('div');
                                html += r.data.error;
                                wrapper.innerHTML = html;
                                swal(wrapper);
                                this.props.refreshView();

                            } else if (r.data.result && r.data.result.message) {
                                swal(r.data.result.message);
                                this.props.refreshView();

                            } else {
                                swal('Error! An error occurred. Please try again later!');
                            }
                        }
                    ).catch((e) => {
                        console.error(e);
                    });
                    return true;
                case "cancel":
                    return false;
                default:
                    return false;
            }
        });
    }

    /**
     *
     * @returns {*}
     */
    render() {
        const styles = {
            button: {
                marginLeft: 5,
                marginRight: 5,
                color: '#fff',
                backgroundColor: 'rgb(220, 0, 78)'
            },
        };

        const order = this.props.record;
        let invoice = order ? this.props.record.invoice : null;

        const labelText = 'CANCEL ORDER';
        return ((order &&
                ((isCancelAbleStores(order.channel_id) && invoice == null) || isReshipChannel(order.channel_id)) &&
                order.internal_status !== 'refunded' &&
                order.internal_status !== 'canceled' &&
                order.internal_status !== 'shipped' &&
                order.internal_status !== 'complete') ?
                <span>
                <Snackbar
                    open={this.state.open}
                    message={this.state.msg}
                    autoHideDuration={this.state.autoHideDuration}
                    onClose={this.handleRequestClose}
                />

                <Button className="px-5" size={"small"}
                        variant="contained"
                        color="secondary"
                        style={styles.button}
                        onClick={this.handleClickCancel} icon={<CancelIcon/>}
                        label={labelText}>{labelText}</Button>


            </span> : ''
        );
    }
}

/**
 *
 * @type {{orderId: Requireable<any>, record: Requireable<any>, raised: Requireable<boolean>, label: Requireable<string>}}
 */
CancelOrderBtn.propTypes = {
    label: PropTypes.string,
    raised: PropTypes.bool,
    orderId: PropTypes.any,
    record: PropTypes.any,
};

/**
 *
 */
export default connect(undefined, {refreshView: refreshViewAction})(
    CancelOrderBtn
);

