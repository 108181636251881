import React, { Component } from 'react';
import {
    Card,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow, Typography,
} from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import laravelJsonServerClient  from '../../restClient'
import {GET_LIST, Title, TopToolbar} from 'react-admin';
import {getChannelName} from '../channels';
import Open from "./orders/open";
import CreateShipment from "../orders/actions/createShipmentBtn";

class Orders extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: []
        }
    }
    componentDidMount() {
        this.fetchData();
    }
    fetchData() {
        laravelJsonServerClient(GET_LIST, 'customerOrder', {
            filter: {email: this.props.record.email, reship: this.props.reship},
        }).then(r => {
            this.setState({
                data: r.data,
            });
        });
    }

    render() {
        const orders = this.state.data;
        const reship = this.props.reship;
        const cardActionStyle = {
            zIndex: 2,
            display: 'inline-block',
            width: '100%',
        };
        if (!orders || orders.length === 0) {
            return (
                <div>
                </div>
            )
        }

        return (
            <Paper style={{ margin: '20px 0 0 0', padding: '10px' }} zdepth={2}>
                <Card style={{boxShadow: 'none'}}>
                    <TopToolbar style={cardActionStyle}>
                        <Typography style={{float:"left"}} gutterBottom variant="h5" component="h5">
                            {reship ? 'Customer Reships' : 'Customer Orders'}
                        </Typography>
                    </TopToolbar>
                </Card>
                <Table selectable="false" >
                    <TableHead>
                        <TableRow>
                            <TableCell>Channel</TableCell>
                            <TableCell>Created At</TableCell>
                            <TableCell>Order #</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Total</TableCell>
                            <TableCell>Items</TableCell>
                            <TableCell>&nbsp;</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {orders.map(order =>
                            <TableRow key={order.id}>
                                <TableCell className="pl-24">{getChannelName(order.channel_id)}</TableCell>
                                <TableCell>{order.created_at}</TableCell>
                                <TableCell>{order.channel_order_no}</TableCell>
                                <TableCell>{order.internal_status}</TableCell>
                                <TableCell>{order.grand_total}</TableCell>
                                <TableCell>{order.skus}</TableCell>
                                <TableCell><Open record={order} /></TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </Paper>
        );
    }
};

export default Orders;
