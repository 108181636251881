import React from 'react';
import {Create} from 'react-admin';
import ReshipForm from "./ReshipForm";
import {defaultReSource, authorized} from '../../authorized'

export const ReShipCreate = (props) => {
    if (authorized.permissions.indexOf('order.reship.create') === -1) {
        return (<span/>)
    }

    return (
        <Create title={<OrderTitle/>} {...props}>
            <ReshipForm {...props}/>
        </Create>
    );
}


const OrderTitle = () => {
    return <span>{''}</span>;
};