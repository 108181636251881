import React, {Component, useCallback} from 'react';
import laravelJsonServerClient from '../../restClient'
import {
    GET_LIST,
    FormTab,
    TextInput,
    NumberInput,
    BooleanInput,
    required,
    email,
    Toolbar,
    SelectInput, CREATE, UPDATE, GET_ONE,
    TabbedForm, number, minValue, maxValue
} from 'react-admin';
import {
    Table,
    TableBody,
    TableRow,
    TableCell,
    TableHead
} from '@material-ui/core';
import {connect} from 'react-redux';
import {countries} from "../countries";
import {useForm} from 'react-final-form';
import OMSSaveButton from "../form/OMSSaveButton";
import {showNotification as showNotificationAction, hideNotification} from 'react-admin';
import PropTypes from 'prop-types';
import {ReasonforRefund, warehouseFilter} from "../../config";
import swal from "sweetalert";
import {getUrlParameter} from "../../rest/common";
import FormHelperText from "@material-ui/core/FormHelperText";
import {isAbleAutoRefundStores} from "../channels";
import {country2To3ISOMapping, EUList} from "../countries"
import {defaultReSource, authorized} from '../../authorized'

/**
 *
 */
class ReturnRequestForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            addresses: [],
            items: [],
            styleToolbar: 'hideToolBar',
            shippers: [],
            orderId: '',
            data: [],
            loading: false,
            isAutoRefund: false,
            numberInputDefaultValue: 0,
            country: '',
            requestId: ''
        };

        this.validate = this.validate.bind(this);
        this.isAutomaticRefund = this.isAutomaticRefund.bind(this);
    }

    /**
     *
     */
    componentDidMount() {
        let orderId = getUrlParameter('orderId', window.location.hash);
        if (orderId) {
            this.orderId = orderId;
            this.setState({orderId: orderId});
            this.fetchData();
            return;
        }

        if (!this.props.record.id) {
            alert('Order ID not found');
            return;
        }

        this.orderId = this.props.record.sales_order_id;
        this.setData();
    }

    /**
     *
     * @param nextProps
     * @param nextContext
     */
    componentWillReceiveProps(nextProps, nextContext) {
        if(this.props.record.items) {
            this.setState({items: this.props.record.items});
        }
    }

    /**
     *
     */
    fetchData() {
        if (this.orderId) {
            this.fetchAddress();
            this.fetchItems();
            this.fetchShipper();
        }
    }

    setData() {
        this.fetchShipper();
        this.setState({requestId: this.props.record.id});
        this.setState({country: this.props.record.country3Iso});
        this.setState({items: this.props.record.items});
        this.setState({orderId: this.orderId});
    }

    /**
     *
     */
    fetchAddress() {
        laravelJsonServerClient(GET_LIST, 'address', {
            filter: {order_id: this.orderId},
        }).then(r => {
            this.setState({
                addresses: r.data,
            });

            if (r.data && r.data.length > 0) {
                r.data.forEach((address) => {
                    if (address.address_type === 'shipping') {
                        let country = address["country"];
                        if (country === 'UK') {
                            country = 'GB';
                        }
                        this.setState({country: country2To3ISOMapping[country.toUpperCase()]});
                    }
                });
            }
        });
    }

    /**
     *
     */
    fetchItems() {
        laravelJsonServerClient(GET_ONE, 'returnsRequest/items', {
            id: this.orderId
        }).then(r => {
            this.setState({
                items: r.data,
            });
        });
    }

    fetchShipper() {
        laravelJsonServerClient(GET_ONE, 'returnsRequest/shipper').then(r => {
            let shippers = [];

            if (r.data.error !== true) {
                r.data.forEach((item) => {
                    const isEuList = EUList.includes(item.address.country);
                    shippers.push({
                        _id: JSON.stringify({
                            id: item.id,
                            slug: item.slug,
                        }),
                        country: item.address.country,
                        name: item.description,
                        eu: isEuList
                    });
                });
            }

            this.setState({
                shippers: shippers,
            });
        });
    }


    /**
     *
     * @param values
     * @param redirect
     */
    onSubmit = (values) => {
        this.setState({loading: true})
        this.props.record.id ? this.updateRequest(values) : this.createRequest(values);
    };

    /**
     *
     * @param values
     */
    updateRequest = (values) => {
        values = this.prepairData(values)
        laravelJsonServerClient(UPDATE, 'returnsRequest', {id: this.props.record.id, data: values}).then(
            r => {
                if (r.data.error === true) {
                    swal(r.data.message, {title: 'NOTIFY'});
                    this.setState({loading: false})
                } else {
                    this.props.showNotification('Request Created', 'warning');
                    this.props.history.push('/returnsRequest/' + r.data.id + '/show');
                    this.setState({loading: false})
                }
            }
        ).catch((e) => {
            console.error(e);
            this.setState({loading: false});
        });
    }

    /**
     *
     * @param values
     */
    createRequest = (values) => {
        values = this.prepairData(values)
        laravelJsonServerClient(CREATE, 'returnsRequest', {data: values}).then(
            r => {
                if (r.data.json.error === true) {
                    swal(r.data.json.message, {title: 'NOTIFY'});
                    this.setState({loading: false})
                } else {
                    this.props.showNotification('Request Created', 'warning');
                    this.props.history.push('/returnsRequest/' + r.data.id + '/show');
                    this.setState({loading: false})
                }
            }
        ).catch((e) => {
            console.error(e);
            this.setState({loading: false});
        });
    }

    isAutomaticRefund = (ev) => {
        this.setState({isAutoRefund: ev.target.checked});
    }

    changeNumberInputDefaultValue = (event) => {
        this.setState({
            numberInputDefaultValue: ""
        });
    }

    /**
     *
     * @param key
     * @returns {string}
     */
    customSource(key) {
        return 'itemsku__' + key;
    }

    /**
     *
     * @param values
     */
    prepairData(values) {
        values['shipper_account'] = JSON.parse(values.shipper_account);
        values['items'] = [];
        let skus = _.pickBy(values, function (inputValue, inputKey) {
            return _.startsWith(inputKey, "itemsku__") && inputValue > 0;
        });

        for (const [key, value] of Object.entries(skus)) {
            let item = {
                'sku': key.replace('itemsku__', ''),
                'qty':  value,
                'disposition': 'refund',
            }
            values['items'].push(item);
        }
        return values;
    }

    validate(value) {
        let skus = _.pickBy(value, function (inputValue, inputKey) {
            return _.startsWith(inputKey, "itemsku__") && inputValue > 0;
        });

        if (Object.keys(skus).length === 0) {
            return {
                message: '',
                value: value
            };
        }
        this.setState({data: value});
        return true;
    }

    /**
     *
     * @param max
     * @returns {*[]}
     */
    qtyValidate(max) {
        return [number(), minValue(0), maxValue(max)];
    }


    /**
     *
     * @returns {*}
     */
    render() {
        const addresses = this.state.addresses;
        const shippers = this.state.shippers;
        let items = this.state.items === undefined ? [] : this.state.items;
        let can_refund_auto = false;
        let asnRequiredValidator = [];

        let defaultValue = {};
        let allowedFields = [
            'sales_order_id', 'email', 'firstname', 'lastname', 'city', 'region', 'postcode', 'country', 'address1',
            'middlename', 'company', 'address2', 'address3', 'telephone', 'fax', 'is_commercial', 'is_pobox'
        ];

        if (addresses && addresses.length > 0) {
            addresses.forEach((address) => {
                if (address.address_type === 'shipping') {
                    allowedFields.forEach(field => {
                        if (address.hasOwnProperty(field)) {
                            defaultValue[field] = address[field];
                        }
                        if (field === 'country') {
                            const warehouse = warehouseFilter.filter((item) => (item.returnRequest === true && item.countryReturn.includes(address[field].toUpperCase())));

                            if (warehouse.length > 0) {
                                defaultValue['warehouse'] = warehouse[0]['_id'];
                            }

                            if (address[field] === 'UK' || address[field] === 'GB') {
                                asnRequiredValidator = [required()];
                            }

                        }
                    });
                }
            });
        }

        if (shippers.length > 0 && this.state.country !== '') {
            defaultValue['shipper_account'] = null;
            let shipper = shippers.filter((item) => (item.country.includes(this.state.country)));
            const isEuList = EUList.includes(this.state.country);

            if (shipper.length === 0 && isEuList === true) {
                shipper = shippers.filter((item) => (item.eu === true));
            }

            if (shipper.length > 0) {
                defaultValue['shipper_account'] = shipper[0]['_id'];
            }
        }

        for (let i = 0; i < items.length; i++) {
            let current = items[i];
            can_refund_auto = isAbleAutoRefundStores(current['channel_id']) === true;
            defaultValue['itemsku__' + current['sku']] = this.props.record.id !== undefined ? current['qty'] : 0;
        }


        const requiredValidator = [required()];
        const emailValidator = [required(), email()];
        const returnToolBar = this.state.styleToolbar;


        /**
         *
         * @param props
         * @returns {*}
         * @constructor
         */
        const CreateCreateToolbar = props => {

            const form = useForm();
            var formdata = form.getState().values;
            const handleClick = useCallback(() => {
                this.onSubmit(formdata);
            }, [formdata, form]);

            return (<Toolbar  {...props} >
                <OMSSaveButton label={this.props.record.id  ? 'Update Request' : 'Create Request'}
                               redirect={'show'}
                               onClick={handleClick}
                               isSaving={this.state.loading}
                               disabled={props.invalid}
                               disabledWhenSaving={true}
                               submitOnEnter={false}/></Toolbar>);
        }

        return (
            <TabbedForm styletoolbar={returnToolBar}
                        onSubmit={this.onSubmit}
                        invalid={false}
                        validate={this.validate}
                        toolbar={<CreateCreateToolbar/>}
                        label="Create Return Request"
                        defaultValue={defaultValue} {...this.props}>
                <FormTab label="Reason">
                    <TextInput style={{display: 'none'}} disabled source="sales_order_id"/>

                    <SelectInput name="reason" isRequired={true}
                                 source="reason"
                                 validate={requiredValidator}
                                 choices={ReasonforRefund}
                                 optionValue="name"/>
                    <NumberInput label={"Ticket ID"} source="warranty_ticket_id"
                                 validate={[number(), minValue(1), required()]} className={"custom_number_input"}/>
                    <SelectInput name="warehouse" isRequired={true}
                                 source="warehouse"
                                 validate={requiredValidator}
                                 choices={warehouseReturn}
                                 label="Inbound warehouse"
                                 optionValue="_id"/>
                    <SelectInput name="shipper_account" isRequired={true}
                                 source="shipper_account"
                                 validate={requiredValidator}
                                 defaultValue={defaultValue['shipper_account']}
                                 choices={shippers}
                                 optionText="name"
                                 optionValue="_id"/>
                    <TextInput label="ASN" source="asn" defaultValue="" validate={asnRequiredValidator}/>
                    <TextInput multiline source="comments" defaultValue=""/>
                    <div>
                        <BooleanInput label="Refund automatically" disabled={!can_refund_auto}
                                  onClick={this.isAutomaticRefund}
                                  source="can_refund_auto" defaultValue={false} />
                        {(this.state.isAutoRefund) ?
                            <NumberInput name="adjustment_fee" isRequired={true} label="Adjustment fee"
                                                                  source="adjustment_fee"
                                                                  validate={[number(), minValue(0), maxValue(1000), required()]}
                                                                  min="0" max={1000}
                                                                  onChange={evt => this.changeNumberInputDefaultValue(evt)}
                                                                  defaultValue={this.state.numberInputDefaultValue}
                                                                  className={"custom_number_input"}
                        /> : ("")}
                        <FormHelperText> When enabled, order will be automatically refunded once customer ships an item.
                            You can disable it ONLY before customer ships an item.</FormHelperText>

                    </div>
                </FormTab>
                <FormTab label="Ship From Address">
                    <TextInput source="email" validate={emailValidator}/>
                    <TextInput source="firstname" validate={requiredValidator}/>
                    <TextInput source="lastname" validate={requiredValidator}/>
                    <TextInput source="middlename"/>
                    <TextInput source="city" validate={requiredValidator}/>
                    <TextInput source="region" validate={requiredValidator}/>
                    <TextInput source="postcode" validate={requiredValidator}/>
                    <SelectInput name="country" isRequired={true}
                                 source="country"
                                 choices={countries}
                                 optionValue="code"/>
                    <TextInput label="Address" source="address1" validate={requiredValidator}/>
                    <TextInput label="Second Address" source="address2"/>
                    <TextInput label="Third Address" source="address3"/>
                    <TextInput source="company"/>
                    <TextInput source="telephone" validate={requiredValidator}/>
                    <TextInput source="fax"/>
                    <BooleanInput label="Commersial Use" source="is_commercial"/>
                    <BooleanInput label="Is PO Box Address" source="is_pobox"/>
                </FormTab>
                <FormTab label="Items to Return">
                    <Table selectable="false" style={{width: '100%'}}>
                        <TableHead>
                            <TableRow>
                                <TableCell>Item SKU </TableCell>
                                <TableCell>Qty Ordered</TableCell>
                                <TableCell>Qty Returned</TableCell>
                                <TableCell>Returning Items</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {items.map((item, key) =>
                                <TableRow key={key}>
                                    <TableCell>{item.sku}</TableCell>
                                    <TableCell>{item.qty_shipped !== undefined ? item.qty_shipped : item.qty}</TableCell>
                                    <TableCell>{item.qty_return !== undefined ? item.qty_return : 0}</TableCell>
                                    <TableCell>
                                        <NumberInput source={this.customSource(item.sku)}
                                                     initialValue={0}
                                                     min={0}
                                                     max={item.qty_shipped - item.qty_return}
                                                     disabled={item.qty_shipped - item.qty_return <= 0 || item.status === 'pending'}
                                                     step={1} label=""
                                                     validate={this.qtyValidate(item.qty_shipped - item.qty_return)}/>
                                        <br/><small><i>Returning Items are count the past returns by this
                                        order</i></small>
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>

                </FormTab>
            </TabbedForm>
        );
    }
}

export const warehouseReturn = warehouseFilter.filter((item) => item.returnRequest === true);

/**
 *
 * @type {{hideNotification: Requireable<(...args: any[]) => any>, showNotification: Requireable<(...args: any[]) => any>}}
 */
ReturnRequestForm.propTypes = {
    showNotification: PropTypes.func,
    hideNotification: PropTypes.func
};

/**
 *
 */
export default connect(null, {
    showNotification: showNotificationAction,
    hideNotification: hideNotification
})(ReturnRequestForm);

