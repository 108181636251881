import React, {Component} from 'react';
import Button from '@material-ui/core/Button';
import laravelJsonServerClient from '../../../restClient'
import {refreshView as refreshViewAction, UPDATE} from "react-admin";
import {Snackbar} from "@material-ui/core";
import PropTypes from "prop-types";
import LaunchIcon from "@material-ui/icons/Launch";
import swal from "sweetalert";
import {connect} from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import SaveIcon from "@material-ui/icons/Save";

class CancelBtn extends Component {
    constructor(props) {
        super(props);
        let location = window.location.hash.split('/');
        this.state = {
            autoHideDuration: 8000,
            open: false,
            returnId: location[2],
            loading: false
        };
    };

    handleClick = () => {
        this.setState({
            open: true,
            loading: true,
        });

        laravelJsonServerClient(UPDATE, 'returnsRequest/cancel', {id: this.state.returnId}).then(
            r => {
                this.setState({
                    loading: false,
                });
                if (r.data.error === true) {
                    swal(r.data.message, {title: 'NOTIFY'});
                } else {
                    this.props.refreshView();
                }
            }
        ).catch((e) => {
            this.props.refreshView();
            console.error(e);
        });

    };

    handleRequestClose = () => {
        this.setState({
            open: false,
        });
    };

    render() {
        const styles = {
            button: {
                marginLeft: 5,
                marginRight: 5,
                color: '#fff',
                backgroundColor: 'rgb(220, 0, 78)'
            },
        };

        let showButton = true;
        if (this.props.record) {
            showButton = (this.props.record.status !== 'canceled' && this.props.record.status !== 'shipped' && this.props.record.status !== 'returned');
        }


        return ((showButton === true) ? <span><Button
            onClick={this.handleClick}
            id="resend_email_button"
            color="secondary"
            primary="true"
            style={styles.button}
            size={"small"}
            className="display-block"
            disabled={this.state.loading}
            startIcon={this.state.loading === false  ? <LaunchIcon/> : <CircularProgress size={15} color={'inherit'}/>}
        >
            <Snackbar
            open={this.state.open}
            message="Requested Cancel Label."
            autoHideDuration={this.state.autoHideDuration}
            onClose={this.handleRequestClose}
        />Cancel Request</Button>
        </span>:<span/>);
    }
}

CancelBtn.propTypes = {
    record: PropTypes.object,
};

export default connect(undefined, {refreshView: refreshViewAction})(
    CancelBtn
);
