import {config} from "../config";
import * as React from "react";
import {
    List,
    Datagrid,
    TextField,
    Filter,
    TextInput,
    TopToolbar, Pagination
} from 'react-admin';
import EditRoleBtn from "./roles/editBtn";
import {defaultReSource, authorized} from '../authorized'
/**
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const RolesFilters = (props) => (
    <Filter {...props}>
        {/*<TextInput alwaysOn text="name" source="name"/>*/}
    </Filter>
);

/**
 *
 * @param resource
 * @param filters
 * @param displayedFilters
 * @param filterValues
 * @param basePath
 * @param showFilter
 * @returns {*}
 * @constructor
 */
const RolesAction = ({resource, filters, displayedFilters, filterValues, basePath, showFilter}) => (
    <TopToolbar style={cardActionStyle}>
        {filters && React.cloneElement(filters, { resource, showFilter, displayedFilters, filterValues, context: 'button' }) }
    </TopToolbar>
);

/**
 * @type {{display: string, float: string, zIndex: number}}
 */
const cardActionStyle = {
    zIndex: 2,
    display: 'inline-block',
    float: 'right',
};

/**
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const RolesPagination = props => <Pagination rowsPerPageOptions={[config.perPage]} {...props} />;

/**
 *
 * @param props
 * @returns {*}
 * @constructor
 */
export const RolesList = (props) => (
    <span>
        <List {...props} actions={<RolesAction/>}
              perPage={config.perPage}
              filters={<RolesFilters/>}
              bulkActionButtons={false}
              pagination={<RolesPagination />}
              sort={{field: 'id', order: 'DESC'}}>
            <Datagrid>
                <TextField label="ID" source="id"/>
                <TextField label="Name" source="name"/>
                <EditRoleBtn />
            </Datagrid>
        </List>
    </span>

);
