import React, {Component} from 'react';
import laravelJsonServerClient from '../../../restClient'
import {refreshView as refreshViewAction, UPDATE} from "react-admin";
import PropTypes from "prop-types";
import swal from "sweetalert";
import {connect} from "react-redux";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import FormHelperText from "@material-ui/core/FormHelperText";
import {isAbleAutoRefundStores} from "../../channels";
import {authorized} from "../../../authorized";

/**
 *
 */
class AutoRefundSwitch extends Component {
    constructor(props) {
        super(props);
    };

    handleClick = () => {
        laravelJsonServerClient(UPDATE, 'returnsRequest/refundauto', {id: this.props.record.id}).then(
            r => {
                if (r.data.error === true) {
                    swal(r.data.message, {title: 'NOTIFY'});
                } else {
                    this.props.refreshView();
                }
            }
        ).catch((e) => {
            this.props.refreshView();
            console.error(e);
        });

    };

    render() {
        return <div><FormControlLabel
            control={<Switch
                checked={this.props.record.can_refund_auto === 1 ? true : false}
                label="Refund automatically"
                inputProps={{'aria-label': 'secondary checkbox'}}
                disabled={this.props.record.status !== 'processing' || !isAbleAutoRefundStores(this.props.record.channel_id) === true || authorized.permissions.indexOf('customer.returns_request.edit') === -1}
                onChange={this.handleClick}
            />}
            label="Refund automatically"
        />
            <FormHelperText> When enabled, order will be automatically refunded once customer ships an item. You can
                disable
                it ONLY before customer ships an item.</FormHelperText>

        </div>
    }
}

AutoRefundSwitch.propTypes = {
    record: PropTypes.object,
};

export default connect(undefined, {refreshView: refreshViewAction})(
    AutoRefundSwitch
);
