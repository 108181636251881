import * as React from 'react';
import {
    List,
    Datagrid,
    TextField,
    Filter,
    Show,
    SimpleShowLayout,
    ShowButton, Pagination, TopToolbar, RefreshButton,
} from 'react-admin';
import {TextInput} from 'react-admin';
import Addresses from './customers/addresses';
import Orders from './customers/orders';
import {countries} from "./countries";
import {AutocompleteInput} from 'react-admin';
import {config} from "../config";
import {cloneElement} from "react";
import {defaultReSource, authorized} from '../authorized'

/**
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const Filters = (props) => (
    <Filter {...props}>
        <TextInput text="Customer Name" source="customer_name"/>
        <TextInput text="Customer Address" source="customer_address"/>
        <TextInput alwaysOn text="Customer email" source="email"/>
        <AutocompleteInput label="Countries" source="country_id" choices={countries} optionValue="code"
                           optionsText="name"/>
    </Filter>
);
/**
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const OMSPagination = props => <Pagination rowsPerPageOptions={[config.perPage]} {...props} />;

/**
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const ListActions = (props) => {
    return (
        <TopToolbar>
            {cloneElement(props.filters, {context: 'button'})}
        </TopToolbar>
    );
};
/**
 *
 * @param props
 * @returns {*}
 * @constructor
 */
export const CustomerList = (props) => {

    if (authorized.permissions.indexOf('customer.list') === -1) {
        return (<span/>)
    }

    return (
        <List {...props} filters={<Filters/>} bulkActionButtons={false}
              perPage={config.perPage}
              actions={<ListActions/>}
              pagination={<OMSPagination/>}
              sort={{field: 'email', order: 'DESC'}}>
            <Datagrid>
                <CustomerEmail label="Email" source="email"/>
                <TextField label="FirstName" source="firstname"/>
                <TextField label="LastName" source="lastname"/>
                <TextField label="Country" source="country"/>
                <TextField label="Postcode" source="postcode"/>
                <TextField label="City" source="city"/>
                {authorized.permissions.indexOf('customer.get') !== -1 ? <ShowButton/> : <span/>}
            </Datagrid>
        </List>
    );
};

/**
 *
 * @param props
 * @returns {*}
 * @constructor
 */
export const CustomerShow = (props) => {
    if (authorized.permissions.indexOf('customer.get') === -1) {
        return (<span/>)
    }

    return (
        <main className="customerDetail">
            <Show title={<CustomerTitle/>} {...props}>
                <SimpleShowLayout>
                    <TextField label="FirstName" source="firstname"/>
                    <TextField label="LastName" source="lastname"/>
                    <Addresses/>
                    <Orders/>
                    <Orders reship={true}/>
                </SimpleShowLayout>
            </Show>
        </main>
    )
};

/**
 *
 * @param record
 * @returns {*}
 * @constructor
 */
const CustomerTitle = ({record = {email: ""}}) => {
    return <span>{record ? `${record.email}` : ''}</span>;
};

/**
 *
 * @param record
 * @returns {*}
 * @constructor
 */
const CustomerEmail = ({record = {email: ""}}) => (
    <span>{record.email}</span>
);
