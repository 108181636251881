import React, {Component} from 'react';

import PropTypes from "prop-types";
import {Link} from 'react-router-dom'
import {isReshipChannel} from "../channels";
import {defaultReSource, authorized} from '../../authorized'


/**
 *
 */
class OriginalOrder extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: []
        }
    }

    render() {
        const {record} = this.props;
        const isReship = isReshipChannel(record.channel_id);

        return (isReship && record.original_order !== undefined) ?
            <div className="card">
                <label>Original order</label>
                <span>
                <Link to={{
                    pathname: '/order/' + record.original_order.id + '/show',
                    search: "",
                    state: {orderId: this.props.id}
                }}> {record.original_order.channel_order_no} </Link>

            </span></div> : <div className="card"></div>;
    }
}

OriginalOrder.propTypes = {
    record: PropTypes.object,
};

export default OriginalOrder;