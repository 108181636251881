import React, {cloneElement} from 'react';
import {
    Datagrid,
    DateInput,
    Filter,
    List, Pagination, RefreshButton, SelectInput,
    TextField,
    TextInput, TopToolbar
} from "react-admin";
import {dateParser} from "../filters/dateParser";
import {Card} from '@material-ui/core';
import SettlementInformation from "./SettlementInformation"
import moment from "moment";
import {formatCurrency} from "../../rest/common";
import {getChannelName} from "../channels";
import {config} from "../../config";
import {authorized} from '../../authorized'

/**
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const SettlementFilters = (props) => (
    <Filter {...props} >
        <DateInput text="To date" source="deposit_date_to" label="Deposit to" parse={dateParser}/>
        <DateInput alwaysOn text="From date" label="Deposit from" source="deposit_date_from"
                   filterdefaultvalues={defautDate} parse={dateParser}/>
        <SelectInput alwaysOn label="Provider" source="payment_service" choices={[
            {id: 'Stripe', name: 'Stripe'},
            {id: 'Affirm', name: 'Affirm'},
            {id: 'Paypal', name: 'Paypal'},
            {id: 'Amazon', name: 'Amazon'},
        ]}/>
        <TextInput alwaysOn label="Payout" source="payout"/>
        <SelectInput alwaysOn label="Currency" source="currency" choices={[
            {id: 'USD', name: 'USD'},
            {id: 'CAD', name: 'CAD'},
            {id: 'GBP', name: 'GBP'},
            {id: 'EUR', name: 'EUR'},
            {id: 'MXN', name: 'MXN'},
        ]}/>
    </Filter>
);

/**
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const SettlementItemFilters = (props) => (
    <Filter {...props} >
        <SelectInput alwaysOn label="Currency" source="currency" choices={[
            {id: 'USD', name: 'USD'},
            {id: 'CAD', name: 'CAD'},
            {id: 'GBP', name: 'GBP'},
            {id: 'EUR', name: 'EUR'},
            {id: 'MXN', name: 'MXN'},
        ]}/>
        <TextInput alwaysOn label="Transaction Type" source="amount_type"/>
        <TextInput alwaysOn label="Type" source="event_type"/>
    </Filter>
);

/**
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const OMSPagination = props => <Pagination rowsPerPageOptions={[config.perPage]} {...props} />;


/**
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const ListActions = (props) => {
    return (
        <TopToolbar>
            {cloneElement(props.filters, {context: 'button'})}
            <RefreshButton/>
        </TopToolbar>
    );
};

/**
 *
 * @param props
 * @returns {*}
 * @constructor
 */
export const SettlementReport = (props) => {
    if (authorized.permissions.indexOf('finance.settlement_reports.list') === -1) {
        return (<span/>)
    }

    return (
        <span>
        <List {...props} filterdefaultvalues={{deposit_date_from: defautDate}}
              filters={<SettlementFilters/>}
              bulkActionButtons={false}
              title="Settlement List"
              actions={<ListActions/>}
              pagination={<OMSPagination/>}
              perPage={config.perPage}
              sort={{field: 'id', order: 'DESC'}}>
            <Datagrid>
                <RecordId label="id" source="id"/>
                <SettlementPayout label="Payout" source="payout"/>
                <TextField label="deposit date" source="deposit_date"/>
                <SettlementTotal label="Total" source="total_amount"/>
                <TextField label="provider" source="payment_service"/>
            </Datagrid>
        </List>
    </span>
    );
};

/**
 *
 * @param props
 * @returns {*}
 * @constructor
 */
export const SettlementReportShow = (props) => {
    if (authorized.permissions.indexOf('finance.settlement_reports.get') === -1) {
        return (<span/>)
    }

    return (
        <main className="settlement">
            <Card>
                <SettlementInformation {...props}/>
            </Card>
            <div style={{'padding': '15px 0 0 0'}}>
                <List {...props}
                      resource={'settlementItemsReport'}
                      filters={<SettlementItemFilters/>}
                      bulkActionButtons={false}
                      actions={<ListActions/>}
                      pagination={<OMSPagination/>}
                      filter={{sales_settlement_id: props.match.params.id}}
                      title="Settlement Items" perPage={config.perPage}
                      sort={{field: 'id', order: 'DESC'}}>
                    <Datagrid>
                        <TextField label="Posted date" className="pl-24" source="posted_date"/>
                        <TextField label="Transaction Type" source="amount_type"/>
                        <SettlementItemOrder label="Order No" source="channel_order_no"/>
                        <SettlementItemAmount label="Amount" source="amount"/>
                        <SettlementItemFees label="Fees" source="fees"/>
                        <SettlementItemEventType label="Type" source="event_type"/>
                        <SettlementItemMarketPlace label="Marketplace" source="channel_id"/>
                        <TextField label="Date" source="created_at"/>
                    </Datagrid>
                </List>
            </div>
        </main>
    );
}

/**
 *
 * @type {undefined}
 */
const defautDate = dateParser(moment().subtract(12, 'months').format(''), 'created_at_from')

/**
 *
 * @param record
 * @returns {*}
 * @constructor
 */
const RecordId = ({record = {id: ""}}) => (
    <span>{record.id}</span>
);

/**
 *
 * @param record
 * @returns {*}
 * @constructor
 */
const SettlementTotal = ({record = {total_amount: "", currency: ""}}) => (
    <span className="uppercase">{(record) ? record.total_amount + " " + record.currency : ""}</span>
);

/**
 *
 * @param record
 * @returns {*}
 * @constructor
 */
const SettlementItemOrder = ({record = {sales_order_id: "", channel_order_no: ""}}) => (

    <span>
        {record.sales_order_id ?
            <a rel="noopener noreferrer" target="_blank" href={'#order/' + record.sales_order_id + '/show'}
               className="a-customize"
               title="Click here to display details">
                {record.channel_order_no}</a> : record.channel_order_no}</span>
);

/**
 *
 * @param record
 * @returns {*}
 * @constructor
 */
const SettlementPayout = ({record = {id: "", payout: ""}}) => (
    <span>
        {record.id ?
            <a rel="noopener noreferrer" target="_blank" href={'#settlementReport/' + record.id + '/show'}
               className="a-customize"
               title="Click here to display details">
                {record.payout}</a> : ''}</span>
);

/**
 *
 * @param record
 * @returns {*}
 * @constructor
 */
const SettlementItemAmount = ({record = {currency: "", amount: ""}}) => (
    <span>{formatCurrency(record.amount, record.currency)}</span>
);

/**
 *
 * @param record
 * @returns {*}
 * @constructor
 */
const SettlementItemFees = ({record = {fees: "", currency: ""}}) => (
    <span>{formatCurrency(record.fees, record.currency)}</span>
);

/**
 *
 * @param record
 * @returns {*}
 * @constructor
 */
const SettlementItemEventType = ({record = {event_type: "", amount_description: ""}}) => (
    <span>{record.amount_description} {record.event_type}</span>
);

/**
 *
 * @param record
 * @returns {*}
 * @constructor
 */
const SettlementItemMarketPlace = ({record = {order: {channel_id: ""}}}) => (
    <span>{record.order ? getChannelName(record.order.channel_id) : ''}</span>
);

