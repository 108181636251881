import * as React from 'react';
import {useState} from 'react';
import {
    MenuItemLink,
    getResources,
    useTranslate,
    DashboardMenuItem
} from 'react-admin';
import LabelIcon from '@material-ui/icons/Label';
import {makeStyles} from '@material-ui/core/styles';
import classnames from 'classnames';
import {useMediaQuery} from '@material-ui/core';
import PropTypes from 'prop-types';
import {useSelector, shallowEqual} from 'react-redux';
import DefaultIcon from '@material-ui/icons/ViewList';
import CustomMenuItem from './CustomMenuItem';
import * as _ from 'lodash'

const useStyles = makeStyles(theme => ({
    open: {
        width: 240,
    },
    closed: {
        width: 55,
    },
    main: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),

        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        [theme.breakpoints.only('xs')]: {
            marginTop: 0,
        },
        [theme.breakpoints.up('md')]: {
            marginTop: '1.5em',
        },
    },
}));

const Menu = (props) => {
    const {
        className,
        dense,
        hasDashboard,
        onMenuClick,
        logout,
        ...rest
    } = props;


    const classes = useStyles(props);
    const translate = useTranslate();
    const open = useSelector((state) => state.admin.ui.sidebarOpen);
    const pathname = useSelector((state) => state.router.location.pathname);
    let resources = useSelector(getResources, shallowEqual);
    resources = resources.filter((item) => (item.options.authorized === true));
    resources = _.orderBy(resources, item => item.options.order, ['asc']);

    const hasList = (resource) => (resource.hasList);

    const handleToggle = (parent) => {
        setState(state => ({[parent]: !state[parent]}));
    };

    const isHideMenu = (resources, pathname) => {
        let isHide = false;
        resources.forEach(resource => {
            if (pathname.startsWith(`/${resource.name}`) && resource.options.hasOwnProperty('isHideMenu')) {
                isHide = true;
            }
        });
        return isHide;
    };

    const isXSmall = useMediaQuery((theme) =>
        theme.breakpoints.down('xs')
    );

    const isParent = (resource) => (
        resource.options &&
        resource.options.hasOwnProperty('isMenuParent') &&
        resource.options.isMenuParent
    );

    const isOrphan = (resource) => (
        /**
         * Check if the given resource is an orphan
         * i.e. has no parents defined. Needed as
         * these resources are supposed to be rendered
         * as is
         *
         */
        resource.options &&
        !resource.options.hasOwnProperty('menuParent') &&
        !resource.options.hasOwnProperty('isMenuParent') &&
        !resource.options.hasOwnProperty('display')
    );

    const isChildOfParent = (resource, parentResource) => (
        resource.options &&
        resource.options.hasOwnProperty('menuParent') &&
        resource.options.menuParent === parentResource.name
    );

    const geResourceName = (slug) => {
        if (!slug) return;
        var words = slug.toString().split('_');
        for (var i = 0; i < words.length; i++) {
            var word = words[i];
            words[i] = word.charAt(0).toUpperCase() + word.slice(1);
        }
        return words.join(' ');
    }

    const getPrimaryTextForResource = (resource) => {
        let resourcename = '';
        if (resource.options && resource.options.label)
            resourcename = resource.options.label;
        else if (resource.name) {
            resourcename = translate(`resources.${resource.name}.name`);
            if (resourcename.startsWith('resources.'))
                resourcename = geResourceName(resource.name);
        }
        return resourcename;
    }

    const MenuItem = (resource) => (
        <MenuItemLink
            key={resource.name}
            to={`/${resource.name}`}
            primaryText={getPrimaryTextForResource(resource)}
            leftIcon={
                resource.icon
                    ? <resource.icon/>
                    : <DefaultIcon/>
            }
            onClick={onMenuClick}
            dense={dense}
            sidebarIsOpen={open}
        />
    );

    /**
     * Mapping a "parent" entry and then all its children to the "tree" layout
     */
    const mapParentStack = (parentResource) => {
        if (parentResource.options.useLink === true)
            return <MenuItemLink
                key={parentResource.name}
                to={`/${parentResource.name}`}
                primaryText={getPrimaryTextForResource(parentResource)}
                leftIcon={
                    parentResource.icon
                        ? <resource.icon/>
                        : <DefaultIcon/>
                }
                onClick={onMenuClick}
                dense={dense}
                sidebarIsOpen={open}
            />
        else
            return (
                <CustomMenuItem
                    key={parentResource.name}
                    handleToggle={() => handleToggle(parentResource.name)}
                    isOpen={state[parentResource.name] || parentActiveResName === parentResource.name}
                    sidebarIsOpen={open}
                    name={getPrimaryTextForResource(parentResource)}
                    icon={parentResource.icon ? <parentResource.icon/> : <LabelIcon/>}
                    dense={dense}
                >
                    {
                        // eslint-disable-next-line
                        resources
                            .filter((resource) => isChildOfParent(resource, parentResource) && hasList(resource))
                            .map((childResource) => {
                                return MenuItem(childResource);
                            })
                    }
                </CustomMenuItem>
            );
    }

    /**
     * Mapping independent (without a parent) entries
     */
    const mapIndependent = (independentResource) => {
        return hasList(independentResource) && MenuItem(independentResource);
    }


    /**
     * Initialising the initialExpansionState and
     * active parent resource name at the time of
     * initial menu rendering.
     */
    const initialExpansionState = {};
    let parentActiveResName = null;

    resources.forEach(resource => {
        if (isParent(resource)) {
            initialExpansionState[resource.name] = false;
        } else if (pathname.startsWith(`/${resource.name}`) && resource.options.hasOwnProperty('menuParent')) {
            parentActiveResName = resource.options.menuParent;
        }
    });

    const [state, setState] = useState(initialExpansionState);
    const resRenderGroup = [];

    if(isHideMenu(resources, pathname) === true ){
        return (<div> </div>);
    }

    resources.forEach(r => {
        if (isParent(r)) resRenderGroup.push(mapParentStack(r));
        if (isOrphan(r)) resRenderGroup.push(mapIndependent(r));
    });


    return (
        <div
            className={classnames(classes.main, {
                [classes.open]: open,
                [classes.closed]: !open,
            })}
            {...rest}
        >
            {hasDashboard && (
                <DashboardMenuItem
                    onClick={onMenuClick}
                    dense={dense}
                    sidebarIsOpen={open}
                />
            )}
            {resRenderGroup}
        </div>
    );
}

Menu.propTypes = {
    classes: PropTypes.object,
    className: PropTypes.string,
    dense: PropTypes.bool,
    hasDashboard: PropTypes.bool,
    logout: PropTypes.element,
    onMenuClick: PropTypes.func,
};

Menu.defaultProps = {
    onMenuClick: () => null
};

export default Menu;