import React, { Component } from 'react';
import {
    Table,
    TableBody,
    TableHead,
    TableCell,
    TableRow,
    Paper, Typography, Card
} from '@material-ui/core';
import laravelJsonServerClient  from '../../restClient'
import {GET_LIST, Title, TopToolbar} from 'react-admin';
import CreateShipment from "../orders/actions/createShipmentBtn";

class Addresses extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: []
        }
    }
    componentDidMount() {
        this.fetchData();
    }
    fetchData() {
        laravelJsonServerClient(GET_LIST, 'customerAddress', {
            filter: {email: this.props.record.email},
        }).then(r => {
            this.setState({
                data: r.data,
            });
        });
    }

    render() {
        const addresses = this.state.data;
        const cardActionStyle = {
            zIndex: 2,
            display: 'inline-block',
            width: '100%',
        };
        if (!addresses || addresses.length === 0) {
            return (
                <div>
                </div>
            )
        }

        return (
            <Paper style={{ margin: '20px 0 0 0', padding: '10px' }} zdepth={2}>
                <Card style={{boxShadow: 'none'}}>
                    <TopToolbar style={cardActionStyle}>
                        <Typography style={{float:"left"}} gutterBottom variant="h5" component="h5">
                            Customer Addresses
                        </Typography>
                    </TopToolbar>
                </Card>
                <Table selectable="false" >
                    <TableHead>
                        <TableRow>
                            <TableCell>FirstName</TableCell>
                            <TableCell>LastName</TableCell>
                            <TableCell>Address Type</TableCell>
                            <TableCell>Country</TableCell>
                            <TableCell>Region</TableCell>
                            <TableCell>Postcode</TableCell>
                            <TableCell>City</TableCell>
                            <TableCell>Address</TableCell>
                            <TableCell>Phone</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {addresses.map(address =>
                            <TableRow key={address.id}>
                                <TableCell className="pl-24">{address.firstname}</TableCell>
                                <TableCell>{address.lastname}</TableCell>
                                <TableCell>{address.address_type}</TableCell>
                                <TableCell>{address.country}</TableCell>
                                <TableCell>{address.region}</TableCell>
                                <TableCell>{address.postcode}</TableCell>
                                <TableCell>{address.city}</TableCell>
                                <TableCell>{address.address1}&nbsp;{address.address2}&nbsp;{address.address3}</TableCell>
                                <TableCell>{address.telephone}</TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </Paper>
        );
    }
};

export default Addresses;
