import React, {Component} from 'react';
import {GET_LIST, GET_ONE} from "react-admin";
import moment from "moment";
import laravelJsonServerClient from '../../restClient';
import Button from "@material-ui/core/Button";
import ImportExportIcon from "@material-ui/icons/FileCopy";

/**
 * Export users to excel
 */
class UsersReportDownload extends Component {
    constructor(props) {
        super(props);

        this.state = {
            exporting: false
        };
    };

    handleSubmit = (values) => {
        this.setState({exporting: true});
        const uniqueId = 'users_' + Math.random().toString(36).substr(2, 9);

        laravelJsonServerClient(GET_LIST, 'user/export/download', {filter: {id: uniqueId}}).then(r => {
            this.setState({exporting: false});
            if (r.data.report) {
                const fileName = 'users-' + moment().utc().format('YYYY-MM-DD-h:mm-a') + '.xlsx';
                let downloadLink = document.createElement("a");
                downloadLink.href = r.data.report;
                downloadLink.download = fileName;
                downloadLink.click();
            } else {
                alert("An error occurred while creating a file to download!");
            }
        });
    };

    render() {
        return (
            <Button className="MuiButton-containedSecondary ml-5"
                    onClick={this.handleSubmit}
                    disabled={this.state.exporting}
                    startIcon={<ImportExportIcon/>}>Export to excel</Button>
        );
    }
}

export default UsersReportDownload;
