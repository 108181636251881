import React, {Component} from 'react';
import {SimpleForm, SelectInput, TextInput, UPDATE, GET_LIST} from 'react-admin';
import PropTypes from "prop-types";
import Button from '@material-ui/core/Button';
import laravelJsonServerClient from '../../restClient'
import {warehouseFilter} from "../../config";
import OMSSelectInput from "../form/OMSSelectInput";
import {Dialog, DialogActions, DialogContent, DialogContentText} from '@material-ui/core';
import FulfillmentIssueBtn from "./FulfillmentIssueBtn";
import RefreshIcon from '@material-ui/icons/Refresh';
import {defaultReSource, authorized} from '../../authorized'

/**
 *
 * @param handleClose
 * @param show
 * @param children
 * @returns {*}
 * @constructor
 */
const Modal = ({handleClose, show, children}) => {
    const showHideClassName = show ? "modal display-block" : "modal display-none";
    return (
        <div className={showHideClassName}>
            <section className="modal-main">
                <button onClick={handleClose} type="button" className="close btnClose"><span
                    aria-hidden="true">×</span></button>
                {children}
            </section>
        </div>
    );
};

/**
 *
 */
class ChangeWarehouseBtn extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            orderItem: this.props.orderItem,
            item: {},
            btnChangeText: 'CHANGE WAREHOUSE',
            warehouseLoaded: false,
            warehouseIncSku: {},
            addRedText: false,
            dialog: false,
            roles: '',
            permissions: ''
        };

        this.alertStock = 'textRed_stock';
        this.loadWarehouseBySku(this.props.orderItem);
    };

    loadWarehouseBySku(orderItem) {
        let _this = this;
        if (this.state.orderItem && this.state.orderItem.warehouse_id) {
            laravelJsonServerClient(GET_LIST, 'warehouse/by/order-item/' + this.state.orderItem.id, {}).then(
                r => {
                    let wareHouseData = [];
                    let inWarehouse = false;
                    let _i = 0;
                    r.data.forEach(function (v) {
                        wareHouseData[_i] = ({
                            _id: v.id,
                            name: v.name + ' (Qty: ' + v.available_qty + ')',
                            qty: v.available_qty,
                            className: v.available_qty === 0 ? _this.alertStock : ''
                        });
                        if (v.id === _this.state.orderItem.warehouse_id) {
                            inWarehouse = true;
                        }
                        _i++;
                    });

                    if (inWarehouse === false) {
                        warehouseFilter.forEach(v => {
                            if (v._id === _this.state.orderItem.warehouse_id) {
                                wareHouseData[_i] = ({
                                    _id: v._id,
                                    name: v.name + ' (Qty: 0)',
                                    qty: 0,
                                    className: _this.alertStock
                                });
                            }
                        });
                    }

                    this.setState({warehouseIncSku: wareHouseData, warehouseLoaded: true});
                }
            ).catch((e) => {
                console.error(e);
            });
        }
    }

    handleSubmit = (values) => {
        let _this = this;
        if (values.warehouse_id === undefined) {
            alert('Please select warehouse!');
            return;
        }

        let obj = {id: values.warehouse_id};
        laravelJsonServerClient(UPDATE, 'item/' + values.itemId + '/changewarehouse', obj).then(
            r => {
                if (r.data.result) {
                    this.afterSubmit();
                } else {
                    this.afterSubmit();
                    alert('Update error!');
                }
            }
        ).catch((e) => {
            console.error(e);
        });
    }

    afterSubmit = () => {
        this.setState({dialog: true});
        this.props.updateListItem();
        this.hideModal();
    };

    showModal = () => {
        this.setState({show: true, item: this.props.orderItem});
        this.loadWarehouseBySku(this.props.orderItem);
    };

    hideModal = () => {
        this.setState({show: false, item: {}});
    };

    handleClose = () => {
        this.setState({dialog: false});
    };

    render() {
        let _orderItems = this.props.orderItem;
        const styles = {
            button: {
                margin: 5,
            },
        };
        const actions = [
            <Button
                size={"small"}
                variant={"outlined"}
                color={"secondary"}
                onClick={this.handleClose}
            >Dismiss</Button>,
            <FulfillmentIssueBtn
                label="Export order"
                raised
                size={"small"}
                variant={"outlined"}
                color={"secondary"}
                force={true}
                orderId={_orderItems.sales_order_id}
            />,
        ];

        return ((_orderItems &&
                (_orderItems.status === 'export_error' || _orderItems.status === 'processing' || _orderItems.status === 'pending')
                && this.state.warehouseLoaded) ?
                <main>
                    <Modal show={this.state.show} handleClose={this.hideModal}>
                        <h2>Change Warehouse</h2>
                        <SimpleForm
                            submitOnEnter={false}
                            save={this.handleSubmit}
                            name="chanceWarehouseForm"
                        >
                            <TextInput style={{display:'none'}} disabled  name="itemId" label={''} source="itemId" defaultValue={this.state.item.id}/>
                            <SelectInput
                                name="warehouse_id"
                                isRequired={true}
                                source="warehouse_id"
                                defaultValue={this.state.item.warehouse_id}
                                choices={this.state.warehouseIncSku}
                                optionValue="_id"/>
                        </SimpleForm>
                    </Modal>
                    <Button startIcon={<RefreshIcon/>}
                            size={"small"}
                            variant={"outlined"}
                            color={"secondary"}
                            style={styles.button}
                            onClick={this.showModal.bind(this)}>
                        {this.state.btnChangeText}
                    </Button>
                    <Dialog
                        title="Warehouse changed succesfully"
                        actions={actions}
                        modal={true}
                        open={this.state.dialog}
                        onRequestClose={this.handleClose}
                    >
                        <DialogContent>
                            <DialogContentText>
                                Please click the button below to send the order to the warehouse, or dismiss the dialog to review the order.
                                Please don't forget to click <b>"Export to warehouse"</b> once you've reviewed the information in order to avoid fulfillment delays
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            {actions}
                        </DialogActions>
                    </Dialog>
                </main>
                : <span/>
        );
    }
}

ChangeWarehouseBtn.propTypes = {
    orderItem: PropTypes.object,
};

export default ChangeWarehouseBtn;
