import React, {Component} from 'react';
import {SimpleForm, TextInput, UPDATE, GET_LIST} from 'react-admin';
import PropTypes from "prop-types";
import laravelJsonServerClient from '../../restClient'
import {CardActions, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from '@material-ui/core';
import FulfillmentIssueBtn from "./FulfillmentIssueBtn";
import {FormLabel, Radio, Button} from "@material-ui/core";
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import moment from "moment";
import FetchShipmentWarehouse from "./FetchShipmentWarehouse";
import SaveIcon from "@material-ui/icons/Save";
import {authorized} from "../../authorized";

const LATE_SHIPMENT_DAY = 5;

/**
 *
 */
class ExportedOptions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            showFetshipment: false,
            orderItem: this.props.orderItem,
            item: {},
            btnChangeText: 'OPTIONS ',
            dialog: false,
            selectedOption: 1,
            totalItemShipped: 0
        };

        this.onValueChange = this.onValueChange.bind(this);
        this.formSubmit = this.formSubmit.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    };

    componentDidMount() {
        this.fetchData();
    }

    onValueChange(event) {
        this.setState({
            selectedOption: parseInt(event.target.value)
        });
    }

    fetchData() {
        if (this.props.orderItem) {

            laravelJsonServerClient(GET_LIST, 'shipment', {
                filter: {order_id: this.props.orderItem.sales_order_id},
            }).then(r => {

                let totalItemShipped = 0;
                if (r.data.length > 0) {
                    r.data.forEach(function (k, v) {
                        totalItemShipped += parseInt(k.qty_shipped);
                    });
                }

                this.setState({
                    totalItemShipped: totalItemShipped
                });
            });
        }
    }

    /**
     *
     * @param event
     */
    formSubmit(event) {
        event.preventDefault();
    }

    /**
     *
     * @param values
     */
    handleSubmit(values) {
        if (this.state.orderItem.id === undefined) {
            alert('Some thing error, please try again!');
            return;
        }

        let selectedOption = this.state.selectedOption;
        let obj = {}
        switch (selectedOption) {
            case 1: // Update order item status to export_error, order status to processing
                obj = {id: 'export_error'};
                laravelJsonServerClient(UPDATE, 'item/' + this.state.orderItem.id + '/change-status-to', obj).then(
                    r => {
                        if (!r.data.result) {
                            alert('Update error!');
                        }
                        this.handleSubmitWithRedirect();
                        window.location.reload();
                    }
                ).catch((e) => {
                    console.error(e);
                });
                break;
            case 4: // Update order item status to export_error, order status to processing
                obj = {id: 'canceled'};
                laravelJsonServerClient(UPDATE, 'item/' + this.state.orderItem.id + '/change-status-to', obj).then(
                    r => {
                        if (!r.data.result) {
                            alert('Update error!');
                        }
                        this.handleSubmitWithRedirect();
                        window.location.reload();
                    }
                ).catch((e) => {
                    console.error(e);
                });
                break;
            case 2: // TODO: Search in order warehouse
                this.hideModal();
                this.setState({showFetshipment: true});
                break;
            case 3: // Redirect to create shipment page
                window.location.href = '#/shipment/create?orderId=' + this.state.orderItem.sales_order_id;
                break;
            default:
                break;
        }
    }

    /**
     *
     */
    handleSubmitWithRedirect() {
        window.location.reload();
        this.hideModal();
    };

    showModal = () => {
        this.setState({show: true, item: this.props.orderItem});
        this.setState({showFetshipment: false});
    };

    hideModal = () => {
        this.setState({show: false});
    };

    handleClose = () => {
        this.setState({dialog: false});
        this.props.updateListItem();
    };

    closeShipment = () => {
        this.setState({showFetshipment: false});
        this.props.updateListItem();
    };

    render() {
        let actionsList = [
            {id: 4, name: 'Cancel item'},
            {id: 1, name: 'Export error'},
            {id: 2, name: 'Search Shipment in another warehouse'},
        ];

        if (authorized.permissions.indexOf('order.shipment.create') !== -1) {
            actionsList = [
                {id: 4, name: 'Cancel item'},
                {id: 1, name: 'Export error'},
                {id: 2, name: 'Search Shipment in another warehouse'},
                {id: 3, name: 'Do nothing will ship manually'},
            ];
        }
        const styles = {
            button: {
                margin: 5,
            },
        };

        let showOptsBtn = false;
        let _orderItems = this.props.orderItem;
        if (_orderItems) {
            let dateNow = moment();
            let export_date = moment(_orderItems.export_date, 'YYYY-MM-DD H:mm:ss');
            let diff = dateNow.diff(export_date, 'days');

            if (diff >= LATE_SHIPMENT_DAY) {
                showOptsBtn = true;
            }

            if (_orderItems.status === 'refunded') {
                if (authorized.permissions.indexOf('order.shipment.create') !== -1) {
                    actionsList = [
                        {id: 2, name: 'Search Shipment in another warehouse'},
                        {id: 3, name: 'Do nothing will ship manually'},
                    ];
                } else {
                    actionsList = [
                        {id: 2, name: 'Search Shipment in another warehouse'},
                    ];
                }
            }

            if (_orderItems.status === 'export_error') {
                if (authorized.permissions.indexOf('order.shipment.create') !== -1) {
                    actionsList = [
                        {id: 2, name: 'Search Shipment in another warehouse'},
                        {id: 3, name: 'Do nothing will ship manually'},
                        {id: 4, name: 'Cancel item'},
                    ];
                } else {
                    actionsList = [
                        {id: 2, name: 'Search Shipment in another warehouse'},
                        {id: 4, name: 'Cancel item'},
                    ];
                }
            }
        }

        const actions = [
            <Button
                label="Dismiss"
                primary="true"
                onClick={this.handleClose}
            />,
            <FulfillmentIssueBtn
                label="Export order"
                raised
                force={true}
                orderId={_orderItems.sales_order_id}
            />,
        ];

        const PostCreateToolbarAction = [
            <Button
                label="Submit"
                primary="true"
                icon={<SaveIcon/>}
                variant={"outlined"} color={"secondary"}
                size={"small"}
                startIcon={<SaveIcon/>}
                onClick={this.handleSubmit}
            >Submit</Button>,

            <Button
                label="Cancel"
                primary="true"
                disabled={false}
                key={'primary'}
                size={"small"}
                color={"primary"}
                variant="outlined"
                onClick={this.hideModal}
            >Cancel</Button>

        ];


        return ((_orderItems && showOptsBtn
                && (_orderItems.status === 'exported'
                    || _orderItems.status === 'export_error'
                    || (this.state.totalItemShipped === 0 && _orderItems.status === 'shipped')
                )) ?
                <main>
                    <FetchShipmentWarehouse closeShipment={this.closeShipment}
                                            orderItem={this.state.orderItem}
                                            open={this.state.showFetshipment}/>
                    <Dialog
                        bodyClassName={"modal-edit-address"}
                        title="Don't exists on Wareshouse"
                        actions={PostCreateToolbarAction}
                        maxWidth={'sm'}
                        modal={true}
                        fullWidth={'true'}
                        open={this.state.show}
                        autoScrollBodyContent={true}
                    >
                        <DialogTitle id="customized-dialog-title" onClose={this.handleClose}>
                            Don't exists on Wareshouse
                        </DialogTitle>
                        <DialogContent dividers>
                            <span className="modal-main-radio">
                                <SimpleForm
                                    toolbar={<CardActions/>}
                                    style={{width: "100%"}}
                                    save={this.handleSubmit}
                                    onSubmit={this.handleSubmit}
                                    handleSubmitWithRedirect={this.handleSubmitWithRedirect}
                                >
                                    <TextInput options={{style: {display: 'none'}}} name="itemId" source="itemId"
                                               defaultValue={this.state.item.id}/>
                                    {actionsList.map(item => (<div className="radio" key={item.id}>
                                        <FormLabel style={{width: "100%"}}>
                                            <Radio
                                                name="action"
                                                type="radio"
                                                row
                                                value={item.id}
                                                checked={this.state.selectedOption === item.id}
                                                onChange={this.onValueChange}
                                            />
                                            {item.name}
                                        </FormLabel>
                                    </div>))}
                                </SimpleForm>
                                 </span>
                        </DialogContent>
                        <DialogActions>
                            {PostCreateToolbarAction}
                        </DialogActions>
                    </Dialog>

                    <Button type="button" className="btn-custom"
                            startIcon={<LocalShippingIcon/>}
                            size={"small"}
                            variant={"outlined"}
                            color={"secondary"}
                            onClick={this.showModal.bind(this)}>
                        {this.state.btnChangeText}
                    </Button>
                    <Dialog
                        title="The status has changed successfully"
                        actions={actions}
                        modal={true}
                        open={this.state.dialog}
                        style={styles.button}
                        onRequestClose={this.handleClose}
                    >
                        <DialogContent>
                            <DialogContentText>
                                Please click the button below to send the order to the warehouse, or dismiss the dialog
                                to
                                review the order.
                                Please don't forget to click <b>"Export to warehouse"</b> once you've reviewed the
                                information in order
                                to avoid fulfillment delays
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            {actions}
                        </DialogActions>
                    </Dialog>
                </main>
                : <span/>
        );
    }
}

ExportedOptions.propTypes = {
    orderItem: PropTypes.object,
};

export default ExportedOptions;
