import React, {Component, Fragment, useCallback} from 'react';
import laravelJsonServerClient from "../../../restClient";
import {
    CREATE,
    GET_LIST,
    GET_ONE,
    FormTab,
    TextInput,
    NumberInput,
    BooleanInput,
    required,
    number,
    email,
    SelectInput, FileInput, FileField,
    TabbedForm, Toolbar, minValue
} from 'react-admin';
import {
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    CardHeader, Button, Typography, Card, Paper
} from '@material-ui/core';
import {useForm, Field} from 'react-final-form';
import {channels, warehouseFilter, commonCurrency} from "../../../config";
import OMSSaveButton from "../../form/OMSSaveButton";
import OMSNotification from "../../form/OMSNotification";
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import {countries} from "../../countries";
import swal from 'sweetalert';
import {Link} from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import {showNotification as showNotificationAction, hideNotification} from 'react-admin';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {isReshipChannel} from "../../channels";
import {AutocompleteInput} from 'react-admin';
import {defaultReSource, authorized} from '../../../authorized'
import {value} from "lodash/seq";
/**
 *
 */
class CreateOrderForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            styleToolbar: 'hideToolBar',
            addresses: {},
            items: [],
            defaultValue: {},
            validateItems: [],
            notification_open: false,
            order: null,
            timeout: 7000,
            saving: false,
            timeouts: [],
            orderNumber: '',
            removeItem: '',
            default_warehouseId: '',
            inventory: {},
            warehouseLoaded: false,
            warehouseHasProductInStock: [],
            disable: false,
            loading: false
        };

        this.alertStock = 'textRed_stock';
        this.validate = this.validate.bind(this);
        this.openNewOrder = this.openNewOrder.bind(this);
        this.removeItem = this.removeItem.bind(this);
        this.addItem = this.addItem.bind(this);
        this.loadInventory = this.loadInventory.bind(this);

    }

    /**
     *
     */
    loadWarehouseBySku() {
        let _this = this;
        if (this.state.items) {
            this.state.items.map((item, key) => {
                let wareHouseData = [];
                laravelJsonServerClient(GET_LIST, 'warehouse/by/order-item/' + item.id, {}).then(
                    r => {
                        let inWarehouse = false;
                        let _i = 0;
                        r.data.forEach(function (v, key) {
                            wareHouseData[_i] = ({
                                _id: v.id,
                                name: v.name + ' (Qty: ' + v.available_qty + ')',
                                qty: v.available_qty,
                                className: v.available_qty === 0 ? _this.alertStock : ''
                            });
                            if (v.id === item.warehouse_id) {
                                inWarehouse = true;
                            }
                            _i++;
                        });
                        if (inWarehouse === false) {
                            warehouseFilter.forEach(v => {
                                if (v._id === item.warehouse_id) {
                                    wareHouseData[_i] = ({
                                        _id: v._id,
                                        name: v.name + ' (Qty: 0)',
                                        qty: 0,
                                        className: _this.alertStock
                                    });
                                    item.addRedText = true;
                                }
                            });
                        }
                    }
                ).catch((e) => {
                    console.error(e);
                });
                item.warehouseIncSku = wareHouseData;
            });
            this.setState({warehouseLoaded: true})
        }
    }

    /**
     *
     */
    loadWarehouseHaveProductInStock() {
        laravelJsonServerClient(GET_LIST, 'warehouse/product/inStock', {}).then(
            r => {
                let makeWarehouseData = [];
                r.data.forEach(function (v, key) {
                    makeWarehouseData[key] = ({_id: v.id, name: v.name});
                });
                this.setState({warehouseHaveProductInStock: makeWarehouseData})
                this.addItem();
            }
        ).catch((e) => {
            console.error(e);
        });
    }

    handleChange(value, item, event, key) {
        const newitem = event.target.value
        if (key === 'warehouse_id') {
            let lagRedText = this.alertStock;
            item.warehouseIncSku.forEach(function (value) {
                if (value._id === newitem && (value.qty > 0 || value.qty === undefined)) {
                    lagRedText = '';
                }
            });
            item['className'] = lagRedText;
        }

        const items = this.state.items;
        for (let i = 0; i < items.length; i++) {
            let currentItem = items[i];
            if (currentItem['id'] === item['id']) {
                items[i][key] = newitem;
                if (key === 'sku') {
                    items[i]['sku_' + item['id']] = newitem;
                }

                if (key === 'qty_shipped') {
                    items[i]['qty_' + item['id']] = newitem;
                }

                if (key === 'warehouse_id') {
                    items[i]['warehouse_' + item['id']] = newitem;
                }

                if (key === 'warehouse_id') {
                    this.loadInventory(newitem)
                }
            }
        }

        this.setState({items: items});
    }

    removeItem(id) {
        let items = this.state.items;
        if (items.length > 1) {

            for (let i = 0; i < items.length; i++) {
                let current = items[i];
                if (current['id'] === id) {
                    items.splice(i, 1);
                }
            }
        }

        this.setState({items: items});
    }

    addItem() {
        <Notification type='error' message='test' autoHideDuration={5000}/>;

        let items = this.state.items;
        let validateItems = this.state.validateItems;

        const randitem = 'rand' + Math.floor(Math.random() * 99999) + 1;
        const initItem = {
            id: randitem,
            sku: '',
            warehouse_id: '',
            randitem: true,
            qty_shipped: 1,
            qty_ordered: 0,
            fixed_qty_shipped: 0,
            price: 0
        };

        initItem['qty_' + randitem] = 1;
        initItem['price_' + randitem] = 1;
        initItem['sku_' + randitem] = '';
        initItem['removed'] = false;
        initItem['warehouse_' + randitem] = '';
        initItem['inventory'] = '';
        initItem['warehouseIncSku'] = this.state.warehouseHaveProductInStock;

        items.push(initItem);
        validateItems.push('qty_' + randitem);
        validateItems.push('sku_' + randitem);

        this.setState({items: items, validateItems: validateItems});
    }

    /**
     *
     */
    componentDidMount() {
        this.initAddress();
        this.loadWarehouseBySku();
        this.loadWarehouseHaveProductInStock();
        this.prepairData()

    }

    /**
     *
     */
    prepairData() {
        const items = this.state.items;
        const validateItems = [];
        let defaultValue = {};
        let default_warehouseId = '';

        for (let i = 0; i < items.length; i++) {
            let current = items[i];
            this.loadInventory(current['warehouse_id']);
            default_warehouseId = current['warehouse_id'];
            items[i]['sku_' + current['id']] = items[i]['sku'];
            items[i]['qty_' + current['id']] = 1;
            items[i]['price_' + current['id']] = 0;

            items[i]['warehouse_' + current['id']] = items[i]['warehouse_id'];
            items[i]['inventory'] = '';
            items[i]['removed'] = false;

            defaultValue['qty_' + current['id']] = 1;
            defaultValue['price_' + current['id']] = 0;
            defaultValue['warehouse_' + current['id']] = items[i]['warehouse_id'];
            defaultValue['sku_' + current['id']] = items[i]['sku'];
            validateItems.push('qty_' + current['id']);
            validateItems.push('sku_' + current['id']);
        }

        defaultValue['the_same_billing_css'] = true;


        this.setState({
            default_warehouseId: default_warehouseId,
            items: items,
            defaultValue: defaultValue,
            initialValues: defaultValue,
            validateItems: validateItems
        });

    }

    /**
     *
     * @param warehouse
     */
    loadInventory = warehouse => {
        let inventory = this.state.inventory;
        laravelJsonServerClient(GET_LIST, 'inventory/warehouse/' + warehouse, {filter: {inclOOS: false}}).then(
            r => {
                let _data = [];
                r.data.forEach(function (v, k) {
                    _data[k] = {
                        id: v.id,
                        sku: v.sku,
                        qty: v.available_qty,
                        skuQty: ' (Qty: ' + v.available_qty + ')',
                    };
                });
                inventory[warehouse] = _data;
                this.setState({inventory: inventory});
            }
        ).catch((e) => {
            console.error(e);
        });
    };

    /**
     *
     */
    initAddress() {
        const initAddress =
            {
                address1: "",
                address2: "",
                address3: "",
                address_type: "shipping",
                city: "",
                company: "",
                country: "",
                email: "",
                fax: "",
                firstname: "",
                is_commercial: "",
                is_pobox: "",
                lastname: "",
                middlename: "",
                postcode: "",
                region: "",
                telephone: "",
                b_address1: "",
                b_address2: "",
                b_address3: "",
                b_address_type: "billing",
                b_city: "",
                b_company: "",
                b_country: "",
                b_email: "",
                b_fax: "",
                b_firstname: "",
                b_is_commercial: "",
                b_is_pobox: "",
                b_lastname: "",
                b_middlename: "",
                b_postcode: "",
                b_region: "",
                b_telephone: ""
            };


        this.setState({
            addresses: initAddress
        });
    }

    /**
     *
     * @param value
     * @param item
     * @param event
     */
    theSameBilling(values) {
        let defaultValue = this.state.defaultValue;
        let allowedFields = [];
        defaultValue['the_same_billing_css'] = !values.the_same_billing;

        let addresses = this.state.addresses;
        if (_.size(addresses) > 0) {
            Object.keys(addresses).forEach(field => {
                if (field.indexOf('b_') !== -1) {
                    allowedFields.push(field)
                    addresses[field] = values[field.replace('b_', '')]
                }
            });


            allowedFields.forEach(field => {
                if (addresses.hasOwnProperty(field)) {
                    try {
                        defaultValue[field] = addresses[field];
                    } catch (e) {
                        defaultValue[field] = {}
                    }
                }
            });

            this.setState({
                defaultValue: defaultValue,
                initialValues: defaultValue,
                addresses: addresses
            });
        }
    }

    isReship(values) {
        let allowEdit = false;
        if (isReshipChannel(values.channel_id)) {
            allowEdit = true
        }

        this.setState({
            disable: allowEdit
        });
    }

    /**
     *
     * @param value
     * @returns {boolean}
     */
    validate(value) {
        let grandTotal = Math.abs(value['discount_amount']) * -1 + value['tax_amount'] + value['shipping_amount'];

        this.theSameBilling(value);
        let defaultValue = this.state.defaultValue;

        defaultValue['the_same_billing_css'] = value.the_same_billing;
        this.setState({
            defaultValue: defaultValue,
            initialValues: defaultValue,
        });

        this.isReship(value);
        this.state.items.forEach(function (_item) {
            grandTotal += value['price_' + _item['id']] * value['qty_' + _item['id']]
        });

        if (grandTotal < 0) {
            return {
                message: 'grand total < 0',
                value: value
            }
        }

        return true;
    }

    /**
     *
     */
    componentWillUnmount() {
        this.state.timeouts.forEach(clearTimeout);
    }

    /**
     *
     * @param key
     * @param name
     * @returns {string}
     */
    customSource(key, name) {
        return name + '_' + key;
    }


    /**
     *
     * @param items
     * @param formData
     * @returns {boolean}
     */
    checkToShowWarning(items, formData) {
        let _inventory = this.state.inventory;
        let toggleShow = false;

        items.forEach(function (_item) {
            if (_item.removed === undefined || _item.removed === false) {
                if (_item.qty_ordered > 0) {
                    // Item original
                    let qtyReship = parseInt(formData['qty_' + _item.id]);
                    if (qtyReship > 0) {
                        _item.warehouseIncSku.forEach(function (wh) {
                            if (wh._id === _item.warehouse_id && qtyReship > wh.qty) {
                                toggleShow = true;
                                return false;
                            }
                        });
                    } else {
                        toggleShow = true;
                        return false;
                    }
                } else {
                    // Item added
                    if (_inventory[_item.warehouse_id] !== undefined) {
                        _inventory[_item.warehouse_id].forEach(function (wh) {
                            let qtyReship = parseInt(formData['qty_' + _item.id]);
                            if (wh.sku === formData['sku_' + _item.id] && qtyReship > 0 && qtyReship > wh.qty) {
                                toggleShow = true;
                                return false;
                            }
                        });
                    } else {
                        toggleShow = true;
                        return false;
                    }
                }
            }
        });

        return toggleShow;
    };

    async blobToBase64(blob) {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        return new Promise(resolve => {
            reader.onloadend = () => {
                resolve(reader.result);
            };
        });
    };

    /**
     *
     * @param formData
     */
    doSubmit = async formData => {
        this.setState({saving: true});

        let orderFiles = [];
        const _this = this;
        if (formData['order_files']) {
            for (const file of formData['order_files']) {
                await _this.blobToBase64(file['rawFile']).then(
                    res => {
                        orderFiles.push({data: res, title: file['title'], file: file, type: file['rawFile']['type']})
                    });
            }
        }
        formData['order_files'] = orderFiles;
        laravelJsonServerClient(CREATE, 'order/oms', {data: formData})
            .then(r => {
                if (r.data && r.data.json && !r.data.json.result) {
                    throw new Error(r.data.json.message);
                }

                this.setState({
                    notification_open: true,
                    order: r.data.id,
                });
                this.state.timeouts.push(setTimeout(() => {
                    this.props.history.push('/order')
                }, this.state.timeout));
                this.setState({saving: false});
            })
            .catch((e) => {
                this.setState({saving: false});
                this.props.showNotification(e.message, 'error');
            });
    };

    /**
     *
     * @param formData
     * @returns {boolean}
     */
    onSubmit = formData => {
        if (this.state.saving === true) {
            return false;
        }

        if (this.checkToShowWarning(this.state.items, formData) === false) {
            let validateFormData = {};
            Object.keys(formData).forEach(function (key) {
                if (key.indexOf("_rand") === -1) {
                    validateFormData[key] = formData[key];
                }
            });

            this.state.items.forEach(function (_item) {
                validateFormData['price_' + _item['id']] = formData['price_' + _item['id']];
                validateFormData['qty_' + _item['id']] = formData['qty_' + _item['id']];
                validateFormData['sku_' + _item['id']] = formData['sku_' + _item['id']];
                validateFormData['warehouse_' + _item['id']] = formData['warehouse_' + _item['id']];

            });

            this.doSubmit(validateFormData);
            return true;
        }

        let _this = this;
        let msg = "This order includes items that are out of stock in the selected warehouse. " +
            "Depending on the warehouse, the order will either not be fulfilled until there is stock or will be backordered.";
        swal(msg,
            {
                title: 'WARNING',
                buttons: {
                    cancel: "Cancel",
                    ok: {
                        text: "Ok",
                        value: "catch",
                    }
                },
            }).then(value => {
            switch (value) {
                case "catch":
                    _this.doSubmit(formData);
                    return true;
                case "cancel":
                    return false;
                default:
                    return false;
            }
        });
    };

    openNewOrder = () => {
        return (
            <Button color="secondary" size="small"
                    component={Link}
                    to={{
                        pathname: '/order/' + this.state.order + '/show', search: "",
                        state: {}
                    }}
            >Open order</Button>
        )
    };

    render() {
        const items = this.state.items;
        const requiredValidator = [required()];
        const qtyValidate = [required(), number(), minValue(1)];
        const emailValidator = [required(), email()];
        const inventoryList = this.state.inventory;
        const defaultValue = this.state.defaultValue;
        const toolbarTile = 'Create Order';

        if (defaultValue.length !== 0) {
            items.forEach((item) => {
                if (item['removed'] === false) {
                    if (item.hasOwnProperty('randitem')) {
                        let inventory = [{id: '', sku: '', skuQty: ''}];
                        if (inventoryList.hasOwnProperty(item.warehouse_id)) {
                            inventory = inventoryList[item.warehouse_id];
                        }
                        const optionRenderer = choice => `${choice.sku} ${choice.skuQty} `;

                        item['inventory'] = (<SelectInput
                            source={this.customSource(item.id, 'sku')}
                            label="Item"
                            validate={requiredValidator}
                            optionText={optionRenderer} optionValue="sku"
                            choices={inventory}
                            onChange={(event, index, value) => this.handleChange(value, item, event, 'sku')}
                        />);
                        item['qty'] = <NumberInput source={this.customSource(item.id, 'qty')}
                                                   initialValue={1}
                                                   step={1} label="" placeholder=""
                                                   validate={qtyValidate}/>
                        item['price'] = <NumberInput source={this.customSource(item.id, 'price')}
                                                     initialValue={0}
                                                     disabled={this.state.disable}
                                                     step={1} label="" placeholder=""
                                                     validate={[required(), number(), minValue(0)]}/>
                    }

                    item['remove'] = (<Button
                        onClick={() => this.removeItem(item.id)}
                        id="reship_button"
                        startIcon={<DeleteIcon/>}
                        primary="true"
                        variant="outlined"
                        labelPosition="after"
                        label="Remove">Remove</Button>);
                    item['style'] = {};
                } else {
                    item['style'] = {display: 'none'};
                }
            });
        }

        /**
         *
         * @param props
         * @returns {*}
         * @constructor
         */
        const PostCreateToolbar = props => {
            const form = useForm();
            const formdata = form.getState().values;

            const handleClick = useCallback(() => {
                this.onSubmit(formdata);
            }, [formdata, form]);

            return (
                <Toolbar {...props} >
                    <OMSSaveButton label='Create Order'
                                   disabled={props.invalid}
                                   saving={this.state.saving}
                                   onClick={handleClick}
                                   isSaving={this.state.saving}
                                   disabledWhenSaving={true}
                                   submitOnEnter={false}/>

                </Toolbar>
            );
        }


        /**
         *
         * @param props
         * @returns {*}
         * @constructor
         */
        const SameBilling = props => {
            const form = useForm();
            const formdata = form.getState().values;

            const handleClick = useCallback(() => {
                this.theSameBilling(formdata);
            }, [formdata, form]);

            return (
                <BooleanInput {...props} label="My billing address is the same as my shipping address"
                              source="the_same_billing" defaultValue={true}
                              onChange={() => handleClick()}/>
            );
        }

        const acceptFiles = '.png, .jpg, .jpeg, .pdf'

        return (
            <div className="formReships">
                <CardHeader title={toolbarTile} className="title"/>
                <TabbedForm idToolbar="reshipToolbar" syncWithLocation={false}
                            toolbar={<PostCreateToolbar/>}
                            styleToolbar={this.state.styleToolbar}
                            validate={this.validate}
                            submitOnEnter={false}
                            invalid={false}
                            label={toolbarTile}
                            initialValues={defaultValue} {...this.props}>

                    <FormTab label="Order">
                        <ListChannel/>
                        <TextInput label="Order No" source="channel_order_no" validate={requiredValidator} disabled={true}/>
                        <TextInput label="Payment Method" source="payment_method"/>
                        <SelectInput name="order_currency_code" label="Currency Code" validate={requiredValidator}
                                     source="order_currency_code"
                                     choices={commonCurrency}
                                     optionText="name" optionValue="_id"/>
                        <TextInput source="coupon_code"/>
                        <TextInput source="customer_note"/>
                        <BooleanInput source="is_gift"/>
                        <TextInput source="gift_message"/>
                    </FormTab>
                    <FormTab label="Item">
                        <Table selectable="false" className="tbl_tab3" style={{width: '100%'}}>
                            <TableHead>
                                <TableRow>
                                    <TableCell></TableCell>
                                    <TableCell>Warehouse </TableCell>
                                    <TableCell>SKU </TableCell>
                                    <TableCell>Qty</TableCell>
                                    <TableCell>Price</TableCell>
                                    <TableCell> <Button
                                        onClick={this.addItem}
                                        id="reship_button"
                                        className="display-none"
                                        primary="true"
                                        variant="outlined" color="secondary"
                                        startIcon={<AddIcon/>}
                                        labelPosition="after"
                                        label="Add Item">Add Item</Button></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {items.map((item, key) =>
                                    <TableRow key={key} style={item.style}>
                                        <TableCell>
                                            <SelectInput label="Warehouse"
                                                         source={this.customSource(item.id, 'warehouse')}
                                                         choices={item.warehouseIncSku}
                                                         optionText="name" optionValue="_id"
                                                         onChange={(event, index, value) => this.handleChange(value, item, event, 'warehouse_id')}
                                                         defaultValue={this.state.selectChannelDefault}/>
                                        </TableCell>
                                        <TableCell>{item.inventory}</TableCell>
                                        <TableCell>{item.qty}</TableCell>
                                        <TableCell>
                                            {item.price}
                                        </TableCell>
                                        <TableCell>
                                            {item['remove']}
                                        </TableCell>
                                    </TableRow>
                                )}

                                <TableRow>
                                    <TableCell>
                                    </TableCell>
                                    <TableCell></TableCell>
                                    <TableCell>
                                    </TableCell>
                                    <TableCell>
                                        Shipping Fee
                                        <NumberInput step={0.01} disabled={this.state.disable} label=""
                                                     initialValue={0.00} source="shipping_amount"
                                                     validate={[number(), minValue(0)]}/>
                                    </TableCell>
                                    <TableCell>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>
                                    </TableCell>
                                    <TableCell></TableCell>
                                    <TableCell>
                                    </TableCell>
                                    <TableCell>
                                        Discount
                                        <NumberInput step={0.01} disabled={this.state.disable} label=""
                                                     initialValue={0.00} source="discount_amount"
                                                     validate={[number(), minValue(0)]}/>
                                    </TableCell>
                                    <TableCell>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>
                                    </TableCell>
                                    <TableCell></TableCell>
                                    <TableCell>
                                    </TableCell>
                                    <TableCell>
                                        Total Tax <NumberInput step={0.01} disabled={this.state.disable} label=""
                                                               initialValue={0.00} source="tax_amount"
                                                               validate={[number(), minValue(0)]}/>
                                    </TableCell>
                                    <TableCell>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </FormTab>
                    <FormTab label="Address">
                        <Grid container spacing={3} style={{width: '100%'}}>
                            <Grid item xs={12} sm={6}>

                                <Typography style={{float: "left", width: '100%'}} gutterBottom variant="h5"
                                            component="h5">
                                    Shipping
                                </Typography>

                                <TextInput source="email" validate={emailValidator} style={{width: '70%'}}/>
                                <TextInput source="firstname" validate={requiredValidator} style={{width: '70%'}}/>
                                <TextInput source="lastname" validate={requiredValidator} style={{width: '70%'}}/>
                                <TextInput source="middlename" style={{width: '70%'}}/>

                                <AutocompleteInput name="country" source="country" validate={requiredValidator}
                                                   choices={countries}
                                                   fullWidth={true}
                                                   optionValue="code"
                                                   style={{width: '70%'}}

                                                   options={{class:'country_auto_complete', style:{width: '70%'}}}
                                                   TextFieldProps={{
                                                       placeholder: 'Start typing to create a new item',
                                                   }}
                                />

                                <TextInput source="city" validate={requiredValidator} style={{width: '70%'}}/>
                                <TextInput source="region" style={{width: '70%'}} validate={requiredValidator}/>
                                <TextInput source="postcode" validate={requiredValidator} style={{width: '70%'}}/>
                                <TextInput label="Address" source="address1" validate={requiredValidator}
                                           style={{width: '70%'}}/>
                                <TextInput label="Second Address" source="address2" style={{width: '70%'}}/>
                                <TextInput label="Third Address" source="address3" style={{width: '70%'}}/>
                                <TextInput source="company" style={{width: '70%'}}/>
                                <TextInput source="telephone" style={{width: '70%'}} validate={requiredValidator}/>
                                <TextInput source="fax" style={{width: '70%'}}/>
                                <BooleanInput label="Commersial Use" source="is_commercial"/>
                                <BooleanInput label="Is PO Box Address" source="is_pobox"/>
                                <SameBilling/>
                            </Grid>
                            <Grid item xs={12} sm={6}
                                  style={{display: defaultValue['the_same_billing_css'] === true ? "none" : "block"}}>
                                <Typography style={{float: "left", width: '100%'}} gutterBottom variant="h5"
                                            component="h5">
                                    Billing
                                </Typography>

                                <TextInput source="b_email" label="Email" validate={emailValidator}
                                           style={{width: '70%'}}/>
                                <TextInput source="b_firstname" label="First Name" validate={requiredValidator}
                                           style={{width: '70%'}}/>
                                <TextInput source="b_lastname" label="Last Name" validate={requiredValidator}
                                           style={{width: '70%'}}/>
                                <TextInput source="b_middlename" label="Middle Name" style={{width: '70%'}}/>
                                <AutocompleteInput name="b_country" label="Country" source="country" validate={requiredValidator}
                                                   choices={countries}
                                                   fullWidth={true}
                                                   optionValue="code"
                                                   style={{width: '70%'}}
                                                   options={{class:'country_auto_complete', style:{width: '70%'}}}
                                                   TextFieldProps={{
                                                       placeholder: 'Start typing to create a new item',
                                                   }}
                                />
                                <TextInput source="b_city" label="City Name" validate={requiredValidator}
                                           style={{width: '70%'}}/>
                                <TextInput source="b_region" label="Region" style={{width: '70%'}}
                                           validate={requiredValidator}/>
                                <TextInput source="b_postcode" label="Post code" validate={requiredValidator}
                                           style={{width: '70%'}}/>
                                <TextInput label="Address" source="b_address1" validate={requiredValidator}
                                           style={{width: '70%'}}/>
                                <TextInput label="Second Address" source="b_address2" style={{width: '70%'}}/>
                                <TextInput label="Third Address" source="b_address3" style={{width: '70%'}}/>
                                <TextInput label="Company" source="b_company" style={{width: '70%'}}/>
                                <TextInput label="Telephone" source="b_telephone" style={{width: '70%'}}
                                           validate={requiredValidator}/>
                                <TextInput label="Fax" source="b_fax" style={{width: '70%'}}/>
                                <BooleanInput label="Commersial Use" source="b_is_commercial" style={{width: '70%'}}/>
                                <BooleanInput label="Is PO Box Address" source="b_is_pobox" style={{width: '70%'}}/>
                            </Grid>
                        </Grid>
                    </FormTab>
                    <FormTab label="Packing slips">
                        <FileInput multiple="true" className="orderFiles" source="order_files" label="Packing slips"
                                   accept={acceptFiles}
                                   placeholder={<p>
                                       <p>Drag & drop files to be uploaded. You will be able to remove files by clicking
                                           the red circle button.</p>
                                       <p> Support file formats to upload: PDF, PNG, JPEG</p></p>}>
                            <FileField source="src" title="title"/>
                        </FileInput>

                    </FormTab>
                </TabbedForm>
                <OMSNotification open={this.state.notification_open}
                                 message={'Order Created'} action={this.openNewOrder()}/>
            </div>

        );
    }
}

/**
 *
 * @type {({name: string, _id: number}|{name: string, _id: number}|{name: string, _id: number, invoice: boolean}|{name: string, _id: number}|{name: string, _id: number, invoice: boolean})[]}
 */
export const channelsAllow = channels.filter((item) => item.manual == true);

/**
 *
 * @param props
 * @returns {*}
 * @constructor
 */
export const ListChannel = (props) => {
    const form = useForm();

    /**
     *
     * @type {(...args: any[]) => any}
     */
    const handleClick = useCallback(() => {
        const formdata = form.getState().values;

        if (isReshipChannel(formdata["channel_id"])) {
            Object.keys(formdata).forEach(field => {
                if (field.indexOf('price_') !== -1) {
                    form.change(field, 0);
                }
            });

            form.change("shipping_amount", 0);
            form.change("discount_amount", 0);
            form.change("tax_amount", 0);
        }

        laravelJsonServerClient(GET_ONE, 'order/number', {id: formdata["channel_id"]})
            .then(r => {
                if (r.data && r.data.json && !r.data.json.result) {
                    throw new Error(r.data.json.message);
                }
                form.change("channel_order_no",  r.data.channel_order_no)
            })
            .catch((e) => {
                console.log(e.message)
            });

    }, [form]);

    return (<SelectInput {...props} label="Channel"
                         source="channel_id"
                         validate={[required()]}
                         choices={channelsAllow}
                         onChange={() => handleClick()}
                         optionText="name" optionValue="_id"/>);
}


CreateOrderForm.propTypes = {
    showNotification: PropTypes.func,
    hideNotification: PropTypes.func
};

export default connect(null, {
    showNotification: showNotificationAction,
    hideNotification: hideNotification
})(CreateOrderForm);

