import React, {Component} from 'react';
import {
    Table,
    TableBody,
    TableRow,
    TableHead,
    TableCell,
    Card
} from '@material-ui/core';
import {Paper, Typography} from '@material-ui/core';
import laravelJsonServerClient from '../../restClient'
import {GET_LIST, TopToolbar, RichTextField} from 'react-admin';

import CreateComment from "./actions/createCommentBtn";
import {isAllowCommentChannel} from "../channels";
import {authorized} from "../../authorized";

class Comments extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            fulfilmentHistory: [],
            roles: '',
            permissions: ''
        }
    }

    fetchData() {
        laravelJsonServerClient(GET_LIST, '/order/comment', {
            filter: {order_id: this.props.record.id},
        }).then(r => {
            this.setState({
                data: r.data,
            });

        });
    }

    render() {
        const cardActionStyle = {
            zIndex: 2,
            display: 'inline-block',
            width: '100%',
        };

        const order = this.props.record;
        let items = this.props.record.comments === undefined ? [] : this.props.record.comments;
        const alignRight = {textAlign: 'right'};

        if (authorized.permissions.indexOf('comment.list') === -1) {
            return (
                <div>
                </div>
            )
        }

        return ( isAllowCommentChannel(order.channel_id) === true ?
            <div>
                <Paper style={{margin: '20px 0 0 0', padding: '10px'}} zdepth={2}>
                    <Card style={{boxShadow: 'none'}}>
                        <TopToolbar style={cardActionStyle}>
                            <Typography style={{float: "left"}} gutterBottom variant="h5" component="h5">
                                Order Comments
                            </Typography>
                            <CreateComment record={order}/>
                        </TopToolbar>
                    </Card>

                    <Table selectable="false">
                        <TableHead>
                            <TableRow>
                                <TableCell>ID</TableCell>
                                <TableCell>Message</TableCell>
                                <TableCell>Email</TableCell>
                                <TableCell style={alignRight}>Created By</TableCell>
                                <TableCell style={alignRight}>Created Time</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {items.map(item =>
                                <TableRow key={item.id}>
                                    <TableCell className="pl-16">{item.id}</TableCell>
                                    <TableCell className="pl-10" style={{
                                        fontSize: '10px',
                                        minWidth: '300px',
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis'
                                    }}>
                                        <CommentMessage record={item}/>
                                    </TableCell>
                                    <TableCell>{item.customer_email}</TableCell>
                                    <TableCell style={alignRight}>
                                        {item.name}
                                    </TableCell>
                                    <TableCell style={alignRight}>
                                        {item.created_at}
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </Paper>
            </div> : <span/>
        );
    }
}

/**
 *
 * @param record
 * @returns {*}
 * @constructor
 */
const CommentMessage = ({record = {}}) => (
    <RichTextField label="" className={'inventory-gird-description'} record={record} source="message"/>
);

/**
 *
 */
export default Comments;
