import React, {cloneElement} from 'react';
import {
    List,
    Datagrid,
    TextField,
    Filter,
    Show,
    SimpleShowLayout,
    RefreshButton, TopToolbar, Pagination
} from 'react-admin';
import {CardActions} from '@material-ui/core';
import {DateInput, TextInput} from 'react-admin';
import {Button} from '@material-ui/core';
import {Link} from 'react-router-dom'
import {Create, required, SimpleForm} from "react-admin";
import {dateParser} from "../filters/dateParser";
import AddIcon from '@material-ui/icons/Add';
import {config} from "../../config";
import {defaultReSource, authorized} from '../../authorized'

/**
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const TaxFilters = (props) => (
    <Filter {...props}>
        <TextInput alwaysOn label="Country #" source="country"/>
    </Filter>
);

/**
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const TaxActions = (props) => {
    return (
        <TopToolbar>
            {cloneElement(props.filters, {context: 'button'})}
            {authorized.permissions.indexOf('finance.tax_rates.create') !== -1 ?
                <Button component={Link}
                        to="/taxcodes/create"
                        id="reship_button"
                        className="display-none"
                        primary="true"
                        labelPosition="after"
                        label="Add TaxCode"
                        variant="outlined" color="secondary"
                        size={"small"}
                        startIcon={<AddIcon/>}
                >Add TaxCode</Button> : ''}
        </TopToolbar>
    );
};

const OMSPagination = props => <Pagination rowsPerPageOptions={[config.perPage]} {...props} />;

/**
 *
 * @param props
 * @returns {*}
 */
export const taxCodeList = (props) => {

    if (authorized.permissions.indexOf('finance.tax_rates.list') === -1) {
        return (<span/>)
    }

    return (
        <List {...props} bulkActionButtons={false} title="Tax Codes List"
              actions={<TaxActions/>}
              pagination={<OMSPagination/>}
              filters={<TaxFilters/>}
              perPage={config.perPage} sort={{field: 'created_at', order: 'DESC'}}>
            <Datagrid>
                <TaxName label="Tax Code" source="taxcode"/>
                <TextField label="Country" source="country"/>
                <TextField label="Region" source="region"/>
                <TextField label="City" source="city"/>
                <TextField label="Rate %" source="rate"/>
                <TextField label="Apply At" source="apply_at"/>
                <TextField label="Created At" source="created_at"/>
            </Datagrid>
        </List>
    );
};

/**
 *
 * @param props
 * @returns {*}
 */
export const taxCodeShow = (props) => {
    if (authorized.permissions.indexOf('finance.tax_rates.get') === -1) {
        return (<span/>)
    }

    return (
        <main className="orderDetail">
            <Show title={<TaxTitle/>} {...props}>
                <SimpleShowLayout>
                    <TextField label="Tax Code" source="taxcode"/>
                    <TextField label="Country" source="country"/>
                    <TextField label="Region" source="region"/>
                    <TextField label="City" source="city"/>
                    <TextField label="Rate %" source="rate"/>
                    <TextField label="Apply At" source="apply_at"/>
                    <TextField label="Created At" source="created_at"/>
                </SimpleShowLayout>
            </Show>
        </main>
    );

};

/**
 *
 * @param props
 * @returns {*}
 * @constructor
 */
export const TaxcodeCreate = (props) => {

    if (authorized.permissions.indexOf('finance.tax_rates.create') === -1) {
        return (<span/>)
    }

    return (
        <Create title="Add TaxCode" {...props}>
            <SimpleForm redirect="list">
                <TextInput label="Taxcode" source="taxcode" isRequired={true} validate={required()}/>
                <TextInput label="Country ISO Code" source="country" validate={required()}/>
                <TextInput label="Region ISO Code" source="region"/>
                <TextInput label="City" source="city"/>
                <TextInput label="Tax Rate" source="rate" validate={required()}/>
                <DateInput label="Apply at" source="apply_at" name="apply_at" parse={dateParser}/>
            </SimpleForm>
        </Create>
    );
};

const TaxName = ({record = {taxcode: ""}}) => (
    <span>{record.taxcode}</span>
);

const TaxTitle = ({record = {taxcode: ""}}) => {
    return <span>{record ? `${record.taxcode}` : ''}</span>;
};


const cardActionStyle = {
    zIndex: 2,
    display: 'inline-block',
    float: 'right',
};
