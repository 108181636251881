import React, {Component} from 'react';
import {Link} from 'react-router-dom';

class Open extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: []
        }
    }

    render() {
        const {record} = this.props;
        return (
            <div className="card">
                <span>
                <Link rel="noopener noreferrer" target="_blank" to={{
                    pathname: '/order/' + record.id + '/show'
                }}>Show Order</Link>
            </span></div>);
    }
}

export default Open;