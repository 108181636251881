import React, {Component} from 'react';
import {
    Table,
    TableBody,
    TableHead,
    TableRow,
    TableCell, Typography,
} from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import laravelJsonServerClient from '../../restClient';
import {GET_LIST, Title, TopToolbar} from 'react-admin';
import {Link} from 'react-router-dom';
import {isReshipChannel} from "../channels";
import {defaultReSource, authorized} from '../../authorized'

/**
 *
 */
class ReShipments extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: []
        }
    }

    /**
     *
     */
    componentDidMount() {
        this.fetchData();
    }

    /**
     *
     * @param nextProps
     * @param nextContext
     */
    componentWillReceiveProps(nextProps, nextContext) {
        this.fetchData();
    }

    fetchData() {
        if (!isReshipChannel(this.props.record.channel_id)) {
            laravelJsonServerClient(GET_LIST, 'order/' + this.props.record.id + '/reship', {}).then(r => {
                if (r.data !== undefined) {
                    this.setState({data: r.data});
                }
            });
        }
    }

    render() {
        if (isReshipChannel(this.props.record.channel_id)) {
            return (
                <div>
                </div>
            )
        } else {
            const shipments = this.state.data;
            return (shipments.length ?
                    <Paper style={{margin: '20px 0 0 0', padding: '10px'}} zdepth={2}>
                        <Typography gutterBottom variant="h5" component="h5">
                            Order ReShipments
                        </Typography>
                        <Table selectable="false">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Order ID</TableCell>
                                    <TableCell>Created At</TableCell>
                                    <TableCell>Status</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {shipments.map(shipment =>
                                    <TableRow key={shipment.id}>
                                        <TableCell className="pl-24">
                                            <Link to={{
                                                pathname: `${"/order/" + shipment.id + "/show"}`,
                                                search: "",
                                                state: {orderId: shipment.id}
                                            }}> {shipment.channel_order_no} </Link>

                                        </TableCell>
                                        <TableCell>{shipment.channel_created_at}</TableCell>
                                        <TableCell>{shipment.status}</TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </Paper> : <span/>
            );
        }
    }
}

export default ReShipments;