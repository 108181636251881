import * as React from 'react';
import {
    List,
    Datagrid,
    TextField,
    Filter,
    DateInput,
    SelectInput, TextInput,
    TopToolbar, Pagination, ShowButton
} from 'react-admin';
import {config, warehouseFilter} from "../config";
import {authorized} from '../authorized'

/**
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const TransactionFilters = (props) => (
    <Filter {...props}>
        <DateInput text="To date" source="created_at_to"/>
        <DateInput text="From date" source="created_at_from"/>
        <TextInput alwaysOn text="Sku" source="sku"/>
        <SelectInput alwaysOn label="Warehouse" source="warehouse_id" choices={warehouseFilter} optionValue="_id"
                     optionsText="name"/>
    </Filter>
);


/**
 *
 * @param resource
 * @param filters
 * @param displayedFilters
 * @param filterValues
 * @param basePath
 * @param showFilter
 * @returns {*}
 * @constructor
 */
export const TransactionActions = ({resource, filters, displayedFilters, filterValues, basePath, showFilter}) => (
    <TopToolbar style={cardActionStyle}>
        {filters && React.cloneElement(filters, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: 'button'
        })}

    </TopToolbar>
);

export const TransactionPagination = props => <Pagination rowsPerPageOptions={[config.perPage]} {...props} />;
/**
 *
 * @param props
 * @returns {*}
 * @constructor
 */
export const TransactionList = (props) => {
    if (authorized.permissions.indexOf('inventory.log.list') === -1) {
        return (<span/>)
    }
    return (
        <span>
        <List {...props} actions={<TransactionActions/>}
              filters={<TransactionFilters/>} perPage={config.perPage}
              filterDefaultValues={{instock: true, archive: false}}
              bulkActionButtons={false}
              pagination={<TransactionPagination/>}
              sort={{field: 'created_at', order: 'DESC'}}>
            <Datagrid>
                <TextField label="Date" source="created_at"/>
                <SkuText label="SKU" source="sku"/>
                <TextField label="Warehouse" source="warehouse_name"/>
                <TextField label="Qty" source="qty"/>
                <TextField label="Available Qty" source="available_qty"/>
                <TextField label="Disposition" source="disposition_message"/>
                <UserName label="User" source="user_name" />
                <ShowOrder label="Order"  source="order_id"/>
            </Datagrid>
        </List>
    </span>
    )
};


/**
 *
 * @param record
 * @returns {*}
 * @constructor
 */
const ShowOrder = ({record = {order_id: null}}) => {
    return (record.order_id != null) ?
        <ShowButton resource="Order" record={{id: record.order_id}} basePath={"/order"}/> : "";
};

/**
 *
 * @param record
 * @returns {*}
 * @constructor
 */
const UserName = ({record = {user_id: 0, user_name: '' }}) => {
    let data = {...record};
    data.user_name = record.user_id === 0 ?  'OPA User' : record.user_name;
    return (<TextField label="User" source="user_name" record={data}/>)
};

/**
 *
 * @param record
 * @returns {*}
 * @constructor
 */
const SkuText = ({record = {sku: ""}}) => (
    <span className="sku-text">{record.sku}</span>
);


/**
 *
 * @type {{display: string, float: string, zIndex: number}}
 */
const cardActionStyle = {
    zIndex: 2,
    display: 'inline-block',
    float: 'right',
};
