import React, {Component} from 'react';
import RefreshIcon from '@material-ui/icons/Refresh';
import Button from "@material-ui/core/Button";
import PropTypes from "prop-types";
import {Link} from 'react-router-dom'
import {isReshipChannel} from "../../channels";
import {defaultReSource, authorized} from '../../../authorized'

class ReShipBtn extends Component {
    constructor(props) {
        super(props);
        let location = window.location.hash.split('/');
        this.state = {
            orderId: location[2],
            roles: '',
            permissions: ''
        };
    };

    handleClick() {
    };

    render() {
        const {record} = this.props;
        const styles = {
            button: {
                marginLeft: 5,
                marginRight: 5,
            },
        };

        return (
            (record !== undefined
                && authorized.permissions.indexOf('order.reship.create') !== -1
                && !isReshipChannel(record.channel_id)
                && (record.internal_status === 'shipped' || record.internal_status === 'delivered')) ?
                <Button
                    onClick={this.handleClick}
                    component={Link}
                    to={{
                        pathname: "/reship/create", search: "?orderId=" + this.state.orderId,
                        state: {orderId: this.state.orderId, orderNumber: record.channel_order_no}
                    }}
                    id="reship_button"
                    className="display-none"
                    variant="outlined" color="secondary"
                    size={"small"}
                    startIcon={<RefreshIcon/>}
                    style={styles.button}
                    labelPosition="after"
                >Reship</Button> : <span/>);
    }
}

ReShipBtn.propTypes = {
    record: PropTypes.object,
};


export default ReShipBtn
