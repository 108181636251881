import * as React from 'react';
import {Create} from 'react-admin';
import ShipmentForm from "./shipmentForm";
import {defaultReSource, authorized} from '../../authorized'

/**
 *
 * @param props
 * @returns {*}
 * @constructor
 */
export const ShipmentCreate = (props) => (
    <Create title={<OrderTitle/>} {...props}>
        <ShipmentForm {...props}/>
    </Create>
);

const OrderTitle = () => {
    return <span>{''}</span>;
};