import React, {Component} from "react";
import laravelJsonServerClient from "../restClient";
import {refreshView as refreshViewAction, Title} from "react-admin";
import {connect} from "react-redux";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

/**
 *
 */
class DashBoard extends Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.redirect = this.redirect.bind(this);
    };

    /**
     *
     */
    fetchData() {
    }

    /**
     *
     */
    redirect() {
        /*laravelJsonServerClient(CREATE, 'acl', {id: ''}).then(r => {
            const roles = r.data.json.roles;
            if (roles !== null && roles !== undefined) {
                switch (roles[0]) {
                    case 'admin':
                    case 'merchandising':
                    case 'scm':
                    case 'finance':
                    case 'customer_support_agent':
                    case 'customer_support_manager':
                        this.props.history.push('/order', {})
                        break;
                    case 'warehouse':
                        this.props.history.push('/warehouse', {})
                        break;
                }
                //this.props.refreshView();
            } else {
                this.props.history.push('/login', {})
            }
        });*/
    }

    componentDidMount() {
        this.fetchData();
    }

    componentDidUpdate(prevprops, prevstate) {
        this.fetchData();
    }

    render() {
        return (
            <Card>
                <Title title="Welcome to CE Brands OMS" />
                <CardContent>Please use the left menu and move to the appropriate section</CardContent>
            </Card>
        );
    }
}

/**
 *
 */
export default connect(undefined, {refreshView: refreshViewAction})(
    DashBoard
);

