import React, {Component} from 'react';
import PropTypes from "prop-types";
import { Link } from 'react-router-dom'
import Button from "@material-ui/core/Button";
import RefreshIcon from "@material-ui/icons/Refresh";

/**
 *
 */
class BacktoOrderBtn extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    };

    render() {
        const { record } = this.props;
        const styles = {
            button: {
                marginLeft: 5,
                marginRight: 5,
            },
        };

        return ( record ?
           <Button
                component={Link}
                to={{pathname :"/order/"+  record.sales_order_id + '/show',
                    state:{orderId:record.sales_order_id} }}
                className="display-none"
                variant="outlined" color="primary"
                size={"small"}
                startIcon={<RefreshIcon />}
                style={styles.button}
                labelPosition="after"
            >Back to Order</Button> : <span/>);
    }
}

BacktoOrderBtn.defaultProps = {
    addLabel: true,
};

BacktoOrderBtn.propTypes = {
    addLabel: PropTypes.bool,
    label: PropTypes.string,
    record: PropTypes.object,
    source: PropTypes.string.isRequired,
};


export default BacktoOrderBtn
