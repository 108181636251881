import * as React from 'react';
import { forwardRef, memo } from 'react';
import { Layout, AppBar, UserMenu} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import Menu from "./Menu";
import { Box, Typography } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';

const useStyles = makeStyles(theme => ({
    menuItem: {
        color: theme.palette.text.secondary,
    },
    icon: { minWidth: theme.spacing(5) },
}));

const SwitchLanguage = forwardRef((props, ref) => {
    //console.log(props)
    const classes = useStyles();
    return (
        <span/>
        // <MenuItem
        //     className={classes.menuItem}
        //     onClick={() => {
        //         props.onClick();
        //     }}
        // >
        //     <ListItemIcon className={classes.icon}>
        //         <Language />
        //     </ListItemIcon>
        //     Switch Language
        // </MenuItem>
    );
});



const MyUserMenu = (props) => (
    <UserMenu {...props}>
        <SwitchLanguage />
    </UserMenu>
);

/**
 *
 * @type {{display: string, paddingRight: string, width: string, float: string}}
 */
const imgStyle = {
    'maxWidth': '150px',
    'display': 'block',
    'float': 'left',
    'paddingRight': '20px',
    'paddingTop': '2px'
}

/**
 *
 * @type {React.NamedExoticComponent<object>}
 */
const MyAppBar = memo(props => {
    const classes = useStyles();
    return (<AppBar {...props} userMenu={<MyUserMenu />} >
            <Box flex="1">
                <a href="#/order"><img style={imgStyle} src="cebi-logo.png"/></a>
                <Typography variant="h6" id="react-admin-title">
                </Typography>
            </Box>
        </AppBar>
    );
})

/**
 *
 * @param props
 * @returns {*}
 */
export default props => <Layout {...props} appBar={MyAppBar} menu={Menu}/>;