import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import {connect} from "react-redux";
import {
    CREATE,
    GET_ONE,
    maxValue,
    minValue,
    number,
    NumberInput,
    refreshView as refreshViewAction,
    required,
    SelectInput,
    SimpleForm,
    TextInput
} from "react-admin";
import {CardActions, Dialog, DialogActions, DialogContent, DialogTitle} from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
import laravelJsonServerClient from '../../restClient'
import swal from "sweetalert";
import CircularProgress from "@material-ui/core/CircularProgress";

/**
 *
 */
class ShipmentLabel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            data: null,
            values: null,
            id: '',
            allowSubmit: false,
            shippers: [],
            warehouse: 0,
            loading: false
        }
        this.validate = this.validate.bind(this);
    }

    /**
     *
     */
    handleOpen = () => {
        this.setState({
            open: true,
        });
    };

    /**
     *
     */
    handleClose = () => {
        this.setState({open: false});
    };

    /**
     *
     * @param values
     * @returns {boolean}
     */
    validate = (values) => {
        const allowedFields = [
            "warehouse",
            "shipper_account"
        ]
        this.setState({allowSubmit: true});
        allowedFields.forEach(field => {
            if (values[field] === "" || values[field] === null) {
                this.setState({allowSubmit: false});
                return {}
            }
        });

        let checkQty = false;
        values.items.forEach(item => {
            if (item.warehouse_id === values.warehouse) {
                const labelItemQty = values[item.id + "_id"]
                if (labelItemQty >= 1 && labelItemQty <= (item.qty_ordered - item.qty_shipped - item.qty_canceled)) {
                    checkQty = true;
                }
            }
        });

        if (checkQty === false) {
            this.setState({allowSubmit: false});
            return {}
        }

        this.setState({data: values})
        return true
    }

    /**
     *
     */
    fetchShipper = () => {
        laravelJsonServerClient(GET_ONE, 'shipment/label/shipper').then(r => {
            let shippers = [];
            let channeShipper = [];
            if (r.data.error !== true) {
                Object.values(r.data).forEach((item) => {
                    if (item.channel !== undefined) {
                        if (item.channel.indexOf(this.props.record.channel_id) !== -1) {
                            channeShipper.push({
                                _id: {
                                    id: item.id,
                                    slug: item.slug,
                                    country: item.address.country,
                                    name: item.description
                                },
                                name: item.description
                            });
                        }
                    } else {
                        shippers.push({
                            _id: {id: item.id, slug: item.slug, country: item.address.country, name: item.description},
                            name: item.description
                        });
                    }
                });
            }

            this.setState({
                shippers: channeShipper.length === 0 ? shippers : channeShipper,
            });
        });
    }


    handleChange(event, item, value) {
        const newitem = event.target.value
        if (newitem !== undefined) {
            this.setState({warehouse: newitem});
        }
    }

    /**
     *
     */
    componentDidMount() {
        this.setState({open: this.props.open});
        this.fetchShipper()
        // this.fetchBoxSize()
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.open === true) {
            this.setState({open: nextProps.open});
        }
    }

    /**
     *
     * @param warehouse
     * @param stateWarehouse
     * @returns {number}
     */
    getWarehouse(warehouse, stateWarehouse) {
        return stateWarehouse !== warehouse && stateWarehouse !== 0 && stateWarehouse !== undefined ? stateWarehouse : warehouse;
    }

    /**
     *
     */
    onSubmit = () => {
        this.setState({loading: true})
        laravelJsonServerClient(CREATE, 'shipment/label', {data: this.state.data}).then(
            r => {
                if (r.data.json.error === true) {
                    swal(r.data.json.message, {title: 'NOTIFY'});
                    this.setState({loading: false})
                } else {
                    this.props.refreshView();
                    this.setState({open: false})
                    this.setState({loading: false})

                }
            }
        ).catch((e) => {
            console.error(e);
            this.setState({loading: false});
        });
    };


    /**
     *
     * @returns {*}
     */
    render() {
        const styles = {
            button: {
                marginLeft: 5,
                marginRight: 5,
            },
        };

        const {warehouses} = this.props;
        let record = this.props.record;

        const requiredValidator = [required()];

        const actions = [
            <Button
                label="Cancel"
                primary="true"
                disabled={false}
                key={'primary'}
                size={"small"}
                color={"primary"}
                variant="outlined"
                onClick={this.handleClose}
            >Cancel</Button>,
            <Button
                label="Submit"
                primary="true"
                startIcon={this.state.loading === false ? <SaveIcon/> : <CircularProgress size={15} color={'inherit'}/>}
                variant={"outlined"} color={"secondary"}
                size={"small"}
                disabled={!this.state.allowSubmit || this.state.loading === true}
                onClick={this.onSubmit}
            >Submit</Button>];

        return (<span>
            <SimpleForm submitOnEnter={false}
                        validate={this.validate}
                        invalid={false}
                        defaultValue={record}
                        save={this.handleSubmit}
                        toolbar={<CardActions/>}
                        handleSubmit={this.handleClose}
                        redirect={false} {...this.props}>
                <Dialog
                    bodyClassName={"modal-edit-address"}
                    title="Generate Label"
                    actions={actions}
                    maxWidth={'sm'}
                    modal={true}
                    fullWidth={'true'}
                    open={this.state.open}
                    autoScrollBodyContent={true}
                >
                <DialogTitle id="customized-dialog-title" onClose={this.handleClose}>
                    Generate Label
                </DialogTitle>
                <DialogContent dividers>
                <span>

                        <TextInput style={{display: 'none'}} disabled source="id"/>
                        <SelectInput name="warehouse" isRequired={true}
                                     source="warehouse"
                                     validate={requiredValidator}
                                     choices={warehouses}
                                     onChange={(event) => this.handleChange(event)}
                                     fullWidth={true}
                                     label="Inbound warehouse"
                                     optionValue="_id"/>
                        <SelectInput name="shipper_account" isRequired={true}
                                     source="shipper_account"
                                     validate={requiredValidator}
                                     choices={this.state.shippers}
                                     fullWidth={true}
                                     optionValue="_id"/>

                    {record.warehouse !== 0 ? (
                        record.items.map((item, key) =>
                            item.status !== "internal_canceled" && item.status !== "shipped" && item.warehouse_id === this.getWarehouse(record.warehouse, this.state.warehouse) ? (
                                <NumberInput label={item.sku + ""}
                                             source={item.id + "_id"}
                                             fullWidth={true}
                                             name={item.id + "_id"}
                                             validate={[number(), minValue(0), maxValue(item.qty_ordered - item.qty_shipped - item.qty_canceled), required()]}
                                             initialValue={0}
                                             className={"custom_number_input"}
                                />
                            ) : ("")
                        )
                    ) : ("")}

                </span>
                </DialogContent>
                <DialogActions {...this.props}>
                    {actions}
                </DialogActions>
                </Dialog>
                </SimpleForm>
            </span>);
    }
}

/**
 *
 * @type {{record: Requireable<object>, warehouses: Requireable<object>, open: Requireable<boolean>}}
 */
ShipmentLabel.propTypes = {
    record: PropTypes.object,
    open: PropTypes.bool,
    warehouses: PropTypes.object,
    onClick: PropTypes.any
};


/**
 *
 */
export default connect(undefined, {refreshView: refreshViewAction})(
    ShipmentLabel
);

