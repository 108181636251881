import React, {Component} from 'react';
import {Dialog, CardActions, Button, DialogActions} from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import {
    TabbedForm,
    FormTab,
    TextInput,
    UPDATE,
    refreshView as refreshViewAction, GET_ONE, SelectInput, BooleanInput
} from "react-admin";
import laravelJsonServerClient from '../../restClient'
import {connect} from 'react-redux';
import PropTypes from "prop-types"
import {warehoseRegionReport, warehouseFilter} from "../../config";
import {authorized} from '../../authorized'
import * as _ from "lodash";
import EditIcon from "@material-ui/icons/Edit";

/**
 *
 */
class WarehouseDialog extends Component {
    /**
     *
     * @param props
     */
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            open: false,
            dialog: false,
            values: {},
            allowSubmit: true,
            data: null,
            roles: '',
            permissions: ''
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.validate = this.validate.bind(this);

    };

    /**
     *
     * @param value
     */
    validate(value) {
        Object.keys(value).forEach(key => {
            if (this.state.values.hasOwnProperty(key) === false || this.state.values[key] !== value[key]) {
                this.setState({values: value});
            }
        });
    }


    handleSubmit() {
        const values = this.state.values;
        laravelJsonServerClient(UPDATE, 'warehouse', {id: values.id, data: values}).then(
            r => {
                if (r.data.result) {
                    this.handleClose();
                    this.props.refreshView();
                } else {
                    this.handleClose();
                    alert('Update error!');
                }
            }
        ).catch((e) => {
            console.error(e);
        });
    }

    /**
     *
     * @param $warehouse
     */
    loadWarehouse($warehouse) {
        laravelJsonServerClient(GET_ONE, 'warehouse', {id: $warehouse}).then(
            r => {
                this.setState({data: r['data']});
            }
        ).catch((e) => {
            console.error(e);
        });
    }

    showModal = () => {
        this.setState({show: true});
        this.loadWarehouse(this.props.record.id);
        this.setState({roles: localStorage.getItem('roles')});
        this.setState({permissions: localStorage.getItem('permissions')});
    };

    hideModal = () => {
        this.setState({show: false});
    };

    handleClose = () => {
        this.setState({show: false});
    };

    /**
     *
     * @returns {*}
     */
    render() {
        const record = this.props.record;

        if (authorized.permissions.indexOf('warehouse.edit') === -1) {
            return (<span className="pl-24"/>)
        }

        let actions = [
            <Button
                variant="outlined" color="primary"
                label="Dismiss"
                primary="true"
                onClick={this.handleClose}
            >Dismiss</Button>]
        ;

        if (authorized.permissions.indexOf('warehouse.edit') !== -1) {
            actions = [
                <Button
                    label="Submit"
                    primary="true"
                    size={'small'}
                    variant="outlined" color="secondary"
                    startIcon={<SaveIcon/>}
                    onClick={this.handleSubmit}
                >Submit</Button>,
                <Button
                    label="Dismiss"
                    variant="outlined" color="primary"
                    primary="true"
                    size={'small'}
                    onClick={this.handleClose}
                >Dismiss</Button>]
            ;
        }

        return (
            <main>
                <Button
                    label=""
                    size="small"
                    style={{overflow: 'inherit', color: '#000'}}
                    onClick={this.showModal}
                    startIcon={<EditIcon/>}>
                    Edit
                </Button>

                {this.state.data !== null ?
                    <Dialog
                        bodyClassName={"modal-edit-address"}
                        title={record.warehouse_name}
                        actions={actions}
                        modal={true}
                        open={this.state.show}
                        autoScrollBodyContent={true}
                    >
                        <TabbedForm submitonenter={false}
                                    defaultValue={this.state.data}
                                    save={this.handleSubmit}
                                    validate={this.validate}
                                    toolbar={<CardActions/>}
                                    handleSubmit={this.handleClose}
                                    redirect={false}>
                            <FormTab label="Infomation">
                                <TextInput source="name"/>
                                <TextInput source="stock_owner"/>

                                <TextInput source="city"/>
                                <TextInput source="region"/>
                                <SelectInput name="region_reporting" isRequired={false}
                                             source="region_reporting"
                                             choices={warehoseRegionReport}
                                             optionValue="_id"/>
                                <TextInput source="postcode"/>
                                <TextInput source="country"/>
                                <TextInput source="address"/>
                                <TextInput source="telephone"/>
                            </FormTab>
                            <FormTab label="Config">
                                <BooleanInput label="Add Inventory Manual" defaultValue={false} fullWidth={true} source="add"/>
                                <BooleanInput label="Edit Inventory Manual" defaultValue={false} fullWidth={true} source="edit"/>
                                <BooleanInput label="Return Request" defaultValue={false} fullWidth={true} source="returnRequest"/>
                                <BooleanInput label="Generate Label" defaultValue={false} fullWidth={true} source="generateLabel"/>
                                <BooleanInput label="Find Shipment" defaultValue={false} fullWidth={true} source="findshipment"/>
                                <TextInput source="countryReturn"/>
                            </FormTab>
                        </TabbedForm>
                        <DialogActions>
                            {actions}
                        </DialogActions>
                    </Dialog>
                    :
                    <span></span>
                }
            </main>
        );
    }
}

/**
 *
 * @type {{addLabel: Requireable<boolean>, elStyle: React.DetailedHTMLFactory<React.ObjectHTMLAttributes<HTMLObjectElement>, HTMLObjectElement> | Requireable<Object> | React.DetailedHTMLProps<React.ObjectHTMLAttributes<HTMLObjectElement>, HTMLObjectElement> | string, record: React.DetailedHTMLFactory<React.ObjectHTMLAttributes<HTMLObjectElement>, HTMLObjectElement> | Requireable<Object> | React.DetailedHTMLProps<React.ObjectHTMLAttributes<HTMLObjectElement>, HTMLObjectElement> | string, label: number | string | Requireable<string> | string, source: Validator<NonNullable<T>>}}
 */
WarehouseDialog.propTypes = {
    addLabel: PropTypes.bool,
    elStyle: PropTypes.object,
    label: PropTypes.string,
    record: PropTypes.object,
    source: PropTypes.string,
};

/**
 *
 */
export default connect(undefined, {refreshView: refreshViewAction})(
    WarehouseDialog
);
