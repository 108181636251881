import React, {Component} from 'react';
import Button from '@material-ui/core/Button';
import laravelJsonServerClient from '../../../restClient'
import {refreshView as refreshViewAction, UPDATE} from "react-admin";
import PropTypes from "prop-types";
import ReplayIcon from '@material-ui/icons/Replay';
import swal from "sweetalert";
import {connect} from "react-redux";
import {isAbleAutoRefundStores, isAmazonChannel} from "../../channels";

/**
 *
 */
class AutoRefundBtn extends Component {
    constructor(props) {
        super(props);
        let location = window.location.hash.split('/');
        this.state = {
            autoHideDuration: 2000,
            open: false,
            returnId: location[2]
        };
    };

    handleClick = () => {
        this.setState({
            open: true,
        });

        laravelJsonServerClient(UPDATE, 'returnsRequest/refundauto', {id: this.props.record.id}).then(
            r => {
                if (r.data.error === true) {
                    swal(r.data.message, {title: 'NOTIFY'});
                } else {
                    this.props.refreshView();
                }
            }
        ).catch((e) => {
            this.props.refreshView();
            console.error(e);
        });

    };

    handleRequestClose = () => {
        this.setState({
            open: false,
        });
    };

    render() {
        const styles = {
            button: {
                marginLeft: 5,
                marginRight: 5
            },
        };

        let showButton = true;
        let label = '';
        if (this.props.record) {
            showButton = (this.props.record.status === 'processing' && isAbleAutoRefundStores(this.props.record.channel_id));
            label = this.props.record.can_refund_auto == 1 ? "Disable refund" : "Enable refund";
        }


        return ((showButton === true) ? <span><Button
            onClick={this.handleClick}
            id="resend_email_button"
            color="secondary"
            primary="true"
            style={styles.button}
            variant="outlined"
            size={"small"}
            className="display-block"
            startIcon={<ReplayIcon/>}
        >
        {label}
        </Button>
        </span>:<span/>);
    }
}

AutoRefundBtn.propTypes = {
    record: PropTypes.object,
};

export default connect(undefined, {refreshView: refreshViewAction})(
    AutoRefundBtn
);
