import React, {Component} from 'react';
import {
    Dialog,
    CardActions,
    DialogActions,
    Button,
    DialogTitle,
    DialogContent,
    TableRow,
    TableCell
} from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import EditIcon from '@material-ui/icons/Edit';
import * as _ from 'lodash'
import {
    SimpleForm,
    NumberInput,
    BooleanInput,
    TextInput,
    UPDATE, GET_ONE,
    refreshView as refreshViewAction, SelectInput, required
} from "react-admin";
import laravelJsonServerClient from '../../restClient'
import {connect} from 'react-redux';
import {defaultReSource, authorized} from '../../authorized'

/**
 *
 */
class EditUserBtn extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            open: false,
            dialog: false,
            values: null,
            allowSubmit: true,
            roles: '',
            permissions: '',
            data: ''
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.validate = this.validate.bind(this);
    };

    /**
     *
     * @param value
     */
    validate(value) {
        this.setState({values: value});
    }

    /**
     *
     */
    fetchData() {
        laravelJsonServerClient(GET_ONE, 'user', {id: this.props.record.id}).then(r => {
            this.setState({
                data: r.data ? r.data : [],
            });
        });
    }

    /**
     *
     */
    handleSubmit() {
        const values = this.state.values;
        laravelJsonServerClient(UPDATE, 'user/role', {id: this.props.record.id, data: values}).then(
            r => {
                if (r.data.result) {
                    this.handleClose();
                    this.props.refreshView();
                } else {
                    this.handleClose();
                    alert(r.data.message);
                }
            }
        ).catch((e) => {
            console.error(e);
        });
    }

    /**
     *
     */
    showModal = () => {
        this.fetchData();
        this.setState({show: true});
    };

    /**
     *
     */
    hideModal = () => {
        this.setState({show: false});
    };

    /**
     *
     */
    handleClose = () => {
        this.setState({show: false});
    };

    render() {
        let record = this.props.record;
        const actions = [
            <Button
                label="Submit"
                key={'secondary'}
                primary="true"
                variant={"outlined"} color={"secondary"}
                size={"small"}
                startIcon={<SaveIcon/>}
                onClick={this.handleSubmit}>Submit</Button>,
            <Button
                label="Dismiss"
                primary="true"
                key={'primary'}
                size={"small"}
                color={"primary"}
                variant="outlined"
                onClick={this.handleClose}>Dismiss</Button>
        ];

        let buttonEdit = '';
        if(record){
            buttonEdit =  <Button
                label=""
                size="small"
                style={{overflow: 'inherit', color: '#000'}}
                onClick={this.showModal}
                startIcon={<EditIcon/>} >
                Edit
            </Button>
        }


        return (
            <main>
                {buttonEdit}
                <Dialog
                    bodyClassName={"modal-edit-address"}
                    title={record.email}
                    actions={actions}
                    modal={true}
                    open={this.state.show}
                    autoScrollBodyContent={true}
                >
                    <DialogTitle id="customized-dialog-title" onClose={this.handleClose}>
                        {record.email}
                    </DialogTitle>
                    <DialogContent dividers>
                        <span>
                            <SimpleForm submitOnEnter={false}
                                        defaultValue={record}
                                        save={this.handleSubmit}
                                        validate={this.validate}
                                        toolbar={<CardActions/>}
                                        handleSubmit={this.handleClose}
                                        redirect={false}>
                                <SelectInput defaultValue={this.state.data.Role} source="role" style={{minHeight: "30px", minWidth: "250px"}}
                                             validate={required()}
                                             choices={this.state.data.ListRoles}
                                             options={{fullWidth: false, className: 'fullWidth-false'}}
                                             optionText="name" optionValue="name"/>
                            </SimpleForm>
                        </span>
                    </DialogContent>
                    <DialogActions>
                        {actions}
                    </DialogActions>
                </Dialog>
            </main>
        );
    }
}

/**
 *
 */
export default connect(undefined, {refreshView: refreshViewAction})(
    EditUserBtn
);

