import React, {Component, useCallback} from 'react';
import {
    Table,
    TableBody,
    Dialog,
    CardActions,
    Button,
    DialogActions,
    DialogTitle,
    DialogContent,
    Snackbar,
    Switch, InputBase, TableHead, TableRow, TableCell
} from '@material-ui/core';
import PropTypes from "prop-types";
import SaveIcon from '@material-ui/icons/Save';
import {
    SimpleForm,
    TextInput,
    required,
    CREATE,
    SelectInput,
    TabbedForm,
    refreshView as refreshViewAction,
    UPDATE,
    BooleanInput,
    NumberInput,
    number,
    minValue,
    FormTab, GET_LIST, maxValue,
} from "react-admin";
import laravelJsonServerClient from '../../../restClient'
import CancelIcon from "@material-ui/icons/Cancel";
import {ReasonforRefund} from "../../../config";
import {connect} from "react-redux";
import swal from "sweetalert";
import {isAllowPartialRefund, isMagentoStore, isRefundAbleStores} from "../../channels";
import {defaultReSource, authorized} from '../../../authorized'

/**
 *
 */
class RefundRequest extends Component {
    constructor(props) {
        super(props);
        this.state = {
            snackbarOpen: false,
            open: false,
            data: null,
            values: null,
            id: '',
            allowSubmit: false,
            roles: '',
            permissions: '',
            isPartialRefund: false,
            numberInputDefaultValue: 0,
            qtyToRefund: 0,
            items: [],
            partialRefundNotAllowStatus: ['exported']
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.validate = this.validate.bind(this);
    }

    fetchData() {
        laravelJsonServerClient(GET_LIST, 'items', {
            filter: {order_id: this.props.record.id},
        }).then(r => {
            this.setState({
                items: r.data,
            });
        });

        let qty = 0;
        this.state.items.forEach((item, k) => {
            qty += item.qty_ordered - item.qty_refunded;
        });
        this.setState({qtyToRefund: qty });
    }

    handleSubmit() {
        const values = Object.assign({}, this.state.values);

        let _this = this;
        _this.setState({open: false});
        let informMsg = 'Are you sure you want to REFUND this order?';
        if (this.state.isPartialRefund) {
            informMsg = 'Are you sure you want to PARTIAL REFUND this order?';
        }

        swal(informMsg,
            {
                title: '#' + _this.props.record.channel_order_no,
                buttons: {
                    cancel: "Cancel",
                    ok: {
                        text: "Ok",
                        value: "catch",
                    }
                },
            }).then(value => {
            switch (value) {
                case "catch":
                    swal("Sending request...", {buttons: false});
                    _this.setState({
                        msg: 'Refunding orders #' + _this.props.record.channel_order_no + '...',
                        allowSubmit: false
                    });
                    laravelJsonServerClient(UPDATE, 'order/refund', {id: _this.props.record.id, data: values}).then(
                        r => {
                            if (r.data.message) {
                                let html = '', wrapper = document.createElement('div');
                                html += r.data.message;
                                wrapper.innerHTML = html;
                                swal(wrapper);
                            } else {
                                swal('Error! An error occurred. Please try again later!');
                            }
                            this.props.refreshView();
                        }
                    ).catch((e) => {
                        this.handleClose();
                        console.error(e);
                    });
                    return true;
                case "cancel":
                    return false;
                default:
                    return false;
            }
        });
    }

    /**
     *
     * @param values
     */
    validate(values) {
        const validate_fields = [
            'id',
            'warranty_ticket_id',
            'warranty_reason',
        ];

        let passed = true;
        const data = {};
        validate_fields.forEach(field => {
            if (values.hasOwnProperty(field)) {
                data[field] = values[field];
                if (!values[field]) {
                    passed = false;
                }
            }
        });

        if (values.hasOwnProperty('ensure')) {
            if (values['ensure'] == false) {
                passed = false;
            }
        }
        data.isPartialRefund = this.state.isPartialRefund;
        var orderGrandTotal = this.props.record.grand_total;
        var dataQty = [];
        if (passed && this.state.isPartialRefund) {
            data.adjustment_fee = values.adjustment_fee;
            if (data.adjustment_fee === "" || data.adjustment_fee < 0 || data.adjustment_fee > orderGrandTotal) {
                passed = false;
            }
            if (values.hasOwnProperty('qty_to_refund')) {
                let checkQty = 0;
                let qtyIsInvalid = 0;
                values['qty_to_refund'].forEach((qty, itemId) => {
                    checkQty += qty;
                    if (qty > 0) {
                        dataQty.push({itemId: itemId, qty: qty});
                    }

                    this.state.items.forEach((item, k) => {
                        if (item.id === itemId) {
                            if (qty < 0 || qty > (item.qty_ordered - item.qty_refunded)) {
                                qtyIsInvalid++;
                            }
                        }
                    });
                });
                if (checkQty <= 0 || qtyIsInvalid > 0) {
                    passed = false;
                }
            }

            if (this.state.partialRefundNotAllowStatus.includes(this.props.record.status)) {
                passed = false;
            }
        }
        data.qty_to_refund = dataQty;

        this.setState({values: data});
        this.setState({allowSubmit: passed});
    }

    isStatusAllowRefund = (status) => {
        if (['refunded', 'closed'].includes(status)) {
            return false;
        }
        return true;
    }

    handleOpen = () => {
        this.fetchData();
        this.setState({
            open: true,
            isPartialRefund: false,
            numberInputDefaultValue: 0
        });
    };

    handleClose = () => {
        this.setState({open: false});
    };

    handleRequestClose = () => {
        this.setState({
            snackbarOpen: false,
        });
    };

    refundTypeChange = (checked) => {
        let newState = !this.state.isPartialRefund;
        this.setState({isPartialRefund: newState});
    }

    customSource(key, name) {
        return name + '[' + key + ']';
    }

    changeNumberInputDefaultValue = (event) => {
        this.setState({
            numberInputDefaultValue: ""
        });
    }

    render() {
        const title = "Refund Order";
        const titlePartialRefund = "Partial Refund";
        const open = this.state.open;
        const record = this.props.record;
        let invoice = record ? this.props.record.invoice : null;
        const allowSubmit = !this.state.allowSubmit
        const requiredValidator = [required()];
        const items = this.state.items;

        const cancelBtn = <Button
            label="Cancel"
            primary="true"
            disabled={false}
            key={'primary'}
            size={"small"}
            color={"primary"}
            variant="outlined"
            onClick={this.handleClose}
        >Cancel</Button>;
        const submitBtn = <Button
            label="Submit"
            primary="true"
            icon={<SaveIcon/>}
            variant={"outlined"} color={"secondary"}
            size={"small"}
            startIcon={<SaveIcon/>}
            disabled={allowSubmit}
            onClick={this.handleSubmit}
        >Submit</Button>;
        const actions = [submitBtn, cancelBtn];

        const EnsureCheckBox = props => {
            let checkBoxText = '';
            if (props.status == 'exported') {
                checkBoxText = "I confirm that the order was/will be cancelled on 3PL side manually";
            } else if (props.status == 'shipped' || props.status == 'complete') {
                checkBoxText = "I confirm that I refund order which was/will be returned by the customer";
            } else {
                return ('')
            }

            return (
                <BooleanInput style={{width: "100%"}} label={checkBoxText} source="ensure" defaultValue={false}/>
            );
        }

        /**
         *
         * @param props
         * @returns {boolean}
         */
        const canRefund = props => {
            let invoice = record ? props.invoice : null;
            if (isRefundAbleStores(props.channel_id) && invoice != null && this.isStatusAllowRefund(props.internal_status)) {
                return true;
            }
            return false;
        }

        const DialogBar = () => {
            if (isAllowPartialRefund(record.channel_id)) {
                return (<span>
                    <label className={this.state.isPartialRefund ? 'text-disabled' : ''}>{title}</label>
                    <Switch onClick={this.refundTypeChange} checked={this.state.isPartialRefund}/>
                    <label className={!this.state.isPartialRefund ? 'text-disabled' : ''}>{titlePartialRefund}</label>
                </span>);
            } else {
                return (<label>{title}</label>);
            }
        }

        return ((record && canRefund(record) && authorized.permissions.indexOf('order.refund.create') !== -1) ?
                <span>
                <Dialog
                    bodyClassName={"modal-edit-address"}
                    title={title}
                    actions={actions}
                    maxWidth={'sm'}
                    modal={true}
                    fullWidth={'true'}
                    open={open}
                    autoScrollBodyContent={true}
                >
                <DialogTitle id="customized-dialog-title" onClose={this.handleClose}>
                    <DialogBar/>
                </DialogTitle>
                <DialogContent dividers>
                <span>
                    <SimpleForm submitOnEnter={false}
                                validate={this.validate}
                                invalid={false}
                                defaultValue={record}
                                save={this.handleSubmit}
                                toolbar={<CardActions/>} handleSubmit={this.handleClose}
                                redirect={false}
                                className="form-refund"
                    >
                        <TextInput style={{display: 'none'}} disabled source="id"/>
                        {(this.state.isPartialRefund && this.state.partialRefundNotAllowStatus.includes(record.status)) ?
                            ("") :
                            (<div>
                                <SelectInput name="warranty_reason" isRequired={true} label="Refund Reason"
                                             source="warranty_reason"
                                             choices={ReasonforRefund}
                                             optionValue="name"/>
                                <NumberInput label={"Ticket ID"} source="warranty_ticket_id"
                                             validate={[number(), minValue(1), required()]}
                                             className={"custom_number_input"}/>
                            </div>)}
                        {this.state.isPartialRefund ? (<div className={""}>
                            {this.state.partialRefundNotAllowStatus.includes(record.status) ? (
                                <p className="text-danger"><i>*Please take a note that partial refund won’t be to cancel
                                    an order on the warehouse side, please cancel it manually or contact the warehouse
                                    to cancel.</i></p>
                            ) : (
                                <main>
                                    <Table selectable="false" className="tbl_tab3">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>&nbsp;</TableCell>
                                                <TableCell className="px-10">Item SKU</TableCell>
                                                <TableCell>Qty</TableCell>
                                                <TableCell>Qty to Refund</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {items.map((item, key) =>
                                                item.status !== "internal_canceled" ? (
                                                    <TableRow>
                                                        <TableCell>
                                                            <p>{item.sku}</p>
                                                        </TableCell>
                                                        <TableCell>
                                                            <p className="my-0">Qty Ordered: {item.qty_ordered}</p>
                                                            <p className="my-0">Qty Invoiced: {item.qty_invoiced}</p>
                                                            <p className="my-0">Qty Refunded: {item.qty_refunded}</p>
                                                        </TableCell>
                                                        <TableCell>
                                                            <NumberInput label={false}
                                                                         source={this.customSource(item.id, 'qty_to_refund')}
                                                                         name={this.customSource(item.id, 'qty_to_refund')}
                                                                         validate={[number(), minValue(0), maxValue(item.qty_ordered - item.qty_refunded), required()]}
                                                                         initialValue={item.qty_ordered - item.qty_refunded}
                                                                         className={"custom_number_input"}
                                                                         disabled={(item.qty_refunded >= item.qty_ordered) ? true : false}
                                                            />
                                                        </TableCell>
                                                    </TableRow>) : ("")
                                            )}
                                        </TableBody>
                                    </Table>

                                    <br/>
                                    <NumberInput name="adjustment_fee" isRequired={true} label="Adjustment fee"
                                                 source="adjustment_fee"
                                                 validate={[number(), minValue(0), maxValue(this.props.record.grand_total), required()]}
                                                 min="0" max={this.props.record.grand_total}
                                                 onChange={evt => this.changeNumberInputDefaultValue(evt)}
                                                 defaultValue={this.state.numberInputDefaultValue}
                                                 className={"custom_number_input"}
                                    />
                                </main>
                            )}
                        </div>) : ("")}
                        {this.state.isPartialRefund && this.state.partialRefundNotAllowStatus.includes(record.status) ? "" :
                            <EnsureCheckBox status={record.internal_status}/>}

                    </SimpleForm>
                </span>
                </DialogContent>
                <DialogActions>
                    {actions}
                </DialogActions>
            </Dialog>
            <Button className="px-5" size={"small"}
                    variant="contained"
                    color="secondary"
                    style={styles.button}
                    onClick={this.handleOpen} icon={<CancelIcon/>}
                    label='REFUND ORDER'>REFUND ORDER</Button>
            <Snackbar
                open={this.state.snackbarOpen}
                message="Created Warranty Claims."
                autoHideDuration={5000}
                onRequestClose={this.handleRequestClose}
                onClose={this.handleRequestClose}
            />
            </span> : ''
        );
    }
}

const styles = {
    button: {
        marginLeft: 5,
        marginRight: 5,
        color: '#fff',
        backgroundColor: 'rgb(220, 0, 78)'
    },
};

RefundRequest.propTypes = {
    record: PropTypes.object,
    open: PropTypes.any,
    hasEdit: PropTypes.any,
    hasList: PropTypes.any,
    hasShow: PropTypes.any,
};

const EditActions = () => (
    <CardActions/>
);

export default connect(undefined, {refreshView: refreshViewAction})(
    RefundRequest
);
