import React, { Component } from 'react';
import {Snackbar} from '@material-ui/core/';
import PropTypes from "prop-types";


export class OMSNotification extends Component {

    constructor(props) {
        super(props);
    };

    handleRequestClose = () => {
        this.setState({
            open: false,
        });
    };

    render() {
        const { open, message, action, duration, onClick } = this.props;
        return (
            <div>
                <Snackbar
                    open={open}
                    message={message}
                    action={action}
                    autoHideDuration={duration}
                />
            </div>
        );
    }
}

OMSNotification.propTypes = {
    open: PropTypes.bool,
    message: PropTypes.string,
    action: PropTypes.any,
    duration: PropTypes.number,
    onClick: PropTypes.any,
    autoHideDuration: PropTypes.bool
};

OMSNotification.defaultProps = {
    duration: 4000,
};

export default OMSNotification